<nb-card class="serving_size_container" #SelectionLayout1
  [ngClass]="ShowOK == true ? 'nb_card_pop_up meal_item mobilePopup' : ''">

  <nb-card-header [hidden]="!ShowOK">
    <div class="title">
      <ng-container i18n="@@AddNewItemToBlock">Edit Item</ng-container>
    </div>
    <div class="actions">
      <!--<button i18n="@@AddNewItem" type="submit" style="width:120px;z-index: 1000;" class="btn-circle btn-xl btn-main StandardGreenButton" (click)="onNewCustomMealItem()">Add New Item</button>-->

      <button nbButton ghost tabindex="-1">
        <nb-icon icon="close-outline" (click)="CloseWithNoSave()" style="z-index: 1000;"></nb-icon>
      </button>

    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="position: absolute;right:0;top:0px" *ngIf="AssociatedObject && AssociatedObject.selected">
      <nb-checkbox readonly [checked]=true></nb-checkbox>
    </div>
    <div class="row serving_size_item" style="justify-content: center;" *ngIf="value">
      <div>
        <div class="serving_size_image" *ngIf="!HasNoImage">
          <img src="{{ItemImage}}">

          <div *ngIf="!value.isCustomServingSize">

            <div *ngIf="ShowPhotoControls()"
              style="float:right; background-color:rgba(255, 255, 255, 0.6); width:70px; border-bottom-left-radius:10px;">

              <a href="javascript:void(0);" class="action">
                <nb-icon icon="minus-circle-outline" style="width:35px; height:35px;" (click)="DecreaseServingSize()">
                </nb-icon>
              </a>

              <a href="javascript:void(0);" class="action">
                <nb-icon icon="plus-circle-outline" style="width: 35px; height: 35px;" (click)="IncreaseServingSize()">
                </nb-icon>
              </a>

            </div>

          </div>

          <div *ngIf="ItemQuantity != 1 && ShowPhotoControls()" class="QuantityCircle CircleLayout">
            <div>x<span class="quantity" [innerHTML]="ItemQuantity"></span></div>
          </div>

          <div class="custom-serving-size-overlay" *ngIf="value.isCustomServingSize">
            <span>{{value.customServingSize | gramConverter : ConversionFactorsValue}}</span>
          </div>

        </div>

      </div>
      <div class="item-description" style="margin-top: 10px;">
        <span style="font-size: 20px; font-weight:600">{{Name}}</span>


        <div style="display:flex" class="item-title-margin">
          <div *ngIf="!this.isQuickAdd">
            <div i18n="@@ServingSize">Serving Size </div>
            <div class="serving-size-selection" style="max-width:200px;">
              <select style="height:25px; min-width: 125px;" [(ngModel)]="selectedValue"
                (ngModelChange)='ServingSizeChanged($event)' *ngIf="!value.isCustomServingSize">
                <option *ngFor="let selection of Selections" [ngValue]="selection">{{selection.selectName}}</option>
              </select>
              <input style="max-width:50px;" type="number" [(ngModel)]="CustomServingSize"
                (change)="onCustomServingSizeChanged()" min="1" *ngIf="value.isCustomServingSize">
            </div>
          </div>

          <div style="margin-left: 10px;">
            <div i18n="@@Quantity">Quantity</div>
            <div style="margin:0px;">
              <div style="float:left;">
                <input type="number" class="NoPadding" nbInput style="height: 25px;width: 50px"
                  [disabled]="value.isCustomServingSize" [(ngModel)]="ItemQuantity" (change)="onQuantityServingSizeChanged()"
                  min=".1" />
              </div>

            </div>



          </div>

        </div>
        <div class="d-flex">
          <div *ngIf="!this.isQuickAdd">
            <div>Units</div>
            <div class="serving-size-selection" style="max-width:200px;">
              <select *ngIf="ConversionFactorsValue" style="height:25px;" [(ngModel)]="ConversionFactorsValue"
                (ngModelChange)="ConversionFactorsValueChange($event)">
                <option *ngFor="let selection of ConversionFactors" [ngValue]="selection.value">{{selection.text}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="!this.isQuickAdd" style="font-size:x-small;">
            <span *ngIf="ItemQuantity != 1">
              (Total {{TotalGrams | gramConverter : ConversionFactorsValue}})

            </span>
          </div>

          <nb-checkbox *ngIf="!this.isQuickAdd" i18n="@@ManuallyEnterServingSize" [(ngModel)]="value.isCustomServingSize"
            (change)="onCustomServingSizeChanged($event)"><span style="font-size:x-small;">Manually enter portion
              weight</span>
          </nb-checkbox>
        </div>


      </div>
      <div>
        <div style="height: 85px !important;">
          <app-meal-metrics-graph [MealCals]="CaloriesRendered" [MealProtein]="ProteinRendered"
            [MealNetCarbs]="NetCarbsRendered" [MealCarbs]="CarbsRendered" [MealFats]="TotalFatRendered"
            [GraphInBack]="true"></app-meal-metrics-graph>
        </div>

        <div style="max-width: 180px;">
          <span style="font-size: 8pt; color: #424242;">Source: </span><span style="font-size: 8pt; color: #424242;"
            [innerHTML]="InfoSource"></span> -
          <span style="font-size: 8pt; color: #424242;">
            <a href="javascript:void(0)" (click)="onClickNutritionalFacts()">Nutritional facts</a>
          </span>
        </div>
        <br />

        <button *ngIf="!ShowOK" style="width: 180px;" nbButton type="submit"
          class="btn-circle btn-xl btn-main button_add_to_plan" (click)="ClickButton()" i18n="@@AddToPlan">Add Food
          Item</button>
        <a href='javascript:void(0)' (click)="ClickDeleteButton()"
          *ngIf="!ShowOK && value?.isCustomFood && value?.AssociatedObject?.patientId">
          <i class="far fa-trash-alt action-icon mx-2"></i>
        </a>

      </div>
    </div>
  </nb-card-body>
  <nb-card-footer [hidden]="!ShowOK || ViewOnly">
    <table>
      <tr>
        <td>
          <button i18n="@@Save" nbButton class="btn-circle btn-xl btn-main" (click)="Close()">Save</button>
        </td>
        <td>
          <button i18n="@@Remove" nbButton class="back btn-circle btn-xl" (click)="Remove()">
            Remove <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </td>
      </tr>
    </table>
  </nb-card-footer>


</nb-card>