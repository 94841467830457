import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoicesService } from 'app/@core/backend/common/services/CalorieFriend/invoices.service';
import {
  InvoiceFilter,
  InvoicePaymentStatus,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import {
  InvoiceFormComponent,
  RecurringInvoiceFormComponent,
} from './invoice-form/invoice-form.component';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import { flatMap, switchMap, tap } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';
import { InvoicesTableComponent } from './invoices-table/invoices-table.component';
import { RecurringInvoicesService } from 'app/@core/backend/common/services/CalorieFriend/recurring-invoices.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  paymentStatus$: Observable<InvoicePaymentStatus>;
  private reload$ = new Subject<void>();

  constructor(
    private invoiceService: InvoicesService,
    private recurringInvoiceService: RecurringInvoicesService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {
    this.paymentStatus$ = this.reload$.pipe(
      switchMap(() => {
      return this.invoiceService.getPaymentStatus()
    })
    );
  }

  ngOnInit() {
    setTimeout(()=>{
      this.reloadData();
    }, 2000);
  }

  @ViewChild('invoiceTable')
  invoiceTable: InvoicesTableComponent;
  @ViewChild('recurrentTable')
  recurrentTable: InvoicesTableComponent;

  /**
   * !This function should be arrow functions
   * @param filter filter for querying invoices
   * @returns
   */
  loadInvoices = (filter: InvoiceFilter, recurrent = false) => {
    if (recurrent) return this.recurringInvoiceService.list(filter);
    return this.invoiceService.list(filter);
  };

  createInvoice(recurrent: boolean) {
    let ref: NbDialogRef<any>;
    if (recurrent) {
      ref = this.dialogService.open(RecurringInvoiceFormComponent, {
        context: { editable: true },
        closeOnBackdropClick: false,
      });
    } else {
      ref = this.dialogService.open(InvoiceFormComponent, {
        context: { editable: true },
        closeOnBackdropClick: false,
      });
    }
    ref.onClose.subscribe({
      next: (saved: boolean) => {
        if (saved) {
          if (!recurrent) this.invoiceTable?.refresh();
          else this.recurrentTable?.refresh();
          this.reloadData();
        }
      },
    });
  }

  onViewEdit({ id = 0, editable = false }, recurrent: boolean) {
    let ref: NbDialogRef<any>;
    if (recurrent) {
      ref = this.dialogService.open(RecurringInvoiceFormComponent, {
        context: { invoiceId: id, editable },
        closeOnBackdropClick: false,
      });
    } else {
      ref = this.dialogService.open(InvoiceFormComponent, {
        context: { invoiceId: id, editable },
        closeOnBackdropClick: false,
      });
    }
    ref.onClose.subscribe({
      next: (saved: boolean) => {
        if (saved) {
          if (!recurrent) this.invoiceTable?.refresh();
          else this.recurrentTable?.refresh();
          this.reloadData();
        }
      },
    });
  }

  /**
   * ! This function should be an arrow function because it can be called from other context
   * @param id Invoice Id
   */
  sendInvoice = (id: number) => {
    return this.invoiceService.send(id).pipe(tap({
      next: (res) => {
        if (res) {
          this.toastrService.success('', 'Invoice sent successfully');
        } else this.toastrService.danger('', 'Sending failed');
      },
    }));
  };

  /**
   * ! This function should be an arrow function because it can be called from other context
   */
  deleteInvoice = (id: number, recurrent: boolean) => {
    return this.dialogService
      .open(ConfirmMessageDialogComponent, {
        closeOnEsc: true,
        closeOnBackdropClick: true,
        context: { Message: 'Are you sure wants to delete this invoice?' },
      })
      .onClose.pipe(
        flatMap((confirmed: boolean) => {
          if (confirmed) {
            return (
              recurrent ? this.recurringInvoiceService : this.invoiceService
            )
              .delete(id)
              .pipe(
                tap((res) => {
                  if (res) {
                    this.toastrService.success('', 'Item deleted!');
                    this.reloadData();
                  } else {
                    this.toastrService.danger('', 'Something wrong.');
                  }
                })
              );
          }
          return of(false);
        })
      );
  };
  reloadData() {
    this.reload$.next();
  }
}
