<nb-card class="nb-card">
  <nb-card-header>
    <!--{{MealLabel}}-->

    <div class="MealEditingLocation">
      <div class="meal-name">
        <smart-edit #MealNameEdit [(value)]="PlanMeal.name" [readonly]="PlanView" [DisplaySecond]="true"
          [CallbackThis]="this" [(SecondDisplayCallback)]="UpdateMealLabel" (keyup)="MealNameChange($event)">
        </smart-edit>
      </div>
      <div class="meal-time">
        <ng-container *ngIf="PlanView">
          <span>{{PlanMeal.time}}</span>
        </ng-container>
        <ng-container *ngIf="!PlanView">
          <smart-time-edit [(ngModel)]="PlanMeal.time"></smart-time-edit>
        </ng-container>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body style="padding:0 0 !important; overflow: hidden;">

    <div class="row">
      <div class="col-lg-9 col-md-12">
        <app-meal-items-presentation #MealItems [Patient]="Patient" [DiaryPatientId]="DiaryPatientId"  [UserId]="UserId" [PlanMeal]="PlanMeal" [PlanView]="PlanView">
        </app-meal-items-presentation>
      </div>
      <div class="col-lg-3 col-md-12">
        <app-meal-metrics-graph #MealGraph class="Metrics" [MealCals]="MealCals" [MealProtein]="MealProtein"
          [MealCarbs]="MealCarbs" [MealNetCarbs]="MealNetCarbs" [MealFats]="MealFats" GraphInBack="true">
        </app-meal-metrics-graph>
      </div>
    </div>

  </nb-card-body>
</nb-card>
