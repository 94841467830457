import { Component, Input, OnInit } from '@angular/core';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';

@Component({
  selector: 'app-macro-goals-progress',
  templateUrl: './macro-goals-progress.component.html',
  styleUrls: ['./macro-goals-progress.component.scss']
})
export class MacroGoalsProgressComponent implements OnInit {

  @Input("Patient")
  patient: Patient;

  @Input("Cals")
  cals = 0;
  
  @Input("Protein")
  protein = 0;
  
  @Input("Carbs")
  carbs = 0;

  @Input("Fats")
  fats = 0;

  constructor() { }

  ngOnInit() {

  }

  get goalCalories() {
    return this.patient?.calories || 1e-3;
  }
  get goalProtein() {
    return (this.patient?.calories / 100 * this.patient?.protein / 4) || 1e-3;
  }
  get goalCarbs() {
    return ((this.patient?.calories / 100) * this.patient?.carbs) / 4 || 1e-3;
  }
  get goalFats() {
    return ((this.patient?.calories / 100) * this.patient?.fats) / 9 || 1e-3;
  }
}
