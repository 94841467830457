<div>
  <div>
    <div style="position:relative">
      <div [ngClass]="IsPatientView == true ? 'item-image-outside-patientview' : 'item-image-outside'">
        <div class="item-image-inside">
          <div class="item-image-wrapper">
            <img id="MealItemImage" class="item-image" [src]='ServingSize.ItemImage' (click)="onClickImage()" />


            <div class="custom-serving-size-overlay" *ngIf="Item.isCustomServingSize &&  !IsQuickAdd">
              <span>{{gramConvertor(Item.customServingSize,units)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="QuantityCircle CircleLayout" [hidden]="!ShowQuanitity">
        <div>x<span class="quantity" [innerHTML]="ItemQuantity"></span></div>
      </div>
    </div>

    <div class="OverlaySelectionFormat OverlayLocation" [hidden]="!OverlaySelection">
      <div>
        <a href="javascript:void(0);" class="action">
          <nb-icon icon="minus-circle-outline"></nb-icon>
        </a>
      </div>
      <div class="OverlaySelectionFont">
        {{CurrentSelection}}
      </div>
      <div>
        <a href="javascript:void(0);" class="action">
          <nb-icon icon="plus-circle-outline"></nb-icon>
        </a>
      </div>
    </div>
  </div>

  <ul class="food_list_description">
    <li [hidden]="OverlaySelection">
      <div class="item-name" [innerHTML]="ItemSelection"></div>
    </li>
    <li>
      <div class="item-portion" [innerHTML]="PortionSelectionText" *ngIf="!IsQuickAdd"></div>
    </li>
    <li>
      <div class="item-quantity" [innerHTML]="ItemQuantityTimes" *ngIf="!IsQuickAdd"></div>
    </li>
  </ul>

</div>
