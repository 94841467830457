import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NbDialogRef, NbToastrService, NbDialogService } from '@nebular/theme';

import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import {
  FitnessPlanData,
  MealPlanData,
} from 'app/@core/interfaces/common/CalorieFriend/plan';
import { DatePipe } from '@angular/common';
import { Utility } from 'app/@core/utils/utility';
import {
  Workout,
  WorkoutDetail,
} from 'app/@core/interfaces/common/CalorieFriend/workout';
import { WorkoutService } from 'app/@core/backend/common/services/CalorieFriend/workout.service';
import { PatientsService } from 'app/@core/backend/common/services/CalorieFriend/patients.service';

type LogDetail = {
  [exNo: string]: WorkoutDetail[];
};

@Component({
  selector: 'app-workout-logs-modal',
  templateUrl: './workout-logs-modal.component.html',
  styleUrls: ['./workout-logs-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class WorkoutLogsModalComponent {
  @Input('workoutId')
  workoutId: number;
  workoutDetails: WorkoutDetail[];
  displayedColumns: string[] = ['id','exerciseName', 'startDateTime', 'endDateTime', 'status'];
  workout: Workout;
  logDetails: LogDetail;
  @Input('patient')
  patient: Patient;

  constructor(
    private ref: NbDialogRef<WorkoutLogsModalComponent>,
    private workoutService: WorkoutService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    this.workoutDetails = await this.workoutService.getDetailsAll(this.workoutId);
    this.workout = await this.workoutService.getWorkout(this.workoutId);

    this.workoutDetails = this.workoutDetails.map(x => ({
      ...x,
      exerciseLog: JSON.parse(x.exerciseLog?.snapshot || 'null'),
    }));
    let blockIndex = -1,
      itemIndex = 0;
    const details: LogDetail = {};
    for (let i = 0; i < this.workoutDetails.length; i++) {
      const prevW = i > 0 && this.workoutDetails[i - 1];
      const w = this.workoutDetails[i];
      if (w.blockId != prevW?.blockId) {
        blockIndex++;
        itemIndex = 0;
      }
      if (prevW?.isCircuitSetNumber != w.isCircuitSetNumber) {
        itemIndex = 0;
      }
      ++itemIndex;
      let exNo = w.exerciseName? w.exerciseName: `${String.fromCharCode(0x41 + blockIndex)}${itemIndex}`;

      if (w.isCircuitSetNumber == 0 || !details[exNo]) {
        details[exNo] = [];
      }
      details[exNo].push(w);
    }
    console.log('details', this.workoutDetails);
    this.logDetails = details;

    this.cdRef.detectChanges();
  }

  get workoutStart() {
    return new Date(this.workout?.startDateTime + 'Z').toLocaleString();
  }
  get workoutDuration() {
    if (!this.workout) return 0;
    return Utility.getTimeDiffDisplayString(
      this.workout?.startDateTime,
      this.workout?.endDateTime,
      true
    );
  }

  get logDetailKeys() {
    if (!this.logDetails) return [];
    return Object.keys(this.logDetails);
  }

  onClose() {
    this.ref.close();
  }
}
