<div class="MealLayout">
  <table>
    <tr>
      <td class="MealTitle">
        {{MealLabel}}
        <hr />
      </td>
    </tr>
    <tr>
      <td>
        <table>
          <tr>
            <td style="min-width:1000px; max-width:1000px">
              <app-meal-items-report-presentation [PlanMeal]="PlanMeal" [PlanView]="PlanView">
              </app-meal-items-report-presentation>
            </td>
            <td style="width:50px"></td>
            <td>
              <app-meal-metrics-graph class="Metrics" [MealCals]="MealCals" [MealProtein]="MealProtein"
                [MealCarbs]="MealCarbs" [MealFats]="MealFats" GraphInBack="true"></app-meal-metrics-graph>
            </td>
          </tr>
        </table>
      </td>
    </tr>

  </table>

</div>
