<nb-card>
  <nb-card-header>
    <div class="title">
      <span>{{ Title }}</span>
    </div>
  </nb-card-header>

  <nb-card-body>
    {{ Message }}
  </nb-card-body>

  <nb-card-footer>
    <div class="actions float-right">
      <button
        i18n="@@Ok"
        nbButton
        class="btn-circle btn-xl btn-main"
        type="submit"
        (click)="onConfirm()"
      >
        Ok
      </button>
      <button
        i18n="@@Cancel"
        nbButton
        class="back btn-circle btn-xl"
        type="submit"
        (click)="onCancel()"
        *ngIf="!isAlert"
      >
        Cancel
      </button>
    </div>
  </nb-card-footer>
</nb-card>
