import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NbRoleProvider } from '@nebular/security';
import { ROLES } from '../roles';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private roleProvider: NbRoleProvider) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.roleProvider.getRole().pipe(
      map((role) => {
        const roles = role instanceof Array ? role : [role];
        return roles.some((x) => x && x.toLowerCase() === ROLES.ADMIN);
      }),
    );
  }
}
