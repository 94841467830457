import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewEncapsulation,
  ComponentRef,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { format } from 'support';

import { BaseComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import { MealItem } from '../../../../../../../@core/interfaces/common/CalorieFriend/meal';
import { FoodData, FoodItemModel } from '../../../../../../../@core/interfaces/common/CalorieFriend/food';
import { ServingSizeSelectionComponent } from '../../ServingSize/Selection/serving-size-selection.component';
import { OverlayMealItemPresentationComponent } from './overlay-meal-item-presentation/overlay-meal-item-presentation.component';
import { GramConverterPipe } from 'app/@theme/pipes';
import { UserStore } from 'app/@core/stores/user.store';

/**
 * Abstract class to decalre the type to sharing MealItem object.
 */
export abstract class PassingStruct {
  Item: MealItem;
}

/**
 * Interface for selection options of the food items.
 */
export interface SelectionOption {
  selectName: string;
  selectPortion: string;
  selectIndex: number;
  urlImage: string;
  urlLargeImage: string;
  current: FoodItemModel;
  totalGram: number;
}

/**
 * Base component for individual Meal/Food Item among the items list of one Meal Set presentation components.
 */
export class MealItemPresentationComponentBase extends BaseComponentCanDeactivate implements OnInit {
  ItemQuantity = '';
  ShowQuanitity = false;
  CurrentSelection = '';
  FatPercent = '';
  SatFatPercent = '';
  CholesterolPercent = '';
  SodiumPercent = '';
  CarbPercent = '';
  FiberPercent = '';

  AboutLabel = $localize`:@@SmallAbout:about`;

  Math = Math;

  @Input('MealItem') Item: MealItem;
  @Input('OverlayMealItem') OverlayItem: MealItem = null;
  @Input('UpdateCallBack') UpdateCallBack = null;
  @Input('OverlaySelection') OverlaySelection = false;

  @Input('IsPatientView') IsPatientView: boolean = false;
  @Input('DeleteCallBack') DeleteCallBack = null;

  @Input('IsViewOnly') IsViewOnly: boolean = false;

  ServingSize: ServingSizeSelectionComponent;
  protected readonly unsubscribe$ = new Subject<void>();
  private gramConverterPipe: GramConverterPipe = new GramConverterPipe();

  ItemSelection = '';
  PortionSelection = '';
  PortionSelectionWithQuantity = '';
  InfoSource = '';
  IsQuickAdd = false;

  VisualStyle = '';
  Updating: boolean = false;

  constructor(
    private foodService: FoodData,
    private chdetRef: ChangeDetectorRef,
    private dialogService: NbDialogService,
    public userStore: UserStore
  ) {
    super(dialogService);
  }

  public gramConvertor(value: number, unit: string) {
    return this.gramConverterPipe.transform(value, unit);
  }
  @Output() onItemChange = new EventEmitter();

  public get itemQuantityTimes(): string {
    let ItemQuantityTimes = '';
    this.IsQuickAdd = this.Item.isQuickAdd;
    if (this.Item.isCustomServingSize) {
      // This.ItemQuantityTimes = '';
      // this.gramConverterPipe.transform( this.Item.customServingSize.toFixed(2))
      ItemQuantityTimes = format('{0}.', this.gramConvertor(this.Item.totalGrams, this.units));
    } else {
      if (
        !this.Item.Current?.foodItem.isCustomFood &&
        this.Item.Current?.foodItem.units != null &&
        this.Item.Current.foodItem.units !== undefined &&
        this.Item.Current.foodItem.units.length > 0
      ) {
        if (this.Item.quantity === 1) {
          ItemQuantityTimes = format('{0}.', this.gramConvertor(this.Item.totalGrams, this.units));
        } else {
          ItemQuantityTimes = format(
            '{0}x of portion (total {1}.)',
            this.Item.quantity.toString(),
            this.gramConvertor(this.Item.totalGrams, this.units)
          );
        }
      } else {
        // it has not units
        ItemQuantityTimes = format('{0}x of portion', this.Item.quantity.toString());
      }
    }
    return ItemQuantityTimes;
  }

  public get units(): string {
    return this.Item?.conversionFactorsValue? this.Item?.conversionFactorsValue : 'g';
  }

  public get PortionSelectionText(): string {
    if(this.Item.isCustomServingSize) return `Custom Serving Size<br/>
    ${this.gramConvertor(Math.round(this.Item.customServingSize),this.Item.conversionFactorsValue)}`;
    if(this.Item.quantity > 1) {
      return `${this.Item.Current?.currentFriendlyPortionSize} (${this.gramConvertor(Math.round(this.Item.Current?.foodItem?.photoIncrementInGram * (this.Item.selectedServingSizeIndex + 1))
        ,this.Item.conversionFactorsValue)})<br>
      ${format(
        '<span class=\'quantity-grams\'>{0}x of portion (total {1}.)</span>',
        this.Item.quantity,
        this.gramConvertor(Math.round((this.Item.Current?.foodItem?.photoIncrementInGram * (this.Item.selectedServingSizeIndex + 1))*this.Item.quantity)
          ,this.Item.conversionFactorsValue),
      )}`;
    } 
    if(this.Item.Current?.foodItemUnit?.weight) return `${this.Item.Current?.currentFriendlyPortionSize} (${this.gramConvertor(Math.round(this.Item.Current?.foodItem?.photoIncrementInGram * (this.Item.selectedServingSizeIndex + 1))
      ,this.Item.conversionFactorsValue)})`;
    else return this.gramConvertor(this.Item.totalGrams,this.Item.conversionFactorsValue) as string;
  }

  /**
   * Initializes the component.
   * Sets the event handlers and subscribers for serving size changes.
   */
  ngOnInit(): void {
    console.log(this.Item)
    if (this.OverlayItem != null && this.OverlayItem !== undefined) {
      this.Item = this.OverlayItem;
      this.OverlaySelection = true;
    }

    this.ServingSize = new ServingSizeSelectionComponent(
      this.foodService,
      this.chdetRef,
      this.dialogService,
      null,
      this.userStore
    );

    const self = this;
    this.ServingSize.Update = () => this.ServingUpdate(self);
    this.ServingSize.CallerThis = this;

    this.ServingSize.value = this.Item;
    this.ServingSize.PrepareSelection();

    // if (this.Item.Update == null || this.Item.Update == undefined)
    //     this.Item.Update = new Subject();

    if (this.Item.Update != null && this.Item.Update !== undefined) {
      const Current = this;
      this.Item.Update.subscribe((data) => {
        Current.ServingSize.ItemQuantity = Current.Item.quantity;
        Current.ServingSize.TotalGrams = Current.Item.totalGrams;

        this.ServingUpdate(Current);
      });
    }

    //this is pure non-sense.

    if (this.Item && this.Item.Current) {
      this.InfoSource = this.GetFoodSource(this.Item.Current.foodItem);
    } else {
      this.InfoSource = this.GetFoodSource(null);
    }
  }

  GetFoodSource(item): string {
    let result: string = 'Unknown Source';
    try {
      if (item.foodItemDataSource === 'usda') {
        result = 'USDA';
      } else {
        result = item.source;
      }
      if (result.toLowerCase().indexOf('usda') >= 0) {
        result = "<a href='https://fdc.nal.usda.gov/' target='_blank'>USDA FoodCentral</a>";
      }
    } catch {}
    return result;
  }

  /**
   * Method to update the serving sizes information.
   * Gathers the food information including serving sizes using API service and prepares the data for representation.
   * @param This Self referencing object
   */
  private ServingUpdate(This) {
    if (!This.Updating) {
      This.PrepareSelection(This);

      This.ItemQuantity = format('{0}', this.Item.quantity.toString()); //  $localize`:@@ServingSizeTimes:times`

      This.ShowQuanitity = this.Item.quantity !== 1;

      this.chdetRef.detectChanges();

      // if (This.UpdateCallBack != null && This.UpdateCallBack != undefined) This.UpdateCallBack();
    } else This.Updating = false;
  }

  public get portionSelectionGet(): string {
    if (this.Item.Current?.foodItem.isCustomFood) {
      return 'Custom serving size';
    } else {
      return 'Portion';
    }
  }

  /**
   * Prepares the Serving sizes list for selection.
   * @param This Self referencing object
   */
  PrepareSelection(This) {
    const item = this.Item;
    console.log(item);
    // images
    // This.ServingSize.ItemImage = item.Current.currentFoodImage.defaultImage.httpThumbPath;
    // This.ServingSize.ItemLargeImage = item.Current.currentFoodImage.defaultImage.httpPath;
    // if (!This.ServingSize.ItemLargeImage) {
    //   This.ServingSize.ItemImage = "/assets/images/NoImage.png";
    //   This.ServingSize.ItemLargeImage = "/assets/images/NoImage.png";
    // }

    // nutrition data
    this.FatPercent = (
      (item.Current.nutrientsDataItem.fat / item.Current.nutrientsDataItem.fatRecomendedDailyIntake) *
      100
    ).toFixed(2);
    this.SatFatPercent = (
      (item.Current.nutrientsDataItem.saturatedFat / item.Current.nutrientsDataItem.saturatedFatRecomendedDailyIntake) *
      100
    ).toFixed(2);
    this.CholesterolPercent = (
      (item.Current.nutrientsDataItem.cholesterol / item.Current.nutrientsDataItem.cholesterolRecomendedDailyIntake) *
      100
    ).toFixed(2);
    this.SodiumPercent = (
      (item.Current.nutrientsDataItem.sodium / item.Current.nutrientsDataItem.sodiumRecomendedDailyIntake) *
      100
    ).toFixed(2);
    this.CarbPercent = (
      (item.Current.nutrientsDataItem.carb / item.Current.nutrientsDataItem.carbRecomendedDailyIntake) *
      100
    ).toFixed(2);
    this.FiberPercent = (
      (item.Current.nutrientsDataItem.fiber / item.Current.nutrientsDataItem.fiberRecomendedDailyIntake) *
      100
    ).toFixed(2);

    // food information
    This.ItemSelection = item.Current.foodItem.friendlyName;

    if (!item.selectedServingSizeIndex) item.selectedServingSizeIndex = 0;

    const TotalGram = item.totalGrams;
    let PortionText = '';
    let CurrentTotalUnitLabel = '';
    if (
      !item.isCustomServingSize &&
      (item.Current.foodItem.units &&
      item.Current.foodItem.units.length > 0)
    ) {
      const CurrentTotalUnit =
        (item.Current.foodItem.units[0]?.defaultUnit * TotalGram)
        // / item.Current.foodItem.units[0].weight;
        
      CurrentTotalUnitLabel = `${format(item.Current.foodItem.units[0]?.unit, item.Current.foodItem.units[0]?.defaultUnit)} ${this.gramConverterPipe.transform(CurrentTotalUnit, this.units)}`;
      // format(
      //   item.Current.foodItem.units[0].unit,
      //   CurrentTotalUnit.toFixed(2),
      // );
    }

    if (CurrentTotalUnitLabel !== '') {
      PortionText = CurrentTotalUnitLabel;
    } else {
      PortionText = `${this.gramConverterPipe.transform(TotalGram, this.units)}`;
    }

    This.PortionSelection = PortionText;
    // format('{0}', PortionText);

    item.totalGrams = item.isCustomServingSize ? item.customServingSize : Math.round(item.quantity * TotalGram);

    This.ItemQuantity = format('{0}', item.quantity.toString()); //  $localize`:@@ServingSizeTimes:times`

    if (item.isCustomServingSize) {
      // This.PortionSelection = format("{0} g.", This.ServingSize.TotalGrams);
      This.PortionSelection = 'Custom Serving Size';
      This.PortionSelectionWithQuantity = format(
        "{0}.<br/><span class='quantity-grams'>Custom Serving Size</span>",
        this.gramConverterPipe.transform(item.totalGrams, this.units)
      );
    } else {
      if (+This.ItemQuantity !== 1) {
        if (
          !item.Current.foodItem.isCustomFood &&
          item.Current.foodItem.units != null &&
          item.Current.foodItem.units !== undefined &&
          item.Current.foodItem.units.length > 0
        ) {
          // has unit
          This.PortionSelection = format('{0}', PortionText);
          This.PortionSelectionWithQuantity = format(
            "{0}<br/><span class='quantity-grams'>{1}x of portion (total {2}.)</span>",
            PortionText,
            This.ItemQuantity,
            this.gramConverterPipe.transform(item.totalGrams, this.units)
          );
        } else {
          // no unit
          This.PortionSelection = format('{0}.', this.gramConverterPipe.transform(item.totalGrams, this.units));
          This.PortionSelectionWithQuantity = format(
            "{0} .<br/><span class='quantity-grams'>{1}x of portion</span>",
            this.gramConverterPipe.transform(item.totalGrams, this.units),
            This.ItemQuantity
          );
        }
      } else {
        This.PortionSelectionWithQuantity = format('{0}', PortionText);
      }
    }

    This.CurrentSelection = This.PortionSelection + ' ' + This.ItemSelection;

    if (This.ItemSelection.length > 23) This.ItemSelection = format('{0}...', This.ItemSelection.substr(0, 23));

    this.chdetRef.detectChanges();
  }
}

/**
 * Component for Meal Item presentation.
 * Inherits from the MealItemPresentationComponentBase component.
 */
@Component({
  selector: 'app-meal-item-presentation',
  templateUrl: './meal-item-presentation.component.html',
  styleUrls: ['./meal-item-presentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MealItemPresentationComponent extends MealItemPresentationComponentBase implements OnInit {
  constructor(
    private PresDialogService: NbDialogService,
    PresFoodService: FoodData,
    PresChdetRef: ChangeDetectorRef,
    userStore: UserStore
  ) {
    super(PresFoodService, PresChdetRef, PresDialogService, userStore);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onClickImage(): void {
    if (this.IsPatientView) {
      this.PresDialogService.open(OverlayMealItemPresentationComponent, {
        hasScroll: false,
        closeOnBackdropClick: true,
        context: { OverlayItem: this.Item },
        dialogClass: 'overlay-meal-item-dialog',
      });
    } else {
      var modal = this.PresDialogService.open(ServingSizeSelectionComponent, {
        closeOnEsc: false,
        dialogClass: 'popup-edit-food',
        closeOnBackdropClick: false,
        context: {
          value: this.Item, // FoundItem,
          ShowOK: true, // dialog contained wtihin another dialog that has dialog movement controls
          ViewOnly: this.IsViewOnly, // dialog contained wtihin another dialog that has dialog movement controls
          ShowDialog: false, // don't display until dynamic move to item location
          RemoveCallBack: this.onDeleteItem.bind(this), // binding callback for remove item action
          SaveCallBack: this.onCloseSaveCallBack.bind(this),
        },
      });

      this.ServingSize = modal.componentRef.instance;
    }
  }

  
  onCloseSaveCallBack(): void {
    if (this.onItemChange != null && this.onItemChange != undefined) this.onItemChange.emit();
  }

  onDeleteItem() {
    if (this.Item && this.DeleteCallBack) {
      this.DeleteCallBack(this.Item);
    }
  }
}
