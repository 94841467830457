import { Component, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Component({
  selector: 'app-landing-page',
  template: '<router-outlet></router-outlet>',
})
export class LandingComponent implements OnInit {
  constructor(
    private accessChecker: NbAccessChecker,
    private authService: NbAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe((authenticated) => {
      if (!authenticated) {
        this.router.navigate(['auth/login']);
      }
      this.accessChecker.isGranted('view', 'users').subscribe((hasAccess) => {
        if (hasAccess) {
          this.router.navigate(['admin']);
        } else {
          this.router.navigate(['pages']);
        }
      });
    });
  }
}
