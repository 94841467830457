import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { NbCalendarRange, NbToastrService } from '@nebular/theme';
import { PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

export abstract class PatientSubscriptionsTableComponentBase<T> implements OnInit {
  protected abstract get displayedColumns();

  @Input()
  loadSubscriptions: (filter: PatientSubscriptionsFilter) => Observable<GridData<T>>;

  @Input()
  patientView?: boolean = false;

  @Input()
  delete: (id: number) => Observable<boolean>;

  @Output('onViewEdit')
  viewEditEmitter: EventEmitter<{ id: number; editable?: boolean }> = new EventEmitter();

  subscriptions: Observable<GridData<T>> = of({
    totalCount: 0,
    items: [],
  });
  contentLoaded = false;
  paging: BehaviorSubject<PageEvent> = new BehaviorSubject({
    pageIndex: 0,
    pageSize: 5,
    length: 0,
  });
  sort: BehaviorSubject<Sort> = new BehaviorSubject({} as Sort);
  search: BehaviorSubject<string> = new BehaviorSubject('');
  showCanceled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  range = new BehaviorSubject<NbCalendarRange<Date>>(null);

  ngOnInit() {
    this.subscriptions = combineLatest([this.paging, this.sort, this.search, this.range, this.showCanceled]).pipe(
      flatMap(([paging, sort, search, range, showCanceled]) => {
        const startIndex = paging.pageSize * paging.pageIndex;
        const filter: PatientSubscriptionsFilter = {
          pageSize: paging.pageSize,
          pageNumber: paging.pageIndex + 1,
          sortBy: sort.active || '',
          orderBy: sort.direction || '',
          search,
          showCanceled
        };
        this.contentLoaded = false;
        return this.loadSubscriptions(filter).pipe(
          map((gridData) => {
            gridData.items = gridData.items.map((item, index) => ({
              ...item,
              ...this.getSubscriptionActions(item),
              index: index + startIndex,
            }));
            return gridData;
          })
        );
      })
    );
    this.subscriptions.subscribe({
      next: () => {
        this.contentLoaded = true;
      },
      error: () => {
        this.contentLoaded = true;
      },
    });
  }
  ngAfterViewInit(): void {}

  onFilterChanged() {}
  onPagingChanged(event: PageEvent) {
    this.paging.next(event);
  }
  onSortChanged(event: Sort) {
    this.sort.next(event);
  }
  onSearchChanged(event: InputEvent) {
    this.search.next((event.target as HTMLInputElement).value);
  }
  onShowCanceledChanged(event: boolean) {
    this.showCanceled.next(event);
  }
  onRangeChanged(event: NbCalendarRange<Date>) {
    this.range.next(event);
  }
  onRangeInputChanged(event: InputEvent) {
    if ((event.target as HTMLInputElement).value == '') {
      this.onRangeChanged(null);
    }
  }

  onView(id: number) {
    this.viewEditEmitter.emit({ id });
  }
  onEdit(id: number) {
    this.viewEditEmitter.emit({ id, editable: true });
  }

  onDelete(id: number, event: MouseEvent) {
    event?.stopPropagation();
    this.delete &&
      this.delete(id).subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.paging.next(this.paging.value);
  }

  /**
   * TODO update function below for further update.
   * @param subscription PatientSubscription object to get actions
   * @returns boolean values showing actions are enabled or not (edit, send, delete etc.)
   */
  protected abstract getSubscriptionActions(subscription: T);
}
