/**
 * Created by Vinicius
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { COLORS, ColorSetting } from 'app/@theme/styles/colors';

@Component({
  selector: 'ngx-inline-color-picker',
  templateUrl: './inline-color-picker.component.html',
  styleUrls: ['./inline-color-picker.component.scss'],
})
export class NgxInlineColorPickerComponent implements OnChanges {
  @Input() checked: number = -1;
  @Input() set default(value: ColorSetting) {
    if (!value) return;
    this.checked = this.COLORS.findIndex(
      (x) => x.primary === value.primary || x.alternative === value.alternative
    );
  }
  @Input() isWaiting: boolean = false;
  @Input() isAppColors: boolean = false;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  COLORS = COLORS;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAppColors?.currentValue) {
      const newColors = [
        { primary: '#ffffff', alternative: '#000000' },
        { primary: '#A9A9A9', alternative: '#ffffff' },
      ];
  
      newColors.forEach(newColor => {
        const colorExists = this.COLORS.some(
          color => color.primary === newColor.primary && color.alternative === newColor.alternative
        );
  
        if (!colorExists) {
          this.COLORS.push(newColor);
        }
      });
    }
  }

  onColor(index: number): void {
    if (this.isWaiting) return;
    this.checked = index;
    this.selected.emit(COLORS[index]);
  }

  private invertColor(hex: string): string {
    if (hex.indexOf('#') === 0) hex = hex.slice(1);

    // convert 3-digit hex to 6-digits.
    if (hex.length === 3)
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

    // invert color components
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }

  private padZero(str: string, len: number = 2): string {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  get iconStyle(): string {
    if (this.checked === -1) return '';
    return (
      'color: white; width: 2em; height: 2em; stroke: ' +
      this.invertColor(COLORS[this.checked].primary)
    );
  }
}
