import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { CookieService } from 'ngx-cookie-service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
} from './utils';

import { InitUserService } from '../@theme/services/init-user.service';
import { CommonBackendModule } from './backend/common/common-backend.module';
import { UserStore } from './stores/user.store';
import { UsersService } from './backend/common/services/users.service';
import { SettingsService } from './backend/common/services/settings.service';
import { PatientStore } from './stores/patient.store';
import { FitnessPlansStore, MealPlansStore } from './stores/plans.store';

export const NB_CORE_PROVIDERS = [
  ...CommonBackendModule.forRoot().providers,

  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
  CookieService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,

        UserStore,
        PatientStore,
        MealPlansStore,
        FitnessPlansStore,

        UsersService,
        InitUserService,
        SettingsService,
      ],
    };
  }
}
