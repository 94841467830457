<div class="container">

  <div class="row">

    <div style="margin-left:auto; margin-right:auto">
      <h2 class="title">Please Log in with your email.</h2>
      <p *ngIf="!this.NativeApp.isFromApp()" class="sub-title">
        <span i18n="@@NewUserClick">New user? </span>
        <a class="text-link" routerLink="/auth/cfregister/free"><span i18n="@@here"> Click here </span></a>
        <span i18n="@@StartYourRegistration">to start your registration.</span>
      </p>
    </div>
  </div>


</div>



<nb-card class="nb-card-auth">

  <nb-card-body>

    <div class="row">

      <div style="width: 100%; margin:auto">

        <div *ngIf="showMessages.error && errors?.length && !submitted" class="message-label"
          style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
          <p><b>Oh snap!</b></p>

            <div *ngFor="let error of errors" class="alert-message">{{ error }}</div>

        </div>

        <div *ngIf="showMessages.success && messages?.length && !submitted" class="message-label"
          style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
          <p><b>Success!</b></p>

            <div *ngFor="let message of messages" class="alert-message">{{ message }}</div>

        </div>

        <div class="loginForm">
          <form [formGroup]="loginForm" aria-labelledby="title" (ngSubmit)="login()">
            <div class="form-control-group">
              <label i18n="@@LoginTitle" for="input-email">Email address:</label>
              <input nbInput fullWidth id="input-email" formControlName="email"
                [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null" i18n-placeholder="@@EmailAddress"
                placeholder="Email address">
              <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
                [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
            </div>

            <div class="form-control-group">
              <span class="label-with-link">
                <label i18n="@@Password" for="input-password">Password</label>
                <a class="forgot-password caption-2" routerLink="../request-password"><span
                    i18n="@@ForgotPassword">Forgot Password?</span></a>
              </span>
              <input nbInput fullWidth id="input-password" formControlName="password" type="password"
                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
                i18n-placeholder="@@Password" placeholder="Password">
              <ngx-validation-message label="Password"
                [showMinLength]="password?.hasError('minlength') && password.touched"
                [showMaxLength]="password?.hasError('maxlength') && password.touched"
                [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
                [maxLength]="maxLength"></ngx-validation-message>
            </div>

            <div class="form-control-group">
              <nb-checkbox i18n="@@RememberMe" class="remember-me" formControlName="rememberMe" name="rememberMe"
                *ngIf="rememberMe">Remember me</nb-checkbox><br>
              <button i18n="@@LogIn" nbButton type="submit" [disabled]="!loginForm.valid || isLoading"
              [nbSpinner]="isLoading"
                [class.btn-pulse]="submitted" class="btn-circle btn-xl btn-main"
                style="width: 100%; margin-top: 5px;">Log In</button>

            </div>

          </form>

        </div>
      </div>
    </div>

  </nb-card-body>
</nb-card>
