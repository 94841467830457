

import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `<div style='text-align:right; width:100%;'><a href="https://trainerfriend.com/support" style="color:#6a6a6a; text-decoration:none;"><span class="fas fa-comments"></span> Need Help? Chat with us.</a></div>`,
})
export class FooterComponent {
  get currentYear(): number {
    return new Date().getFullYear();
  }
}
