import { Currency } from '../interfaces/common/CalorieFriend/invoices';

export const CURRENCIES: {
  name: Currency;
  description: string;
}[] = [
  { name: 'CAD', description: 'Canadian Dollar' },
  { name: 'USD', description: 'US Dollar' },
  { name: 'EUR', description: 'Euro' },
  { name: 'GBP', description: 'British Pound' },
  { name: 'AUD', description: 'Australian Dollar' },
];
