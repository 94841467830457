import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import {
  CalendarOverrideItem,
  Patient,
  PatientFilter,
} from 'app/@core/interfaces/common/CalorieFriend/patients';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Settings } from 'app/@core/interfaces/common/settings';
import { PatientWithInvoiceNumber } from 'app/@core/interfaces/common/CalorieFriend/invoices';

@Injectable()
export class PatientsApi {
  private readonly apiController: string = 'patients';

  constructor(private api: HttpService) {}

  // Updated by Vinicius
  list(filter: PatientFilter): Observable<GridData<Patient>> {
    return this.api.list(this.apiController, filter);
  }

  listWithCompliacne(filter: PatientFilter): Observable<GridData<Patient>> {
    return this.api.list(`${this.apiController}/withCompliance`, filter);
  }

  add(patient: Patient): Observable<any> {
    return this.api.post(this.apiController, patient);
  }

  get(id: number, guid: string): Observable<Patient> {
    if (guid) return this.api.get(`${this.apiController}/${guid}`);
    return this.api.get(`${this.apiController}/${id}`);
  }

  // Created by Vinicius
  getSettings(id: number, guid: string): Observable<Settings> {
    if (guid) return this.api.get(`${this.apiController}/settings/${guid}`);
    return this.api.get(`${this.apiController}/settings/${id}`);
  }

  getCalendarOverrides(
    id: number,
    guid: string
  ): Observable<CalendarOverrideItem[]> {
    if (guid)
      return this.api.get(`${this.apiController}/calendaroverrides/${guid}`);
    return this.api.get(`${this.apiController}/calendaroverrides/${id}`);
  }

  setCalendarOverrides(
    id: number,
    overrides: CalendarOverrideItem,
    guid: string
  ): Observable<CalendarOverrideItem[]> {
    if (guid)
      return this.api.post(
        `${this.apiController}/setcalendaroverrides/${guid}`,
        overrides
      );
    return this.api.post(
      `${this.apiController}/setcalendaroverrides/${id}`,
      overrides
    );
  }

  // Reviewed by Milos
  getDemoPatient(): Observable<Patient> {
    return this.api.get(`${this.apiController}/demo`);
  }

  update(patient: Patient, guid?: string): Observable<Patient> {
    if (guid) return this.api.post(`${this.apiController}/${guid}`, patient);
    return this.api.post(`${this.apiController}/${patient.id}`, patient);
  }

  // Reviewed by Milos
  delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  // Reviewed by Milos
  archive(id: number): Observable<boolean> {
    return this.api.post(`${this.apiController}/archive/${id}`, null);
  }

  // Reviewed by Milos
  unarchive(id: number): Observable<boolean> {
    return this.api.post(`${this.apiController}/unarchive/${id}`, null);
  }

  // Reviewed by Milos
  getActiveCount(): Observable<number> {
    return this.api.get(`${this.apiController}/active/count`);
  }

  getAllClientsWithInvoiceNumber(): Observable<PatientWithInvoiceNumber[]> {
    return this.api.get(`${this.apiController}/withInvoiceNumber`);
  }

  getPatientSubscriptionItems(id): Observable<any> {
    return this.api.get(`${this.apiController}/patient-subscription/${id}`);
  }
}
