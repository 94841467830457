import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UsersApi } from '../api/users.api';
import { UserData, User } from '../../../interfaces/common/users';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { NbAuthService } from '@nebular/auth';
import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class UsersService extends UserData {
  /**
   * Injects a Users API instance and an Auth Service instance
   * @param api Users API injectable
   * @param authService Auth Service injectable
   */
  private user: User;
  constructor(private api: UsersApi, private authService: NbAuthService) {
    super();
  }

  /**
   * Service function to get the users list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.usersDataSource;
  }

  public clearUser() {
    this.user = null;
  }

  /**
   * Service function to get the users list of the page {pageNumber} with the page size {pageSize}
   * @param pageNumber Page Number of Paging
   * @param pageSize Page Size of Paging
   */
  list(pageNumber: number = 1, pageSize: number = 10): Observable<User[]> {
    return this.api.list(pageNumber, pageSize);
  }

  /**
   * Service function to get the current user information
   */
  getCurrentUser(): Observable<User> {
    return this.authService.isAuthenticated().pipe(
      switchMap((authenticated) => {
        if(authenticated) {
          if(this.user) return of(this.user);
          return this.api.getCurrent();
        }
        return of(null);
      }),
      map((u) => {
        this.user = u;
        if (u && !u.settings) u.settings = {};
        return u;
      }),
    );
  }

  /**
   * Service function to get the user with the identifier @id
   * @param id User Identifier
   */
  get(id: number): Observable<User> {
    return this.api.get(id);
  }

  /**
   * Service function to add a new user.
   * @param user User Form data
   */
  create(user: any): Observable<User> {
    return this.api.add(user);
  }

  /**
   * Service function to update the user data
   * @param user User Form data
   */
  update(user: any): Observable<User> {
    return this.api.update(user);
  }

  /**
   * Service function to update the current user data
   * @param user Current User Form data
   */
  updateCurrent(user: any): Observable<User> {
    return this.api.updateCurrent(user);
  }

  /**
   * Service function to update the current user initial data
   * @param user Current User Initial Form data
   */
  updateCurrentInitial(user: any): Observable<User> {
    return this.api.updateCurrentInitial(user);
  }

  /**
   * Service function to delete an existing user.
   * @param id Identifier of the User
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to update the current user's User plan
   * @param user User object
   * @param planType Plan Type
   */
  updateCurrentUserPlan(user: User, planCode: string): Observable<boolean> {
    return this.api.updateCurrentUserPlan(user.id, planCode);
  }

  userExist(email: string): Observable<boolean> {
    return this.api.userExist(email);
  }

  submitVerificationEmail(email: string): Observable<boolean> {
    return this.api.submitVerificationEmail(email);
  }

  verifyEmail(email: string, validationCode: string): Observable<boolean> {
    return this.api.verifyEmail(email, validationCode);
  }
}
