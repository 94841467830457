import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { NbDialogService, NbThemeService } from '@nebular/theme';
import { BaseComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import { FoodData } from 'app/@core/interfaces/common/CalorieFriend/food';
import {
  MealPlanDay,
  PlanDayProcessing,
} from 'app/@core/interfaces/common/CalorieFriend/plan-day';

/**
 * Base Component class for Meal Metrics Graph components.
 */
export class MealMetricsGraphComponentBase
  extends BaseComponentCanDeactivate
  implements OnInit {
  CalsLabel = $localize`:@@MetricsCals:Cals.`;
  MealProteinLabel = $localize`:@@Protein:Protein`;
  MealCarbsLabel = $localize`:@@MetricsCarbs:Carbs.`;
  MealNetCarbsLabel = $localize`:@@MetricsCarbs:Net Carbs.`;
  MealFatsLabel = $localize`:@@Fats:Fats`;

  MealCals = '';
  MealProtein = '';
  MealCarbs = '';
  MealNetCarbs = '';
  MealFats = '';

  MealProteinPercent = '';
  MealCarbsPercent = '';
  MealNetCarbsPercent = '';
  MealFatsPercent = '';

  Cals = 0;
  Protein = 0;
  Carbs = 0;
  NetCarbs = 0;
  Fats = 0;

  data: any;
  options: any;
  themeSubscription: any;

  /**
   * Input to specify if graph should be displayed in front of the labels.
   */
  @Input('GraphInFront') GraphInFront = false;

  /**
   * Input to specify if graph should be displayed at the back of the labels.
   */
  @Input('GraphInBack') GraphInBack = false;

  /**
   * Input to specify if graph should be displayed at the top of the labels.
   */
  @Input('GraphOnTop') GraphOnTop = false;

  /**
   * Input to specify if description should be hidden.
   */
  @Input('HideDescriptions') HideDescriptions = false;

  GraphCharWidth = '80px';
  @Input('ChartWidth') set UpdateChartWidth(Width: string) {
    this.GraphCharWidth = Width;
  }

  GraphCharHeight = '80px';
  @Input('ChartHeight') set UpdateChartHeight(Height: string) {
    this.GraphCharHeight = Height;
  }

  DontShowVerticalGraphInFront: boolean = true;
  DontShowVerticalGraphInBack: boolean = true;
  DontShowGraphAbove: boolean = true;

  colors: any;
  chartjs: any;

  /**
   * Input to specify Calories amount.
   */
  @Input('MealCals') set UpdateCals(Cals: number) {
    this.ModifyCals(Cals);
  }
  ModifyCals(Cals: number) {
    this.MealCals = Math.round(Cals).toFixed(0);
    this.Cals = Cals;
    this.calculatePercents();
    this.PrepareChart();
  }

  /**
   * Input to specify Protein amount.
   */
  @Input('MealProtein') set UpdateProtein(Proteins: number) {
    this.ModifyProtein(Proteins);
  }
  ModifyProtein(Proteins: number) {
    this.MealProtein = Math.round(Proteins).toFixed(0);
    this.Protein = Proteins;
    this.calculatePercents();
    this.PrepareChart();
  }

  /**
   * Input to specify Carbs amount.
   */
  @Input('MealCarbs') set UpdateCarbs(Carbs: number) {
    this.ModifyCarbs(Carbs);
  }

  ModifyCarbs(Carbs: number) {
    this.MealCarbs = Math.round(Carbs).toFixed(0);
    this.Carbs = Carbs;
    this.calculatePercents();
    this.PrepareChart();
  }

  @Input('MealNetCarbs') set UpdateNetCarbs(Carbs: number) {
    this.ModifyNetCarbs(Carbs);
  }

  ModifyNetCarbs(NetCarbs: number) {
    this.MealNetCarbs = Math.round(NetCarbs).toFixed(0);
    this.NetCarbs = NetCarbs;
    this.calculatePercents();
    this.PrepareChart();
  }

  /**
   * Input to specify Fats amount.
   */
  @Input('MealFats') set UpdateFats(Fats: number) {
    this.ModifyFats(Fats);
  }
  ModifyFats(Fats: number) {
    this.MealFats = Math.round(Fats).toFixed(0);
    this.Fats = Fats;
    this.calculatePercents();
    this.PrepareChart();
  }

  /** MealMetricsGraph ctor */
  constructor(private theme: NbThemeService, dialogService: NbDialogService) {
    super(dialogService);
  }

  ngOnInit(): void {
    this.PrepareChart();
  }

  /**
   * Method to prepare the chart of meal's properties (Protein, Carbs, Fats)
   */
  PrepareChart() {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      this.colors = config.variables;
      this.chartjs = config.variables.chartjs;

      this.data = {
        labels: [
          this.MealProteinLabel,
          this.MealCarbsLabel,
          this.MealFatsLabel,
        ],
        datasets: [
          {
            data: [this.Protein, this.Carbs, this.Fats],
            backgroundColor: ['#2dd377', '#2C7E6E', '#88AB9F'],
          },
        ],
      };

      this.options = {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        tooltips: {
          enabled: false,
          titleFontSize: 10,
          bodyFontSize: 10,
        },
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
        legend: {
          display: false,
          // labels: {
          //   fontColor: this.chartjs.textColor,
          // },
        },
      };
    });
  }

  calculatePercents() {
    if (this.Cals) {
      this.MealProteinPercent = Math.round(
        ((this.Protein * 4) / this.Cals) * 100,
      ).toFixed(0);
      this.MealCarbsPercent = Math.round(
        ((this.Carbs * 4) / this.Cals) * 100,
      ).toFixed(0);
      this.MealFatsPercent = Math.round(
        ((this.Fats * 9) / this.Cals) * 100,
      ).toFixed(0);
    }
  }

  get chartStyle(): string {
    return `width: ${this.GraphCharWidth};height: ${this.GraphCharHeight};`;
  }
}

/**
 * Component for Meal Metrics Graph.
 * Inherits from MealMetricsGraphComponentBase component.
 */
@Component({
  selector: 'app-meal-metrics-graph',
  templateUrl: './meal-metrics-graph.component.html',
  styleUrls: ['./meal-metrics-graph.component.scss'],
})
export class MealMetricsGraphComponent extends MealMetricsGraphComponentBase {
  constructor(GCTheme: NbThemeService, GCDialogService: NbDialogService) {
    super(GCTheme, GCDialogService);
  }
}

/**
 * Component for Adjust Meal Metrics Graph.
 * Inherits from MealMetricsGraphComponentBase component.
 */
@Component({
  selector: 'adjust-app-meal-metrics-graph',
  templateUrl: './adjust-meal-metrics-graph.component.html',
  styleUrls: ['./meal-metrics-graph.component.scss'],
})
export class AdjustMetricsGraphComponent
  extends MealMetricsGraphComponentBase
  implements OnInit, AfterViewInit {
  CurrentSelection = '';
  ApplyAllDaysLabel = 'Apply To All Days';
  ApplyAllDays: boolean = false;
  @Input('PlanDays') PlanDays: Array<MealPlanDay> = null;
  @Input('ActiveDay') Day: MealPlanDay = null;
  @Input('ViewOnly') ViewOnly: boolean = false;

  Processing: Array<PlanDayProcessing> = null;

  constructor(
    private foodService: FoodData,
    AGCTheme: NbThemeService,
    AGCDialogService: NbDialogService,
  ) {
    super(AGCTheme, AGCDialogService);

    this.GraphInBack = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.Processing = new Array<PlanDayProcessing>();
    this.PrepareMealItems();
  }

  ngAfterViewInit() {}

  /**
   * Function to update the processing list of Plan days.
   */
  PrepareMealItems() {
    if (this.PlanDays != null && this.PlanDays !== undefined) {
      for (const day of this.PlanDays) {
        let found = this.Processing.find(
          (addedday) => addedday.Day.id === day.id,
        );
        if (found == null || found === undefined) {
          found = new PlanDayProcessing(day, this.foodService);
          this.Processing.push(found);
        }
        found.UpdateProcessing();
      }
    }
  }

  /**
   * Event handler of the 'Previous Serving Size' action
   */
  PreviousServingSize() {
    if (this.ApplyAllDays) {
      for (const processor of this.Processing) {
        this.DecreaseServingSize(processor);
      }
    } else {
      const ThisDay_Processing = this.Processing.find(
        (prcessor) => prcessor.Day.id === this.Day.id,
      );
      if (ThisDay_Processing != null && ThisDay_Processing !== undefined) {
        this.DecreaseServingSize(ThisDay_Processing);
      }
    }
    this.isDirty = true;
  }

  /**
   * Event handler of the 'Next Serving Size' action
   */
  NextServingSize() {
    if (this.ApplyAllDays) {
      for (const processor of this.Processing) {
        this.IncreaseServingSize(processor);
      }
    } else {
      const ThisDay_Processing = this.Processing.find(
        (prcessor) => prcessor.Day.id === this.Day.id,
      );
      if (ThisDay_Processing != null && ThisDay_Processing !== undefined) {
        this.IncreaseServingSize(ThisDay_Processing);
      }
    }
    this.isDirty = true;
  }

  /**
   * Method to increase the serving size for PlanDayProcessing
   * @param Process PlanDayProcessing object
   */
  private IncreaseServingSize(Process: PlanDayProcessing) {
    Process.MoveSelectionUp();
  }

  /**
   * Method to decrease the serving size for PlanDayProcessing
   * @param Process PlanDayProcessing object
   */
  private DecreaseServingSize(Process: PlanDayProcessing) {
    Process.MoveSelectionDown();
  }

  CheckedChange($event) {
    this.ApplyAllDays = $event;
  }
}
