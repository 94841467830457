

import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utility } from '../../@core/utils/utility';

/**
 * The component for inputting the height in metric / imperial unit (cm / feet and inch)
 * @usageNotes
 * It is used for inputting the height for patient profiles or assessment data
 */
@Component({
  selector: 'feet-and-inch',
  templateUrl: './feet-and-inch.html',
  styleUrls: ['./feet-and-inch.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FeetAndInchComponent),
      multi: true,
    },
  ],
})

export class FeetAndInchComponent implements ControlValueAccessor {
  @Input()
  get value() {
    return this._value;
  }

  set value(val) {
    if (val)
      this._value = val;

    this.updateValues(false);

    this.onChange(val);
    this.onTouched();
  }

  public _value: string = '';

  /**
   * Input Property which identifies if it is for Metric or Imperial
   */
  @Input('isImperial') isImperial: boolean = false;

  /**
   * Input Property which identifies if it is for Edit or View
   */
  @Input('IsView') IsView: boolean = false;

  @Input('status') status;

  onChange: any = () => { };
  onTouched: any = () => { };

  feet: string;
  inches: string;

  constructor() {
  }

  /**
   * Implements ControlValueAccessor's registerOnChange
   * @param fn
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Implements ControlValueAccessor's writeValue
   * @param fn
   */
  writeValue(value) {
    this.value = value;
  }

  /**
   * Implements ControlValueAccessor's registerOnTouched
   * @param fn
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * 'Change' event handler of input controls
   */
  onValuesChange() {

    this.updateValues(this.isImperial);

    this.onChange(!this.value || +this.value == 0.0 ? '' : this.value);
  }

  /**
   * The function which calculates the feet and inches from the metric value or vice versa
   */
  updateValues(updateFromImperial: boolean) {
    if (!updateFromImperial) {
      if (!this._value) {
        this._value = '';
        this.feet = '';
        this.inches = '';
      } else {
        let rawFeet = parseFloat(this._value) / 30.48;
        if (Number.isNaN(rawFeet))
          rawFeet = 0;
        this.feet = Math.floor(rawFeet).toFixed(1);
        this.inches = ((rawFeet - parseFloat(this.feet)) * 12).toFixed(1);
      }
      if (!this.feet || this.feet == '0')
        this.feet = '';
      if (!this.inches || this.inches == '0')
        this.inches = '';
    } else {
      if (!this.inches)
        this.inches = '0';
      if (!this.feet)
        this.feet = '0';
      const rawFeet = +this.feet + +(this.inches) / 12;
      this._value = (rawFeet * 30.48).toFixed(1);
      if (+this._value == 0)
        this._value = '';
    }
  }

  /**
   * Validator that validates the number with the ##.# pattern
   */
  validateNumber = Utility.validateNumber;

}
