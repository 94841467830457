/**
 * Created by Vinicius
 */

import { ColorSetting } from 'app/@theme/styles/colors';
import { ROOT_STYLE_INJECT_CLASSNAME } from '../interfaces/global';

export const rootStyleHtml = (color: ColorSetting): string => {
  return `:root {
      --color-primary: ${color.primary};
      --color-alternative: ${color.alternative};
      --color-alternative-fade: ${lightenColor(color.alternative, -15)};
      --color-primary-focus: ${lightenColor(color.primary, -30)};
      --color-info-500: ${lightenColor(color.primary, 0)};
      --color-info-400: ${lightenColor(color.alternative, 30)};
      --color-primary-transparent-default: ${lightenColor(color.primary, 80)};
    }`;
};

export const replaceRootStyle = (
  document: Document,
  color: ColorSetting
): void => {
  if (!document) return;

  const oldEle = document.head.querySelector(
    `style.${ROOT_STYLE_INJECT_CLASSNAME}`
  );
  if (oldEle) document.head.removeChild(oldEle);
  if (!color) return;
  const newEle = document.createElement('style');
  newEle.className = ROOT_STYLE_INJECT_CLASSNAME;
  newEle.innerHTML = rootStyleHtml(color);
  document.head.appendChild(newEle);
};

const lightenColor = (col: string, amt: number) => {
  let usePound: boolean = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (
    (usePound ? '#' : '') +
    (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
  );
};
