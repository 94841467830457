<nb-card>
  <nb-card-header>
    <table>
      <tr>
        <td><img src="assets/images/logo.png" class="image-logo"></td>
      </tr>
      <tr>
        <td><label i18n="@@EMailValidation_Line1">It is required to validate your email.</label></td>
      </tr>
      <tr>
        <td><label i18n="@@EMailValidation_Line2">Please select send to send a verification email. When receivced use
            the supplied value to validate the email.</label></td>
      </tr>
    </table>
  </nb-card-header>

  <nb-card-body>
    <div style="display: flex;">
      <div style="vertical-align:middle !important;flex: 0 0 20%;">
        <label i18n="@@Cofirm Number">Confirm Number</label>
      </div>
      <div style="flex: 1;">
        <input type="text" #ConfirmNumber />
      </div>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <table>
      <tr>
        <td>
          <button i18n="@@Send" nbButton  (click)="SendConfirm()">Send Confirm</button>
        </td>
        <td>
          <button i18n="@@Confirm" nbButton  (click)="Validate()">Confirm</button>
        </td>
      </tr>
    </table>
  </nb-card-footer>
</nb-card>
