import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

import { UsersService } from 'app/@core/backend/common/services/users.service';
import {
  FoodData,
  SimiliarFoodItem,
} from 'app/@core/interfaces/common/CalorieFriend/food';
import { HiddenSimilarFoodData } from 'app/@core/interfaces/common/CalorieFriend/hidden-similar-food';
import { User } from 'app/@core/interfaces/common/users';
import { MealItemPresentationComponentBase } from '../meal-item-presentation.component';
import { GramConverterPipe } from 'app/@theme/pipes';
import { UserStore } from 'app/@core/stores/user.store';

/**
 * Component for Overlay presentation of Meal Item.
 * Inherits from the MealItemPresentationComponentBase component.
 */
@Component({
  selector: 'overlay-app-meal-item-presentation',
  templateUrl: './overlay-meal-item-presentation.component.html',
  styleUrls: ['../meal-item-presentation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverlayMealItemPresentationComponent
  extends MealItemPresentationComponentBase
  implements OnInit {
  constructor(
    private OverlayChdetRef: ChangeDetectorRef,
    private hiddenFoodService: HiddenSimilarFoodData,
    private OverlayUsersService: UsersService,
    private OverlayRouter: Router,
    private OverlayDialogRef: NbDialogRef<OverlayMealItemPresentationComponent>,
    OverlayFoodService: FoodData,
    OverlayDialogService: NbDialogService,
    public userStore: UserStore,
  ) {
    super(OverlayFoodService, OverlayChdetRef, OverlayDialogService, userStore);
  }

  currentUser: User = null;
  isPatientView: boolean = false;

  ngOnInit() {
    super.ngOnInit();

    const loadUser = this.OverlayUsersService.getCurrentUser();
    loadUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.isPatientView = this.OverlayRouter.url.includes('/PatientView/');
  }

  onShowSimilarFood(similar) {
    const dto = this.convertSimilarToDto(similar);
    this.hiddenFoodService.show([dto]).subscribe(() => {
      similar.isHidden = false;
      this.OverlayChdetRef.detectChanges();
    });
  }

  onShowAll() {
    const dtos = [];
    for (const similar of this.Item.Current.foodItem.similiarFoods) {
      const dto = this.convertSimilarToDto(similar);
      dtos.push(dto);
    }

    this.hiddenFoodService.show(dtos).subscribe(() => {
      for (const similar of this.Item.Current.foodItem.similiarFoods) {
        similar.isHidden = false;
      }
      this.OverlayChdetRef.detectChanges();
    });
  }

  onHideSimilarFood(similar: SimiliarFoodItem) {
    const dto = this.convertSimilarToDto(similar);
    this.hiddenFoodService.hide([dto]).subscribe(() => {
      similar.isHidden = true;
      this.OverlayChdetRef.detectChanges();
    });
  }

  onHideAll() {
    const dtos = [];
    for (const similar of this.Item.Current.foodItem.similiarFoods) {
      const dto = this.convertSimilarToDto(similar);
      dtos.push(dto);
    }

    this.hiddenFoodService.show(dtos).subscribe(() => {
      for (const similar of this.Item.Current.foodItem.similiarFoods) {
        similar.isHidden = true;
      }
      this.OverlayChdetRef.detectChanges();
    });
  }

  get canShowAll(): boolean {
    return (
      this.Item.Current.foodItem.similiarFoods &&
      !this.Item.Current.foodItem.similiarFoods.find((s) => !s.isHidden)
    );
  }

  get canHideAll(): boolean {
    return (
      this.Item.Current.foodItem.similiarFoods &&
      this.Item.Current.foodItem.similiarFoods.findIndex((s) => !s.isHidden) >=
        0
    );
  }

  convertSimilarToDto(similar: SimiliarFoodItem): any {
    const foodItem = this.Item.Current.foodItem;
    const dto: any = {};
    dto.isCustom = foodItem.isCustomFood;
    dto.foodId = foodItem.foodId;
    dto.similarItemId = similar.foodItem.foodId;
    return dto;
  }

  onClose() {
    this.OverlayDialogRef.close();
  }
}
