import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Meal, MealItem } from 'app/@core/interfaces/common/CalorieFriend/meal';
import { Subject, Observable } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { FoodDiary, FoodDiaryData, FoodDiaryContentMealItem } from 'app/@core/interfaces/common/CalorieFriend/food-diary';
import { MealPresentationComponent } from 'app/pages/CalorieFriend/Plans/PlanMeals/Meal/MealLayout/MealPresentation/meal-presentation.component';
import { CopyMealItem } from 'app/BuildOps';
import { Patient, PatientData } from 'app/@core/interfaces/common/CalorieFriend/patients';

@Component({
  selector: 'ngx-food-diary-app',
  templateUrl: './food-diary-app.component.html',
  styleUrls: ['./food-diary-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FoodDiaryAppComponent implements OnInit {

  patient: Observable<Patient>;

  @ViewChildren('meals') meals: QueryList<MealPresentationComponent>;

  @Input() userId: number;

  private _patientId: number;
  @Input() set patientId(value: number) {
    this._patientId = value;
    if (value && value > 0) {
      this.loadDiary();
      this.patient = this.patientService.get(value, null).pipe(share());
    }
  } get patientId(): number {
    return this._patientId;
  }

  private _day: Date;
  @Input() set day(value: Date) {
    this._day = value;
    if (value) {
      this.loadDiary();
    }
  } get day(): Date {
    return this._day;
  }

  @Input() patientGuid: string;

  @Input() readOnly: boolean;

  constructor(private foodDiaryService: FoodDiaryData,
    private patientService: PatientData,
    private cdRef: ChangeDetectorRef) {
    this.createNewDiary();

  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  currentDiary: FoodDiary;
  PlanView: boolean = false;
  isInit: boolean = false;
  isWaiting: boolean = false;

  ngOnInit(): void {

  }

  loadDiary() {


    this.isWaiting = true;
    this.cdRef.detectChanges();

    if (!this.patientId || this.patientId === 0 || !this.day)
      return;

    this.foodDiaryService.get(this.patientId, this.getRawDay())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res) {
          this.currentDiary = res;
        } else {
          this.createNewDiary();
        }
        this.isWaiting = false;
        this.isInit = true;
        this.cdRef.detectChanges();
      });

  }

  getRawDay(): Date {
    let day: Date;
    if (this.day) {
      day = new Date(this.day.getTime());
    } else {
      day = new Date();
    }
    day.setHours(0, 0, 0);
    return day;
  }

  createNewDiary() {

    let breakfast: Meal;
    let lunch: Meal;
    let dinner: Meal;
    let snacks: Meal;


    breakfast = {
      name: "Breakfast",
      id: 0,
      dayID: 0,
      time: "8:00 AM",
      items: new Array<MealItem>(),
    }

    lunch = {
      name: "Lunch",
      id: 1,
      dayID: 0,
      time: "12:00 PM",
      items: new Array<MealItem>(),
    }

    dinner = {
      name: "Dinner",
      id: 2,
      dayID: 0,
      time: "6:00 PM",
      items: new Array<MealItem>(),
    }

    snacks = {
      name: "Snacks",
      id: 3,
      dayID: 0,
      time: "8:00 PM",
      items: new Array<MealItem>(),
    }

    var allMeals: Array<Meal> = new Array<Meal>();
    allMeals.push(breakfast);
    allMeals.push(lunch);
    allMeals.push(dinner);
    allMeals.push(snacks);


    this.currentDiary = {
      day: this.getRawDay(),
      notes: '',
      content: allMeals,
    }

  }

  public convertContentToLiteFormat(): FoodDiary {

    var result: FoodDiary = {
      day: this.currentDiary.day,
      notes: '',
      content: new Array(),
    }



    for (let m = 0; m < this.currentDiary.content.length; m++) {
      var meal = this.currentDiary.content[m] as Meal;


      var newMeal = {
        name: meal['name'],
        id: meal['id'],
        dayID: 0,
        time: meal['time'],
        items: new Array<FoodDiaryContentMealItem>(),
      }


      for (let i = 0; i < meal.items.length; i++) {
        var mealItem = meal.items[i];

        var newItem: FoodDiaryContentMealItem = {
          mealId: mealItem['mealId'],
          foodId: mealItem['foodId'],
          quantity: mealItem['quantity'],
          selectedServingSizeIndex: mealItem['selectedServingSizeIndex'],
          selectedServingSizeEntered: mealItem['selectedServingSizeEntered'],
          isCustomFood: mealItem['isCustomFood'],
          order: mealItem['order'],
          isCustomServingSize: mealItem['isCustomServingSize'],
          customServingSize: mealItem['customServingSize'],
          conversionFactorsValue: mealItem['conversionFactorsValue'],
        }

        newMeal.items.push(newItem)
      }

      result.content.push(newMeal);
    }

    result.calories = this.getSummary('cals');
    result.carbs = this.getSummary('carbs');
    result.fats = this.getSummary('fats');
    result.netCarbs = this.getSummary('netcarbs');
    result.proteins = this.getSummary('protein');

    return result;
  }

  

  Copy(block: Meal) {
    MealPresentationComponent.clipboard = block;
  }


  Paste(block: Meal, index:Number) {
    const list: MealItem[] = [];

    if (MealPresentationComponent.clipboard != null) {
      MealPresentationComponent.clipboard.items.forEach((item) => {
        const newItem = CopyMealItem(item);
        newItem.mealID = block.id;
        list.push(newItem);
      });
    }


    this.meals.find(
      (y) => y.Index === index
    ).MealItemsView.UpdateSelectedItem(list);

    MealPresentationComponent.clipboard = null;
  }

  HasClipboard(): boolean {
    return MealPresentationComponent.clipboard != null;
  }


  onItemChange(): void {


    if (this.isWaiting) return;
    this.save();
  }

  save(): void {
    this.foodDiaryService.save(this.patientId, this.convertContentToLiteFormat())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res) {


        } else {

        }
      });
  }



  getSummary(macro: string) {


    if (!this.meals) return;

    switch (macro) {

      case "cals":
        var sum: number = 0;
        this.meals.forEach(a => sum += a.MealCals);
        return sum;

        break;

      case "carbs":

        var sum: number = 0;
        this.meals.forEach(a => sum += a.MealCarbs);
        return sum;

        break;

      case "netcarbs":

        var sum: number = 0;
        this.meals.forEach(a => sum += a.MealNetCarbs);
        return sum;

        break;

      case "fats":
        var sum: number = 0;
        this.meals.forEach(a => sum += a.MealFats);
        return sum;
        break;

      case "protein":
        var sum: number = 0;
        this.meals.forEach(a => sum += a.MealProtein);
        return sum;
        break;


    }
  }


}
