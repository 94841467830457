import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { InvoicePayment } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { fadeIn, fadeOut } from 'app/@core/utils/animation';
import { BehaviorSubject, interval, Observable, pipe } from 'rxjs';
import { map, scan } from 'rxjs/operators';

@Component({
  selector: 'app-invoice-payment-status',
  templateUrl: './invoice-payment-status.component.html',
  styleUrls: ['./invoice-payment-status.component.scss'],
  animations: [
    trigger('paymentStatusAnimation', [
      transition('void => *', [
        useAnimation(fadeIn, { params: { time: '1300ms' } }),
      ]),
      transition('* => void', [
        useAnimation(fadeOut, { params: { time: '1300ms' } }),
      ]),
    ]),
  ],
})
export class InvoicePaymentStatusComponent implements OnInit {
  constructor() {}

  @Input('payments')
  payments: InvoicePayment[];

  @Input('label')
  label: string;

  currentIndex = new BehaviorSubject<number>(0);

  ngOnInit() {
    if (this.payments.length > 0) {
      interval(5000)
        .pipe(map((value) => value % this.payments.length))
        .subscribe(this.currentIndex);
    }
  }

  getPaymentCounts(payments: InvoicePayment[]) {
    return payments.reduce((sum, p) => sum + p.count, 0);
  }
}
