export function convertKgToPounds(value: number, isImperial: boolean): number {
    const poundsPerKg = 2.20462;
    if (isImperial) {
      const lbs = value * poundsPerKg;
      return Math.round(lbs * 100) / 100; // Round to 2 decimal places
    } else {
      return value;
    }
  }

  export function convertPoundsToKg(value: number, isImperial: boolean): number {
    const poundsPerKg = 2.20462;
    if (isImperial) {
      const kg = value / poundsPerKg;
      return Math.round(kg * 100) / 100; // Round to 2 decimal places
    } else {
      return value;
    }
  }