import { Pipe, PipeTransform } from '@angular/core';
import { Conversions } from 'app/@core/utils/conversionFactors.data';

@Pipe({name: 'convertToGram'})
export class ConvertToGramPipe implements PipeTransform {
  

  transform(value: number, unit: string): string {
    const conversionFactor = Conversions[unit];

    if (!conversionFactor) {
     return value.toFixed(2);
    }

    return (value * conversionFactor).toFixed(2);
  }
}