import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export abstract class FoodData {
  abstract get gridDataSource(): DataSource;
  abstract getFoodDefintion(
    id: string,
    Portion: number,
    isCustomFood?: boolean,
    isCustomServingSize?: boolean,
    customServingSize?: number,
  ): Observable<FoodItemModel>;
  abstract clearCache(): void;
  abstract GetFilterResult(query: string): Observable<string[]>;
}

export class NutrientsDataItem {
  calorie: number;
  calorieFromFat: number;
  fat: number;
  saturatedFat: number;
  transFat: number;
  cholesterol: number;
  sodium: number;
  carb: number;
  fiber: number;
  sugar: number;
  protein: number;
  currentPortionInGram: number;
  currentTotalUnit: number;
  fatRecomendedDailyIntake: number;
  saturatedFatRecomendedDailyIntake: number;
  cholesterolRecomendedDailyIntake: number;
  sodiumRecomendedDailyIntake: number;
  carbRecomendedDailyIntake: number;
  fiberRecomendedDailyIntake: number;
}

export interface FoodDTO {
  id: number;
  defaultUnit: number;
  unit: string;
  weight: number;
  foodItemUnitType: FoodItemUnitType;
}

export interface FoodCategory {
  id: number;
  categoryName: string;
}

export interface SimiliarFoodItem {
  id: number;
  similarFoodId: number;
  similarity: number;
  url: string;
  foodItem: CalorieFriendFood;
  isHidden: boolean;
}

export interface FoodSearchTerm {
  id: number;
  searchTerm: string;
  revelencyScore: number;
}

export interface CalorieFriendFood {
  id: number;
  formattedUrlDescription: string;
  urlPath: string;
  urlPathWithSpaces: string;
  foodId: number;
  description: string;
  searchKeywords: string;
  photoName: string;
  photoCount: number;
  photoIncrementInGram: number;
  defaultPhotoServingSize: number;
  friendlyName: string;
  unit: string;
  calorie: number;
  calorieFromFat: number;
  fat: number;
  saturatedFat: number;
  transFat: number;
  cholesterol: number;
  sodium: number;
  carb: number;
  fiber: number;
  sugar: number;
  protein: number;
  netCarb: number;
  imageURL: string;
  selected: boolean;
  passed: boolean;
  units: FoodDTO[];
  source: string;
  useFrontImageAsDefault: boolean;
  foodCategories: FoodCategory[];
  similiarFoods: SimiliarFoodItem[];
  searchTerms: FoodSearchTerm[];
  foodItemDataSource: FoodItemDataSource;
  foodItem: any;
  isCustomFood: boolean;
  conversionFactorsValue: string;
  isQuickAdd: boolean;
  patientId?: number;
}

export interface SeoData {
  id: number;
  foodId: number;
  htmlSnippet1: string;
  htmlSnippet2: string;
  htmlSnippet3: string;
}

export interface FoodItemUnit {
  Id: number;
  defaultUnit: number;
  unit: string;
  weight: number;
  foodItemUnitType: FoodItemUnitType;
}

export interface FoodImageItem {
  imageName: string;
  thumbnail: string;
  httpPath: string;
  httpThumbPath: string;
}

export interface FoodImage {
  useFrontAsDefault: boolean;
  topImage: FoodImageItem;
  frontImage: FoodImageItem;
  defaultImage: FoodImageItem;
}

export interface FoodItemViewInfo {
  path: string;
  foodImageInfo: FoodImage;
  isSelected: boolean;
  imageId: number;
}

export interface FoodAnalysisReport {
  calorieDensityType: CalorieDensityType;
  carbDensity: MacroDensityType;
  proteinDensity: MacroDensityType;
  fatDensity: MacroDensityType;
  ketoQuality: KetoQuality;
  isHighFiber: boolean;
  isHighSatFat: boolean;
  isHighSurgar: boolean;
  isHighSodium: boolean;
  carbRatio: number;
  proteinRatio: number;
  fatRatio: number;
  otherSourceCalories: number;
  tags: FoodAnalysisTag[];
  nutrients: NutrientsDataItem;
}

export interface FoodItemModel {
  foodItem: CalorieFriendFood;
  seoDataItem: SeoData;
  foodItemUnit: FoodItemUnit;
  nutrientsDataItem: NutrientsDataItem;
  allFoodItemViewInfo: FoodItemViewInfo[];
  currentFoodImage: FoodImage;
  currentFriendlyPortionSize: string;
  exist: boolean;
  selectedPortion: number;
  foodAnalysisReport: FoodAnalysisReport;
}

export enum FoodItemDataSource {
  Undefined,
  Figwee,
  USDA,
}

enum FoodItemUnitType {
  gram,
}

enum CalorieDensityType {
  low,
  medium,
  high,
}

enum MacroDensityType {
  veryLow,
  low,
  medium,
  high,
}

enum KetoQuality {
  good,
  warning,
  notKeto,
}

enum FoodAnalysisTag {
  lowCarb = 1,
  keto = 2,
  lowCalorieDensity = 3,
  highProtein = 4,
  highGoodFat = 5,
  highFiber = 6,
  lowFat = 7,
  zeroCalorie = 8,
  highSodium = 100,
  junkFood = 101,
  highSugar = 102,
  badFats = 103,
  highCalorieDensity = 104,
  alchool = 105,
  flagedAsBug = 200,
}
