import {
  animate,
  animation,
  AnimationTriggerMetadata,
  group,
  keyframes,
  style,
  transition,
  trigger,
  query,
  animateChild,
  AnimationReferenceMetadata,
  useAnimation,
} from '@angular/animations';

export function useAnimationIncludingChildren(
  animation: AnimationReferenceMetadata,
  options?: IAnimationOptions
) {
  return [
    ...(options && options.animateChildren === 'before'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
    group([
      useAnimation(animation),
      ...(!options ||
      !options.animateChildren ||
      options.animateChildren === 'together'
        ? [query('@*', animateChild(), { optional: true })]
        : []),
    ]),
    ...(options && options.animateChildren === 'after'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
  ];
}

export interface IAnimationOptions {
  /**
   * Name of the animation anchor that will be used in a template
   */
  anchor?: string;
  /**
   * Duration in ms
   */
  duration?: number;
  /**
   * Delay in ms
   *
   * Default: 0
   */
  delay?: number;
  /**
   * This parameter can only be set in a component's decorator.
   * Cannot be set in a template.
   *
   * Whether children animation should run 'before', 'together' or 'after' animation.
   * When set to 'none' chldren are not animated.
   *
   * Default: 'together'
   */
  animateChildren?: 'before' | 'together' | 'after' | 'none';
}

const zoomIn = () =>
  animation(
    group([
      animate(
        '{{duration}}ms {{delay}}ms',
        keyframes([
          style({ opacity: 0, easing: 'ease', offset: 0 }),
          style({ opacity: 1, easing: 'ease', offset: 0.5 }),
          style({ opacity: 1, easing: 'ease', offset: 1 }),
        ])
      ),
      animate(
        '{{duration}}ms {{delay}}ms',
        keyframes([
          style({
            visibility: 'visible',
            transform: 'scale3d(4,4,4)',
            easing: 'ease',
            offset: 0,
          }),
          style({ transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 1 }),
        ])
      ),
    ])
  );



const DEFAULT_DURATION = 1000;

export function zoomInAnimation(
  options?: IAnimationOptions
): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'zoomIn', [
    transition(
      '0 => 1',
      [
        style({ visibility: 'hidden' }),
        ...useAnimationIncludingChildren(zoomIn(), options),
      ],
      {
        params: {
          delay: (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION,
        },
      }
    ),
  ]);
}

export function zoomInOnEnterAnimation(
  options?: IAnimationOptions
): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'zoomInOnEnter', [
    transition(
      ':enter',
      [
        style({ visibility: 'hidden' }),
        ...useAnimationIncludingChildren(zoomIn(), options),
      ],
      {
        params: {
          delay: (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION,
        },
      }
    ),
  ]);
}

export const fadeIn = animation([
  style({ opacity: 0 }), // start state
  animate('{{time}}', style({ opacity: 1 })),
]);

export const fadeOut = animation([animate('{{time}}', style({ opacity: 0 }))]);

