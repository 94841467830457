import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssessmentSettings } from "app/@core/interfaces/common/CalorieFriend/assessment-settings";
import { HttpService } from "../http.service";

@Injectable()
export class AssessmentSettingsApi {
    private readonly apiController: string = 'assessmentSettings';
    constructor(private api: HttpService) {
    }

    public getAssessmentSettings(): Observable<AssessmentSettings>{
        return this.api.get(`${this.apiController}`);
    }

    public getAssessmentSettingsByUserId(id: number): Observable<AssessmentSettings>{
      return this.api.get(`${this.apiController}/patient/${id}`);
  }

    public updateAssessmentSettings(assessmentSettings: AssessmentSettings): Observable<AssessmentSettings>{
        return this.api.post(`${this.apiController}`, assessmentSettings);
    }
}
