<nb-layout windowMode>
  <nb-layout-header fixed>
    <a class="auth-logo-link" href="#">
      <img [src]="logo" class="auth-logo" />
    </a>
  </nb-layout-header>

  <nb-layout-column>
    <nb-auth-block>
      <router-outlet></router-outlet>
    </nb-auth-block>
  </nb-layout-column>
</nb-layout>
