import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { IActiveUserConnections, IChatMessage, IChatThread } from '../domain';
import { filter, map, mergeMap, scan, switchMap } from 'rxjs/operators';
import { ChatUserService } from './chat-user.service';
import { ChatApi } from './../api/chat.api';

@Injectable({
  providedIn: 'root',
})
export class ChatThreadService {
  private _threads: BehaviorSubject<IChatThread[]> = new BehaviorSubject([]);
  private _currentThreadSubject: BehaviorSubject<IChatThread> =
    new BehaviorSubject(null);

  constructor(private userService: ChatUserService, private chatApi: ChatApi) {
    //this._threads.subscribe((th) => console.log('threads', th));
  }

  get threads() {
    return this._threads.value;
  }
  get onThreadsChange() {
    return this._threads;
  }
  set threads(newThreads: IChatThread[]) {
    this._threads.next(newThreads);
  }

  get currentThread() {
    return this._currentThreadSubject.value;
  }
  set currentThread(thread: IChatThread) {
    this._currentThreadSubject.next(thread);
  }
  get onCurrentThreadChange() {
    return this._currentThreadSubject;
  }

  public updateThreadUsersOnline(activeUsers: IActiveUserConnections) {
    this.threads = this.threads.map((thread) => {
      thread.users = thread.users.map((user) => {
        return {
          ...user,
          active: Boolean(activeUsers[user.uid]?.length > 0),
        };
        return user;
      });
      return thread;
    });
  }
}
