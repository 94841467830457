import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import {
  FoodData,
  CalorieFriendFood,
} from '../../../../../@core/interfaces/common/CalorieFriend/food';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { IPageInfo } from 'ngx-virtual-scroller';

import { ServingSizeSelectionComponent } from '../Meal/ServingSize/Selection/serving-size-selection.component';
import { format } from '../../../../../../support';
import {
  CustomFoodData,
  CustomPublicFoodData,
} from '../../../../../@core/interfaces/common/CalorieFriend/custom-food';

import { Labelling } from '../../../general-text.component';
import { Observable } from 'rxjs';
import { CustomFoodComponent } from 'app/admin/public-foods/custom-food/custom-food.component';
import { NativeAppService } from 'app/@core/backend/common/services/native-app.service';
import { MealItem } from 'app/@core/interfaces/common/CalorieFriend/meal';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import { PatientStore } from 'app/@core/stores/patient.store';
import { cloneDeep } from 'lodash';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';

/**
 * Component for Selection of Meal Items to add into a Meal
 */
@Component({
  selector: 'app-meal-items',
  templateUrl: './meal-items.component.html',
  styleUrls: ['./meal-items.component.scss'],
})
export class MealItemsComponent implements OnInit,OnChanges {
  @Input('DiaryPatientId') DiaryPatientId: number;
  @Input('UserId') UserId: number;
  Patient: Patient;
  FoodSource: DataSource;
  FoodSourceNoImages: DataSource;
  CustomFoodSource: DataSource;
  PublicCustomFoodSource: DataSource;

  FriendlyNameLabel = 'Name';
  CaloriesLabel = 'Calories';
  ProteinLabel = 'Protein';
  CarbLabel = 'Carbs';
  FatLabel = 'Fats';
  ImageLabel = 'Image';
  Select = 'Select';
  AcceptLabel = 'Close';
  CancelLabel = 'Cancel';
  OptionsLabel = 'Options';
  ImageListCountHTML = '';
  ListWithImageCountLabel = $localize`:@@WithImages:With Images`;
  NoImageListCountHTML = '';

  ListWithoutImageCountLabel = $localize`:@@WithoutImages:Without Images`;
  FoodFilterLabel = 'Filter';
  CurrentFilter = '';
  AppliedFilter = '';
  NewMealItemLabel = 'Add New Item To Meal';
  NewCustomMealItemLabel = 'Add New Item';

  CustomFoodListCountHTML = '';
  CustomPublicFoodListCountHTML = '';
  ListFromCustomFoodCountLabel = $localize`:@@MyFood:My Food`;
  ListFromCustomPublicFoodCountLabel = $localize`:@@MyFood:Food Groups`;

  Labels: Labelling;

  SelectedFood: Array<CalorieFriendFood>;

  LoadedCurrentlyActiveFood: Array<CalorieFriendFood> = null;
  private CurrentlyActiveFood: Array<CalorieFriendFood> =
    new Array<CalorieFriendFood>();

  ItemsWithImage: Array<any> = new Array<any>();
  ItemsWithNoImage: Array<any> = new Array<any>();
  CustomItems: Array<any> = new Array<any>();
  CustomPublicItems: Array<any> = new Array<any>();

  CallerParent: any;

  filterItems = null;
  filterCustomFoodItems = null;

  @Input() SelectedItems: any[];
  ServiingSizes: Array<ServingSizeSelectionComponent> = null;

  @ViewChild('FoodTable') FoodWithImages: any = null;
  @ViewChild('filter') filter: ElementRef;

  constructor(
    private foodService: FoodData,
    private customFoodService: CustomFoodData,
    private customPublicFoodService: CustomPublicFoodData,
    private dialogService: NbDialogService,
    private toastService: NbToastrService,
    protected ref: NbDialogRef<MealItemsComponent>,
    private nativeApp: NativeAppService,
    private patientStore: PatientStore
  ) {
    this.SelectedFood = new Array<CalorieFriendFood>();

    this.ImageListCountHTML = format(
      '{0} {1}',
      0,
      this.ListWithImageCountLabel
    );
    this.NoImageListCountHTML = format(
      '{0} {1}',
      0,
      this.ListWithoutImageCountLabel
    );
    this.CustomFoodListCountHTML = format(
      '{0} {1}',
      0,
      this.ListFromCustomFoodCountLabel
    );
    this.CustomPublicFoodListCountHTML = format(
      '{0} {1}',
      0,
      this.ListFromCustomPublicFoodCountLabel
    );

    this.Labels = new Labelling();
    this.ServiingSizes = new Array<ServingSizeSelectionComponent>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Initializes the component.
   * Initializes the data source for Foods with images, Foods without images and Custom foods
   * and their subscriptions.
   */
  ngOnInit(): void {
    // initialize active list with prep list
    // for (const food of this.LoadedCurrentlyActiveFood)
    // {
    //   this.CurrentlyActiveFood.push(food);
    // }

    this.FoodSource = this.foodService.gridDataSource;
    this.FoodSourceNoImages = this.foodService.gridDataSource;
    this.CustomFoodSource = this.customFoodService.gridDataSourceForMeal(
      this.UserId ? this.UserId : 0,
      this.DiaryPatientId ? this.DiaryPatientId : 0
    );
    this.PublicCustomFoodSource =
      this.customPublicFoodService.gridDataSourceForPublicMeal;

    this.FoodSource.onChanged().subscribe((Data) => {
      this.PrepRetrievedItems(Data);

      for (const item of Data.elements) {
        const found = this.ItemsWithImage.find(
          (find) => find.foodId === item.foodId
        );

        if (found == null || found === undefined) {
          this.ItemsWithImage.push(item);

          const Item =
            this.CurrentlyActiveFood != null &&
            this.CurrentlyActiveFood !== undefined &&
            this.CurrentlyActiveFood.length > 0
              ? this.CurrentlyActiveFood.find(
                  (food) => food.foodId === item.foodId
                )
              : null;

          item.value = {
            foodId: item.foodId,
            displayQuantitySelection: false,
            displayWithinGroup: true,
            isCustomFood: item.isCustomFood,
            AssociatedObject: item,
            SelectedItem:
              Item != null && Item !== undefined ? Item.foodItem : null,
            ShowDialog: false,
            ButtonCheckedUpdate: this.ButtonCheckedUpdate,
            CallerThis: this,
          };
        }
      }

      this.ImageListCountHTML = format(
        '{0} ({1})',
        this.ListWithImageCountLabel,
        this.FoodSource.count()
      );
    });

    this.FoodSource.onChanged();

    this.FoodSourceNoImages.onChanged().subscribe((Data) => {
      {
        this.PrepRetrievedItems(Data);

        for (const item of Data.elements) {
          const found = this.ItemsWithNoImage.find(
            (find) => find.foodId === item.foodId
          );

          if (found == null || found === undefined) {
            this.ItemsWithNoImage.push(item);

            const Item =
              this.CurrentlyActiveFood != null &&
              this.CurrentlyActiveFood !== undefined &&
              this.CurrentlyActiveFood.length > 0
                ? this.CurrentlyActiveFood.find(
                    (food) => food.foodId === item.foodId
                  )
                : null;

            item.value = {
              foodId: item.foodId,
              displayQuantitySelection: false,
              displayWithinGroup: true,
              isCustomFood: item.isCustomFood,
              AssociatedObject: item,
              SelectedItem:
                Item != null && Item !== undefined ? Item.foodItem : null,
              ShowDialog: false,
              ButtonCheckedUpdate: this.ButtonCheckedUpdate,
              CallerThis: this,
            };
          }
        }

        this.NoImageListCountHTML = format(
          '{0} ({1})',
          this.ListWithoutImageCountLabel,
          this.FoodSourceNoImages.count()
        );
      }
    });

    this.CustomFoodSource.onChanged().subscribe((Data) => {
      this.PrepRetrievedItems(Data);

      for (const item of Data.elements) {
        const found = this.CustomItems.find(
          (find) => find.foodId === item.foodId
        );

        if (found == null || found === undefined) {
          this.CustomItems.push(item);

          const Item =
            this.CurrentlyActiveFood != null &&
            this.CurrentlyActiveFood !== undefined &&
            this.CurrentlyActiveFood.length > 0
              ? this.CurrentlyActiveFood.find(
                  (food) => food.foodId === item.foodId
                )
              : null;

          item.value = {
            foodId: item.foodId,
            displayQuantitySelection: false,
            displayWithinGroup: true,
            isCustomFood: item.isCustomFood,
            AssociatedObject: item,
            SelectedItem:
              Item != null && Item !== undefined ? Item.foodItem : null,
            ShowDialog: false,
            ButtonCheckedUpdate: this.ButtonCheckedUpdate,
            DeleteCustomFood: this.DeleteCustomFood,
            CallerThis: this,
          };
        }
      }

      this.CustomFoodListCountHTML = format(
        '{0} ({1})',
        this.ListFromCustomFoodCountLabel,
        this.CustomFoodSource.count()
      );
    });

    this.PublicCustomFoodSource.onChanged().subscribe((Data) => {
      this.PrepRetrievedItems(Data);

      for (const item of Data.elements) {
        const found = this.CustomPublicItems.find(
          (find) => find.foodId === item.foodId
        );

        if (found == null || found === undefined) {
          this.CustomPublicItems.push(item);

          const Item =
            this.CurrentlyActiveFood != null &&
            this.CurrentlyActiveFood !== undefined &&
            this.CurrentlyActiveFood.length > 0
              ? this.CurrentlyActiveFood.find(
                  (food) => food.foodId === item.foodId
                )
              : null;

          item.value = {
            foodId: item.foodId,
            displayQuantitySelection: false,
            displayWithinGroup: true,
            isCustomFood: item.isCustomFood,
            AssociatedObject: item,
            SelectedItem:
              Item != null && Item !== undefined ? Item.foodItem : null,
            ShowDialog: false,
            ButtonCheckedUpdate: this.ButtonCheckedUpdate,
            CallerThis: this,
          };
        }
      }

      this.CustomPublicFoodListCountHTML = format(
        '{0} ({1})',
        this.ListFromCustomPublicFoodCountLabel,
        this.PublicCustomFoodSource.count()
      );
    });

    // selected food
    // filter food
    if (this.CurrentlyActiveFood !== null) {
      for (const food of this.CurrentlyActiveFood) {
        if (food !== null && food !== undefined) this.SelectedFood.push(food);
      }

      this.filterItems = this.CurrentlyActiveFood.filter(
        (food) => !food.isCustomFood
      ).map((food) => food.foodId);
      this.RefreshLists();

      this.filterCustomFoodItems = this.CurrentlyActiveFood.filter(
        (food) => food.isCustomFood
      ).map((food) => food.foodId);

      this.RefreshCustomFoodLists();
      this.RefreshCustomFoodPublicLists();
    }
  }

  ngAfterViewInit() {
    if (this.nativeApp.isFromAppFromAndroidApp())
      setTimeout(() => this.nativeApp.sendMessageToApp('PageY', '0'), 25);
  }

  /**
   * Retrieve Item data and updates its presentation including selection UI.
   * @param Data Item data
   */
  private PrepRetrievedItems(Data) {
    if (
      this.CurrentlyActiveFood != null &&
      this.CurrentlyActiveFood !== undefined
    ) {
      for (const LoadFood of Data.elements) {
        const FoundItem = this.CurrentlyActiveFood.find(
          (food) =>
            food.foodId === LoadFood.foodId &&
            food.isCustomFood === LoadFood.isCustomFood
        );
        if (FoundItem == null || FoundItem === undefined)
          this.CurrentlyActiveFood.push(LoadFood);
      }
    } else this.CurrentlyActiveFood = Data.elements;

    this.PrepareSelectedList();

    for (const food of Data.elements) {
      const FoundFood = this.SelectedFood.find(
        (x) => x.foodId === food.foodId && x.isCustomFood === food.isCustomFood
      );
      if (FoundFood !== null && FoundFood !== undefined)
        food.selected = FoundFood.selected;
      else food.selected = false;
    }
  }

  /**
   * Event handler of 'change' event of Item Selection.
   * Updates the 'Selected' status of the food item and add it to the selected foods list.
   * @param event Event object
   */
  public onItemSelect(event) {
    if (event.data === null) {
      // full selection - de selection
      for (const food of this.CurrentlyActiveFood) {
        food.selected = !food.selected;
      }
    } else {
      event.data.selected = !event.data.selected;
      // const FoundFood = this.SelectedFood.find(curfood => curfood.foodId === event.data.foodId);
      // if (FoundFood !== null && FoundFood !== undefined)
      //   FoundFood.selected = event.data.selected;
      // else {
      //   if (event.data !== null && event.data !== undefined)
      //     this.SelectedFood.push(event.data);
      // }
      if (event.data !== null && event.data !== undefined)
        this.SelectedFood.push(event.data);
    }
  }

  /**
   * Function to update the selected foods list.
   */
  private PrepareSelectedList() {
    if (
      this.CurrentlyActiveFood !== null &&
      this.CurrentlyActiveFood !== undefined
    ) {
      let Process: Boolean = false;

      for (const food of this.CurrentlyActiveFood) {
        Process = false;
        if (food.selected !== undefined && food.selected) {
          // find the source to update selection and quantity
          Process = this.ConfigureItemModifiedSettings(
            this.ItemsWithImage,
            food
          );
          if (!Process)
            Process = this.ConfigureItemModifiedSettings(
              this.ItemsWithNoImage,
              food
            );
          if (!Process)
            Process = this.ConfigureItemModifiedSettings(
              this.CustomItems,
              food
            );

          if (Process) {
            // see if the item is already in list
            const FoundFood = this.SelectedFood.find(
              (curfood) =>
                curfood.foodId === food.foodId &&
                curfood.isCustomFood === food.isCustomFood
            );
            if (
              FoundFood === null ||
              (FoundFood === undefined && food !== null && food !== undefined)
            ) {
              this.SelectedFood.push(food);
            }
          }
        }
      }
    }
  }

  /**
   * Searches the food item in the data source and updates the selection and quantity.
   * @param Source Food Data Source to search in
   * @param Food Food item
   */
  private ConfigureItemModifiedSettings(Source, Food): Boolean {
    let Res: Boolean = false;

    if (
      Source != null &&
      Source !== undefined &&
      Food != null &&
      Food !== undefined
    ) {
      const SourceItem = Source.find((item) => item.foodId === Food.foodId);

      if (SourceItem != null && SourceItem !== undefined) {
        Food.quantity = SourceItem.value.quantity;
        Food.selectedServingSizeIndex =
          SourceItem.value.selectedServingSizeIndex;

        if (Food.quantity == null || Food.quantity === undefined)
          Food.quantity = 1;
        if (
          Food.selectedServingSizeIndex == null ||
          Food.selectedServingSizeIndex === undefined
        )
          Food.selectedServingSizeIndex = 0;

        Res = true;
      }
    }

    return Res;
  }

  /**
   * Event handler of Accept button
   */
  Accept() {
    // if (this.SelectedFood !== null && this.SelectedFood !== undefined)
    // {
    //   const SelectedFood = this.SelectedFood.filter(food => food.selected === true);

    //   if (SelectedFood.length > 0)
    //   {
    //     this.PrepareSelectedList();
    //     this.ref.close(SelectedFood);
    //   }
    // }

    // const SelectedFood = this.SelectedFood.filter(food => food.selected === true);
    // this.PrepareSelectedList();
    // this.ref.close(SelectedFood);
    this.ref.close();
  }

  /**
   * Event handler of Cancel button
   */
  Cancel() {
    this.ref.close();
  }

  /**
   * Prepares the button's call back function
   * @param instance
   */
  PrepButtonUpdate(instance) {
    instance.PrepareItemselection = false;
    instance.PrepareItemSelectionFromValue = true;

    instance.SetButtonCallBack(this, this.ButtonCheckedUpdate, 1);
    instance.SetDeleteButtonCallBack(this, this.DeleteCustomFood);
  }

  /**
   * Add the selected food item to the caller's food list
   * @param food Food item to add to the list
   */
  PerformItemAdd(food) {
    if (this.CallerParent && this.CallerParent.UpdateSelectedItem) {
      // add the food item to the caller's food list
      this.CallerParent.UpdateSelectedItem(food);

      // show toast
      this.toastService.success(
        '',
        `${food.friendlyName} has been added to meal.`
      );
    }
  }

  /**
   * Updates the selection and quantity for the Food item added
   * @param This Self referencing object
   * @param ItemAdded Item added
   */
  ButtonCheckedUpdate(This, ItemAdded) {
    const addedFoodItem = ItemAdded.value;

    let FoundFood = null;

    if (FoundFood === null || FoundFood === undefined) {
      FoundFood = This.CurrentlyActiveFood.find(
        (curfood) =>
          curfood.foodId === addedFoodItem.foodId &&
          curfood.isCustomFood === addedFoodItem.isCustomFood
      );

      if (FoundFood != null && FoundFood !== undefined) {
        // if(!FoundFood.selected)
        {
          FoundFood.selected = true;
          FoundFood.quantity = addedFoodItem.quantity;
          FoundFood.selectedServingSizeIndex =
            addedFoodItem.selectedServingSizeIndex;
          FoundFood.isCustomServingSize = addedFoodItem.isCustomServingSize;
          FoundFood.customServingSize = addedFoodItem.customServingSize;
          FoundFood.conversionFactorsValue = ItemAdded.ConversionFactorsValue;
          This.SelectedFood.push(FoundFood);
          const cloneFood: any = Object.assign({}, FoundFood);
          This.PerformItemAdd(cloneFood);
        }
      }
    }

    // if (FoundFood !== null && FoundFood !== undefined)
    // {
    //   FoundFood.selected = true;
    //   FoundFood.quantity = 1;
    //   FoundFood.selectedServingSizeIndex = 0;
    // }
  }

  /**
   * * Delete Custom Food
   * ! Should be arrow function
   */
  DeleteCustomFood = (This, itemDeleted) => {
    const item = itemDeleted.value.AssociatedObject;

    if (!item.isCustomFood || !item.patientId) return;
    this.dialogService
      .open(ConfirmMessageDialogComponent, {
        context: {
          Message: 'Are you sure you want to remove this item?',
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          const guid = this.patientStore.patient()?.patientGuid;
          this.customFoodService.delete(item.foodId, guid).subscribe({
            next: () => {
              this.RefreshCustomFoodLists();
            },
          });
        }
      });
    //if(this.value)
  };

  filteredFoods: string[];
  searchTimeout = null;

  /**
   * Event handler of the auto-complete action.
   * Searches the items within Name or Description fields by the {query} value
   * @param Search Query string to search items
   */
  FilterChange(Search) {
    this.CurrentFilter = Search;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(
      function (self) {
        self.ApplyFilterChange();
      },
      700,
      this
    );
  }

  onClear() {
    this.filter.nativeElement.value = '';
    this.FilterChange('');
  }

  /**
   * Function to filter foods by query using API service
   * @param query Query
   */
  private filterFunc(query: string): Observable<string[]> {
    return this.foodService.GetFilterResult(query);
  }

  /**
   * Activated Search
   */
  ApplyFilterChange() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    if (this.AppliedFilter !== this.CurrentFilter) {
      this.RefreshLists();
      this.RefreshCustomFoodLists();
      this.RefreshCustomFoodPublicLists();
    }

    this.AppliedFilter = this.CurrentFilter;
  }

  /**
   * Function to refresh data sources for tables.
   */
  private RefreshLists() {
    if (this.FoodSource !== null && this.FoodSource !== undefined) {
      // this.FoodSource.addFilter({ field: 'SkipItems', search: this.filterItems }, true, false);
      this.FoodSource.addFilter(
        { field: 'OnlyPictures', search: 'true' },
        true,
        false
      );
      this.FoodSource.addFilter(
        { field: 'FriendlyName', search: this.CurrentFilter },
        true,
        false
      );

      this.ItemsWithImage = new Array<any>();
      this.FoodSource.setPaging(1, 3, false);

      this.FoodSource.refresh();
    }

    if (
      this.FoodSourceNoImages !== null &&
      this.FoodSourceNoImages !== undefined
    ) {
      // this.FoodSourceNoImages.addFilter({ field: 'SkipItems', search: this.filterItems }, true, false);
      this.FoodSourceNoImages.addFilter(
        { field: 'OnlyPictures', search: 'false' },
        true,
        false
      );
      this.FoodSourceNoImages.addFilter(
        { field: 'FriendlyName', search: this.CurrentFilter },
        true,
        false
      );

      this.ItemsWithNoImage = new Array<any>();
      this.FoodSourceNoImages.setPaging(1, 6, false);

      this.FoodSourceNoImages.refresh();
    }
  }

  /**
   * Function to refresh data source for Custom Foods list.
   */
  private RefreshCustomFoodLists() {
    if (this.CustomFoodSource !== null && this.CustomFoodSource !== undefined) {
      // this.CustomFoodSource.addFilter({ field: 'SkipItems', search: this.filterCustomFoodItems }, true, false);
      this.CustomFoodSource.addFilter(
        { field: 'FriendlyName', search: this.CurrentFilter },
        true,
        false
      );

      this.CustomItems = new Array<any>();
      this.CustomFoodSource.setPaging(1, 6, false);

      this.CustomFoodSource.refresh();
    }
  }

  /**
   * Function to refresh data source for Public Custom Foods list Items.
   */
  private RefreshCustomFoodPublicLists() {
    if (
      this.PublicCustomFoodSource !== null &&
      this.PublicCustomFoodSource !== undefined
    ) {
      // this.PublicCustomFoodSource.addFilter({ field: 'SkipItems', search: this.filterCustomFoodItems }, true, false);
      this.PublicCustomFoodSource.addFilter(
        { field: 'FriendlyName', search: this.CurrentFilter },
        true,
        false
      );

      this.CustomPublicItems = new Array<any>();
      this.PublicCustomFoodSource.setPaging(1, 6, false);

      this.PublicCustomFoodSource.refresh();
    }
  }

  /**
   * Event handler of 'New Item' action to add a new custom food.
   * Opens CustomFoodDefComponent in a pop up dialog.
   */
  onNewCustomMealItem() {
    this.dialogService
      .open(CustomFoodComponent, {
        closeOnEsc: false,
        context: {
          id: null,
          userId: this.UserId,
          diaryPatientId: this.DiaryPatientId,
        },
      })
      .onClose.subscribe((goal) => {
        this.RefreshCustomFoodLists();
        this.RefreshCustomFoodPublicLists();
      });
  }

  FetchMoreItemWithImages(event: IPageInfo) {
    if (
      this.ItemsWithImage == null ||
      event.endIndex <= 0 ||
      event.endIndex !== this.ItemsWithImage.length - 1
    )
      return;

    this.FoodSource.setPaging((event.endIndex + 1) / 3 + 1, 3, true);
  }

  FetchMoreItemWithNoImages(event: IPageInfo) {
    if (
      this.ItemsWithNoImage == null ||
      event.endIndex <= 0 ||
      event.endIndex !== this.ItemsWithNoImage.length - 1
    )
      return;

    this.FoodSourceNoImages.setPaging((event.endIndex + 1) / 3 + 1, 3, true);
  }

  FetchMoreCustomItem(event: IPageInfo) {
    if (
      this.CustomItems == null ||
      event.endIndex <= 0 ||
      event.endIndex !== this.CustomItems.length - 1
    )
      return;

    this.CustomFoodSource.setPaging(
      Math.round((event.endIndex + 1) / 3) + 1,
      3,
      true
    );
  }

  FetchMoreCustomPublicItem(event: IPageInfo) {
    if (
      this.CustomPublicItems == null ||
      event.endIndex <= 0 ||
      event.endIndex !== this.CustomPublicItems.length - 1
    )
      return;

    this.PublicCustomFoodSource.setPaging(
      Math.round((event.endIndex + 1) / 3) + 1,
      3,
      true
    );
  }
}
