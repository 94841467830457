

<div class="container">

  <div class="row">


    <div style="margin-left:auto; margin-right:auto">
      <h2 class="title">Reset password</h2>
      <p i18n="Reset Password Title@@ResetPasswordFullText" class="sub-title">Please set a new password</p>
  
    </div>


  </div>
</div>





<nb-card class="nb-card-auth">

  <nb-card-body>

    <div class="row">

      <div style="width:100%; margin:auto">


        <div *ngIf="showMessages?.error && errors?.length && !submitted" class="message-label"
        style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
        <p><b>Oh snap!</b></p>
          <div *ngFor="let error of errors" class="alert-message">{{ error }}</div>
      </div>

      <div  *ngIf="showMessages?.success && messages?.length && !submitted"class="message-label"
      style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
      <p><b>Success!</b></p>
    
        <div *ngFor="let message of messages" class="alert-message">{{ message }}</div>
     
    </div>


        <div [formGroup]="restorePasswordForm" aria-labelledby="title" class="loginForm">

          <div class="form-control-group">
            <label i18n="@@NewPassword" for="input-password">New Password</label>
            <input nbInput autofocus fullWidth id="input-password" formControlName="newPassword" type="password"
              [status]="newPassword.dirty ? (newPassword.invalid  ? 'danger' : 'success') : ''" class="first"
              i18n-placeholder="@@NewPassword" placeholder="New Password">
            <ngx-validation-message i18n-label="@@NewPassword"
              [showMinLength]="newPassword?.hasError('minlength') && newPassword.touched"
              [showMaxLength]="newPassword?.hasError('maxlength') && newPassword.touched"
              [showRequired]="newPassword?.errors?.required && newPassword.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
          </div>

          <input type="hidden" formControlName="token" />
          <input type="hidden" formControlName="email" />

          <div class="form-control-group">
            <label i18n="@@ConfirmPassword" for="input-re-password">Confirm Password</label>
            <input nbInput fullWidth id="input-re-password" formControlName="confirmPassword" type="password"
              [status]="confirmPassword.dirty ? (confirmPassword.invalid || newPassword.value != confirmPassword.value  ? 'danger' : 'success') : ''"
              i18n-placeholder="@@ConfirmPassword" placeholder="Confirm Password">
            <ngx-validation-message i18n-label="@@ConfirmPassword" label="Confirm Password"
              [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
              [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
              [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
            <p i18n="@@InvalidConfirmPassword" class="error-message caption status-danger"
              *ngIf="newPassword.value != confirmPassword.value && confirmPassword.touched">New password and confirm
              password does not match!</p>
          </div>

          <button i18n="@@ResetPassword" nbButton status="success" fullWidth class="btn-circle btn-xl btn-main"
            [disabled]="submitted || !restorePasswordForm.valid || newPassword.value != confirmPassword.value"
            [class.btn-pulse]="submitted" (click)="restorePass()">
            Reset password
          </button>
        </div>

        <section class="sign-in-or-up" aria-label="Sign in or sign up" style="margin: 25px;">
          <p><a i18n="@@BackToLogin" class="text-link" routerLink="../login">Back to Log In</a></p>
          <p><a i18n="@@Register" class="text-link" routerLink="/auth/cfregister">Register</a></p>
        </section>

      </div>
    </div>

  </nb-card-body>
</nb-card>
