
    <div class="skeleton-table">
        <div class="skeleton-table-header">
          <div class="skeleton-cell skeleton-header-cell-long"></div>
          <div class="skeleton-cell skeleton-header-cell"></div>
          <div class="skeleton-cell skeleton-header-cell"></div>
          <div class="skeleton-cell skeleton-header-cell"></div>
        </div>
        <div class="skeleton-table-row" *ngFor="let _ of [].constructor(10)">
          <div class="skeleton-cell-content">
            <div class="skeleton user-icon"></div>
            <div class="skeleton-general-background text-top"></div>
            <div class="skeleton-general-background text-bottom"></div>
          </div>
          <div class="skeleton-cell-general"></div>
          <div class="skeleton-cell-general"></div>
          <div class="skeleton-cell-general"></div>
        </div>
      </div>