import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';

/**
 * Injectable class for PendingUser API
 */
@Injectable()
export class PendingUserApi {
  /**
   * API Controller Name - 'PendingUser'
   */
  protected readonly apiController: string = 'PendingUser';

  constructor(protected api: HttpService) { }

  /**
   * API function to add an email as pending user.
   * @param email email to add
   * Method: POST
   * URL: /add
   */
  add(email: string): Observable<any> {
    const form_data = new FormData();
    form_data.append('email', email);
    console.log(email);
    console.log(form_data);
    return this.api.post(`${this.apiController}/add`, form_data);
  }

}

