import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Assessment,
  AssessmentData,
  AssessmentFilter,
  AssessmentView,
} from '../../../../interfaces/common/CalorieFriend/assessment';
import { AssessmentApi } from '../../api/CalorieFriend/assessment.api';

import { GridData } from '../../../../interfaces/common/grid-data';

@Injectable()
export class AssessmentsService extends AssessmentData {
  constructor(private api: AssessmentApi) {
    super();
  }

  /**
   * Service function to get all the assessments list of the patient from the API
   * @param patientId Identifier of the Patient in number format
   * @param NotificationToken Identifier of the Patient in GUID format
   */
  AllAssessmentsList(
    patientId: number,
    NotificationToken: string,
  ): Observable<GridData<AssessmentView>> {
    return this.api.allAssessmentsList(patientId, NotificationToken);
  }

  // Updated by Vinicius
  list(filter: AssessmentFilter): Observable<GridData<AssessmentView>> {
    return this.api.list(filter);
  }

  /**
   * Service function to get the assessment with the identifier @guid of the patient
   * ith the notification token @notificationToken
   * @param guid Identifier of the assessment
   * @param notificationToken Idetnfier of the patient
   */
  getByNotification(guid: any, notificationToken: any): Observable<Assessment> {
    return this.api.getByNotification(guid, notificationToken);
  }

  /**
   * Service function to get the assessment with the identifier @guid
   * @param guid Identifier of the assessment
   */
  get(guid: any): Observable<Assessment> {
    return this.api.get(guid);
  }

  /**
   * Service function to add a new Assessment.
   * @param assessment Assessment Form data
   */
  create(assessment: any): Observable<Assessment> {
    return this.api.add(assessment);
  }

  /**
   * Service function to delete an existing assessment.
   * @param id Identifier of the assessment in number format
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to update an existing assessment
   * @param assessment Assessment Form data
   */
  update(assessment: any): Observable<Assessment> {
    return this.api.update(assessment);
  }

  /**
   * Service function to submit the weekly assessment data by the patient
   * @param Assessment Assessment Form data
   */
  submit(assessment: any): Observable<Assessment> {
    return this.api.submit(assessment);
  }
}
