/**
 * Created by Vinicius
 */

export interface ColorSetting {
  primary: string;
  alternative: string;
  primaryFocus?: string;
  info500?: string;
  info400?: string;
}

export const COLORS: ColorSetting[] = [
  {
    primary: '#37ae47',
    alternative: '#d6f6da',
    primaryFocus: '#17a858',
    info500: '#2dd377',
    info400: '#d2f5df',
  }, // Default Green
  { primary: '#000000', alternative: '#a9a9a9' }, // Black
  { primary: '#13213d', alternative: '#acc7ff' }, // Dark Blue
  { primary: '#0574ca', alternative: '#c5e6ff' }, // Blue
  { primary: '#10731e', alternative: '#7cff8e' }, // Dark Green
  { primary: '#00db2e', alternative: '#b1ffc2' }, // Lime Green
  { primary: '#ff8a00', alternative: '#ffc177' }, // Orange
  { primary: '#ff471c', alternative: '#ffd0a7' }, // Red
  { primary: '#c13dd1', alternative: '#f8bdff' }, // Fuschia
  { primary: '#ff20d8', alternative: '#ffbff4' }, // Pink
];
