

import { Component, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DefaultFilter } from 'ng2-smart-table';
import { FormControl } from '@angular/forms';

/**
 * Custom Filter component for 'Date' type fields of ng2 smart table
 */
@Component({
  template: `
    <input
      [ngClass]="inputClass"
      [formControl]="inputControl"
      class="form-control"
      style="width:170px;"
      (change)="onChange($event)"
      [placeholder]="column.title"
      type="date">
  `,
})
export class NgxDateFieldComponent extends DefaultFilter implements OnInit, OnChanges {

  @Output() filter: EventEmitter<string> = new EventEmitter();

  inputControl = new FormControl();

  constructor() {
    super ();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Event handler of 'Change' event of the input control
   * @param event Event object
   */
  onChange(event) {
    this.query = event.target.value || '';
    this.setFilter();
  }

}
