import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../http.service';
import { GridData } from '../../../../interfaces/common/grid-data';
import {
  AssessmentFilter,
  AssessmentView,
} from '../../../../interfaces/common/CalorieFriend/assessment';

@Injectable()
export class AssessmentApi {
  private readonly apiController: string = 'assessment';

  constructor(private api: HttpService) {}

  /**
   * API function to get all the assessments list of the patient given by
   * {patientId} param or {NotificationToken} param in GridData format for displaying graph.
   * @param patientId Identifier of the Patient in number format
   * @param notificationToken Identifier of the Patient in GUID format for anonymous users
   * Method: GET
   * URL: /graphDataForPatient/${patientId} or /graphDataForPatient/${notificationToken}
   */
  allAssessmentsList(
    patientId: number,
    notificationToken: string,
  ): Observable<GridData<AssessmentView>> {
    if (notificationToken != null && notificationToken !== undefined)
      return this.api.get(
        `${this.apiController}/graphDataForPatient/${notificationToken}`,
      ) as Observable<GridData<AssessmentView>>;
    else
      return this.api.get(
        `${this.apiController}/graphDataForPatient/${patientId}`,
      ) as Observable<GridData<AssessmentView>>;
  }

  // Updated by Vinicius
  list(filter: AssessmentFilter): Observable<GridData<AssessmentView>> {
    if (filter.patientGuid)
      return this.api.list(
        `${this.apiController}/list/${filter.patientGuid}`,
        filter,
      );
    return this.api.list(this.apiController, filter);
  }

  /**
   * API function to get the assessment with the identifier @guid of the patient
   * with the notification token @notificationToken
   * @param guid Identifier of the assessment
   * @param notificationToken Idetnfier of the patient
   * Method: GET
   * URL: /getByToken/${notificationToken}/${guid}
   */
  getByNotification(guid: any, notificationToken: any): Observable<any> {
    return this.api.get(
      `${this.apiController}/getByToken/${notificationToken}/${guid}`,
    );
  }

  /**
   * API function to get the assessment with the identifier @guid
   * @param guid Identifier of the assessment
   * Method: GET
   * URL: /getByGuid/${guid}
   */
  get(guid: any): Observable<any> {
    return this.api.get(`${this.apiController}/getByGuid/${guid}`);
  }

  /**
   * API function to add a new Assessment.
   * @param Assessment Assessment Form data
   * Method: POST
   * URL: /
   */
  add(Assessment: any): Observable<any> {
    const form_data = new FormData();
    for (const key in Assessment) {
      if(Assessment[key]&& Assessment[key] !== 'undefined' && Assessment[key] !== 'null' && Assessment[key] !== '') form_data.append(key, Assessment[key]);
    }

    return this.api.post(this.apiController, form_data);
  }

  /**
   * API function to delete an existing assessment.
   * @param id Identifier of the assessment in number format
   * Method: POST
   * URL: /delete/${id}
   */
  delete(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/delete/${id}`, null);
  }

  /**
   * API function to update an existing assessment
   * @param Assessment Assessment Form data
   * Method: POST
   * URL: /edit
   */
  update(Assessment: any): Observable<any> {
    const form_data = new FormData();
    for (const key in Assessment) {
      if(Assessment[key]&& Assessment[key] !== 'undefined' && Assessment[key] !== 'null' && Assessment[key] !== '') form_data.append(key, Assessment[key]);
    }

    return this.api.post(`${this.apiController}/edit`, form_data);
  }

  /**
   * API function to submit the weekly assessment data by the patient
   * @param Assessment Assessment Form data
   * Method: POST
   * URL: /submit
   */
  submit(Assessment: any): Observable<any> {
    const form_data = new FormData();
    for (const key in Assessment) {
      if(Assessment[key]&& Assessment[key] !== 'undefined' && Assessment[key] !== 'null' && Assessment[key] !== '') form_data.append(key, Assessment[key]);
    }

    return this.api.post(`${this.apiController}/submit`, form_data);
  }
}
