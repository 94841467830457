<div class="py-2 d-flex flex-column flex-md-row">
<button
    nbButton
    status="primary"
    size="medium"
    outline
    class="mx-2 my-2 btn-circle btn-xl btn-main"
    shape="round"
    (click)="createSubscriptionMeta()"
  >
    <nb-icon icon="plus"></nb-icon>
    Create Package
  </button>
</div>
<app-patient-subscriptions-meta-table
  #subscriptionsMetaTable
  [loadSubscriptions]="loadSubscriptionsMeta"
  [delete]="deleteSubscriptionMeta"
  (onViewEdit)="onViewEditMeta($event)"
  (onSendSubscription)="onSendSubscriptionMeta($event)"
></app-patient-subscriptions-meta-table>
<app-patient-subscriptions-data-table
  #subscriptionsDataTable
  [loadSubscriptions]="loadSubscriptionsData"
  (onCancelSubscription)="cancelSubscriptionData($event)"
  (openExpressLink)="expressLogin()"
></app-patient-subscriptions-data-table>
