<div class="EditControlWrapper">
  <!--single line label element-->
  <label
    for="name"
    [style.max-width]="MultiLineWidth"
    [class.smart-edit-label]="value==''"
    (click)="onClickEdit();"
    [hidden]="hideLabel ? true : DisplaySecond"
  >
    <div [innerHTML]="formattedValue()"></div>

    <div *ngIf="!_value" style="color:lightgray; font-weight:300">(edit)</div>

  </label>

  <!--multi line label element-->
  <label
    for="name"
    [style.max-width]="MultiLineWidth"
    (click)="onClickEdit();"
    *ngIf="!(hideLabel ? true : !DisplaySecond)"
  >
    {{ _SecondValue }}
  </label>

  <input
    nbInput
    type="text"
    #input
    [readonly]="readonly"
    (focusout)="onFocusout()"
    [hidden]="IsMultiLine == false ? hideEdit : true"
    [(ngModel)]="_value"
    [style.width.ch]="[textLength]"
    [style.max-width]="MultiLineWidth"
    [minlength]="MinLength"
    [maxlength]="MaxLength"
    class="input-full-width size-medium status-basic shape-semi-round"
    (keyup)="onKeyup($event)"
    (input)="onInput()"
    (change)="onValuesChange()"
    [placeholder]="placeholder"
  />

  <textarea
    [(ngModel)]="_value"
    #input2
    [readonly]="readonly"
    [hidden]="IsMultiLine == true ? hideEdit : true"
    (focusout)="onFocusout()"
    (change)="onValuesChange()"
    (input)="onTextareaInput()"
    [placeholder]="placeholder"
    [style.width]="MultiLineWidth"
    [minlength]="MinLength"
    [maxlength]="MaxLength"
    class="size-medium status-basic shape-semi-round"
    fullWidth
    shape="semi-round"
    nbInput
    type="text"
  ></textarea>

  <div class="controls EditControlLayout">
    <a
      class="edit"
      href="javascript:void(0);"
      (click)="onClickEdit();"
      [hidden]="hideLabel"
    >
      <nb-icon
        icon="modify-pencil-outline"
        *ngIf="!readonly"
        pack="custom"
      ></nb-icon>
      <nb-icon icon="eye-outline" *ngIf="readonly"></nb-icon>
    </a>
  </div>
</div>
