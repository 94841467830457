import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';
import { HttpService } from '../../../@core/backend/common/api/http.service';
import { getDeepFromObject } from '../../helpers';

/**
 * The component for resetting the password (The process after 'Forgot Password')
 */
@Component({
  selector: 'ngx-restore-password-page',
  styleUrls: ['./restore-password.component.scss'],
  templateUrl: './restore-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRestorePasswordComponent implements OnInit {
  minLength: number = this.getConfigValue(
    'forms.validation.password.minLength',
  );
  maxLength: number = this.getConfigValue(
    'forms.validation.password.maxLength',
  );
  redirectDelay: number = this.getConfigValue(
    'forms.restorePassword.redirectDelay',
  );
  showMessages: any = this.getConfigValue('forms.restorePassword.showMessages');
  strategy: string = this.getConfigValue('forms.restorePassword.strategy');
  isPasswordRequired: boolean = this.getConfigValue(
    'forms.validation.password.required',
  );

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  restorePasswordForm: FormGroup;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    private route: ActivatedRoute,
    private api: HttpService,
    protected router: Router,
  ) {}

  /**
   * It defines the form for resetting the password and its input validators
   */
  ngOnInit(): void {
    const passwordValidators = [
      Validators.minLength(this.minLength),
      Validators.maxLength(this.maxLength),
    ];

    this.isPasswordRequired && passwordValidators.push(Validators.required);

    this.route.queryParams.subscribe((params) => {
      const email = params.email;
      const token = params.token;

      this.restorePasswordForm = this.fb.group({
        newPassword: this.fb.control('', [...passwordValidators]),
        confirmPassword: this.fb.control('', [...passwordValidators]),
        token: this.fb.control(token),
        email: this.fb.control(email),
      });
    });
  }

  get newPassword() {
    return this.restorePasswordForm.get('newPassword');
  }
  get confirmPassword() {
    return this.restorePasswordForm.get('confirmPassword');
  }
  get token() {
    return this.restorePasswordForm.get('token');
  }
  get email() {
    return this.restorePasswordForm.get('email');
  }

  /**
   * The process for resetting the password.
   * It gets the password and confirm password and submits the form for the reset
   */
  restorePass(): void {
    const redirect = '/';
    this.errors = this.messages = [];
    this.submitted = true;
    this.user = this.restorePasswordForm.value;

    this.api.post(`auth/restore-pass`, this.user).subscribe(
      (data) => {
        this.submitted = false;
        if (data.token && data.token.refresh_token) {
          this.messages = ['Your password has been reset successfully'];
        } else {
          this.errors = ['Reset password failed.'];
        }
      },
      (err) => {
        this.submitted = false;
        this.errors = ['Reset password failed.'];
      },
      () => {
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
        this.cd.detectChanges();
      },
    );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
