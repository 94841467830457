import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { PatientWithInvoiceNumber } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { Patient, PatientData } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'ngx-patients4subscription',
  templateUrl: './patients4subscription.component.html',
  styleUrls: ['./patients4subscription.component.scss'],
})
export class Patients4subscriptionComponent implements OnInit, OnDestroy {
  
  @Input("clients")
  clients$: Observable<PatientWithInvoiceNumber[]> = of([]);
    
  clients: PatientWithInvoiceNumber[] = [];
  selected: number[] = [];
  subscription$: Subscription;

  constructor(private dialogRef: NbDialogRef<Patients4subscriptionComponent>
  ) { }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  ngOnInit() {
    this.subscription$ = this.clients$.subscribe({
      next: (res) => {
        this.clients = res;
      }
    });
  }
  
  onAll(checked: boolean) {
    this.selected = checked ? this.clients.map(c => c.id) : [];
  }

  onToggle(id: number, checked: boolean) {
    this.selected = checked ? [...this.selected, id] : this.selected.filter(x => x !== id);
  }

  send() {
    this.dialogRef.close(this.selected);
  }

  public onClose() {
    this.dialogRef.close();
  }
}
