<div class="row graph_and_caloric_adjustment">

  <div [hidden]="ViewOnly" class="caloric_adjustment">

    <div>
      <div>
        <label i18n="@@CaloricAdjustment">Caloric Adjustment</label>
      </div>
      <div>
        <nb-checkbox i18n="@@ApplyToAllDays" [checked]="ApplyAllDays" (checkedChange)="CheckedChange($event)">Apply To
          All Days</nb-checkbox>
      </div>
    </div>

    <div>
      <div>
        <a href="javascript:void(0);" class="action">
          <nb-icon icon="minus-circle-outline" (click)="PreviousServingSize()"></nb-icon>
        </a>
      </div>
      <div>
        <a href="javascript:void(0);" class="action">
          <nb-icon icon="plus-circle-outline" (click)="NextServingSize()"></nb-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="GraphPlacement">
    <app-meal-metrics-graph class="adjust-meal-metrics" [MealCals]="MealCals" [MealProtein]="MealProtein"
      [MealCarbs]="MealCarbs" [MealNetCarbs]="MealNetCarbs" [MealFats]="MealFats" [GraphInBack]="true"
      [ChartWidth]="'80px'" [ChartHeight]="'80px'"></app-meal-metrics-graph>
  </div>
</div>
