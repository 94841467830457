import { Exercise } from 'app/@core/interfaces/common/CalorieFriend/exercise';
import { ExerciseLog } from './exerciseLog';
import { FitnessBlock } from 'app/@core/interfaces/common/CalorieFriend/fitnessItem';
import { FitnessPlanDay } from 'app/@core/interfaces/common/CalorieFriend/plan-day';
import { FitnessPlan } from './plan';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { WorkoutMode } from 'app/@core/backend/common/services/CalorieFriend/workout.service';
export enum WorkoutStatus {
  None,
  Started,
  Completed,
  Canceled
}
export enum WorkoutDetailStatus {
  Completed = 'completed',
  Skipped = 'skipped',
}

export interface Workout {
  id?: number;
  patientId: number;
  planId: number;
  plan?: FitnessPlan;
  dayId: number;
  day?: FitnessPlanDay;
  startDateTime: Date;
  endDateTime: Date;
  status: WorkoutStatus | string;
  workoutDetails?: WorkoutDetail[];
  patientWorkoutDataDetails?: WorkoutDetail[];
  workoutTime?: number;
}

export interface WorkoutDetail {
  id?: number;
  blockId: number;
  exerciseId: string;
  itemId?: number;
  startDateTime: Date;
  endDateTime: Date;
  exerciseLogId: number;
  exerciseLog?: ExerciseLog;
  isCircuitSetNumber?: number;
  isSupersetNumber?: number;
  exerciseName?: string
  status: WorkoutDetailStatus;
  patientWorkoutDataId: number;
  customName?:string;
}
