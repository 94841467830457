<div class="input-group" style="flex-wrap:nowrap;">
  <input type="text" nbInput [readonly]="IsView" class="half-width size-medium status-basic shape-semi-round" step="0.1" (keypress)="validateNumber($event)" (change)="onValuesChange()" [(ngModel)]="feet"
    i18n-placeholder="@@Feet" [status]="status" placeholder="Feet" *ngIf="isImperial">
  <div class="input-group-addon input-cm label" *ngIf="isImperial">ft</div>

  <input type="text" nbInput [readonly]="IsView" class="size-medium status-basic shape-semi-round" step="0.1" (keypress)="validateNumber($event)" (change)="onValuesChange()" [(ngModel)]="_value"
    placeholder="Cm" [status]="status"  *ngIf="!isImperial">
  <div class="input-group-addon input-cm label" *ngIf="!isImperial">cm</div>

  <input type="text" fullWidth nbInput [readonly]="IsView" class="half-width size-medium status-basic shape-semi-round" step="0.1" (keypress)="validateNumber($event)" (change)="onValuesChange()" [(ngModel)]="inches"
    i18n-placeholder="@@Inches" [status]="status" placeholder="Inches"  *ngIf="isImperial">
  <div class="input-group-addon input-cm label" *ngIf="isImperial">in</div>

</div>
