import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { NbCalendarRange, NbToastrService } from '@nebular/theme';
import { InvoiceFilter, Invoice, RecurringInvoice } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { PatientSubscriptionMeta, PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { PatientSubscriptionsTableComponentBase } from '../patient-subscriptions-table-base/patient-subscriptions-table.component.base';

@Component({
  selector: 'app-patient-subscriptions-meta-table',
  templateUrl: './patient-subscriptions-meta-table.component.html',
  styleUrls: ['./patient-subscriptions-meta-table.component.scss'],
})
export class PatientSubscriptionsMetaTableComponent extends PatientSubscriptionsTableComponentBase<PatientSubscriptionMeta> {
  get displayedColumns() {
    return ['id', 'recurrence', 'price', 'tax', 'discount', 'actions'];
  }
  connecting: boolean = false;

  @Input() sendOnly: boolean = false;

  @Input()
  delete: (id: number) => Observable<boolean>;

  @Output("onSendSubscription")
  sendEventEmitter = new EventEmitter<number>();

  @Output() openExpressLink = new EventEmitter<void>();

  /**
   * TODO update function below for further update.
   * @param subscription PatientSubscription object to get actions
   * @returns boolean values showing actions are enabled or not (edit, send, delete etc.)
   */
  protected getSubscriptionActions(subscription: PatientSubscriptionMeta) {
    const deletable = true;
    const editable = false;
    return {
      deletable,
      editable,
      sendable: true
    };
  }

  onDelete(id: number, event: MouseEvent) {
    event?.stopPropagation();
    this.delete &&
      this.delete(id).subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
  }

  onSend(id: number) {
    this.sendEventEmitter.emit(id);
  }

  onCopy(link: string, copyTest: HTMLElement){
    copyTest.innerText = "Copying";
    navigator.clipboard.writeText(link).then(function() {
      copyTest.innerText = "Copied!";
      setTimeout(() => {
        copyTest.innerText = "Copy link";
      }, 3000);
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  }
  public getTotalPriceOfInvoices(invoiceItems: any[]) : number{
    return invoiceItems.reduce((pre, curr)=> pre+ curr.itemPrice, 0);
  }
}
