import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Currency } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { CURRENCIES } from 'app/@core/utils/currency';
@Component({
  selector: 'app-invoice-item-dialog',
  templateUrl: './invoice-item-dialog.component.html',
  styleUrls: ['./invoice-item-dialog.component.scss'],
})
export class InvoiceItemDialogComponent implements OnInit {
  itemName = '';
  itemPrice = 0;
  itemCurrency : Currency = 'USD';

  readonly currencies = CURRENCIES;
  get currencySymbol() {
    return getCurrencySymbol(this.itemCurrency || 'USD', 'wide');
  }

  constructor(private dialogRef: NbDialogRef<InvoiceItemDialogComponent>) {}

  ngOnInit() {}

  onAdd() {
    this.dialogRef.close({
      itemName: this.itemName,
      itemPrice: this.itemPrice,
      itemCurrency: this.itemCurrency,
    });
  }
}
