<form #form="ngForm">
<nb-card class="subscription-form nb_card_pop_up full_pop_up mobilePopup">
  <nb-card-header *ngIf="!isPatientView">
    <h6 i18n="@@Title">
      {{ title }}
    </h6>
    <button (click)="onClose()" nbButton ghost shape="round">
      <nb-icon icon="close"></nb-icon>
    </button>
  </nb-card-header>
    <nb-card-body>
      <nb-badge *ngIf="isPatientView && subscriptionData" position="top right" [status]="subscriptionDataStatus"
        [text]="subscriptionData.status" class="m-3">
      </nb-badge>
      <div class="row">
        <div class="col-12">
          <p><b>Product*</b></p>
          <div class="w-100 my-2">
            <input nbInput fullWidth placeholder="Package Name"
            name="itemName"
              [(ngModel)]="subscription.invoiceItems[0].itemDescription" required #itemName="ngModel"
              [readonly]="!editable" />
            <div *ngIf="itemName.invalid && (itemName.dirty || itemName.touched)" class="text-danger">

              <div *ngIf="itemName.errors.required">
                Package name is required.
              </div>

            </div>
        </div>
        <div class="w-100 my-2 d-flex flex-row align-items-center">
          <div class="my-2 mr-2">
            <nb-select placeholder="Currency" name="currency" [(ngModel)]="subscription.currency" [disabled]="!editable">
              <nb-option fullWidth *ngFor="let currency of currencies" [value]="currency.name">
                {{ currency.name }} - {{ currency.description }}
              </nb-option>
            </nb-select>
          </div>
          <span>{{ currencySymbol }}</span>
          <div class="flex-grow-1">
            <input nbInput fullWidth name="itemPrice" required 
              #itemPrice="ngModel"
              placeholder="Item Price" type="number"
              min="1" max="1000000"
              [(ngModel)]="subscription.invoiceItems[0].itemPrice" [readonly]="!editable" />
              <div *ngIf="itemPrice.invalid && (itemPrice.dirty || itemPrice.touched)" class="text-danger">

                <div *ngIf="itemPrice.errors.required">
                  Item price is required.
                </div>

                <div *ngIf="itemPrice.errors.min">
                  Age must be at least 1.
                </div>
  
              </div>
          </div>
        </div>

      </div>

      <div class="col-12">
        <hr />

        <p><b>Billing Period</b></p>
        <div class="d-flex align-items-center">
          <span>Every </span>
          <div class="mx-1" style="width: 50px">
            <input nbInput fullWidth placeholder="" name="frequencyIntervalCount" [(ngModel)]="subscription.frequencyIntervalCount" class=""
              type="number" min="0" max="100" [readonly]="!editable" />
          </div>
          <div class="flex-grow-1">
            <nb-select fullWidth [(ngModel)]="subscription.frequencyInterval" name="frequencyInterval" [disabled]="!editable">
              <nb-option *ngFor="let interval of frequencyIntervals" [value]="interval"> {{ interval }}s </nb-option>
            </nb-select>
          </div>
        </div>
      </div>

      <div class="col-12">
        <hr />
        <div class="d-flex justify-content-between">
          <p><b>Subtotal</b></p>
          <span>{{ subTotal | currency : subscription.currency || 'CAD' : 'symbol' : '1.2-2' }}</span>
        </div>
        <div class="d-flex my-3">
          <div class="ml-auto d-flex align-items-center">
            <span>First period discount %</span>
            <input nbInput fullWidth placeholder="Add Discount" name="discount" [(ngModel)]="subscription.discount"
              class="text-right discount-input" type="number" min="0" max="100" [readonly]="!editable" />
          </div>
        </div>
        <div class="d-flex my-3">
          <div class="ml-auto d-flex align-items-center">
            <span matSuffix>%</span>
            <input nbInput fullWidth placeholder="Add Tax" name="tax" [(ngModel)]="subscription.tax"
              class="text-right discount-input" type="number" min="0" max="100" [readonly]="!editable" />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <p>
            <b>Amount Due ({{ subscription.currency || 'CAD' }})</b>
          </p>
          <span>{{ amountDue | currency : subscription.currency || 'CAD' : 'symbol' : '1.2-2' }}</span>
        </div>
      </div>

      <!-- <div class="col-12">
        <hr />
        <p><b>Add Notes / Terms</b></p>
        <div class="w-100">
          <textarea nbInput fullWidth placeholder="Enter your terms and conditions." [(ngModel)]="subscription.terms"
            rows="8" [readonly]="!editable" name="terms"></textarea>
        </div>
      </div> -->

      <div class="col-12">
        <ng-container *ngIf="patient$ | async as patient">
          <!-- <p *ngIf="patient">{{patient.firstName}}&ensp;{{patient.lastName}}</p> -->
          <a *ngIf="subscriptionData?.status != 'active'" nbButton size="large" shape="round" status="primary"
            color="primary" class="mt-4" href="{{ subscription?.subscriptionLink }}&prefilled_email={{ patient.eMail }}"
            target="blank">Subscribe</a>
        </ng-container>
      </div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="!isPatientView">
      <div class="d-flex">
        <button nbButton shape="round" status="primary" class="w-100 mx-3" color="primary" *ngIf="editable"
          (click)="onSaveSubscription()" [nbSpinner]="loading" [disabled]="loading||form.invalid">
          {{ !subscriptionMetaId ? 'Create' : 'Update' }}
        </button>
        <button nbButton shape="round" status="primary" class="w-100 mx-3" color="primary" *ngIf="editable && loading"
          (click)="onCancel()">
          Cancel
        </button>
      </div>
    </nb-card-footer>
</nb-card>
</form>