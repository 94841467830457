<div class="row noMargin">
  <div class="col-12" style="text-align: center;">
    <p>
      The world's most intuitive tool for trainers to create meal and workout plans for their clients.
    </p>
  </div>
</div>


<nb-card class="register-form nb-card-mobile nb-card-auth">
  <nb-card-body class="nb-card-body-mobile">
    <div>
      <h2>Create your account now</h2>
   
      <div class="loginForm">


        <div *ngIf="showMessages.error && errors?.length && !submitted" class="message-label"
          style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
          <p><b>Oh snap!</b></p>
          <div *ngFor="let error of errors" class="alert-message">{{ error }}</div>
        </div>

        <div *ngIf="showMessages.success && messages?.length && !submitted" class="message-label"
          style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
          <p><b>Success!</b></p>

          <div *ngFor="let message of messages" class="alert-message">{{ message }}</div>

        </div>



        <div [formGroup]="registerForm" aria-labelledby="title">
          <div class="form-control-group">
            <label i18n="@@EmailAddress" for="input-email">Email address:</label>
            <input nbInput fullWidth fieldSize="large" id="input-email" formControlName="email"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null" i18n-placeholder="@@EmailAddress"
              placeholder="Work Email Address" (keyup)="EMAilChange($event)">
            <ngx-validation-message label="Email address"
              [showPattern]="email?.hasError('pattern') && email.touched"
              [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
            <label i18n-placeholder="@@EmaiDuplicateWarning" style="color:red" *ngIf="!HideDuplicateEmail"
              [hidden]="HideDuplicateEmail">Email
              already exist. <a class="text-link" routerLink="/auth/login">Login instead.</a></label>
          </div>

          <div class="form-control-group">
            <label i18n="@@Password" for="input-password">Password</label>
            <input nbInput fullWidth fieldSize="large" id="input-password" formControlName="password"
              type="password" [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
              i18n-placeholder="@@Password" placeholder="Password">
            <ngx-validation-message label="Password"
              [showMinLength]="password?.hasError('minlength') && password.touched"
              [showMaxLength]="password?.hasError('maxlength') && password.touched"
              [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
          </div>

          <div class="form-control-group">
            <label i18n="@@ConfirmPassword" for="input-re-password">Confirm Password</label>
            <input nbInput fullWidth fieldSize="large" id="input-re-password" formControlName="confirmPassword"
              type="password"
              [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
              i18n-placeholder="@@ConfirmPassword" placeholder="Confirm Password">
            <ngx-validation-message i18n-label="@@ConfirmPassword" label="Confirm Password"
              [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
              [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
              [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
            <p i18n="@@InvalidConfirmPassword" class="caption status-danger"
              *ngIf="password.value != confirmPassword.value">Password and confirm password does not match!</p>
          </div>

          <div class="form-control-group">
            <nb-checkbox #Aggreement name="termsandconditions" formControlName="terms" class="float-left"
              (click)="AcceptAgreement()"></nb-checkbox>
            <a i18n="@@AgreeTermsAndConditions" href="javascript:void(0)" class="condition-link"
              (click)='DisplayTermsAgreement()'> I agree to the Terms & Conditions</a>
          </div>

          <div class="form-control-group accept-group">
            <button fullWidth i18n="@@Register" nbButton type="submit" size="medium" class="btn-circle btn-xl btn-main"
              [disabled]="EnableRegistration()" (click)="onRegister()">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<br>

<div class="row noMargin">


  <nb-card class="register-form nb-card-mobile nb-card-auth">
    <nb-card-body class="nb-card-body-mobile">

    <div class="loginForm">
      <div>

        <div class="row">

          <div class="col-lg-5 my-auto">
            <div class="align-middle" style="text-align:center;">
              <h2>Deliver impressive workout plans.</h2>
              <p>Build comprehensive and stunning visual workout plans with more than 3,000 exercises and 1500 videos.
              </p>
            </div>
          </div>

          <div class="col-lg-7">

            <video muted autoplay width="100%" autoplay="true" loop="true" playsinline="true" muted="true">
              <source src="https://trainerfriend.com/img/exercises.mp4" type="video/mp4">
            </video>
          </div>

          <br />
        </div>

      </div>
    </div>


    
    </nb-card-body></nb-card>

</div>
<br />

<div class="row noMargin">


  <nb-card class="register-form nb-card-mobile nb-card-auth">
    <nb-card-body class="nb-card-body-mobile">
    <div class="loginForm">
      <div>

        <div class="row">

          <div class="col-lg-5 my-auto">
            <div class="align-middle" style="text-align:center;">
              <h2>Create Meal and Macros plans like you have never seen before.</h2>
              <p>With our platform and database of over 40,000 food portion
                images, you can create the most powerful meal plans imaginable!
              </p>
            </div>
          </div>

          <div class="col-lg-7">

            <video muted autoplay width="100%" autoplay="true" loop="true" playsinline="true" muted="true">
              <source src="https://trainerfriend.com/img/ipad.mp4" type="video/mp4">
            </video>
          </div>

          <br />
        </div>

      </div>
    </div>
    </nb-card-body></nb-card>
</div>

<br />

<div class="row noMargin">


  <nb-card class="register-info nb-card-mobile nb-card-auth">
    <nb-card-body class="nb-card-body-mobile">
      <div style="padding-bottom:30px;">
        <div class="loginForm">
          <h2>Join TrainerFriend Today!</h2>
          <p>
            You are 30 seconds away of improving your customer facing experience and simplifying your life.
          </p>

          <div style="margin-left:-30px;">
            <ul style="list-style: none;">
              <li>✔ Wourkout Plan Builder with 3 000+ exercises</li>
              <li>✔ Best Meal Plan Builder in the world</li>
              <li>✔ World's largest portion food database in pictures</li>
              <li>✔ Manage your customers information and goals</li>
              <li>✔ Mobile client zone</li>
              <li>✔ Automated client assesments</li>
            </ul>
          </div>

          <button nbButton type="submit"  size="medium" class="btn-circle btn-xl btn-main"
          style="width: 100% !important; font-size: 12pt !important;"
          onclick="javascript:document.getElementById('input-email').focus();">
          Create free account
        </button>



        </div>
      </div>
    </nb-card-body>
  </nb-card>



<br />
<br /><br /><br /><br />
