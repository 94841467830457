import { Observable } from 'rxjs';
import * as internal from 'stream';
import { Meal } from './meal';


export abstract class FoodDiaryData {
  abstract save(patientId: number, diary: FoodDiary): Observable<boolean>;
  abstract get(patientId: number, date: Date): Observable<FoodDiary>;
  abstract getDiariesInMonth(
    patientId: number,
    year: number,
    month: number
  ): Promise<FoodDiary[]>;

  abstract getComplianceScore(
    patientId: number,
    startDate: Date,
    endDate?: Date
  ): Observable<number>;
}


export interface FoodDiary {
  day: Date;
  content: Array<object>;
  notes: string;
  calories?: number;
  carbs?: number;
  proteins?: number;
  fats?: number;
  netCarbs?: number;
}


export interface FoodDiaryContentMealItem {
  mealId: number;
  foodId: number;
  quantity: number;
  selectedServingSizeIndex: number;
  selectedServingSizeEntered: number;
  isCustomFood: boolean;
  order: Number;
  isCustomServingSize: boolean;
  customServingSize: number;
  conversionFactorsValue:string;
}


export interface FoodDiaryContent {
  meals: Meal[]
}