<div class="mg-progress-container">
  <div *ngIf="patient" class="row">
    <div class="col-6 col-sm-3">
      <label class="macro-label"> {{ goalCalories }} cals</label>
      <div class="mg-progress">
        <nb-progress-bar status="primary" [value]="(cals / goalCalories) * 100"></nb-progress-bar>
        <label class="pg-label">{{ cals }} cals. <span style="font-size: 0.45rem;">({{cals /  goalCalories * 100 | number:'1.0-0'}}%)</span></label>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <label class="macro-label"> {{ goalProtein | number:'1.0-0' }} g. Proteins</label>
      <div class="mg-progress">
        <nb-progress-bar status="primary" [value]="(protein / goalProtein) * 100"></nb-progress-bar>
        <label class="pg-label">{{ protein }} g. <span style="font-size: 0.45rem;">({{protein /  goalProtein * 100 | number:'1.0-0'}}%)</span></label>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <label class="macro-label"> {{ goalCarbs | number:'1.0-0' }} g. Carbs</label>
      <div class="mg-progress">
        <nb-progress-bar status="primary" [value]="(carbs / goalCarbs) * 100"></nb-progress-bar>
        <label class="pg-label">{{ carbs }} g. <span style="font-size: 0.45rem;">({{carbs /  goalCarbs * 100 | number:'1.0-0'}}%)</span></label>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <label class="macro-label"> {{ goalFats | number:'1.0-0'  }} g. Fats</label>
      <div class="mg-progress">
        <nb-progress-bar status="primary" [value]="(fats / goalFats) * 100"></nb-progress-bar>
        <label class="pg-label">{{ fats }} g. <span style="font-size: 0.45rem;">({{fats /  goalFats * 100 | number:'1.0-0'}}%)</span></label>
      </div>
    </div>
  </div>
</div>
