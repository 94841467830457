import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NB_AUTH_OPTIONS,
  NbAuthSocialLink,
  NbAuthService,
  NbAuthResult,
} from '@nebular/auth';
import { getDeepFromObject } from '../../helpers';
import { EMAIL_PATTERN } from '../constants';
import { NbDialogService } from '@nebular/theme';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';

import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { UserData } from 'app/@core/interfaces/common/users';
import { PendingUserService } from 'app/@core/backend/common/services/CalorieFriend/pendingUsers-service';
import { FacebookPixelService } from 'app/@theme/services/FacebookPixelService';
import { GoogleAnalyticsService } from 'google-analytics.service';
import { EmailAccessibleValidationComponent } from '../email-accessible-validation/email-accessible-validation.component';
import { InitUserService } from 'app/@theme/services/init-user.service';

/**
 * Component for the register form
 */
@Component({
  selector: 'ngx-register',
  styleUrls: ['./register.component.scss'],
  templateUrl: './register.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterComponent implements OnInit {
  // @Input()
  PlanCode: string = '';

  TermsAndConditionLabel = 'I agree to the Terms & Conditions';
  public Aggreement = false;

  // minLoginLength: number = this.getConfigValue(('forms.validation.fullName.minLength'));
  // maxLoginLength: number = this.getConfigValue(('forms.validation.fullName.maxLength'));
  minLength: number = this.getConfigValue(
    'forms.validation.password.minLength',
  );
  maxLength: number = this.getConfigValue(
    'forms.validation.password.maxLength',
  );
  // isFullNameRequired: boolean = this.getConfigValue('forms.validation.fullName.required');
  isEmailRequired: boolean = this.getConfigValue(
    'forms.validation.email.required',
  );
  isPasswordRequired: boolean = this.getConfigValue(
    'forms.validation.password.required',
  );
  redirectDelay: number = this.getConfigValue('forms.register.redirectDelay');
  showMessages: any = this.getConfigValue('forms.register.showMessages');
  strategy: string = this.getConfigValue('forms.register.strategy');
  socialLinks: NbAuthSocialLink[] = this.getConfigValue(
    'forms.login.socialLinks',
  );

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  HideDuplicateEmail = true;
  protected readonly unsubscribe$ = new Subject<void>();

  registerForm: FormGroup;

  EmailConfirmed: boolean = false;

  @ViewChild('Aggreement', { static: false }) AggreementControl: any;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    private fb: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private pendingUserService: PendingUserService,
    private usersService: UserData,
    protected facebookPixelService: FacebookPixelService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected initUserService: InitUserService,
  ) { }

  // get login() { return this.registerForm.get('fullName'); }
  get email() {
    return this.registerForm.get('email');
  }
  get password() {
    return this.registerForm.get('password');
  }
  get confirmPassword() {
    return this.registerForm.get('confirmPassword');
  }
  get terms() {
    return this.registerForm.get('terms');
  }

  /**
   * It defines validators and the form for the register of new users
   */
  ngOnInit(): void {
    this.PlanCode = this.route.snapshot.paramMap.get('PlanCode');
    const email = this.route.snapshot.queryParamMap.get('email');
    if (email) {
      this.pendingUserService.add(email).subscribe(() => { });
    }

    // const loginValidators = [
    // Validators.minLength(this.minLoginLength),
    // Validators.maxLength(this.maxLoginLength),
    // ];
    // this.isFullNameRequired && loginValidators.push(Validators.required);

    const emailValidators = [Validators.pattern(EMAIL_PATTERN)];
    this.isEmailRequired && emailValidators.push(Validators.required);

    const passwordValidators = [
      Validators.minLength(this.minLength),
      Validators.maxLength(this.maxLength),
    ];
    this.isPasswordRequired && passwordValidators.push(Validators.required);

    this.registerForm = this.fb.group({
      // fullName: this.fb.control('', [...loginValidators]),
      email: this.fb.control(email, [...emailValidators]),
      password: this.fb.control('', [...passwordValidators]),
      confirmPassword: this.fb.control('', [...passwordValidators]),
      terms: this.fb.control(false),
    });

    if (email && email.length > 0) {
      setTimeout(() => { this.EMAilChange(null); }, 500);
    };

  }

  onRegister(): void {
    this.user = this.registerForm.value;

    // calorie friend has a plan type for user registration
    this.user.PlanCode = this.PlanCode;

    this.errors = this.messages = [];
    this.submitted = true;

    this.service
      .register(this.strategy, this.user)
      .subscribe((result: NbAuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();
          this.initUserService.initCurrentUser().subscribe();

          // fb pixel
          this.facebookPixelService.load(
            FacebookPixelService.CompleteRegistrationEvent,
          );

          // GA
          this.googleAnalyticsService.eventEmitter(
            FacebookPixelService.CompleteRegistrationEvent,
            'Users',
            'Register',
          );
        } else {
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
        this.cd.detectChanges();
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  /**
   * It opens the Terms And Conditions component on the pop up dialog
   */
  public DisplayTermsAgreement() {
    this.dialogService
      .open(TermsConditionsComponent, { context: {} })
      .onClose.subscribe((accept) => this.SetAgreement(accept));
  }

  public SetAgreement(Accept) {
    this.Aggreement = Accept;
    this.terms.setValue(Accept);
    this.cd.detectChanges();
  }

  public EnableRegistration() {
    const FormValid = this.registerForm.valid; // this.EmailConfirmed

    const Res1 = this.submitted || !FormValid;
    let Res =
      Res1 ||
      this.password.value !== this.confirmPassword.value ||
      !this.Aggreement;
    Res = !Res ? !this.HideDuplicateEmail : true;

    return Res;
  }

  public AcceptAgreement() {
    this.Aggreement = !this.AggreementControl._checked;
  }

  public EMAilChange($event) {
    this.EmailConfirmed = false;

    if (!this.email.invalid) {
      const Exist = this.usersService.userExist(this.email.value);
      Exist.pipe(takeUntil(this.unsubscribe$)).subscribe(
        (Res) => {
          this.HideDuplicateEmail = !Res;
        },
        (err) => {
          this.HideDuplicateEmail = true;
        },
      );
    }
  }

  public EMailValidation() {
    if (!this.email.invalid) {
      const Exist = this.usersService.userExist(this.email.value);
      Exist.pipe(takeUntil(this.unsubscribe$)).subscribe(
        (Res) => {
          this.HideDuplicateEmail = !Res;

          if (this.HideDuplicateEmail) {
            this.dialogService
              .open(EmailAccessibleValidationComponent, {
                context: {
                  eMail: this.email.value,
                },
              })
              .onClose.subscribe((accept) => {
                this.EmailConfirmed = accept;
              });
          }
        },
        (err) => {
          this.HideDuplicateEmail = true;
        },
      );
    }
  }
}

/**
 * Component for the register form
 */
@Component({
  selector: 'ngx-register-landing',
  styleUrls: ['./register.component.scss'],
  templateUrl: './register-landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterLandingComponent extends NgxRegisterComponent {
  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected facebookPixelService: FacebookPixelService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    _fb: FormBuilder,
    _route: ActivatedRoute,
    _dialogService: NbDialogService,
    _pendingUserService: PendingUserService,
    _usersService: UserData,
    initUserService: InitUserService,
  ) {
    super(
      service,
      options,
      cd,
      _fb,
      router,
      _route,
      _dialogService,
      _pendingUserService,
      _usersService,
      facebookPixelService,
      googleAnalyticsService,
      initUserService,
    );
  }
}
