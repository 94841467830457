<div class="skeleton-table">
  <div class="skeleton-table-header">
    <div class="skeleton-cell skeleton-header-cell"></div>
    <div class="skeleton-cell skeleton-header-cell"></div>
    <div class="skeleton-cell skeleton-header-cell"></div>
    <div class="skeleton-cell skeleton-header-cell"></div>
  </div>
  <div class="skeleton-table-row" *ngFor="let _ of [].constructor(rows)">
    <div class="skeleton-cell-general"></div>
    <div class="skeleton-cell-general"></div>
    <div class="skeleton-cell-general"></div>
    <div class="skeleton-cell-general"></div>
  </div>
</div>
