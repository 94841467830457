import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import { BaseFilter, GridData } from '../grid-data';

export abstract class CustomExerciseData {
  abstract list(
    filter: CustomExerciseFilter,
  ): Observable<GridData<CustomExercise>>; // Created by Vinicius
  abstract get gridDataSourceForBlock(): DataSource;
  abstract create(customExercise: any): Observable<CustomExercise>;
  abstract get(id: number): Observable<CustomExercise>;
  abstract delete(id: number): Observable<boolean>; // Updated by Vinicius
  abstract update(order: any): Observable<CustomExercise>;
  abstract GetFilterResult(query: string): Observable<string[]>;
}

export interface CustomExercise {
  id: number;

  searchWeight: number;
  name: string;

  imageName: string;
  videoName: string;

  exerciseImageUrl: string;
  exerciseVideoUrl: string;
  exerciseVideoThumbnailUrl: string;
  youtubeUrl: string;

  selected: boolean;
}

export interface CustomExerciseFilter extends BaseFilter {
  Name?: string;
}
