import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import { map, publishReplay, refCount } from 'rxjs/operators';
import { Exercise, ExerciseData } from 'app/@core/interfaces/common/CalorieFriend/exercise';
import { ExerciseApi } from '../../api/CalorieFriend/exercise.api';

/**
 * Injectable class for the Exercise Service
 */
@Injectable()
export class ExerciseService extends ExerciseData {
  /**
   * Injects a Food API instance and a CustomFood API instance
   * @param api Food API injectable
   */
  constructor(private api: ExerciseApi) { super(); }

  /**
   * Caching mechanism for the 'GetFoodDefinition' API endpoint
   */
  exerciseCaches: Map<string, Observable<Exercise>> = new Map<string,  Observable<Exercise>>();

  /**
   * Service function to get the foods list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.exerciseDataSource;
  }

  /**
   * Service function to get portion information of the food with the identifier @id.
   * It uses caching mechanism to minimize the total calls to the back-end service.
   * So it checks if there is any result cached for the given parameters and returns it if exists.
   * If there is no cache, it calls the back-end API endpoint and add the result into the cache.
   * @param id Identifier of the food
   */
  GetExerciseDefintion(id: string): Observable<Exercise> {
    const key = id;

    if (!this.exerciseCaches.has(key)) {
      const api = this.api.GetExerciseDefintion(id);

      const observable = api.pipe(
        map(data => data), publishReplay(1), refCount(),
      );

      this.exerciseCaches.set(key, observable);
      return this.exerciseCaches.get(key);
    } else {
      return this.exerciseCaches.get(key);
    }

  }

  /**
   * Service function to clear the cache / refresh the cache.
   */
  ClearCache(): void {
    this.exerciseCaches.clear();
  }

  /**
   * Service function to get the foods list filtered by @query
   * @param query Query string
   */
  GetFilterResult(query: string): Observable<string[]> {
   return this.api.GetFilterResult(query) ;
  }

  GetFilterOptions(): Observable<any> {
    return this.api.GetFilterOptions();
  }

}
