import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

import { HttpService } from '../http.service';
import {
  BasePlanFilter,
  FitnessPlan,
  MealPlan,
  TemplatePlan,
} from '../../../../interfaces/common/CalorieFriend/plan';
import { GridData } from '../../../../interfaces/common/grid-data';
import { ProgramPlans, ProgramTemplate } from 'app/@core/interfaces/common/CalorieFriend/program-template';
import { SmartPlanTemplateGridFilter } from 'app/@core/interfaces/common/CalorieFriend/mealTemplates';

export abstract class BasePlansApi<T> {
  protected abstract readonly apiController: string;

  constructor(protected api: HttpService) {}

  // Created by Vinicius
  templateList(filter: BasePlanFilter): Observable<GridData<TemplatePlan<T>>> {
    return this.api.list(`${this.apiController}/templates`, filter);
  }

  /**
   * API function to get the plans list in DataSource format.
   * Method: GET
   * URL: /
   */
  get plansDataSource(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}`
    );
  }

  /**
   * API function to get the plans list.
   * Method: GET
   * URL: /
   */

  list(filter: SmartPlanTemplateGridFilter): Observable<GridData<TemplatePlan<T>>> {
    return this.api.list(
      `${this.apiController}/GetTemplates`,
      filter
    );
  }
  /**
   * API function to get all the plans list of the patient given by
   * {patientId} param in GridData format for displaying graph.
   * @param patientId Identifier of the patient in number format
   * Method: GET
   * URL: /patient/${patientId}
   * Reviewed by Vinicius
   */
  listById(patientId: number): Observable<GridData<FitnessPlan | MealPlan>> {
    return this.api.get(
      `${this.apiController}/patient/${patientId}`
    ) as Observable<GridData<FitnessPlan | MealPlan>>;
  }

  /**
   * API function to get all the plans list of the patient given by
   * {patientGuid} param in GridData format for displaying graph.
   * @param patientGuid Identifier of the patient in number format
   * Method: GET
   * URL: /patient/${patientGuid}
   * Reviewed by Vinicius
   */
  listByGuid(patientGuid: string): Observable<GridData<T>> {
    return this.api.get(
      `${this.apiController}/patient/${patientGuid}`
    ) as Observable<GridData<T>>;
  }

  /**
   * API function to get templates list in DataSource format for ng2 smart table
   * Method: GET
   * URL: /GetTemplates
   */
  get PlanTemplatesDataSource(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/GetTemplates`
    );
  }

  get(id: number): Observable<T> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to add a new plan.
   * @param plan Plan Form data
   * Method: POST
   * URL: /
   * Reviewed by Vinicius
   */
  create(plan: T): Observable<T> {
    return this.api.post(this.apiController, plan);
  }

  /**
   * API function to update an existing plan
   * @param plan Plan Form data
   * Method: PUT
   * URL: /
   * Reviewed by Vinicius
   */
  update(plan: FitnessPlan | MealPlan): Observable<FitnessPlan | MealPlan> {
    return this.api.put(`${this.apiController}`, plan);
  }

  /**
   * API function to duplicate an existing plan.
   * @param id Identifier of the plan in number format
   * Method: POST
   * URL: /duplicate/${id}
   * Reviewed by Vinicius
   */
  duplicate(id: number): Observable<FitnessPlan | MealPlan > {
    return this.api.post(`${this.apiController}/duplicate/${id}`, null);
  }

  /**
   * API function to delete an existing plan.
   * @param id Identifier of the plan in number format
   * Method: DELETE
   * URL: /${id}
   * Reviewed by Vinicius
   */
  delete(id: number): Observable<any> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  /**
   * API function to add a new template.
   * @param Template Template Form data
   * Method: POST
   * URL: /TemplateGenerate
   */
  CreateTemplate(Template: any): Observable<any> {
    return this.api.post(`${this.apiController}/TemplateGenerate/`, Template);
  }

  /**
   * API function to update an existing template
   * @param Template Template Form data
   * Method: POST
   * URL: /TemplateUpdate
   */
  updateTemplate(Template: any, nameOnly = false): Observable<any> {
    if(nameOnly) return this.api.post(`${this.apiController}/TemplateNameUpdate/`, Template);
    return this.api.post(`${this.apiController}/TemplateUpdate/`, Template);
  }

  /**
   * API function to get an existing template bu Id
   * @param Id Template Id
   * Method: GET
   * URL: /GetTemplateById/{id:int}
   */
  getTemplatById(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/GetTemplateById/${id}`);
  }

  // Updated by Vinicius
  duplicateTemplate(templateId: number): Observable<any> {
    return this.api.post(
      `${this.apiController}/template/duplicate/${templateId}`,
      null
    );
  }

  // Updated by Milos
  deleteTemplate(templateId: number): Observable<any> {
    return this.api.post(
      `${this.apiController}/template/delete/${templateId}`,
      null
    );
  }

  /**
   * API function to get the meal plan as PDF format
   * @param Id Platient Identifier
   * @param PlanId Plan Identifier
   * Method: GET
   * URL: /SaveToPDF/${Id}/${PlanId}
   */
  SaveAsPDF(Id: number, PlanId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Accept: 'application/octet-stream',
        'Access-Control-Allow-Headers': 'Content-Type',
      }),
      responseType: 'blob',
    };

    return this.api.get(
      `${this.apiController}/SaveToPDF/${Id}/${PlanId}`,
      httpOptions
    );
  }

  /**
   * API function to get the meal plan as PDF format
   * @param TokenGuid Platient Identifier in GUID format
   * @param PlanId Plan Identifier
   * Method: GET
   * URL: /SaveToPDF/${TokenGuid}/${PlanId}
   */
  SaveAsPDFFromToken(TokenGuid: string, PlanId: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Accept: 'application/octet-stream',
        'Access-Control-Allow-Headers': 'Content-Type',
      }),
      responseType: 'blob',
    };

    return this.api.get(
      `${this.apiController}/SaveToPDF/${TokenGuid}/${PlanId}`,
      httpOptions
    );
  }

  /**
   * API function to send a notfication e-mail to the patient.
   * @param id Patient Identifier
   * @param planId Plan Identifier
   * @param isProgram
   * Method: POST
   * URL: /notify-patient/${id}/${planId}?isProgram=${isProgram}
   * Reviewed by Vinicius
   */
  submitPatientNotification(
    id: number,
    planId: number,
    isProgram: boolean = false
  ): Observable<boolean> {
    return this.api.post(
      `${this.apiController}/notify-patient/${id}/${planId}?isProgram=${isProgram}`,
      null
    );
  }

  /**
   * API function to send a notfication e-mail to the patient.
   * @param token Patient Identifier in Guid format
   * @param planId Plan Identifier
   * @param isProgram
   * Method: POST
   * URL: /SubmitPatientNotification/${Id}/${planId}?isProgram=${isProgram}
   * Reviewed by Vinicius
   */
  submitPatientNotificationByGuid(
    token: string,
    planId: number,
    isProgram: boolean = false
  ): Observable<any> {
    return this.api.post(
      `${this.apiController}/notify-patient/${token}/${planId}?isProgram=${isProgram}`,
      null
    );
  }

  /**
   * API function to activate an existing plan.
   * @param id Identifier of the plan in number format
   * @param active Active
   * Method: PATCH
   * URL: /activate/${id} or /disactive/${id}
   * Reviewed by Vinicius
   */
  activate(id: number, active: boolean): Observable<boolean> {
    return this.api.patch(
      `${this.apiController}/${active ? '' : 'dis'}activate/${id}`,
      null
    );
  }

  /**
   * Service function to move plan in Program Mode to change the zOrders.
   * @param id Identifier of the plan in number format
   * @param previous Previous Index of the plan
   * @param next Next Index of the plan
   * Reviewed by Vinicius
   */
  movePlan(
    patientId: number,
    planId: number,
    previous: number,
    next: number
  ): Observable<boolean> {
    return this.api.post(`${this.apiController}/move-plan`, {
      patientId,
      planId,
      previous,
      next,
    });
  }
}

@Injectable()
export class MealPlansApi extends BasePlansApi<MealPlan> {
  protected readonly apiController: string = 'plans/meal';

  constructor(api: HttpService) {
    super(api);
  }
}

@Injectable()
export class FitnessPlansApi extends BasePlansApi<FitnessPlan> {
  protected readonly apiController: string = 'plans/fitness';

  constructor(api: HttpService) {
    super(api);
  }
}

@Injectable()
export class ProgramTemplateApi extends BasePlansApi<ProgramPlans> {
  protected readonly apiController: string = 'program-template';

  constructor(api: HttpService) {
    super(api);
  }

  
}