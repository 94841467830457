import { Injectable } from "@angular/core";
import { AssessmentSettingsApi } from "../../api/CalorieFriend/assessment-settings.api";
import { Observable } from "rxjs";
import { AssessmentSettings } from "app/@core/interfaces/common/CalorieFriend/assessment-settings";

@Injectable()
export class AssessmentSettingService {
    constructor(private assessmentSettingsApi: AssessmentSettingsApi) {
    }
    public getAssessmentSettings(): Observable<AssessmentSettings> {
        return this.assessmentSettingsApi.getAssessmentSettings();
    }

    public getAssessmentSettingsByUserId(id: number): Observable<AssessmentSettings> {
      return this.assessmentSettingsApi.getAssessmentSettingsByUserId(id);
  }

    public updateAssessmentSettings(assessmentSettings: AssessmentSettings): Observable<AssessmentSettings> {
        return this.assessmentSettingsApi.updateAssessmentSettings(assessmentSettings);
    }
}
