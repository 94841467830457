import { FitnessItem } from './../../../../interfaces/common/CalorieFriend/fitnessItem';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { addDays } from 'date-fns';
import {
  Workout,
  WorkoutDetail,
} from 'app/@core/interfaces/common/CalorieFriend/workout';
import { HttpService } from './../http.service';
import * as DateFns from 'date-fns';
import { map } from 'rxjs/operators';
@Injectable()
export class WorkoutApi {
  private readonly apiController: string = 'plans/fitness/workout';

  constructor(private api: HttpService) {}

  add(workout: Workout) {
    return this.api.post(this.apiController, workout);
  }

  update(id: number, data: Partial<Workout>) {
    return this.api.put(`${this.apiController}/${id}`, data);
  }
  get(id: number) {
    return this.api.get(`${this.apiController}/${id}`);
  }

  getByDate(dayId: number, date: Date): Observable<Workout[]> {
    var today = new Date();
    today.setHours(0, 0, 0);

    return this.api.get(this.apiController, {
      params: {
        dayId,
        startDate: today.toISOString().substring(0, 19), // send today's utc date as ISO format
        endDate: addDays(today, 1).toISOString().substring(0, 19),
      },
    });
  }

  getWorkoutsInRange(patientId: number, startDate: Date, endDate: Date) {
    return this.api
      .get(this.apiController, {
        params: {
          patientId,
          startDate: startDate.toISOString().substring(0, 19),
          endDate: endDate.toISOString().substring(0, 19),
        },
      })
      .toPromise<Workout[]>();
  }

  getLastWorkoutItem(
    workoutId: number
  ): Observable<{ blockId: number; itemIndex: number; setNo: number }> {
    return this.api.get(`${this.apiController}/${workoutId}/lastItem`);
  }

  addDetail(detail: WorkoutDetail): Observable<WorkoutDetail[]> {
    return this.api.post(
      `${this.apiController}/${detail.patientWorkoutDataId}/details`,
      detail
    );
  }

  getDetails(workoutId: number): Observable<WorkoutDetail[]> {
    return this.api.get(`${this.apiController}/${workoutId}/details`);
  }

  getDetailsAll(workoutId: number): Observable<WorkoutDetail[]> {
    return this.api.get(`${this.apiController}/${workoutId}/details/all`);
  }

  getComplianceScore(patientId: number, startDate: Date, endDate?: Date) {
    return this.api.get(`${this.apiController}/compliance/${patientId}`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: (endDate || new Date()).toISOString()
      }
    });
  }
}
