import { Observable } from 'rxjs';


export interface ExerciseLog {
  id: number;

  patientID: number;

  fitnessItemID: number;

  exerciceId: string;

  dateTime: Date;

  snapshot: string | any;

  firstSetWeight: number;
  firstRepDone: number;
}

export abstract class ExerciseLogData {
  abstract ExerciseLogsList(patientId: number, fitnessItemId: number): Observable<Array<ExerciseLog>>;
  abstract create(log: any): Observable<ExerciseLog>;
  abstract delete(id: number): Observable<boolean>;
  abstract update(log: any): Observable<ExerciseLog>;
  abstract getLastExerciseLog(fitnessItemId: number): Observable<ExerciseLog>;
}
