<div class="EditControlWrapper">
  <div *ngIf="readonly">
    <label>
      <div [innerHTML]="formattedValue"></div>
    </label>
  </div>

  <div *ngIf="!readonly">
    <nb-checkbox
      class="m-1"
      *ngFor="let day of WeekDays"
      [checked]="isChecked(day)"
      (checkedChange)="onToggle(day)"
    >
      {{ day }}
    </nb-checkbox>
  </div>
</div>
