<div *ngFor="let exerciseLog of sortedLogByDate; let i = index">
  <p class="logged-date-time">Logged At: {{formatDateAndTime(exerciseLog.dateTime)}}</p>
  <div class="mb-2">
  <mat-table [dataSource]="exerciseLog.snapshot" class="mat-elevation-z8">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef> {{convertCamelCaseToTitleCase(column)}} </mat-header-cell>
      <mat-cell *matCellDef="let element" [attr.data-label]="convertCamelCaseToTitleCase(column)"> {{element[column] || '-'}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
</div>
