<div #scrollContainer class="items-container" (document:mousemove)="onMouseMove($event)" (mousewheel)="onWheel($event)"
  style="width:100%" (window:resize)="onResize($event)">

  <div class="items-container" style="width:100%" #ItemsContainer>
    <div class="meal_all_items_container" #rowLayout cdkDropListGroup>

      <div class="meal-items-row" *ngFor="let itemsRow of getItemsTable()" cdkDropList
        cdkDropListOrientation="horizontal" [cdkDropListData]="itemsRow" 
        (cdkDropListDropped)="reorderDroppedItem($event)">
        <ng-container *ngFor="let item of itemsRow">

          <div *ngIf="item!=null" cdkDrag [cdkDragStartDelay]="cdkDelay()" [cdkDragDisabled]="PlanView" class="item-box-container">

            <div class="drag-placeholder" *cdkDragPlaceholder></div>
            <div class="meal-item-container" [hidden]="PlanView">
              <app-meal-item-presentation [MealItem]="item" [DeleteCallBack]="Delete.bind(this)"
                (onItemChange)="onItemChange()"></app-meal-item-presentation>
            </div>

            <div  class="meal-item-container"  [hidden]="!PlanView">
              <app-meal-item-presentation #MealItem [MealItem]="item" [IsViewOnly]="true" [nbPopover]="OverlayComponent"
                nbPopoverTrigger="hover" [nbPopoverContext]="{ OverlayItem: item }" nbPopoverPlacement="right">
              </app-meal-item-presentation>
            </div>
          </div>

        </ng-container>
        

      </div>

      <div class="meal-items-row">

        <div style="display: contents;">
          <div class="quick_add">
            <div [hidden]="PlanView" (click)="SelectItems()">
              <a class="AddItemLocation" id="MealItemImage" src='assets/images/AddItemImage.png'>Add Food</a>
            </div>
          </div>

             <div class="quick_add" >
            <div [hidden]="PlanView" (click)="onQuickAdd()">
              <a class="AddItemLocation">Quick Add</a>
            </div>
          </div>


        </div>

    


      </div>

    </div>

  </div>

</div>