import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'confirm-message-dialog',
  templateUrl: './confirm-message-dialog.component.html',
  styleUrls: ['./confirm-message-dialog.component.scss'],
})
export class ConfirmMessageDialogComponent {
  Title: string = 'Warning';
  Message: string = '';
  isAlert: boolean = false;

  constructor(private dialogRef: NbDialogRef<ConfirmMessageDialogComponent>) {}

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
