import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import {
  GoalData,
  Goal,
  GoalView,
} from '../../../../interfaces/common/CalorieFriend/goals';
import { GridData } from '../../../../interfaces/common/grid-data';
import { GoalsApi } from '../../api/CalorieFriend/goals.api';

/**
 * Injectable class for the Goals Service
 */
@Injectable()
export class GoalsService extends GoalData {
  /**
   * Injects a Goals API instance
   * @param api Goals API injectable
   */
  constructor(private api: GoalsApi) {
    super();
  }

  /**
   * Service function to get the goals list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.goalsDataSource;
  }

  /**
   * API function to get all the goals list of the patient given by {patientId} param or {NotificationToken} param in GridData format for displaying graph.
   * @param patientId Identifier of the patient in number format
   * @param NotificationToken Identifier of the patient in number format
   */
  GoalsList(
    patientId: number,
    NotificationToken: string
  ): Observable<GridData<GoalView>> {
    return this.api.goalsList(patientId, NotificationToken);
  }

  /**
   * API function to get the Goal with the identifier @id
   * @param id Identifier of the goal
   */
  get(id: number): Observable<Goal> {
    return this.api.get(id);
  }

  /**
   * API function to add a new goal.
   * @param goal Goal Form data
   */
  create(goal: any): Observable<Goal> {
    return this.api.add(goal);
  }

  /**
   * API function to delete an existing goal.
   * @param id Identifier of the goal in number format
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * API function to update an existing goal
   * @param goal Goal Form data
   */
  update(goal: any): Observable<Goal> {
    return this.api.update(goal);
  }
}
