
<nb-card>
  <nb-card-header>

        {{PlanMeal.name}} - {{PlanMeal.time}}
   
  </nb-card-header>

  <nb-card-body>

    <div class="row row_mobile">
      <div class="col-lg-12" style="padding-right: 10px;padding-left: 10px">
        <div class="row">
          <div class="mobile_meal_item" *ngFor="let item of PlanMealItems(); let i = index;">
            <app-meal-item-presentation [IsPatientView]="true" [MealItem]="item"></app-meal-item-presentation>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mobile_meal_graph">
        <div style="float:right">
          <app-meal-metrics-graph #MealGraph class="Metrics" [MealCals]="MealCals" [MealProtein]="MealProtein"
            [MealCarbs]="MealCarbs" [MealNetCarbs]="MealNetCarbs" [MealFats]="MealFats" GraphInBack="true">
          </app-meal-metrics-graph>
        </div>
      </div>
    </div>

  </nb-card-body>
</nb-card>


