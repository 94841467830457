import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-compliance-gauge',
  templateUrl: './compliance-gauge.component.html',
  styleUrls: ['./compliance-gauge.component.scss']
})
export class ComplianceGaugeComponent implements OnInit {

  @Input("value")
  value: number;

  @Input("icon")
  icon: string;

  @Input("size")
  size: number = 70;

  constructor() { }

  ngOnInit() {
  }
  
  readonly gaugeLabel = (value) => this.value <= 0 ? 'N/A' : `${Math.round(value * 10) / 10}%`;
}
