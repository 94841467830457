import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import {
  Invoice,
  InvoiceFilter,
  InvoicePaymentStatus,
  PatientWithInvoiceNumber,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { map } from 'rxjs/operators';

@Injectable()
export class InvoicesApi {
  private readonly apiController: string = 'invoices';
  constructor(private api: HttpService) {}

  public list(filter: InvoiceFilter): Observable<GridData<Invoice>> {
    return this.api.list(this.apiController, filter);
  }

  public listForPatient(guid: string, filter: InvoiceFilter): Observable<GridData<Invoice>> {
    return this.api.list(`${this.apiController}/${guid}`, filter);
  }

  public add(invoice: Invoice): Observable<Invoice> {
    return this.api.post(this.apiController, invoice);
  }

  public edit(invoice: Invoice): Observable<Invoice> {
    return this.api.post(this.apiController, invoice);
  }

  public get(id: number, patientGuid?: string): Observable<Invoice> {
    let params: any = {};
    if (patientGuid) params.patientGuid = patientGuid;
    return this.api.get(`${this.apiController}/${id}`, { params });
  }

  public delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  public getPaymentStatus(): Observable<InvoicePaymentStatus> {
    return this.api.get(`${this.apiController}/payments-status`);
  }

  public send(id: number): Observable<boolean> {
    return this.api.post(`${this.apiController}/${id}/send`, {});
  }
}
