import { Injectable } from '@angular/core';
import {
  Invoice,
  InvoiceFilter,
  PatientWithInvoiceNumber,
  InvoicePaymentStatus
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { InvoicesApi } from '../../api/CalorieFriend/invoices.api';

@Injectable()
export class InvoicesService {
  constructor(private api: InvoicesApi) {}

  list(filter: InvoiceFilter): Observable<GridData<Invoice>> {
    return this.api.list(filter).pipe(share());
  }

  listForPatient(guid: string, filter: InvoiceFilter): Observable<GridData<Invoice>> {
    return this.api.listForPatient(guid, filter).pipe(share());
  }

  add(invoice: Invoice): Observable<Invoice> {
    return this.api.add(invoice);
  }

  edit(invoice: Invoice): Observable<Invoice> {
    return this.api.edit(invoice);
  }

  get(id: number, patientGuid?: string): Observable<Invoice> {
    return this.api.get(id, patientGuid).pipe(share());
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  getPaymentStatus(): Observable<InvoicePaymentStatus> {
    return this.api.getPaymentStatus();
  }

  send(id: number): Observable<boolean>{
    return this.api.send(id);
  }
}
