import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';

/**
 * Injectable class for Hidden Similar API
 */
@Injectable()
export class TutorialApi {
  /**
   * API Controller Name - 'Tutorial'
   */
  protected readonly apiController: string = 'Tutorial';

  constructor(protected api: HttpService) { }

  /**
   * API function to show a similar food item.
   * @param Tutorial
   * Method: POST
   * URL: /show
   */
  save(tutorial: string): Observable<any> {
    return this.api.post(`${this.apiController}/save/${tutorial}`, null);
  }

}


