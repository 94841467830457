import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject, Subscription, Observable, of } from 'rxjs';
import { distinct, pairwise, takeUntil, takeWhile, filter, finalize } from 'rxjs/operators';
import {
  CalendarEvent,
  CalendarMonthViewComponent,
  CalendarView,
  collapseAnimation,
  DateAdapter,
} from 'angular-calendar';
import { isSameMonth, isSameDay, addDays } from 'date-fns';

import {
  CalendarOverrideItem,
  Patient,
  PatientData,
  PatientProgramViewType,
} from 'app/@core/interfaces/common/CalorieFriend/patients';
import {
  FitnessPlanData,
  MealPlanData,
  MealPlan,
  FitnessPlan,
  ProgramTemplateData,
} from 'app/@core/interfaces/common/CalorieFriend/plan';
import { BasePlanDay } from 'app/@core/interfaces/common/CalorieFriend/plan-day';
import { ActivatedRoute, Router } from '@angular/router';
import { NativeAppService } from 'app/@core/backend/common/services/native-app.service';
import { PatientsService } from 'app/@core/backend/common/services/CalorieFriend/patients.service';
import { Workout, WorkoutDetailStatus, WorkoutStatus } from 'app/@core/interfaces/common/CalorieFriend/workout';
import { WorkoutService } from 'app/@core/backend/common/services/CalorieFriend/workout.service';
import { NbDialogService } from '@nebular/theme';
import { WorkoutLogsModalComponent } from 'app/pages/CalorieFriend/PatientSheetView/workout-logs-modal/workout-logs-modal.component';
import { FoodDiary, FoodDiaryData } from 'app/@core/interfaces/common/CalorieFriend/food-diary';
import { FoodDiaryLogModalComponent } from 'app/pages/CalorieFriend/PatientSheetView/food-diary-log-modal/food-diary-log-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'ngx-patient-calendar',
  templateUrl: './patient-calendar.component.html',
  styleUrls: ['./patient-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation],
})
export class PatientCalendarComponent implements OnInit {
  CalendarView = CalendarView;
  refreshCalendar: Subject<void> = new Subject();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  calendarOverrides: CalendarOverrideItem[];
  patientVisibility: PatientProgramViewType;

  monthCompliance: [Observable<number>, Observable<number>] = [of(0), of(0)];
  rollingSevenDaysCompliance: [Observable<number>, Observable<number>] = [of(0), of(0)];
  public isLoading: boolean = false;

  @Input() set patient(value: Patient) {
    if (!value || value == undefined) return;

    this._patient = value;
    this.patientVisibility = value.programViewType;
    this.isLoading = true;
    this.patientService
      .getCalendarOverrides(this.patient.id, null)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.isLoading = false;
          this.cdRef.detectChanges();
        })
      )
      .subscribe((result) => {
        if (result) this.calendarOverrides = result;

        this.loadEvents();

        if (this.isClientView) {
          var hasEvent = this.events.find((p) => new Date(p.start).toString() === new Date().toString()) != null;
          if (hasEvent) {
            this.activeDayIsOpen = true;
          }
        }
      });

    this.monthCompliance = this.getMonthCompliance(this.today.getFullYear(), this.today.getMonth() + 1);
    this.rollingSevenDaysCompliance = this.getRollingSevenDaysCompliance();
  }

  @Input() isClientView: boolean = false;
  @Input() isProgramTemplate: boolean = false;

  get patient(): Patient {
    return this._patient;
  }
  readonly WorkoutStatus = WorkoutStatus;

  events: CalendarEvent<PlanEvent>[] = [];
  loaded: boolean = false;
  view: CalendarView = CalendarView.Month;
  lastClickedDate: Date;
  day: any;
  today: Date = new Date();
  showHelpMessage: boolean = false;

  _viewDate = new BehaviorSubject<Date>(new Date());
  get viewDate(): Date {
    return this._viewDate.value;
  }
  set viewDate(value: Date) {
    this._viewDate.next(value);

    if (!isSameMonth(this.lastClickedDate, value)) {
      this.activeDayIsOpen = false;
    }
  }

  minimumEventHeight: number = 80;
  activeDayIsOpen: boolean = false;

  private _patient: Patient = null;
  private fitnessPlans: FitnessPlan[] = [];
  private mealPlans: MealPlan[] = [];

  private _workoutSubscription: Subscription;
  workoutsInMonth: Workout[];
  diariesInMonth: FoodDiary[];

  constructor(
    private fitnessPlanService: FitnessPlanData,
    private mealPlanService: MealPlanData,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private nativeApp: NativeAppService,
    protected dateAdapter: DateAdapter,
    private patientService: PatientData,
    private workoutService: WorkoutService,
    private dialogService: NbDialogService,
    private foodDiaryService: FoodDiaryData,
    private programTemplateService: ProgramTemplateData
  ) {}

  ngOnInit(): void {
    if (this.isProgramTemplate) {
      this.activeRoute.params.subscribe((params) => {
        if (params['id']) {
          this.loadEvents(params['id'] as number);

          if (this.isClientView) {
            var hasEvent = this.events.find((p) => new Date(p.start).toString() === new Date().toString()) != null;
            if (hasEvent) {
              this.activeDayIsOpen = true;
            }
          }

          this.monthCompliance = this.getMonthCompliance(this.today.getFullYear(), this.today.getMonth() + 1);
          this.rollingSevenDaysCompliance = this.getRollingSevenDaysCompliance();
        }
      });
    }

    /**
     * Compliance Logic
     */
    // this._viewDate
    //   .pipe(
    //     pairwise(),
    //     filter(([prev, curr]) => {
    //       return !(prev.getFullYear() == curr.getFullYear() && prev.getMonth() == curr.getMonth());
    //     })
    //   )
    //   .subscribe({
    //     next: ([, date]) => {
    //       // no need to run this, always same
    //       //this.monthCompliance = this.getMonthCompliance(date.getFullYear(), date.getMonth() + 1);
    //     },
    //   });
  }

  public refresh() {
    this.patient = this.patient;
  }
  public isDateSmaller(firstDate, secondDate): boolean {
    let now = moment.utc(firstDate).startOf('day').format('YYYY-MM-DD'); // get current UTC time
    let otherDate = moment.utc(secondDate).startOf('day').format('YYYY-MM-DD'); // convert your UTC date string to a moment object
    if (moment(now).isAfter(otherDate)) {
      return true;
    } else {
      return false;
    }
  }

  private async loadEvents(programTemplateId: number = -1): Promise<void> {
    console.log('loadEvents');
    this.loaded = false;
    this.isLoading = true;
    this.events = [];
    this.cdRef.detectChanges();
    if (this.isProgramTemplate && programTemplateId > 0) {
      console.log('loadEvents programTemplateId', programTemplateId);
      this.programTemplateService.GetTemplateById(programTemplateId)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.cdRef.detectChanges();
      }))
      .subscribe(({ programPlans }) => {
        this.fitnessPlans = programPlans?.fitnessPlans ?? [];
        this.mealPlans = programPlans?.mealPlan ?? [];
        if (this.fitnessPlans.length > 0) this.checkPlan(this.fitnessPlans, true);
        if (this.mealPlans.length > 0) this.checkPlan(this.mealPlans, false);

        if (this.events.length == 0 && !this.isClientView) this.showHelpMessage = true;

        this._workoutSubscription?.unsubscribe();
        this._workoutSubscription = this._viewDate.subscribe(async (date) => {
          //if (isSameMonth(this.lastClickedDate, date)) return;
          this.lastClickedDate = date;
          this.workoutsInMonth = [];

          this.diariesInMonth = [];

          this.checkDiaries();
          this.checkWorkout();
          this.loaded = true;
          this.cdRef.detectChanges();
        });
      });
    } else {
      combineLatest([
        this.fitnessPlanService.listByGuid(this.patient.patientGuid),
        this.mealPlanService.listByGuid(this.patient.patientGuid),
      ])
        .pipe(
          takeWhile(() => !this.loaded),
          finalize(() => {
            this.isLoading = false;
            this.cdRef.detectChanges();
          }))
        .subscribe(([fitnessPlans, mealPlans]) => {
          this.fitnessPlans = fitnessPlans?.items ?? [];
          this.mealPlans = mealPlans?.items ?? [];
          if (this.patient.mealProgramMode > 0) this.checkPlan(this.mealPlans, false);
          if (this.patient.fitnessProgramMode > 0) {
            this.checkPlan(this.fitnessPlans);
          }

          if (this.events.length == 0 && !this.isClientView) {
            this.showHelpMessage = true;
          }

          this._workoutSubscription?.unsubscribe();
          this._workoutSubscription = this._viewDate.subscribe(async (date) => {
            this.lastClickedDate = date;
            this.workoutsInMonth = await this.workoutService.getWorkoutsInMonth(
              this.patient.id,
              this.viewDate.getFullYear(),
              this.viewDate.getMonth()
            );

            this.diariesInMonth = await this.foodDiaryService.getDiariesInMonth(
              this.patient.id,
              this.viewDate.getFullYear(),
              this.viewDate.getMonth()
            );

            this.checkDiaries();
            this.checkWorkout();
            this.loaded = true;
            this.cdRef.detectChanges();
          });
        });
    }
  }

  private checkDiaries() {
    for (let i in this.diariesInMonth) {
      const diary = this.diariesInMonth[i];

      const wStartDate = new Date(Date.parse(diary.day.toString()));
      const ev = this.events.find((e) => e.meta.isMealPlan && e.start.toUTCString() == wStartDate.toUTCString());
      if (ev) {
        ev.meta.diaryCompleted = true;
        ev.meta.completed = true;
      } else {
        this.events.push({
          start: wStartDate,
          title: 'Food Diary',
          meta: {
            dayId: 0,
            isMealPlan: true,
            originalDate: wStartDate,
            planId: 0,
            diaryCompleted: true,
            unplanned: true,
          },
        });
      }
      //console.log(ev);
    }

    this.events = [...this.events];
    this.cdRef.markForCheck();
  }

  private checkWorkout() {
    for (let i in this.workoutsInMonth) {
      const workout = this.workoutsInMonth[i];
      const wStartDate = new Date(workout.startDateTime + 'Z'); //convert utc date. ???
      const ev = this.events.find(
        (e) =>
          !e.meta.isMealPlan &&
          e.start <= wStartDate &&
          wStartDate <= addDays(e.start, 1) &&
          workout.dayId == e.meta.dayId
      );
      if (ev) {
        ev.meta.workoutStatus = workout.status;
        ev.meta.workoutId = workout.id;
        ev.meta.completed = true;
      } else {
        if (workout.status != WorkoutDetailStatus.Completed) continue;
        const planForWorkout = this.fitnessPlans.find((p) => p.id === workout.planId);
        const dayForWorkout = planForWorkout?.days.find((d) => d.id === workout.dayId);

        let title = 'Workout';

        if (planForWorkout && dayForWorkout) {
          title = planForWorkout.name + ' - ' + dayForWorkout.name + ' (Unplanned)';
        }
        this.events.push({
          start: new Date(workout.startDateTime + 'Z'),
          title: title,
          meta: {
            dayId: workout.dayId,
            isMealPlan: false,
            originalDate: new Date(workout.startDateTime + 'Z'),
            planId: workout.planId,
            workoutId: workout.id,
            workoutStatus: WorkoutStatus.Completed,
            unplanned: true,
          },
        });
      }
      //console.log(ev);
    }

    this.events = [...this.events];
    this.cdRef.markForCheck();
  }
  private checkPlan(plans: MealPlan[] | FitnessPlan[], isFitness: boolean = true): void {
    let planStartDate: Date = new Date();
    if (!this.isProgramTemplate) {
      planStartDate = new Date(isFitness ? this.patient.fitnessProgramStartDate : this.patient.mealProgramStartDate);
    }
    planStartDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < plans.length; planStartDate.setDate(planStartDate.getDate() + plans[i++].duration * 7)) {
      if (!plans[i].active && !this.isProgramTemplate) break;
      const planEndDate: Date = new Date(planStartDate);
      planEndDate.setDate(planEndDate.getDate() + plans[i].duration * 7);

      plans[i].days.forEach((day: BasePlanDay) => {
        for (const date = new Date(planStartDate); date < planEndDate; date.setDate(date.getDate() + 1)) {
          if (day.dayOfWeek.includes(date.getDay())) {
            let override = null;

            if (this.calendarOverrides)
              override = this.calendarOverrides.find(
                (p) =>
                  new Date(p.SourceDay).toString() === new Date(date).toString() &&
                  p.PlanDayId.toString() === day.id.toString() &&
                  p.IsFitnessPlan === isFitness
              );

            if (this.canShowEvent(plans, plans[i].id, new Date(planStartDate), new Date(date))) {
              this.events.push({
                title: plans[i].name + ' - ' + day.name,
                start: override ? new Date(override.DestDay) : new Date(date),
                draggable: !this.isProgramTemplate,
                allDay: true,
                cssClass: isFitness ? 'fitness-node' : 'meal-node',
                meta: {
                  isMealPlan: !isFitness,
                  planId: plans[i].id,
                  dayId: day.id,
                  originalDate: new Date(date),
                },
              });
            }
          }
        }
      });
    }

    this.events = [...this.events];
    this.cdRef.markForCheck();
  }

  canShowEvent(plans: MealPlan[] | FitnessPlan[], planId: number, programStartDate: Date, calendarDay: Date): boolean {
    if (!this.isClientView) return true;
    if (this.patientVisibility.toString() === 'full') return true;

    var test = PatientProgramViewType[this.patientVisibility];

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const startDate = new Date(programStartDate);
    startDate.setHours(0, 0, 0, 0);
    calendarDay.setHours(0, 0, 0, 0);
    var currentPlanId: number = null;

    if (startDate.toDateString() === today.toDateString() || startDate > today) currentPlanId = plans[0].id;
    if (startDate < today) {
      let i = 0;
      while (i < plans.length) {
        startDate.setDate(startDate.getDate() + plans[i].duration * 7);
        if (startDate > today) break;
        i++;
      }
      if (i < plans.length) currentPlanId = plans[i].id;
    }

    switch (this.patientVisibility.toString()) {
      case 'currentPhase':
        if (currentPlanId && planId.toString() === currentPlanId.toString()) return true;

        break;

      case 'fourWeekFuture':
        var maxDay = new Date();
        maxDay.setDate(maxDay.getDate() + 4 * 7);

        if (calendarDay <= maxDay) {
          return true;
        }

        break;

      case 'eightWeekFuture':
        var maxDay = new Date();
        maxDay.setDate(maxDay.getDate() + 8 * 7);

        if (calendarDay <= maxDay) {
          return true;
        }

        break;

      case 'twelveWeekFuture':
        var maxDay = new Date();
        maxDay.setDate(maxDay.getDate() + 12 * 7);

        if (calendarDay <= maxDay) {
          return true;
        }

        break;
    }

    return false;
  }

  onDayClicked({ date, events }: { date: Date; events: CalendarEvent<PlanEvent>[] }): void {
    if (!isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = false;
      return;
    }

    if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen) || !events.length) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
      this.lastClickedDate = date;
    }
    this.cdRef.detectChanges();
  }

  onEventClicked(event: CalendarEvent<PlanEvent>): void {
    let url = `Client/Patient/${this.patient.patientGuid}/${event.meta.isMealPlan ? 'Meal' : 'Workout'}/${
      event.meta.planId
    }?dayId=${event.meta.dayId}`;

    if (this.isClientView) {
      this.router.navigateByUrl(url);
    } else {
      if (this.nativeApp.isFromApp()) {
        this.nativeApp.sendMessageToApp('openblank', '/' + url);
      } else {
        window.open(url, '_blank');
      }
    }
  }

  eventDropped(dropEvent: CalendarEvent): void {
    this.lastDropEvent = dropEvent;
    if (this.lastMouseOverDay && dropEvent) {
      dropEvent.start = this.lastMouseOverDay;

      let event: CalendarOverrideItem = {
        SourceDay: dropEvent.meta.originalDate,
        DestDay: this.lastMouseOverDay,
        IsFitnessPlan: !dropEvent.meta.isMealPlan,
        PlanDayId: dropEvent.meta.dayId,
      };

      this.patientService
        .setCalendarOverrides(this.patient.id, event, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          if (res) {
            if (!dropEvent.meta.isOverride) {
              this.patient.calendarOverrides.push(event);
            }
          }
        });

      this.viewDate = this.viewDate;
      this.cdRef.detectChanges();
      this.refreshCalendar.next();

      this.lastMouseOverDay = null;
      this.lastDropEvent = null;
    }
  }

  /**
   * open Details Log Dialog for specific event
   */
  viewWorkoutLog(workoutId: number) {
    this.dialogService.open(WorkoutLogsModalComponent, {
      context: {
        patient: this.patient,
        workoutId,
      },
    });
  }

  viewFoodDiaryLog(startDate: Date) {
    this.dialogService.open(FoodDiaryLogModalComponent, {
      context: {
        patientId: this.patient.id,
        userId: this.patient.createdByUserId,
        day: startDate,
      },
    });
  }

  //hack to get last mouse over day because the "day" param from angular-calendar was always undefined.
  lastMouseOverDay: Date;
  lastDropEvent: CalendarEvent;
  mouseDate(day: any): void {
    this.lastMouseOverDay = day.date;
    this.eventDropped(this.lastDropEvent);
  }

  private getMonthCompliance(year: number, month: number): [Observable<number>, Observable<number>] {
    if (!this.patient) return [of(0), of(0)];
    return [of(this.patient.workoutCompliance30), of(this.patient.diaryCompliance30)];
    // * Replaced by month compliance property
    // const from = new Date(year, month - 1, 1),
    //   to = new Date(year, month, 1);
    // return this.getComplianceScore(this.patient.id, from, to);
  }

  private getRollingSevenDaysCompliance(): [Observable<number>, Observable<number>] {
    if (!this.patient) return [of(0), of(0)];
    return [of(this.patient.workoutCompliance7), of(this.patient.diaryCompliance7)];
    // * Replaced by week compliance property
    // const to = this.today;
    // to.setHours(0, 0, 0, 0);
    // const from = moment(to).subtract(7, 'days').toDate();
    // return this.getComplianceScore(this.patient.id, from, to);
  }

  private getComplianceScore(patientId: number, from: Date, to: Date): [Observable<number>, Observable<number>] {
    let workoutScore = this.workoutService.getComplianceScore(patientId, from, to);
    let foodDiaryScore = this.foodDiaryService.getComplianceScore(patientId, from, to);
    return [workoutScore, foodDiaryScore];
  }
}

interface PlanEvent {
  isMealPlan: boolean;
  planId: number;
  dayId: number;
  originalDate: Date;
  workoutId?: number;
  workoutStatus?: WorkoutStatus | string;
  diaryCompleted?: boolean;
  unplanned?: boolean;
  completed?: boolean;
}
