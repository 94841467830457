<nb-card>
  <nb-card-header>Add an invoice item</nb-card-header>
  <nb-card-body>
    <div class="w-100 my-2">
      <input nbInput fullWidth="true" placeholder="Item Name" [(ngModel)]="itemName" />
    </div>
    <div class="d-flex align-items-center my-2">
      <span >{{currencySymbol}}</span>
      <input
        nbInput
        fullWidth="true"
        placeholder="Item Price"
        type="number"
        [(ngModel)]="itemPrice"
        class="flex-grow-1"
      />
    </div>
    <div class="w-100">
      <nb-select placeholder="Currency" [(ngModel)]="itemCurrency" class="w-100">
        <nb-option *ngFor="let currency of currencies" [value]="currency.name">
          {{ currency.name }} - {{ currency.description }}
        </nb-option>
      </nb-select>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton nbButton
      size="large"
      shape="round"
      status="primary"
      class="w-100 mx-3"
      color="primary" class="w-100" (click)="onAdd()" [disabled]="!itemName || !itemPrice"
    >
      Add
    </button>
  </nb-card-footer>
</nb-card>
