import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { map } from 'rxjs/operators';
import { PatientSubscriptionMeta, PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';

@Injectable()
export class PatientSubscriptionsMetaApi {
  private readonly apiController: string = 'patient-subscriptions-meta';
  constructor(private api: HttpService) {}

  public list(filter: PatientSubscriptionsFilter): Observable<GridData<PatientSubscriptionMeta>> {
    return this.api.list(this.apiController, filter);
  }

  public add(subscription: PatientSubscriptionMeta): Observable<PatientSubscriptionMeta> {
    return this.api.post(this.apiController, subscription);
  }

  public edit(subscription: PatientSubscriptionMeta): Observable<PatientSubscriptionMeta> {
    return this.api.post(this.apiController, subscription);
  }

  public get(id: number, patientGuid?: string): Observable<PatientSubscriptionMeta> {
    let params: any = {};
    if (patientGuid) params.patientGuid = patientGuid;
    return this.api.get(`${this.apiController}/${id}`, { params });
  }

  public delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  public send(id: number, patientIds: number[]): Observable<number>{
    return this.api.post(`${this.apiController}/send`, {
      id, patientIds
    });
  }
}
