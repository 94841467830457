import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ExerciseLogData,
  ExerciseLog,
} from '../../../../interfaces/common/CalorieFriend/exerciseLog';
import { ExerciseLogApi } from '../../api/CalorieFriend/exerciseLog.api.';

/**
 * Injectable class for the ExerciseLog Service
 */
@Injectable()
export class ExerciseLogService extends ExerciseLogData {
  /**
   * Injects a Biometric History API instance
   * @param api Biometric History API injectable
   */
  constructor(private api: ExerciseLogApi) {
    super();
  }

  /**
   * Service function to get the biometric histories list of the patient given by {patientId} param or {NotificationToken} param in DataSource format which can be used for ng2 smart table.
   * @param patientId Identifier of the Patient in number format
   * @param notificationToken Identifier of the Patient in GUID format for anonymous users
   */
  ExerciseLogsList(
    patientId: number,
    fitnessItemId: number
  ): Observable<Array<ExerciseLog>> {
    return this.api.exerciseLogsList(patientId, fitnessItemId);
  }

  getExerciseLogsListByExerciseId(patientId: number, exerciseId: string) {
    return this.api.exerciseLogsListByExerciseId(patientId, exerciseId);
  }

  getLastExerciseLog(fitnessItemId: number): Observable<ExerciseLog> {
    return this.api.getLastExerciseLog(fitnessItemId);
  }

  /**
   * Service function to add a new biometric history.
   * @param exerciseLog Biometric History Form data
   */
  create(exerciseLog: any): Observable<ExerciseLog> {
    return this.api.add(exerciseLog);
  }

  /**
   * Service function to delete an existing biometric history.
   * @param id Identifier of the biometric history in number format
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to update an existing biometric history
   * @param exerciseLog Biometric History Form data
   */
  update(exerciseLog: any): Observable<ExerciseLog> {
    return this.api.update(exerciseLog);
  }
}
