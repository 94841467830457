import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-skeleton-item-list',
  templateUrl: './skeleton-item-list.component.html',
  styleUrls: ['./skeleton-item-list.component.scss']
})
export class SkeletonItemListComponent implements OnInit {

  @Input()
  rows = 10;

  constructor() { }

  ngOnInit(): void {
  }

}
