



<nb-card class="nb-card-auth">

  <nb-card-body>



  <div style="width:100%; margin:auto">


    <h1 i18n="@@VerifyEmail" id="title" class="title">Verify Email</h1>

    <nb-alert *ngIf="!submitted" outline="info" role="alert">
      <ul class="alert-message-list">
        <li class="alert-message">{{ information }}</li>
      </ul>
    </nb-alert>

    <nb-alert *ngIf="submitted" outline="{{verificationResult ? 'success' : 'danger'}}" role="alert">
      <ul class="alert-message-list">
        <li class="alert-message">{{ message }}</li>
      </ul>
    </nb-alert>

    <section class="sign-in-or-up" aria-label="Sign in or sign up" style="margin: 25px;">
      <p><a i18n="@@Login" class="text-link" routerLink="/auth/login">Log In</a></p>
      <p><a i18n="@@Register" class="text-link" routerLink="/auth/cfregister">Register</a></p>
    </section>

  </div>

  
  </nb-card-body>

</nb-card>
