<nb-card class="nb_card_pop_up full_pop_up mobilePopup"
  style="height: 90vh; position: relative; width: 90vw; max-width: 1000px;">
  <nb-card-header>
    <div class="title">
      <span>{{titleLabel}}</span>
    </div>
    <div class="actions">
      <button nbButton ghost shape="round"  tabindex="-1" (click)="onBack()" >
        <nb-icon icon="close-outline" style="z-index: 1000;"></nb-icon>
      </button>
    </div>
  </nb-card-header>

  <nb-card-body [formGroup]="foodItemForm">



    <div class="row">
      <div class="col-sm-8">
        <!-- Name -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@FoodName">Food Name</span> *
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="name" [readonly]="!editable"
              [status]="(name?.errors?.required || name?.errors?.minlength) ? 'danger' : 'primary'" type="text"
              fullWidth shape="semi-round" i18n-placeholder="@@RequiredField"
              placeholder="{{foodNameLabel}} (required)" />
            <ngx-validation-message i18n-label="@@FoodName" label="Food Name" [showMinLength]="name?.errors?.minlength"
              [showRequired]="name?.errors?.required && name.touched" [minLength]="1">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Description -->
        <div class="form-group row">
          <div i18n="@@Description" class="col-sm-4 label col-form-label">Description</div>
          <div class="col-sm-8">
            <textarea nbInput formControlName="description" [readonly]="!editable" class="description-text" rows="2"
              fullWidth shape="semi-round" i18n-placeholder="@@Description" placeholder="Description">
          </textarea>
          </div>
        </div>

        <!-- Default Serving Size -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@DefaultServingSize">Default Serving Size</span> *
          </div>
          <div class="col-sm-8 d-flex">
            <input nbInput formControlName="defaultServingSize" [readonly]="!editable" type="text"
              class="input-with-unit flex-grow-1" shape="semi-round"
              [status]="(defaultServingSize?.errors?.required || defaultServingSize?.errors?.min) ? 'danger' : 'primary'"
              (keypress)="validateNumber($event)" i18n-placeholder="@@RequiredField"
              placeholder="{{defaultServingSizeLabel}} (required)">
              <nb-select formControlName="conversionFactorsValue">
                <nb-option *ngFor="let selectOption of ConversionFactors" [value]="selectOption.value">{{selectOption.text}}</nb-option>
              </nb-select>
            <ngx-validation-message i18n-label="@@DefaultServingSize" label="Default Serving Size"
              [showMin]="defaultServingSize?.errors?.min && defaultServingSize.touched"
              [showRequired]="defaultServingSize?.errors?.required && defaultServingSize.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>
      </div>

      <div class="col-sm-4">

        <div class="img-container" (mouseover)="imgHover=true" (mouseleave)="imgHover=false">

          <div class="food-img-actions" *ngIf="imgFoodItem != null">
            <a href="javascript:void(0)" (click)="onResetFoodImage()">
              <nb-icon class="control-item2" icon="trash-outline"></nb-icon>
            </a>
          </div>

          <div class="img-inside" (click)="onUploadFoodImage()">

            <img class="food-img" *ngIf="imgFoodItem" [src]="imgFoodItem"
              [ngStyle]="{opacity: imgHover==true  ? '0.25' : '1' }" width="100%" height="100%">
            <input type="hidden" formControlName="foodImageUrl" />
          </div>

        </div>



      </div>


    </div>












    <nb-card>
      <nb-card-header>
        <div i18n="@@NutritionFacts">Nutrition Facts</div>
        <div i18n="@@GeneralInfoSmall" class="small-header"></div>
      </nb-card-header>

      <nb-card-body>

        <div class="row">
          <div class="col-sm-12">

            <!-- Meal Metrics Pie Chart -->
            <div class="pie-chart-container">
              <app-meal-metrics-graph [MealCals]="calories.value" [MealProtein]="protein.value"
                [MealCarbs]="carbohydrate.value" [MealFats]="fats.value" [GraphInBack]="true">
              </app-meal-metrics-graph>
            </div>

          </div>


        </div>


        <!-- Calories -->
        <div class="form-group row">
          <div i18n="@@Calories" class="col-sm-4 label col-form-label required-label">Calories</div>
          <div class="col-sm-8">
            <input nbInput formControlName="calories" readonly class="input-with-unit"
              [status]="(calories?.errors?.min) ? 'danger' : 'primary'" type="text" shape="semi-round"
              i18n-placeholder="@@Calories" placeholder="Calories">
            <ngx-validation-message i18n-label="@@Calories" label="Calories"
              [showMin]="calories?.errors?.min && calories.touched"
              [showRequired]="calories?.errors?.required && calories.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Fats -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Fats">Fats</span> *
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="fats" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="(fats?.errors?.required || fats?.errors?.min) ? 'danger' : 'primary'"
              type="text" shape="semi-round" i18n-placeholder="@@RequiredField" placeholder="{{fatsLabel}} (required)">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Fats" label="Fats" [min]="0"
              [showRequired]="fats?.errors?.required && fats.touched" [showMin]="fats?.errors?.min && fats.touched">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Saturated Fat -->
        <div class="form-group row">
          <div i18n="@@SaturatedFat" class="col-sm-4 label col-form-label label-indent">Saturated Fat</div>
          <div class="col-sm-8">
            <input nbInput formControlName="saturatedFat" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="saturatedFat?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@SaturatedFat" placeholder="Saturated Fat">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@SaturatedFat" label="Saturated Fat"
              [showMin]="saturatedFat?.errors?.min && saturatedFat.touched">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Trans Fat -->
        <div class="form-group row">
          <div i18n="@@TransFat" class="col-sm-4 label col-form-label label-indent">Trans Fat</div>
          <div class="col-sm-8">
            <input nbInput formControlName="transFat" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="transFat?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@TransFat" placeholder="Trans Fat">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@TransFat" label="Trans Fat"
              [showMin]="transFat?.errors?.min && transFat.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Cholesterol -->
        <div class="form-group row">
          <div i18n="@@Cholesterol" class="col-sm-4 label col-form-label">Cholesterol</div>
          <div class="col-sm-8">
            <input nbInput formControlName="cholesterol" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="cholesterol?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Cholesterol" placeholder="Cholesterol">
            <div class="label col-form-label col-unit-label">mg</div>
            <ngx-validation-message i18n-label="@@Cholesterol" label="Cholesterol"
              [showMin]="cholesterol?.errors?.min && cholesterol.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Sodium -->
        <div class="form-group row">
          <div i18n="@@Sodium" class="col-sm-4 label col-form-label">Sodium</div>
          <div class="col-sm-8">
            <input nbInput formControlName="sodium" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="sodium?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Sodium" placeholder="Sodium">
            <div class="label col-form-label col-unit-label">mg</div>
            <ngx-validation-message i18n-label="@@Sodium" label="Sodium"
              [showMin]="sodium?.errors?.min && sodium.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Carbohydrate -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Carbohydrate">Carbohydrate</span> *
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="carbohydrate" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit"
              [status]="(carbohydrate?.errors?.required || carbohydrate?.errors?.min) ? 'danger' : 'primary'"
              type="text" shape="semi-round" i18n-placeholder="@@RequiredField"
              placeholder="{{carbohydrateLabel}} (required)">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Carbohydrate" label="Carbohydrate"
              [showRequired]="carbohydrate?.errors?.required && carbohydrate.touched"
              [showMin]="carbohydrate?.errors?.min && carbohydrate.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Fiber -->
        <div class="form-group row">
          <div i18n="@@Fiber" class="col-sm-4 label col-form-label label-indent">Fiber</div>
          <div class="col-sm-8">
            <input nbInput formControlName="fiber" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="fiber?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Fiber" placeholder="Fiber">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Fiber" label="Fiber" [showMin]="fiber?.errors?.min && fiber.touched"
              [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Sugar -->
        <div class="form-group row">
          <div i18n="@@Sugar" class="col-sm-4 label col-form-label label-indent">Sugar</div>
          <div class="col-sm-8">
            <input nbInput formControlName="sugar" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="sugar?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Sugar" placeholder="Sugar">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Sugar" label="Sugar" [showMin]="sugar?.errors?.min && sugar.touched"
              [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Protein -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Protein">Protein</span> *
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="protein" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit"
              [status]="(protein?.errors?.required || protein?.errors?.min) ? 'danger' : 'primary'" type="text"
              shape="semi-round" i18n-placeholder="@@RequiredField" placeholder="{{proteinLabel}} (required)">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Protein" label="Protein"
              [showRequired]="protein?.errors?.required && protein.touched"
              [showMin]="protein?.errors?.min && protein.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Alcohol -->
        <div class="form-group row">
          <div i18n="@@Alcohol" class="col-sm-4 label col-form-label">Alcohol</div>
          <div class="col-sm-8">
            <input nbInput formControlName="alcohol" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="alcohol?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Alcohol" placeholder="Alcohol">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Alcohol" label="Alcohol"
              [showMin]="alcohol?.errors?.min && alcohol.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>
      </nb-card-body>
    </nb-card>




  </nb-card-body>

  <nb-card-footer>
    <button i18n="@@Save" nbButton *ngIf="editable" size="small" (click)="onSave()"  class="btn-circle btn-xl btn-main btn-main"
      [disabled]="!foodItemForm.valid">Save</button>
  </nb-card-footer>
</nb-card>