import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IChatUser } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class ChatUserService {
  private _currentUser: BehaviorSubject<IChatUser> = new BehaviorSubject(null);

  get currentUser() {
    return this._currentUser.value;
  }
  get onUserChange() {
    return this._currentUser;
  }
  set currentUser(newUser: IChatUser) {
    this._currentUser.next(newUser);
  }
  constructor() {}
}
