import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { SubscriptionPlanData, SubscriptionPlan } from '../../../../interfaces/common/CalorieFriend/subscriptionPlan';
import { SubscriptionPlansApi } from '../../api/CalorieFriend/subscriptionPlans.api';

/**
 * Injectable class for the SubscriptionPlans Service
 */
@Injectable()
export class SubscriptionPlansService extends SubscriptionPlanData {
  /**
   * Injects a SubscriptionPlans API instance and an Auth Service instance
   * @param api SubscriptionPlans API injectable
   * @param authService Auth Service injectable
   */
  constructor(private api: SubscriptionPlansApi, private authService: NbAuthService) {
    super ();
  }

  /**
   * Service function to get the subscription plans list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.subscriptionPlansDataSource;
  }

  /**
   * Service function to get the subscription plans list in array format.
   */
  list(): Observable<SubscriptionPlan[]> {
    return this.api.list();
  }

  /**
   * Service function to get the available (choosable) subscription plans list in array format.
   */
   getAvailableList(): Observable<SubscriptionPlan[]> {
    return this.api.getAvailableList();
  }

  /**
   * Service function to add a new subscription plan.
   * @param SubscriptionPlan Subscription Plan Form data
   */
  create(SubscriptionPlan: any): Observable<SubscriptionPlan> {
    return this.api.add(SubscriptionPlan);
  }

  /**
   * Service function to get the subscription plan with the identifier @id
   * @param id Identifier in number format
   */
  get(id: number): Observable<SubscriptionPlan> {
    return this.api.get(id);
  }

  /**
   * Service function to get the subscription plan with the plan code @planCode
   * @param planCode Plan Code
   */
   getByCode(planCode: string): Observable<SubscriptionPlan> {
     return this.api.getByCode(planCode);
   }

  /**
   * Service function to update an existing subscription plan
   * @param plan Subscription Plan Form data
   */
  update(plan: SubscriptionPlan): Observable<SubscriptionPlan> {
    return this.api.update(plan);
  }

  /**
   * Service function to delete an existing subscription plan.
   * @param id Identifier of the subscription plan in number format
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
