import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, EventEmitter } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  from,
  Subject,
} from 'rxjs';
import { ChatThreadService } from './services/chat-thread.service';
import {
  FromEnum,
  IActiveUserConnections,
  IChatMessage,
  IChatMessageDTO,
  IChatThread,
  IChatUser,
  IUserConnectionInfo,
  ChatThread,
} from './domain';
import { ChatUserService } from './services/chat-user.service';
import { ChatApi } from './api/chat.api';
import { ChatMessageService } from './services/chat-message.service';
import { map, tap, filter, switchMap, share, shareReplay } from 'rxjs/operators';
import { User } from 'app/@core/interfaces/common/users';
import { environment } from 'environments/environment';
import { NbToastrService } from '@nebular/theme';



@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private connectionUrl = environment.chatHubUrl;
  private hubConnection: HubConnection;

  private activeUserConnections: BehaviorSubject<IActiveUserConnections> =
    new BehaviorSubject({});
  private isPatient = false;

  private onHubConnected: EventEmitter<void> = new EventEmitter();

  private threads$: Observable<ChatThread[]>;

  constructor(
    private threadService: ChatThreadService,
    private messageService: ChatMessageService,
    private chatUserService: ChatUserService,
    private chatApi: ChatApi,
    private zone: NgZone,
    private toastrService: NbToastrService
  ) {
    const unseenMessages = combineLatest([
      this.chatUserService.onUserChange,
      this.onHubConnected,
    ]).pipe(
      switchMap(([user, state]) => {
        if (!user) return of<IChatMessage[]>([]);
        const [userId, patientId] = this.getUserAndPatientId(user, null);
        return this.chatApi
          .getUnSeenMessages(
            userId,
            patientId,
            !this.isPatient ? FromEnum.Patient : FromEnum.User
          )
          .pipe(map((messsages) => messsages.map((m) => this.fromDTO(m))));
      })
    );
    unseenMessages.subscribe({
      next: (messages) => {
        //console.log('messages', messages);
        this.messageService.clear.next();
        this.messageService.add.next(messages);
      },
    });

    this.activeUserConnections.subscribe((activeUsers) => {
      this.threadService.updateThreadUsersOnline(activeUsers);
    });

    this.threads$ = combineLatest([
      this.threadService.onThreadsChange,
      this.messageService.messages,
    ]).pipe(
      map(([threads, messages]) => {
        return threads
          .map<ChatThread>((t) => {
            const unSeenMessages = messages.filter(
              (msg) => msg.threadId == t.id && !msg.seen && !msg.reply
            );

            const lastMessage = unSeenMessages.reduce(
              (last, m) => (last?.dateTime > m.dateTime ? last : m),
              null
            );
            console.log("UnSeen Messages", unSeenMessages, lastMessage);
            return {
              ...t,
              unSeenCount: unSeenMessages.length,
              lastMessage,
            };
          })
          .sort((t1, t2) => {
            return (t2.lastMessage?.id || 0) - (t1.lastMessage?.id || 0);
            if(t2.lastMessage?.dateTime != t1.lastMessage?.dateTime)
              return t2.lastMessage?.dateTime > t1.lastMessage?.dateTime ? 1 : -1;
            return 0;
          });
      }),
      shareReplay(1)
    );
  }

  public getThreads = () => {
    return this.threads$;
  }

  public connect = (user: IChatUser, isPatient: boolean = false) => {
    const url = `${this.connectionUrl}?userId=${isPatient ? 'p_' : 'u_'}${
      user.id
    }`;
    console.log('Message hub', 'Connecting to');
    this.hubConnection = this.getConnection(url);
    this.startConnection();

    this.addLiseners();
    this.registerUser(user, isPatient);
  };

  public reconnect = async () => {
    if (this.hubConnection.state == HubConnectionState.Disconnected) {
      if (await this.startConnection()) {
        console.log('reconnect...');
      }
    }
  };

  private getConnection(url: string): HubConnection {
    console.log("Getting connection");
    return new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
  }

  private async startConnection() {
    try {
      console.log('Message hub', 'Connection starting..');
      await this.hubConnection.start();

      this.onHubConnected.emit();
      return true;
    } catch (e) {
      console.log('startConnection error establishing connection hub', e);
      //this.toastrService.danger('Connection failed.', 'Message Hub');
    }
    return false;
  }

  public registerThreads(threads: IChatThread[]) {
    this.threadService.threads = threads;
    /**
     * get online threads from the signalR and update threads list
     */
    this.threadService.updateThreadUsersOnline(
      this.activeUserConnections.value
    );
  }
  private registerUser(user: IChatUser, isPatient: boolean) {
    this.isPatient = isPatient;
    this.chatUserService.currentUser = user;
  }

  public sendMessageToHub(message: IChatMessageDTO) {
    const promise = this.hubConnection.invoke<IChatMessageDTO>(
      'BroadcastNewMessageAsync',
      message
    );
    return from(promise);
  }

  private addLiseners() {
    this.hubConnection.on('messageReceivedFromHub', (data: IChatMessageDTO) => {
      this.zone.run(() => {
        console.log('Message hub', 'Received message', data);
        const msg = this.fromDTO(data);

        this.messageService.add.next(msg);
      });
    });

    this.hubConnection.on(
      'newUserConnected',
      (msg, info: IUserConnectionInfo) => {
        this.zone.run(() => {
          // console.log('Message hub', msg, info);
          this.activeUserConnections.next({
            ...this.activeUserConnections.value,
            [info.userId]: info.connectionIds,
          });
        });
      }
    );
    this.hubConnection.on(
      'userDisconnected',
      (msg, info: IUserConnectionInfo) => {
        this.zone.run(() => {
          // console.log('Message hub', msg, info);
          this.threadService.threads = this.threadService.threads.map((th) => {
            if (th.users[0].uid == info.userId)
              th.users[0].lastSeen = new Date().toISOString();
            return th;
          });
          this.activeUserConnections.next({
            ...this.activeUserConnections.value,
            [info.userId]: info.connectionIds,
          });
        });
      }
    );

    this.hubConnection.on(
      'sendActiveUsers',
      (activeUsersInfo: IUserConnectionInfo[]) => {
        this.zone.run(() => {
          // console.log('send active users', activeUsersInfo);
          const conn = this.activeUserConnections.value;
          for (let info of activeUsersInfo) {
            conn[info.userId] = info.connectionIds;
          }
          this.activeUserConnections.next(conn);
        });
      }
    );

    this.hubConnection.onreconnected(() => {
      this.onHubConnected.emit();
    });

    this.hubConnection.onclose((err) => {
      setTimeout(() => {
        this.reconnect();
      }, 10);
    });
  }

  public async addMessage(message: IChatMessage) {
    if (this.hubConnection.state == HubConnectionState.Disconnected) {
      this.toastrService.success(
        'Chat Connection lost, reconnecting...',
        'Message Hub'
      );
      await this.startConnection();
    }
    const msgDTO = this.toDTO(message);
    this.sendMessageToHub(msgDTO)
      .pipe(
        filter((resDTO) => Boolean(resDTO)),
        map((resDTO) => this.fromDTO(resDTO))
      )
      .subscribe({
        next: (msg) => {
          this.messageService.add.next(msg);
        },
      });
  }

  public fetchMessages(thread: IChatThread, beforeDate?: string) {
    const [userId, patientId] = this.getUserAndPatientId(
      this.chatUserService.currentUser,
      thread
    );

    const fetchingSub = this.chatApi.get(userId, patientId, beforeDate).pipe(
      map((resDTOList) => {
        return resDTOList.map((resDTO) => this.fromDTO(resDTO));
      }),
      share()
    );
    fetchingSub.subscribe({
      next: (msgList) => {
        if (msgList.length > 0) this.messageService.add.next(msgList);
      },
    });
    return fetchingSub;
  }

  public markThreadAsRead(thread: IChatThread) {
    const [userId, patientId] = this.getUserAndPatientId(
      this.chatUserService.currentUser,
      thread
    );
    this.chatApi
      .markAsRead(
        userId,
        patientId,
        !this.isPatient ? FromEnum.Patient : FromEnum.User
      )
      .subscribe({
        next: () => {
          this.messageService.markThreadAsRead.next(thread);
        },
      });
  }

  get totalUnseenCount(): Observable<number> {
    return this.messageService.messages.pipe(
      map((messages) => {
        return messages.filter((msg) => !msg.seen && !msg.reply).length;
      })
    );
  }
  /**
   * Private Methods
   */
  private fromDTO(dto: IChatMessageDTO): IChatMessage {
    if (!dto) return null;
    const {
      dateTime,
      id,
      from,
      userId,
      patientId,
      messagePayloads: payloads,
      seen,
    } = dto;
    const threadId = this.isPatient ? userId : patientId;
    const thread = this.threadService.threads.find((t) => t.id == threadId);
    const senderId = from == FromEnum.User ? userId : patientId;

    return {
      id,
      seen,
      payloads,
      dateTime,
      threadId,
      senderId,
      reply:
        (from == FromEnum.User && !this.isPatient) ||
        (from == FromEnum.Patient && this.isPatient)
          ? true
          : false,
    };
  }

  private getUserAndPatientId(user: IChatUser, thread: IChatThread) {
    let [userId, patientId] = !this.isPatient
      ? [user?.id, thread?.id]
      : [thread?.id, user?.id];
    return [userId, patientId];
  }

  private toDTO(msg: IChatMessage): IChatMessageDTO {
    if (!msg) return null;
    const {
      id,
      dateTime,
      payloads: messagePayloads,
      senderId,
      threadId,
      reply,
      seen,
    } = msg;
    const chatUserId = this.chatUserService.currentUser.id;
    const from =
      (reply && !this.isPatient) || (!reply && this.isPatient)
        ? FromEnum.User
        : FromEnum.Patient;
    return {
      id,
      dateTime,
      messagePayloads,
      patientId: this.isPatient ? chatUserId : threadId,
      from,
      seen,
      userId: this.isPatient ? threadId : chatUserId,
    };
  }
}
