import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import {
  InvoiceBase,
  PatientWithInvoiceNumber,
  InvoiceItem,
  RecurringInvoice,
  Invoice,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { Patient, PatientData } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { User } from 'app/@core/interfaces/common/users';
import { UserStore } from 'app/@core/stores/user.store';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { InvoiceItemDialogComponent } from '../../invoices/invoice-item-dialog/invoice-item-dialog.component';
import { PatientSubscriptionData, PatientSubscriptionMeta } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { PatientSubscriptionsMetaService } from 'app/@core/backend/common/services/CalorieFriend/patient-subscriptions-meta.service';
import { PatientSubscriptionsDataService } from 'app/@core/backend/common/services/CalorieFriend/patient-subscriptions-data.service';
import { PatientStore } from 'app/@core/stores/patient.store';
import { CURRENCIES } from 'app/@core/utils/currency';
import { getCurrencySymbol } from '@angular/common';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';

@Component({
  selector: 'app-patient-subscriptions-form',
  templateUrl: './patient-subscriptions-form.component.html',
  styleUrls: ['./patient-subscriptions-form.component.scss'],
})
export class PatientSubscriptionsFormComponent implements OnInit, OnDestroy {
  @Input()
  subscriptionMetaId?: number;

  @Input()
  subscriptionDataId?: number;

  @Input('editable')
  editable: boolean = false;

  @Input()
  patientGuid?: string;

  get isPatientView() {
    return Boolean(this.patientGuid);
  }

  user: User;
  patient$?: Observable<Patient>;
  subscriptionData?: PatientSubscriptionData;

  frequencyIntervals = ['Day', 'Week', 'Month', 'Year'];

  get displayedColumns() {
    const columns = ['itemName', 'itemPrice', 'itemQTY', 'itemSubtotal'];
    if (this.editable) {
      columns.push('actions');
    }
    return columns;
  }

  subscription: Partial<PatientSubscriptionMeta> = {};

  get subscriptionAsAny() {
    return this.subscription as any;
  }

  get title() {
    if (!this.subscriptionMetaId) {
      return `Create a new Package`;
    }
    const action = this.editable ? 'Edit' : 'View';
    return `${action} Package`;
  }

  get subscriptionDataStatus() {
    const status = this.subscriptionData?.status;
    if (!status || status == 'canceled') return 'basic';
    if (status == 'active') return 'success';
    return 'primary';
  }

  readonly currencies = CURRENCIES;
  get currencySymbol() {
    return getCurrencySymbol(this.subscription.currency || 'USD', 'wide');
  }
  //allClients: Observable<PatientWithInvoiceNumber[]>;

  unsubscription$ = new Subject();

  loading: boolean = false;
  saveSubscription$: Subscription;

  constructor(
    private userStore: UserStore,
    private patientsService: PatientData,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private subscriptionMetaService: PatientSubscriptionsMetaService,
    private subscriptionDataService: PatientSubscriptionsDataService,
    private patientStore: PatientStore,
    @Optional() private dialogRef: NbDialogRef<PatientSubscriptionsFormComponent>
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.user = this.userStore.getUser();

    // if (this.editable) this.allClients = this.patientsService.getAllClientsWithInvoiceNumber();
    // else this.allClients = of([]);

    this.subscription = {
      invoiceItems: [
        {
          itemCode: '',
          itemPrice: 0,
          itemDescription: '',
        },
      ],
      currency: 'USD',
      frequencyInterval: 'Month',
      frequencyIntervalCount: 1,
    };
    if (this.subscriptionDataId) {
      let subscriptionData$ = this.subscriptionDataService.get(this.subscriptionDataId, this.patientGuid);
      subscriptionData$.subscribe({
        next: (subData) => {
          this.patient$ = of(subData.patient);
          this.subscriptionData = subData;
          this.subscriptionMetaId = subData.subscriptionId;
          this.getSubscription$(this.subscriptionMetaId);
        },
      });
    }
    if (this.subscriptionMetaId) {
      this.getSubscription$(this.subscriptionMetaId);
    }
    if (this.patientGuid) {
      this.patient$ = this.patientStore.onPatientChanged();
    }
  }

  getSubscription$(subscriptionId: number) {
    let subscriptionMeta$ = this.subscriptionMetaService.get(subscriptionId, this.patientGuid);
    subscriptionMeta$.subscribe({
      next: (subscription) => {
        if (!subscription) {
          this.toastrService.danger(
            "An error happened while getting the subscription or maybe it doesn't exist",
            'Error occured'
          );
          this.getDialogRef()?.close();
        }

        // append '?' at the end of the url for prefilled_email search params
        const url = new URL(subscription.subscriptionLink);
        if (!url.search) subscription.subscriptionLink += '?';
        
        this.subscription = subscription;
        this.user = this.subscription.createdByUser;
      },
      error: (e) => {
        this.toastrService.danger(e.message);
        this.getDialogRef()?.close();
      },
    });
  }
  getDialogRef(): NbDialogRef<any> {
    return this.dialogRef;
  }

  saveSubscription(): Observable<PatientSubscriptionMeta> {
    if (this.subscriptionMetaId) {
      return this.subscriptionMetaService.edit(this.subscription as PatientSubscriptionMeta);
    } else {
      return this.subscriptionMetaService.add(this.subscription as PatientSubscriptionMeta);
    }
  }

  addInvoiceItem() {
    this.dialogService
      .open(InvoiceItemDialogComponent, {
        context: {
          itemCurrency: this.subscription.currency || 'USD',
        },
      })
      .onClose.subscribe({
        next: (item) => {
          if (!item) return;
          const { itemName, itemPrice, itemCurrency } = item;
          this.subscription.currency = itemCurrency;
          this.subscription.invoiceItems = [
            ...this.subscription.invoiceItems,
            {
              itemDescription: itemName,
              itemPrice,
              itemCode: '',
            },
          ];
        },
      });
  }

  deleteInvoiceItem(item: InvoiceItem) {
    this.subscription.invoiceItems = this.subscription.invoiceItems.filter(
      (x) => x.itemDescription != item.itemDescription || x.itemPrice != item.itemPrice
    );
  }

  get subTotal() {
    return this.subscription.invoiceItems.reduce((sum, cur) => sum + cur.itemPrice, 0);
  }
  get amountDue() {
    return (
      (((this.subTotal * (100 - (this.subscription.discount || 0))) / 100) * (100 + (this.subscription.tax || 0))) / 100
    );
  }

  onSaveSubscription() {
    if (this.subscriptionMetaId) {
      this.dialogService
      .open(ConfirmMessageDialogComponent, {
        closeOnEsc: true,
        closeOnBackdropClick: true,
        context: { Message: `Updated packages information will only apply to new subscriptions.
         Previously active subscription won't be affected by the change. 
         Proceed anyway?` },
      })
      .onClose.subscribe((confirmation: boolean) => {
        if(confirmation) {
          this.callSaveSubscription();
        }
      });
    } else {
      this.callSaveSubscription();
    }
    
  }

  callSaveSubscription() {
    let res: Observable<any>;

    this.loading = true;

    res = this.saveSubscription().pipe(
      tap((subscription) => {
        this.subscriptionMetaId = subscription.id;
      })
    );

    this.saveSubscription$ = res.subscribe({
      next: (result) => {
        this.toastrService.success('', 'Successfully saved!');

        this.loading = false;
        this.getDialogRef()?.close(true);
        //alert("succeed");
      },
      error: (e) => {
        console.log(e);
        if (e.error.message) this.toastrService.danger('', e.error.message);
        else this.toastrService.danger('', 'Error occured while saving the subscription');
        this.loading = false;
      },
    });
  }

  onClose() {
    this.getDialogRef()?.close();
  }

  onCancel() {
    this.loading = false;
    if (this.saveSubscription$) this.saveSubscription$.unsubscribe();
  }
}
