<nb-card class="invoice-form nb_card_pop_up full_pop_up mobilePopup">
  <nb-card-header *ngIf="!isPatientView">
    <h6 i18n="@@Title">
      {{ title }}
    </h6>
    <button (click)="onClose()" nbButton ghost shape="round"><nb-icon icon="close"></nb-icon></button>
  </nb-card-header>
  <nb-card-body>
    <h3 class="border-bottom border-dark text-right mb-4 mt-5 invoice-title position-relative">
      <nb-badge
        *ngIf="invoiceId && !isRecurrent"
        [text]="invoiceAsAny?.isPaid ? 'Paid' : 'UnPaid'"
        position="top left"
        [status]="invoiceAsAny?.isPaid ? 'success' : 'danger'"
      ></nb-badge>
      INVOICE
    </h3>

    <div class="d-flex justify-content-between align-items-center">
      <div class="logo-container" *ngIf="logo">
      <img [src]="logo" />
    </div>
      <ng-container *ngIf="user">
        <div class="d-flex flex-column text-right">
          <p>TrainerFriend</p>
          <p>{{ user.phoneNumber }}</p>
          <p>{{ user.businessName }}</p>
          <p>{{ user.address.street }}</p>
          <p>{{ user.address.city }}, {{ user.address.zipCode }}</p>
         
        </div>
      </ng-container>
    </div>
    <div class="url-box">
    <a class="float-right" href="https://www.TrainerFriend.com"> https://www.TrainerFriend.com </a>
  </div>
    <hr />

    <div class="row mb-5">
      <div class="col-6 col-md-4">
        <p><b>Billed to</b></p>
        <div class="py-1" *ngIf="invoice?.patient as patient">{{ patient.firstName }}&ensp;{{ patient.lastName }}</div>
        <button
          nbButton
          class="w-sm-100"
          class="dashed-button text-secondary mt-2 p-4"
          [matMenuTriggerFor]="clientsMenu"
          *ngIf="editable"
        >
          <i class="fa fa-plus mr-2"></i>
          {{ invoice?.patient ? 'Change client' : 'Add a client' }}
        </button>
        <mat-menu #clientsMenu="matMenu">
          <ng-container *ngIf="allClients | async as allClients">
            <button mat-menu-item *ngFor="let client of allClients"
            class="w-sm-100"
            (click)="addClient(client)">
              {{ client.firstName }}&ensp;{{ client.lastName }}
            </button>
          </ng-container>
        </mat-menu>
      </div>

      <div class="col-6 col-md-3 mb-1">
        <p><b>Invoice No</b></p>
        <div *ngIf="!invoice?.patient">Select a patient to see invoice number</div>
        <div class="py-1" *ngIf="invoiceAsAny.invoiceNumber">inv_{{ invoice.invoiceNumber }}</div>
      </div>

      <div class="col-12 col-md-5 mt-3 mt-md-0">
        <ng-container *ngIf="!isRecurrent">
          <div class="mb-3">
            <p><b>Issue Date</b></p>
            <input
              nbInput
              class="w-sm-100"
              shape="semi-round"
              status="primary"
              [nbDatepicker]="issueDatePicker"
              [(ngModel)]="issueDate"
              [readonly]="!editable"
            />
            <nb-datepicker #issueDatePicker></nb-datepicker>
          </div>
          <div class="mb-3">
            <p><b>Due Date</b></p>
            <!-- <mat-form-field>
            <input matInput [matDatepicker]="dueDatePicker" [(ngModel)]="dueDate" [readonly]="!editable" />
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker" *ngIf="editable"></mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker></mat-datepicker>
          </mat-form-field> -->
            <input
              nbInput
              class="w-sm-100"
              shape="semi-round"
              status="primary"
              [nbDatepicker]="dueDatePicker"
              [(ngModel)]="dueDate"
              [readonly]="!editable"
            />
            <nb-datepicker #dueDatePicker></nb-datepicker>
          </div>
        </ng-container>

        <ng-container *ngIf="isRecurrent">
          <p><b>Invoice on Day Of Month</b></p>
          <input nbInput [(ngModel)]="invoice.dayOfMonth" type="number" placeholder="1 - 28" [readonly]="!editable" />
          <p><b>Invoice Due Day Of Month</b></p>
          <input
            nbInput
            class="w-sm-100"
            [(ngModel)]="invoice.dueDayOfMonth"
            type="number"
            placeholder="1 - 28"
            [readonly]="!editable"
          />
          <p><b>Occurrence</b></p>
          <input nbInput [(ngModel)]="invoice.occurrence" type="number" placeholder="2 - 60" [readonly]="!editable" />
        </ng-container>
      </div>
    </div>

    <mat-table [dataSource]="invoice.invoiceItems" class="">
      <ng-container matColumnDef="itemName">
        <mat-header-cell *matHeaderCellDef>ITEM NAME</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.itemDescription }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="itemPrice">
        <mat-header-cell *matHeaderCellDef>PRICE</mat-header-cell>
        <mat-cell *matCellDef="let element">{{
          element.itemPrice | currency : invoice.currency || 'CAD' : 'symbol' : '1.2-2'
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="itemQTY">
        <mat-header-cell *matHeaderCellDef>QTY</mat-header-cell>
        <mat-cell *matCellDef="let element">1</mat-cell>
      </ng-container>
      <ng-container matColumnDef="itemSubtotal">
        <mat-header-cell *matHeaderCellDef>SUBTOTAL</mat-header-cell>
        <mat-cell *matCellDef="let element">{{
          element.itemPrice | currency : invoice.currency || 'CAD' : 'symbol' : '1.2-2'
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="actions"></mat-header-cell>
        <mat-cell *matCellDef="let element" class="actions ml-auto">
          <button mat-icon-button aria-label="remove button" (click)="deleteInvoiceItem(element)" *ngIf="editable">
            <mat-icon>close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <button mat-button class="dashed-button text-secondary w-100 my-3 py-2" *ngIf="editable" (click)="addInvoiceItem()">
      <i class="fa fa-plus"></i>
      Add an item
    </button>

    <div class="row">
      <div class="col-12 col-md-6">
        <ng-container *ngIf="invoiceAsAny?.paymentLink && !invoiceAsAny?.isPaid">
          <!-- <p class="mt-4"><b>Payment Link</b></p> -->
          <a
            nbButton
            size="large"
            shape="round"
            status="primary"
            class="mt-4"
            [href]="invoice?.paymentLink"
            target="blank"
            >Pay Now</a
          >
        </ng-container>
      </div>
      <div class="col-12 col-md-4 offset-md-2">
        <hr />
        <div class="d-flex justify-content-between">
          <p><b>Subtotal</b></p>
          <span>{{ subTotal | currency : invoice.currency || 'CAD' : 'symbol' : '1.2-2' }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-end mb-3">
          <span class="mr-2">% off</span>
          <input
            nbInput
            placeholder="Add Discount"
            [(ngModel)]="invoice.discount"
            class="text-right discount-input mr-2"
            type="number"
            min="0"
            max="100"
            [readonly]="!editable"
          />
        </div>
        <div class="d-flex align-items-center justify-content-end mb-3">
          <span class="mr-2">%</span>
            <input
              nbInput
              placeholder="Add Tax"
              [(ngModel)]="invoice.tax"
              class="text-right discount-input mr-2"
              type="number"
              min="0"
              max="100"
              [readonly]="!editable"
            />
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <p>
            <b>Amount Due ({{ invoice.currency || 'CAD' }})</b>
          </p>
          <span>{{ amountDue | currency : invoice.currency || 'CAD' : 'symbol' : '1.2-2' }}</span>
        </div>
      </div>
    </div>

    <div>
      <p><b>Add Notes / Terms</b></p>
        <textarea
          nbInput
          fullWidth
          placeholder="Enter your terms and conditions."
          [(ngModel)]="invoice.terms"
          rows="8"
          class="w-100"
          [readonly]="!editable"
        ></textarea>
    </div>
  </nb-card-body>
  <nb-card-footer *ngIf="!isPatientView">
    <div class="d-flex">
      <button
        nbButton
        size="large"
        shape="round"
        status="primary"
        class="w-100 mx-3"
        color="primary"
        *ngIf="editable"
        (click)="onSaveInvoice()"
        [nbSpinner]="loading"
        [disabled]="loading"
      >
        {{ isAutoSend ? (!invoiceId ? 'Create & Send' : 'Update & Send') : !invoiceId ? 'Create' : 'Update' }}
      </button>
      <button class="w-100 mx-3"  nbButton
      size="large"
      shape="round"
      status="primary" *ngIf="editable && loading" (click)="onCancel()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>
