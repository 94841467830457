import { NbAuthService, NbAuthOAuth2JWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class RoleProvider extends NbRoleProvider {
  constructor(private authService: NbAuthService) {
    super();
  }

  getLowerCaseRoles(roles: any): string | string[] {
    if (Array.isArray(roles)) {
      roles = roles.map((element) => {
        return element.toLowerCase();
      });
    } else {
      roles = roles.toLowerCase();
    }
    return roles;
  }

  getRole(): Observable<string | string[]> {
    return this.authService.getToken().pipe(
      map((token: NbAuthOAuth2JWTToken) => {
        if (!token?.isValid()) return 'guest';
        const payload = token.getAccessTokenPayload();
        return !!(payload && payload['role'])
          ? this.getLowerCaseRoles(payload['role'])
          : 'guest';
      }),
    );
  }
}
