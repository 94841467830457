import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BiometricHistoryData,
  BiometricHistory,
  BiometricHistoryView,
  BiometricHistoryFilter,
} from '../../../../interfaces/common/CalorieFriend/biometric-history';
import { GridData } from '../../../../interfaces/common/grid-data';
import { BiometricHistoryApi } from '../../api/CalorieFriend/biometric-history.api';

@Injectable()
export class BiometricHistoryService extends BiometricHistoryData {
  constructor(private api: BiometricHistoryApi) {
    super();
  }

  /**
   * Service function to get all the biometric histories list of the patient
   * given by {patientId} param or {NotificationToken} param in GridData format for displaying graph.
   * @param patientId Identifier of the Patient in number format
   * @param notificationToken Identifier of the Patient in GUID format for anonymous users
   */
  AllHistoriesList(
    patientId: number,
    NotificationToken: string,
  ): Observable<GridData<BiometricHistoryView>> {
    return this.api.allHistoryList(patientId, NotificationToken);
  }

  // Updated by Vinicius
  list(
    filter: BiometricHistoryFilter,
  ): Observable<GridData<BiometricHistoryView>> {
    return this.api.list(filter);
  }

  /**
   * Service function to get the biometric history with the identifier @id
   * @param id Identifier of the biometric history
   */
  get(id: number): Observable<BiometricHistory> {
    return this.api.get(id);
  }

  /**
   * Service function to add a new biometric history.
   * @param biometricHistory Biometric History Form data
   */
  create(biometricHistory: any): Observable<BiometricHistory> {
    return this.api.add(biometricHistory);
  }

  /**
   * Service function to delete an existing biometric history.
   * @param id Identifier of the biometric history in number format
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to update an existing biometric history
   * @param biometricHistory Biometric History Form data
   */
  update(biometricHistory: any): Observable<BiometricHistory> {
    return this.api.update(biometricHistory);
  }
}
