import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { HttpService } from '../http.service';
import { SubscriptionPlan } from '../../../../interfaces/common/CalorieFriend/subscriptionPlan';

/**
 * Injectable class for SubscriptionPlans API
 */
@Injectable()
export class SubscriptionPlansApi {
  /**
   * API Controller Name - 'SubscriptionPlans'
   */
  private readonly apiController: string = 'SubscriptionPlans';

  constructor(private api: HttpService) { }

  /**
   * API function to get the subscription plans list in array format.
   * Method: GET
   * URL: /list
   */
  list(): Observable<any[]> {
    return this.api.get(`${this.apiController}/list`);
  }

  /**
   * API function to get the available (choosable) subscription plans list in array format.
   * Method: GET
   * URL: /list
   */
   getAvailableList(): Observable<any[]> {
    return this.api.get(`${this.apiController}/availableList`);
  }

  /**
   * API function to get the subscription plans list in DataSource format.
   * Method: GET
   * URL: /
   */
  get subscriptionPlansDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  /**
   * API function to add a new subscription plan.
   * @param SubscriptionPlan Subscription Plan Form data
   * Method: POST
   * URL: /
   */
  add(SubscriptionPlan: any): Observable<any> {
    return this.api.post(this.apiController, SubscriptionPlan);
  }

  /**
   * API function to get the subscription plan with the identifier @id
   * @param id Identifier in number format
   * Method: GET
   * URL: /${id}
   */
  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to get the subscription plan with the plan code @planCode
   * @param planCode Plan Code
   * Method: GET
   * URL: /getByCode/${id}
   */
  getByCode(planCode: string): Observable<any> {
    return this.api.get(`${this.apiController}/getByCode/${planCode}`);
  }

  /**
   * API function to update an existing subscription plan
   * @param plan Subscription Plan Form data
   * Method: POST
   * URL: /{id}
   */
  update(plan: SubscriptionPlan): Observable<SubscriptionPlan> {
    return this.api.put(`${this.apiController}/${plan.id}`, plan);
  }

  /**
   * API function to delete an existing subscription plan.
   * @param id Identifier of the subscription plan in number format
   * Method: POST
   * URL: /delete/${id}
   */
  delete(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/delete/${id}`, null);
  }
}
