import { Subject } from 'rxjs-compat';
import { Exercise } from './exercise';



export class FitnessItemHelper {


  static GetSetSettings(FitnessItem: FitnessItem): SettingDetailRow[] {

    let settingDetails:SettingDetailRow[] = [];

    for (let i = 1; i <= FitnessItem.sets; i++) {
      const row = new SettingDetailRow();
      row.Set = i.toString();
      row.Tempo = FitnessItem.defaultTempo;

      if (FitnessItem.defaultRepsOrTime)
        row.RepsOrTime = FitnessItem.defaultRepsOrTime.toString();
      else row.RepsOrTime = '';

      // force 0 for MaxRepBased
      if (FitnessItem.setsBased == SetsBased.MaxRepBased) {
        row.RepsOrTime = '0';
      }

      if (FitnessItem.defaultWeight) {
        let defaultWeight = FitnessItem.defaultWeight;
        if (
          FitnessItem.weightType == WeightType.AbsoluteValue
        ) {
          defaultWeight = Math.round((defaultWeight / 0.453592) * 10) / 10;
        }
        row.Weight = defaultWeight.toString();
      } else row.Weight = '';

      if (FitnessItem.defaultRest)
        row.Rest = FitnessItem.defaultRest.toString();
      else row.Rest = '';

      settingDetails.push(row);
    }

    return settingDetails;
  }




  static IsSuperSet(fitnessItem: FitnessItem) {
    if(!fitnessItem) return false;
    return fitnessItem.setsBased == SetsBased.Superset
  }


  static GetSupersetMaster(fitnessItem: FitnessItem, block: FitnessBlock): FitnessItem {


    if (!fitnessItem || !block || !block.items) return;

    if (FitnessItemHelper.IsSuperSet(fitnessItem)) {

      let currentOrder = fitnessItem.order;
      let previousItems = block.items.filter(y => y.order < currentOrder).sort((n1, n2) => n2.order - n1.order);


      if (previousItems && previousItems.length > 0) {


        for (let x of previousItems) {
          if (!FitnessItemHelper.IsSuperSet(x)) {
            return x;
          }
        }

        return previousItems[previousItems.length - 1];

      } else {
        return null;
      }
    } else {
      if (FitnessItemHelper.IsSuperSetWithNext(fitnessItem, block)) {
        return fitnessItem;
      }
    }

    return null;
  }

  static IsSupersetMaster(fitnessItem: FitnessItem, block: FitnessBlock) {

    if (FitnessItemHelper.IsSuperSet(fitnessItem)) {
      let master = FitnessItemHelper.GetSupersetMaster(fitnessItem, block);
      //cant compare with JSON due to circular ref and object compare in javascript not working.
      if (master.id + '_' + master.order + '_' + master.exerciceId === fitnessItem.id + '_' + fitnessItem.order + '_' + fitnessItem.exerciceId) {
        return true;
      }
    }
    return false;
  }

  static IsSuperSetSlave(fitnessItem: FitnessItem, block: FitnessBlock) {
    if (FitnessItemHelper.IsSuperSet(fitnessItem) && FitnessItemHelper.GetSupersetMaster(fitnessItem, block) != null && !FitnessItemHelper.IsSupersetMaster(fitnessItem, block)) {
      return true;
    }
    return false;
  }


  static IsSuperSetWithNext(fitnessItem: FitnessItem, block: FitnessBlock) {

    //if (FitnessItemHelper.IsSuperSet(fitnessItem, block)) {
    let nextItems = block.items.filter(y => y.order > fitnessItem.order).sort((n1, n2) => n1.order - n2.order);
    if (nextItems && nextItems.length > 0) {
      if (FitnessItemHelper.IsSuperSet(nextItems[0])) {
        return true;
      }
    }

    return false;
  }


}

export interface FitnessItemBase extends Exercise {
  fitnessBlockID: number;

  sets: number;
  setsBased: SetsBased;
  defaultRepsOrTime: number;
  defaultTempo: string;
  defaultRest: number;
  weightType: WeightType;
  defaultWeight: number;
  oneRepMax: number;
  restBeforeNextExercise: number;
  setSettings: string;

  order: number;
  useFemaleVersion: boolean;
  showAdvancedMode: boolean;
  instructions: string;
}

export interface FitnessItem extends FitnessItemBase {
  Update: Subject<void>;
}

export interface FitnessBlock {
  id: number;
  dayID: number;
  name: string;
  blockType: BlockType;
  repetitionCount: number;
  circuitRest: number;
  restEnd: number;
  items: Array<FitnessItem>;
  order: number;
}

export class SettingDetailRow {
  Set: string;
  RepsOrTime: string;
  Tempo: string;
  Rest: string;
  Weight: string;
  UsedWeight: string;
  CalculatedWeight: number;
  displayWeight: string;
  RepsDone: number;
}

export enum BlockType {
  Block,
  Circuit,
  Warmup,
  Cooldown,
}

export enum SetsBased {
  RepsBased,
  TimeBased,
  MaxRepBased,
  Superset,
}

export enum WeightType {
  Procent1MR,
  AbsoluteValue,
}
