<div class="card-item">
  <label
    >{{ getPaymentCounts(payments) }} Payment(s) {{label}}</label
  >
  <ng-container *ngFor="let payment of payments; let i = index">
    <div *ngIf="i === (currentIndex | async)" @paymentStatusAnimation class="amount">
      {{ payment.amount | currency : (payment.currency || "CAD") : 'symbol-narrow' : '1.2-2' }}
      <small>{{payment.currency || "CAD"}}</small>
    </div>
  </ng-container>
</div>
