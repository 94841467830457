import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Settings } from './settings';

export interface User {
  id: number;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  name?: string;
  age: number;
  phoneNumber: string;
  businessName: string;
  businessType: number;
  customersPerMonth: number;
  login: string;
  picture: string;
  planCode: string;
  planSubscription: string;
  uniqueGuid: string;
  isAmbasador: boolean;
  address: Address;
  settings: Settings;
  isEmailValid: boolean;
  isRegistrationCompleted: boolean;
  selectedPlan: string;
  tutorialStates: string;
  planPreference: string;
  createdDate:Date;
  conversionFactorsPreference: string;
  customAppEnabled: boolean;
  isCustomAppInProduction: boolean;
  gHLApiKey?: string;
}

export interface Address {
  street: string;
  secondAddress: string;
  city: string;
  country: string;
  zipCode: string;
}

export abstract class UserData {
  abstract get gridDataSource(): DataSource;
  abstract getCurrentUser(): Observable<User>;
  abstract list(pageNumber: number, pageSize: number): Observable<User[]>;
  abstract get(id: number): Observable<User>;
  abstract update(user: User): Observable<User>;
  abstract updateCurrent(user: User): Observable<User>;
  abstract updateCurrentInitial(user: User): Observable<User>;
  abstract create(user: User): Observable<User>;
  abstract delete(id: number): Observable<boolean>;
  abstract updateCurrentUserPlan(
    user: User,
    planCode: string,
  ): Observable<boolean>;
  abstract userExist(email: string): Observable<boolean>;
  abstract submitVerificationEmail(email: string): Observable<boolean>;
  abstract verifyEmail(
    email: string,
    validationCode: string,
  ): Observable<boolean>;
}
