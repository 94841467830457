import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { Exercise } from 'app/@core/interfaces/common/CalorieFriend/exercise';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { BlockType } from 'app/@core/interfaces/common/CalorieFriend/fitnessItem';
import { WorkoutService } from 'app/@core/backend/common/services/CalorieFriend/workout.service';
import {
  WorkoutDetail,
  WorkoutDetailStatus,
} from 'app/@core/interfaces/common/CalorieFriend/workout';
import { ExerciseService } from 'app/@core/backend/common/services/CalorieFriend/exercise-service';
import { ExerciseLog } from 'app/@core/interfaces/common/CalorieFriend/exerciseLog';
import { ExerciseLogService } from 'app/@core/backend/common/services/CalorieFriend/exerciseLog-service';
import { PatientsService } from 'app/@core/backend/common/services/CalorieFriend/patients.service';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { DatePipe } from '@angular/common';
import {
  NbColorHelper,
  NbJSThemeVariable,
  NbThemeService,
} from '@nebular/theme';
import { Utility } from 'app/@core/utils/utility';
import { convertKgToPounds } from 'app/@core/utils/preferredUnitImperialConvertor';
/**
 * created by @intel
 * @description component for list item in workout log details
 *              displaying image and graph
 */
@Component({
  selector: 'app-fitness-workout-log-item',
  templateUrl: './fitness-workout-log-item.component.html',
  styleUrls: ['./fitness-workout-log-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FitnessWorkoutLogItemComponent implements OnInit, OnDestroy {
  constructor(
    public workoutService: WorkoutService,
    private exerciseService: ExerciseService,
    private exerciseLogService: ExerciseLogService,
    private datepipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private patientsService: PatientsService,
    themeService: NbThemeService
  ) {
    themeService.getJsTheme().subscribe((config) => {
      this.setGraphOptions(config.variables.chartjs);
      this.themeColors = config.variables;
    });
  }

  /**
   * @example A1, A2, B1...
   */
  @Input('exerciseLabel')
  exerciseLabel: string;

  _patientId: number;
  get patientId() {
    return this._patientId;
  }
  @Input('patientId')
  set patientId(value: number) {
    this._patientId = value;
    this.loadLogsFromDetails();
  }

  /**
   * @description workout details for single exercise - several items in circuit mode, only one in block mode
   */
  _details: WorkoutDetail[];
  _exercise: Exercise;
  _logs: ExerciseLog[];
  exerciseDuration: string = '';
  isCompleted = false;
  customName: string;

  themeColors: NbJSThemeVariable;
  graphOptions: any;
  graphData: any;
  isPreferredUnitImperial: boolean;

  @Input('details')
  set details(value: WorkoutDetail[]) {
    this._details = value;
    let total_secs = 0;
    let completed = false;
    for (const d of this._details) {
      const { startDateTime, endDateTime, status } = d;
      total_secs += differenceInSeconds(
        new Date(endDateTime),
        new Date(startDateTime),
        { roundingMethod: 'round' }
      );
      completed = completed || status == WorkoutDetailStatus.Completed;
    }

    this.exerciseDuration = Utility.getMinutesDisplayString(
      Math.floor(total_secs / 60),
      Math.round(total_secs % 60)
    );

    this.isCompleted = completed;

    if (completed) this.loadLogsFromDetails();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  /**
   * @description get exercise and exercise logs from the details.
   * @returns void
   */
  async loadLogsFromDetails() {
    if (!this._details || this._details.length <= 0) return;
    if (!this.patientId) return;
    const exId = this._details[0].exerciseId;
    this._exercise = await this.exerciseService
      .GetExerciseDefintion(exId)
      .toPromise();
      this.isPreferredUnitImperial = (await this.patientsService
          .get(this.patientId, null).toPromise())?.isPreferredUnitImperial;
    this._logs = (await this.exerciseLogService.getExerciseLogsListByExerciseId(
      this.patientId,
      this._details[0].exerciseId
    )).map(x => ({
      ...x,
      snapshot: JSON.parse(x.snapshot).map((y) => {
        y.UsedWeight = convertKgToPounds(parseFloat(y.UsedWeight), this.isPreferredUnitImperial);
        return y;
      })
    }));

    this.customName = this._details[0].customName;

    const backgroundColor = NbColorHelper.hexToRgbA(
      this.themeColors?.primary,
      0.3
    );
    const valuesFirstRepDone = this._logs.map((b) => (b.snapshot)?.[0]?.RepsDone);
    this.graphData = {
      labels: this._logs.map((y) =>
        new Date(y.dateTime).toLocaleDateString('en', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
      ),
      datasets: [
        {
          data: this._logs.map((y) => convertKgToPounds(y.firstSetWeight, this.isPreferredUnitImperial)),
          label: 'First Set Weight',
          fill: false,
          backgroundColor,
          borderColor: this.themeColors?.primary,
          borderWidth: 6,
        },
        {
          data: valuesFirstRepDone,
          label: 'First Rep Done',
          fill: false,
          backgroundColor: '#e74c3c',
          borderColor: '#e74c3c',
          borderWidth: 6,
        },
      ],
    };
    this.cdRef.detectChanges();
  }

  private setGraphOptions(config): void {
    this.graphOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              color: config?.axisLineColor,
            },
            ticks: {
              fontColor: config?.textColor,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              color: config?.axisLineColor,
            },
            ticks: {
              fontColor: config?.textColor,
            },
          },
        ],
      },
      legend: {
        labels: {
          fontColor: config?.textColor,
          usePointStyle: true,
          pointStyle: 'circle',
          lineWidth: 0,
        },
        position: 'bottom',
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'vertical',
            scaleID: 'x-axis-0',
            value: this.datepipe.transform(new Date(), 'MM/dd'),
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              enabled: true,
              fontColor: 'orange',
              content: 'Today',
            },
          },
        ],
      },
    };
  }
}
