import { differenceInMinutes, differenceInSeconds } from 'date-fns';

export class Utility {
  public static INT32_MAX: number = 2147483647;

  private static regex: RegExp = new RegExp(/^\d*\.?\d{0,1}$/g);
  private static specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  /**
   * Validator that validates the number with the ##.# pattern when the {event} happens
   * @param event Event object
   */
  public static validateNumber(event: any) {
    if (Utility.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = event.target.value;
    const position = event.target.selectionStart;
    const next2: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');
    if (next2 && !String(next2).match(Utility.regex)) {
      event.preventDefault();
    }
  }

  public static lastIndexOf(array: any[], key: string, value: any): number {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i][key] === value) return i;
    }
    return -1;
  }

  public static getTimeDiffDisplayString(
    startDate: Date | string,
    endDate: Date | string,
    isISO: boolean = false
  ): string {
    if (isISO) {
      /**
       * in case of date is string - assume that it is ISO datetime, so convert it to ISO datetime string.
       * ex. 2000-01-01T00:00:00 => 2000-01-01T00:00:00Z
       */
      if (typeof startDate == 'string' && !startDate.endsWith('Z')) {
        startDate = startDate + 'Z';
      }
      if (typeof endDate == 'string' && !endDate.endsWith('Z')) {
        endDate = endDate + 'Z';
      }
    }
    let seconds = differenceInSeconds(new Date(endDate), new Date(startDate), {
      roundingMethod: 'round',
    });

    let minutes = Math.floor(seconds / 60);

    let secondsLeft = 0;
    if (minutes > 0) {
      secondsLeft = seconds - minutes * 60;
    } else {
      secondsLeft = seconds;
    }

    return Utility.getMinutesDisplayString(minutes, secondsLeft);
  }
  public static getMinutesDisplayString(minutes: number, seconds: number) {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours <= 0) return `${minutes} m ${seconds} s`;
    return `${hours} h ${minutes % 60} m ${seconds} s`;
  }

  public static getFileTypeFromURL(url: string) {
    const ext = url.split('.').reverse()[0].toLowerCase();
    if (['png', 'bmp', 'jpg', 'jpeg', 'webm'].includes(ext)) {
      return `image/jpeg`;
    }
    return '';
  }

  public static getTimerStringFromDates(
    startDate: Date | string,
    endDate: Date | string
  ) {
    if (typeof startDate == 'string' && !startDate.endsWith('Z')) {
      startDate = startDate + 'Z';
    }
    if (typeof endDate == 'string' && !endDate.endsWith('Z')) {
      endDate = endDate + 'Z';
    }

    let seconds = differenceInSeconds(new Date(endDate), new Date(startDate), {
      roundingMethod: 'round',
    });

    return Utility.getTimerString(seconds, true);
  }

  public static getTimerString(second: number, showHour: boolean) {
    let h = Math.floor(second / 3600);
    let m = showHour
      ? Math.floor((second % 3600) / 60)
      : Math.floor(second / 60);
    let s = second % 60;
    let vals = showHour ? [h, m, s] : [m, s];
    return vals.map((t) => ('0' + t).slice(-2)).join(':');
  }
}

export function getKeyValueFromJson(value: any): any {
  let temp = Object.entries(value);
  return temp.map(x => ({ value: parseInt(x[0]), label: x[1] }));
}
