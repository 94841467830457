import { Injectable } from '@angular/core';
import { TutorialData } from 'app/@core/interfaces/common/CalorieFriend/tutorial';
import { Observable } from 'rxjs';

import { TutorialApi } from '../../api/CalorieFriend/tutorial.api';

/**
 * Injectable class for the Tutorial Service
 */
@Injectable()
export class TutorialService extends TutorialData {
  /**
   * Injects a Tutorial API instance
   * @param api Tutorial API injectable
   */
  constructor(private api: TutorialApi) {
    super ();
  }

  /**
   * Service function to add a new Similar Food.
   * @param Tutorial
   */
  save(tutorial: string): Observable<any> {
     return this.api.save(tutorial);
  }

}
