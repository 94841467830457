

import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DefaultFilter } from 'ng2-smart-table';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Custom Filter component for 'Number' type fields of ng2 smart table
 */
@Component({
  template: `
     <nb-checkbox *ngIf="value == null" [indeterminate]="value == null" (checkedChange)="checkChanged($event)"></nb-checkbox>
     <nb-checkbox *ngIf="value != null"  [checked]="value == 'true'? true: value == 'false'? false: null" (checkedChange)="checkChanged($event)"></nb-checkbox>
  `,
})
export class NgxFilterByBooleanComponent extends DefaultFilter implements OnInit, OnChanges {
  value: any = null;

  constructor() {
    super();
  }

  /**
   * It adds the pipes to the Change event
   */
  ngOnInit() {
    this.query = this.value;
    this.setFilter();
  }

  checkChanged(check: boolean) {
    if(this.value == 'false') {
      this.value = null;
    } else if(this.value == null) {
      this.value = 'true';
    } else {
      this.value = 'false';
    }
    this.query = this.value;
    this.setFilter();
  }

  /**
   * The method to handle the changes of data bound
   * @param changes Change information
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.value = changes.query.currentValue;
    }
  }
}
