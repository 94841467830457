<nb-card>

  <nb-card-header>
    <!-- Title -->
    <div class="title"  *ngIf="!recurrent">Invoices & Payments</div>
    <div class="title"  *ngIf="recurrent">Recurrent Invoices</div>


    <div class="actions">
      <!-- Search -->
      <nb-form-field class="range-field" *ngIf="!recurrent">
        <input
          nbInput
          placeholder="Start Date - End Date"
          [nbDatepicker]="formpicker"
          (change)="onRangeInputChanged($event)"
        />
        <nb-rangepicker
          #formpicker
          (rangeChange)="onRangeChanged($event)"
        ></nb-rangepicker>
      </nb-form-field>
      <nb-form-field class="search-field">
        <input
          nbInput
          (change)="onSearchChanged($event)"
          shape="semi-round"
          fieldSize="small"
          class="search"
          placeholder="Search"
          i18n-placeholder="@@SearchThreeDots"
        />
        <nb-icon nbSuffix icon="search-outline" pack="eva"></nb-icon>
      </nb-form-field>
      <!-- Add -->
 
    </div>
  </nb-card-header>




  <nb-card-body>

    <div class="payment-status-container mb-3" *ngIf="!recurrent && paymentStatus$ | async as paymentStatus">
      <app-invoice-payment-status [payments]="paymentStatus.paymentsInDue" label="in Due"></app-invoice-payment-status>
      <app-invoice-payment-status [payments]="paymentStatus.paymentsReceived" label="received"></app-invoice-payment-status>
      <app-invoice-payment-status [payments]="paymentStatus.paymentsUnpaidOverdue" label="Unpaid / Overdue"></app-invoice-payment-status>
    </div>
    <ng-container *ngIf="invoices | async as invoices">
      <ngx-skeleton-item-list *ngIf="!contentLoaded"></ngx-skeleton-item-list>
      <mat-table
        [dataSource]="invoices?.items || []"
        matSort
        [matSortDisableClear]="true"
        (matSortChange)="onSortChanged($event)"
        [class.hidden]="!contentLoaded"
      >
        <ng-container matColumnDef="invoiceNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-sm-flex"
            >
            Invoice Number
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Invoice Number">
            <a href="javascript:void(0)" class="text-decoration-none"
              (click)="onView(row.id)"
              >inv_{{ row.invoiceNumber }}</a
            >
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Customer
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Customer"
            >{{ row.patient.firstName }}&ensp;{{
              row.patient.lastName
            }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="issueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Issue Date
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Issue Date">{{
            row.issueDate | date : 'MMM d, y'
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Amount
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Amount"
            >{{ row.price | currency : (row.currency || "CAD") : 'symbol' : '1.2-2' }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="dayOfMonth">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Day Of Month
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Day Of Month">{{ row.dayOfMonth }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="occurrence">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Occurrence
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Occurrence">{{ row.occurrence }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Status">
            <span [ngClass]="['state-badge', getInvoiceStatus(row)]">{{
                getInvoiceStatus(row)
              }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paid">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-sm-flex">
            Paid
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Paid">
            <span [ngClass]="['state-badge', row.isPaid?'paid':'unpaid']">{{
                row.isPaid ? 'Yes' : 'No'
              }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="d-none d-sm-flex"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item *ngIf="row.sendable" (click)="onSend(row.id)"><mat-icon>send</mat-icon>Send</button>
              <button mat-menu-item (click)="onView(row.id)"><mat-icon>visibility</mat-icon>View</button>
              <button mat-menu-item (click)="onEdit(row.id)" [disabled]="!row.editable"><mat-icon>edit</mat-icon>Edit</button>
              <button mat-menu-item (click)="onDelete(row.id)" [disabled]="!row.deletable"><mat-icon>delete</mat-icon>Delete</button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onClickInvoice(row.id)"
        ></mat-row>
      </mat-table>
      <mat-paginator
        matPaginator
        [length]="invoices?.totalCount"
        [pageSize]="5"
        [pageSizeOptions]="[2, 5, 10, 25, 50]"
        (page)="onPagingChanged($event)"
      ></mat-paginator>
    </ng-container>
  </nb-card-body>
</nb-card>
