import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { FormGroup } from '@angular/forms';

import { Meal, MealItem } from '../../../../../../../@core/interfaces/common/CalorieFriend/meal';
import { MealItemsPresentationComponent } from '../MealItemsPresentation/meal-items-presentation.component';
import { SmartEditComponent } from '../../../../../../../@components/smart-edit/smart-edit.component';

import { format } from '../../../../../../../../support';
import { BaseComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import { NbDialogService } from '@nebular/theme';
import { isObservable } from 'rxjs';
import { CalculateMetrics } from 'app/BuildOps';
import { MealMetricsGraphComponent } from 'app/admin/public-foods/meal-metrics-graph/meal-metrics-graph.component';
import { OverlayMealItemPresentationComponent } from '../MealItemPresentation/overlay-meal-item-presentation/overlay-meal-item-presentation.component';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';

/**
 * Base Component class for Meal Set Presentation.
 * Consists of Meal Items List and Metrics Graph View.
 */
export class MealPresentationComponentBase
  extends BaseComponentCanDeactivate
  implements OnInit, AfterViewInit {

    @Input() Patient: Patient;
  static clipboard: Meal;
  
  get IsDirty() {
    return (
      this.isDirty || (this.MealItemsView != null && this.MealItemsView.IsDirty)
    );
  }

  resetDirty() {
    super.resetDirty();
    this.MealItemsView.resetDirty();
  }

  @Output() onItemChange = new EventEmitter();
  @Input('DiaryPatientId') DiaryPatientId: number;
  @Input('UserId') UserId: number;


  private _PlanMeal: Meal;
  @Input() set PlanMeal(value: Meal) {
    this._PlanMeal = value;
    if (value) {
      this.PrepareMetrics();
    }
  } get PlanMeal(): Meal {
    return this._PlanMeal;
  }





  @Input('Index') Index: number;
  @Input('PlanView') PlanView: boolean = false;

  TheMealLabel = $localize`:@@Meal:Meal`;
  MealCals = 0;
  MealProtein = 0;
  MealCarbs = 0;
  MealNetCarbs = 0;
  MealFats = 0;

  @ViewChild('MealItems') MealItemsView: MealItemsPresentationComponent;
  @ViewChild('MealGraph') MealGraph: MealMetricsGraphComponent;
  @ViewChild('MealNameEdit') MealNameEdit: SmartEditComponent;
  @ViewChild('MealTime') HTMLMealTime: any;

  ItemUpdateNotification: Subject<Object> = null;

  MealProperitesForm: FormGroup;
  OverlayComponent = OverlayMealItemPresentationComponent;

  /**
   * Function to get Meal's label.
   */
  get MealLabel() {
    return format(
      '{0} {1} ({2} {3})',
      this.TheMealLabel,
      (this.Index + 1).toString(),
      this.PlanMeal.name,
      this.PlanMeal.time,
    );
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogService: NbDialogService,
  ) {
    super(dialogService);
    this.ItemUpdateNotification = new Subject<Object>();
  }

  get MealTime() {
    return this.MealProperitesForm.get('MealTime');
  }

  /**
   * Initializes the Meal Items subscriptions.
   */
  ngOnInit(): void {
    // if (this.PlanMeal !== null && this.PlanMeal !== undefined)
    // {
    //  this.MealProperitesForm.patchValue(
    //    {
    //      MealTime: this.PlanMeal.time
    //    });
    // }

    this.PrepareMealItemsObservables();
    //this.PrepareMetrics();
  }

  /**
   * AfterViewInit implementation.
   * Updates the metrics graph whenever the data has ben changed.
   */
  ngAfterViewInit() {
    if (
      this.MealItemsView != null &&
      this.MealItemsView !== undefined &&
      this.MealItemsView.ItemUpdateNotification &&
      this.MealItemsView.ItemUpdateNotification !== undefined
    ) {
      this.MealItemsView.ItemUpdateNotification.subscribe((data) => {
        this.ItemUpdateNotification.next();
        this.onItemsChanged();
      });
    }

    this.cdRef.detectChanges();

    if (this.MealNameEdit != null) {
      this.MealNameEdit.CallbackThis = this;
    }

    //dirty hack for now to enable compatibility on all modules (yes i know....)
  
    setTimeout(() => this.PrepareMetrics(), 300);
    setTimeout(() => this.PrepareMetrics(), 1500);

    // this.HTMLMealTime.defaultTime = this.PlanMeal.time;
    // this.HTMLMealTime.value = this.PlanMeal.time;
  }


  onItemsChanged(): void {
    this.PrepareMetrics();
    this.onItemChange.emit();
    console.log("onItemsChanged");
  }

  PlanMealItems() {
    return this.PlanMeal.items.sort((a, b) => a.order - b.order);
  }

  MealTimeChange($event) { }

  /**
   * Function to prepare meal items' update subscriptions.
   */
  PrepareMealItemsObservables() {

    // for the meal items  -- configure an observable
    // place an observable on the object
    if (this.PlanMeal !== null && this.PlanMeal !== undefined) {
      if (
        this.PlanMeal.items !== null &&
        this.PlanMeal.items !== undefined &&
        this.PlanMeal.items.length > 0
      ) {
        for (const item of this.PlanMeal.items) {
          if (!isObservable(item.Update)) item.Update = new Subject();
        }
      }
    }
  }

  /**
   * Method to update the metrics data.
   */
  public PrepareMetrics() {

    let Cals = 0;
    let Protein = 0;
    let Carbs = 0;
    let NetCarbs = 0;
    let Fats = 0;

    if (this.PlanMeal !== null && this.PlanMeal !== undefined) {
      if (
        this.PlanMeal.items !== null &&
        this.PlanMeal.items !== undefined &&
        this.PlanMeal.items.length > 0
      ) {
        for (const item of this.PlanMeal.items) {
          const itemMetrics = CalculateMetrics(item);
          Cals += itemMetrics.MealCals;
          Protein += itemMetrics.MealProtein;
          Carbs += itemMetrics.MealCarbs;
          NetCarbs += itemMetrics.MealNetCarbs;
          Fats += itemMetrics.MealFats;
        }
      }
    }

    this.MealCals = Math.round(+Cals);
    this.MealProtein = Math.round(+Protein);
    this.MealCarbs = Math.round(+Carbs);
    this.MealNetCarbs = Math.round(+NetCarbs);
    this.MealFats = Math.round(+Fats);
  }

  public UpdateMealLabel(CurrentLabel: string, ThisThis) {
    ThisThis.PlanMeal.name = CurrentLabel;

    return format(
      '{0} {1} ({2})',
      ThisThis.TheMealLabel,
      (ThisThis.Index + 1).toString(),
      ThisThis.PlanMeal.name,
    );
  }

  MealNameChange($event) {
    this.PlanMeal.name = $event.target.value;
  }

  /**
   * Removes the selected meal
   */
  RemoveMeal() { }

  /**
   * Checks if Meal can be removed from the Day
   */
  get CanRemoveMeal() {
    return true;
  }
}

/**
 * Component for Meal Presentation.
 * Inherits from the MealPresentationComponentBase component.
 */
@Component({
  selector: 'app-meal-presentation',
  templateUrl: './meal-presentation.component.html',
  styleUrls: ['./meal-presentation.component.scss'],
})
export class MealPresentationComponent extends MealPresentationComponentBase { }

@Component({
  selector: 'mobile-meal-presentation',
  templateUrl: './mobile-presentation.component.html',
  styleUrls: ['./meal-presentation.component.scss'],
})
export class MobileMealPresentationComponent extends MealPresentationComponent {
  @Input('OverlayMealItem') OverlayItem: any = null;
}

/**
 * Component for Meal Presentation Report.
 * Inherits from MealPresentationComponentBase component.
 */
@Component({
  selector: 'app-meal-report-presentation',
  templateUrl: './meal-presentation.report.component.html',
  styleUrls: ['./meal-presentation.component.scss'],
})
export class MealPresentationReportComponent extends MealPresentationComponentBase { }
