<div class="row mt-3">
  <div class="col-sm-4 label col-form-label">{{label}}</div>
  <div class="col-sm-8">
    <div class="img-container" (mouseover)="imgHover=true" (mouseleave)="imgHover=false">
      <div class="img-actions" *ngIf="imgHover">
        <a href="javascript:void(0)" (click)="onFileInputClick()">
          <nb-icon class="control-item1" icon="edit-outline"></nb-icon>
        </a>
        <a href="javascript:void(0)">
          <nb-icon class="control-item2" icon="trash-outline" (click)="resetImage()"></nb-icon>
        </a>
      </div>
      <img class="img" *ngIf="imageSrc || previewImage" [src]="imageSrc || previewImage" alt="image placeholder"
        [ngStyle]="{opacity: imgHover==true  ? '0.25' : '1' }">
      <input type="file" style="display:none;" #fileInput accept="image/*" name="fileImage"
        (change)="onImageFileChanged($event)" />
    </div>
  </div>
</div>
