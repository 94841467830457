import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import {
  NbOAuth2CallbackComponent,
  NgxAuthComponent,
  NgxLoginComponent,
  NgxLogoutComponent,
  NgxRegisterComponent,
  NgxRegisterLandingComponent,
  NgxRegisterStripeComponent,
  NgxRequestPasswordComponent,
  NgxResetPasswordComponent,
  NgxRestorePasswordComponent,
  UserPlanComponent,
  VerifyEmailComponent,
} from './components';

const routes: Routes = [
  {
    path: '',
    component: NgxAuthComponent,
    children: [
      { path: 'login', component: NgxLoginComponent },
      { path: 'logout', component: NgxLogoutComponent },
      { path: 'request-password', component: NgxRequestPasswordComponent },
      { path: 'reset-password', component: NgxResetPasswordComponent },
      { path: 'restore-password', component: NgxRestorePasswordComponent },
      {
        path: 'verify-email/:email/:validationCode',
        component: VerifyEmailComponent,
      },
      { path: 'cfregister/:PlanCode', component: NgxRegisterComponent },
      { path: 'cfregister', redirectTo: 'cfregister/free' },
      { path: 'cfregisterpay', component: NgxRegisterStripeComponent },
      { path: 'landing/:PlanCode', component: NgxRegisterLandingComponent },
      { path: 'plan', component: UserPlanComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RouterModule.forChild([
      {
        path: 'callback',
        component: NbOAuth2CallbackComponent,
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
