import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AnalyticsService } from './@core/utils';
import { InitUserService } from './@theme/services/init-user.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';
import { NbIconLibraries } from '@nebular/theme';
import { CUSTOM_ICON_PACK } from './icon-packs';
import { NavigationEnd, Router } from '@angular/router';
import { NativeAppService } from './@core/backend/common/services/native-app.service';
import { replaceRootStyle } from './@core/utils/themes';
import { UserStore } from './@core/stores/user.store';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private analytics: AnalyticsService,
    private initUserService: InitUserService,
    private userStore: UserStore,
    private router: Router,
    private iconLibraries: NbIconLibraries,
    private nativeApp: NativeAppService,
    private ngZone: NgZone,
  ) {
    this.initUser();

    this.iconLibraries.registerSvgPack('custom', CUSTOM_ICON_PACK);

    this.iconLibraries.registerFontPack('fas', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.registerFontPack('far', {
      packClass: 'far',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.registerFontPack('fab', {
      packClass: 'fab',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.setDefaultPack('far');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-TLBDPXX0H9', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    // settings change
    userStore
      .onSettingsStateChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => replaceRootStyle(document, userStore.settings()?.color));
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (message) => this.angularFunctionCalled(message),
    };



    // timer(0, 1000).subscribe(() => {
    //   if (this.nativeApp.isFromApp()) {
    //     this.nativeApp.sendMessageToApp('hbeat',"");
    //   }
    // });

  }

  ngAfterViewInit(): void {
    replaceRootStyle(document, this.userStore.settings()?.color);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onMouseClick(e: MouseEvent) {
    if (this.nativeApp.isFromAppFromAndroidApp()) {
      this.nativeApp.sendMessageToApp('ScreenY', window.innerHeight.toString());
      this.nativeApp.sendMessageToApp('PageY', e.pageY.toString());
    }

  }




  angularFunctionCalled(message) {

    console.log(message);

    let argument = '';
    let keyMessage = message;

    if (message.toString().includes('=')) {
      keyMessage = message.split("=")[0];
      argument = message.split("=")[1];
    }

    console.log(keyMessage);
    console.log('arg is ' + argument);


    switch (keyMessage.toLowerCase()) {
      case 'gotoclients':
        this.router.navigate(['/pages/CalorieFriend/Patients']);
        break;

      case 'gotoworkouts':
        this.router.navigate(['/pages/CalorieFriend/plans/templates/fitness']);
        break;

      case 'gotomealplans':
        this.router.navigate(['/pages/CalorieFriend/plans/templates/meal']);
        break;

      case 'gotoaddnew':
        this.router.navigate(['/pages/CalorieFriend/NewPatient']);
        break;

      case 'goto':

        let url = argument;
        let urlParams = {};

        if (argument.indexOf('?') > 0) {
          urlParams = new URLSearchParams(url);
          url = argument.substring(0, argument.indexOf('?'));
        }


        this.router.navigate([url], { queryParams: urlParams });
        break;
    }
  }

  initUser() {
    this.initUserService
      .initCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        replaceRootStyle(document, this.userStore.settings()?.color),
      );
  }
}
