import { Injectable } from '@angular/core';
import {
  Invoice,
  InvoiceFilter,
  PatientWithInvoiceNumber,
  RecurringInvoice,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { InvoicesApi } from '../../api/CalorieFriend/invoices.api';
import { RecurringInvoicesApi } from '../../api/CalorieFriend/recurring-invoices.api';

@Injectable()
export class RecurringInvoicesService {
  constructor(private api: RecurringInvoicesApi) {}

  list(filter: InvoiceFilter): Observable<GridData<RecurringInvoice>> {
    return this.api.list(filter).pipe(share());
  }

  add(invoice: RecurringInvoice): Observable<RecurringInvoice> {
    return this.api.add(invoice);
  }

  edit(invoice: RecurringInvoice): Observable<RecurringInvoice> {
    return this.api.edit(invoice);
  }

  get(id: number): Observable<RecurringInvoice> {
    return this.api.get(id).pipe(share());
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}
