import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  DayOfWeek,
  WeekDays,
} from 'app/@core/interfaces/common/CalorieFriend/plan-day';

@Component({
  selector: 'day-of-week-checker',
  templateUrl: './day-of-week-checker.component.html',
  styleUrls: ['./day-of-week-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DayOfWeekCheckerComponent),
      multi: true,
    },
  ],
})
export class DayOfWeekCheckerComponent implements ControlValueAccessor {
  @Input() formControlName: string;
  @Input() readonly: boolean = false;
  @Input() placeholder: string;
  @Input() get value(): DayOfWeek[] {
    return this._value;
  }
  set value(val: DayOfWeek[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }
  get formattedValue(): string {
    return this._value
      .map((x) => WeekDays[x])
      .filter((x) => x)
      .join(', ');
  }

  @Output() change: EventEmitter<DayOfWeek[]> = new EventEmitter<DayOfWeek[]>();

  readonly WeekDays = WeekDays;

  private _value: DayOfWeek[] = [];
  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(obj: any): void {
    if (obj) this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isChecked(day: string): boolean {
    return this._value.indexOf(DayOfWeek[day]) !== -1;
  }

  onToggle(day: string): void {
    const val = DayOfWeek[day];
    const index = this._value.indexOf(val);
    if (index === -1) this._value.push(val);
    else this._value.splice(index, 1);
    this.onChange(this._value);
    this.onTouched();
    this.change.emit(this.value);
  }
}
