<ng-template #currentTimeMarkerTemplate>
  <div class="cal-current-time-marker"></div>
</ng-template>
<nb-card class="nb_card_pop_up full_pop_up meal_item mobilePopup" style="width: 1200px; max-width: 95vw !important">
  <nb-card-header class="d-flex align-items-center title">
    <div class="title">
      Workout Details -
      <small>{{ workoutStart }} - {{ workoutDuration }}</small>
    </div>
    <button nbButton type="button" static="basic" ghost shape="round" size="small" class="ml-auto" (click)="onClose()">
      <nb-icon icon="close"></nb-icon>
    </button>
  </nb-card-header>

  <nb-card-body [nbSpinner]="!workoutDetails">
    <ng-container *ngFor="let t of logDetailKeys; let i = index">
      <app-fitness-workout-log-item [exerciseLabel]="t" [details]="logDetails[t]" [patientId]="patient.id">
      </app-fitness-workout-log-item>
    </ng-container>
  </nb-card-body>
  <nb-card-footer></nb-card-footer>
</nb-card>
