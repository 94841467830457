import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PendingUserApi } from '../../api/CalorieFriend/pendingUser.api';

/**
 * Injectable class for the PendingUser Service
 */
@Injectable()
export class PendingUserService {
  /**
   * Injects a Food API instance and a PendingUser API instance
   * @param api PendingUser API injectable
   */
  constructor(private api: PendingUserApi) { }

  /**
   * Service function to add a pending user.
   * @param email Email to Add
   */
  add(email: string): Observable<any> {
    return this.api.add(email);
  }

}
