<div class="row">
  <div class="col" style="margin-top: 10px;">
    {{ viewDate | calendarDate: view + 'ViewTitle':locale }}
  </div>

  <div class="col">
    <div class="btn-group" style="float:right; ">
      <button nbButton style="margin-left: 3px;"
        class="btn-circle btn-xl btn-alternate"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
      >
        Previous
      </button>
      <button nbButton style="margin-left: 3px;"
        class="btn-circle btn-xl btn-main"
        mwlCalendarToday
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
      >
        Today
      </button>
      <button nbButton  style="margin-left: 3px"
        class="btn-circle btn-xl  btn-alternate"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
      >
        Next
      </button>
    </div>
  </div>

</div>

 



  <div class="col" style="display:none;">
    <div class="btn-group">
      <div
        class="btn-circle btn-xl btn-main btn-main"
        (click)="viewChange.emit(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Month
      </div>
      <div
        class="btn-circle btn-xl btn-main btn-main"
        (click)="viewChange.emit(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Week
      </div>
      <div
        class="btn-circle btn-xl btn-main btn-main"
        (click)="viewChange.emit(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Day
      </div>
    </div>
  </div>