import { NgModule } from '@angular/core';
import {NgxImageCompressService} from 'ngx-image-compress';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { GoogleAnalyticsService } from 'google-analytics.service';
import { JoyrideModule } from 'ngx-joyride';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { CoreModule } from './@core/core.module';
import { MaterialModule } from './@components/material.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './@auth/auth.module';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';


const NB_MODULES = [
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbDialogModule.forRoot(),
  NbWindowModule.forRoot(),
  NbToastrModule.forRoot(),
  NbChatModule.forRoot({
    messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
  }),
];

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    ...NB_MODULES,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    JoyrideModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialModule,
    FontAwesomeModule,
    HammerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [NgxImageCompressService, GoogleAnalyticsService, DatePipe, [{ provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }] ],
})
export class AppModule {}
