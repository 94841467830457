import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import {
  Invoice,
  InvoiceFilter,
  PatientWithInvoiceNumber,
  RecurringInvoice,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { map } from 'rxjs/operators';

@Injectable()
export class RecurringInvoicesApi {
  private readonly apiController: string = 'recurring-invoices';
  constructor(private api: HttpService) {}

  public list(filter: InvoiceFilter): Observable<GridData<RecurringInvoice>> {
    return this.api.list(this.apiController, filter);
  }

  public add(invoice: RecurringInvoice): Observable<RecurringInvoice> {
    return this.api.post(this.apiController, invoice);
  }

  public edit(invoice: RecurringInvoice): Observable<RecurringInvoice> {
    return this.api.post(this.apiController, invoice);
  }

  public get(id: number): Observable<RecurringInvoice> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  public delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }
}
