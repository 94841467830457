<nb-card>
  <nb-card-header>
    <h6 i18n="@@Title">
      Send Subscription to Clients
    </h6>
    <button (click)="onClose()" nbButton ghost shape="round">
      <nb-icon icon="close"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <nb-checkbox (checkedChange)="onAll($event)" [checked]="clients.length && selected.length == clients.length" [indeterminate]="selected.length && selected.length < clients.length">All</nb-checkbox><hr class="my-2"/>
    <div *ngFor="let client of clients">
      <nb-checkbox [checked]="selected.includes(client.id)" (checkedChange)="onToggle(client.id, $event)"> {{client.firstName}}&ensp;{{client.lastName}}</nb-checkbox>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" class="btn-circle btn-xl btn-main mr-3" (click)="send()">Send</button>
    <button i18n="@@No" nbButton  class="btn-circle btn-xl btn-back" (click)="onClose()">Cancel</button>
  </nb-card-footer>
</nb-card>