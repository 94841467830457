import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-food-diary-log-modal',
  templateUrl: './food-diary-log-modal.component.html',
  styleUrls: ['./food-diary-log-modal.component.scss']
})
export class FoodDiaryLogModalComponent implements OnInit {

  @Input('patientId') patientId:number;
  @Input('userId') userId:number;
  @Input('day') day:Date;



  constructor(private ref: NbDialogRef<FoodDiaryLogModalComponent>,
    private cdRef: ChangeDetectorRef) { }


  ngOnInit(): void {
  }

  onClose() {
    this.ref.close();
  }

}
