import { Injectable } from '@angular/core';

import { FitnessPlan, MealPlan } from '../interfaces/common/CalorieFriend/plan';

export class BasePlansStore {
  private _plans: (FitnessPlan | MealPlan)[] = [];

  plans(): (FitnessPlan | MealPlan)[] {
    return this._plans;
  }
  setPlans(plans: (FitnessPlan | MealPlan)[]): void {
    this._plans = plans;
  }
  destroyPlans(): void {
    this._plans = null;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MealPlansStore extends BasePlansStore {}

@Injectable({
  providedIn: 'root',
})
export class FitnessPlansStore extends BasePlansStore {}
