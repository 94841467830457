export const ConversionFactorsData: any = [
  {
    text: "grams",
    value: "g"
  },
  {
    text: "ounces",
    value: "oz",
  },
  {
    text: "pounds",
    value: "lbs"
  },
  {
    text: "kilograms",
    value: "kg"
  },
  {
    text: "fluid ounces",
    value: "fl oz"
  },
  {
    text: "cups",
    value: "cup"
  },
  {
    text: "tablespoons",
    value: "tbsp"
  },
  {
    text: "teaspoons",
    value: "tsp"
  },
];

export const Conversions = {
  'lbs': 453.59237,
  'oz': 28.3495,
  'fl oz': 29.5735,
  'cup': 236.588,
  'tbsp': 14.7868,
  'tsp': 4.92892,
  'kg': 1000,
  'g': 1,
};