import { Injectable } from '@angular/core';
import { TutorialData } from 'app/@core/interfaces/common/CalorieFriend/tutorial';
import { Observable } from 'rxjs';

import { TutorialApi } from '../../api/CalorieFriend/tutorial.api';
import { FoodDiaryApi } from '../../api/CalorieFriend/food-diary.api';
import {
  FoodDiary,
  FoodDiaryData,
} from 'app/@core/interfaces/common/CalorieFriend/food-diary';

@Injectable()
export class FoodDiaryService extends FoodDiaryData {
  constructor(private api: FoodDiaryApi) {
    super();
  }

  save(patientId: number, diary: FoodDiary): Observable<boolean> {
    return this.api.save(patientId, diary);
  }

  get(patientId: number, date: Date): Observable<FoodDiary> {
    return this.api.get(patientId, date);
  }

  public async getDiariesInMonth(
    patientId: number,
    year: number,
    month: number
  ) {
    const pastMonth = month - 1;
    let startDate = new Date(pastMonth == 0 ? year-1: year, pastMonth === 0? 12: pastMonth);
    const nextMonth = month + 1;
    let endDate = new Date(nextMonth === 13? year + 1: year, nextMonth === 13 ? 1 : nextMonth);
    const data = await this.api.getDiariesInRange(
      patientId,
      startDate,
      endDate
    );
    return data;
  }

  public getComplianceScore(
    patientId: number,
    startDate: Date,
    endDate?: Date
  ) {
    return this.api.getComplianceScore(
      patientId,
      startDate,
      endDate || new Date()
    );
  }
}
