import { Observable } from 'rxjs';


export interface HiddenSimilarFood {
  id: number;

  isCustom: boolean;
  foodId: number;
  similarItemId: number;
  userId: number;

}

export abstract class HiddenSimilarFoodData {
  abstract show(items: any): Observable<any>;
  abstract hide(items: any): Observable<any>;
}
