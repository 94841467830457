import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { map } from 'rxjs/operators';
import { PatientSubscriptionData, PatientSubscriptionMeta, PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';

@Injectable()
export class PatientSubscriptionsDataApi {
  private readonly apiController: string = 'patient-subscriptions';
  constructor(private api: HttpService) {}

  public list(filter: PatientSubscriptionsFilter): Observable<GridData<PatientSubscriptionData>> {
    return this.api.list(this.apiController, filter);
  }

  public listForPatient(
    guid: string,
    filter: PatientSubscriptionsFilter
  ): Observable<GridData<PatientSubscriptionData>> {
    return this.api.list(`${this.apiController}/${guid}`, filter);
  }

  public get(id: number, patientGuid?: string): Observable<PatientSubscriptionData> {
    let params: any = {};
    if (patientGuid) params.patientGuid = patientGuid;
    return this.api.get(`${this.apiController}/${id}`, { params });
  }

  public cancel(id: number): Observable<boolean>{
    return this.api.post(`${this.apiController}/${id}/cancel`, {});
  }
}
