import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Meal, MealItem } from './meal';
import { FoodData } from './food';
import { FitnessBlock } from './fitnessItem';

export interface BasePlanDay {
  id: number;
  planID?: number;
  name: string;
  notes: string;
  dayOfWeek: DayOfWeek[];
  createMeal: boolean;
  order: number;
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const WeekDays: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const Short_WeekDays: string[] = WeekDays.map((day) => day.slice(0, 3));

/**
 * Interface for holading PlanDay information
 */
export interface MealPlanDay extends BasePlanDay {
  meals: Array<Meal>;
}

export interface FitnessPlanDay extends BasePlanDay {
  blocks: Array<FitnessBlock>;
}

/**
 * Class to perform the individual meal item's selection size processing.
 */
export class ItemProcessing {
  Item: MealItem = null;
  CurrentSelection = 0;
  protected readonly unsubscribe$ = new Subject<void>();
  MaxSelections = 0;

  constructor(Item: MealItem, private foodService: FoodData) {
    this.Item = Item;
    this.PrepareMealItem();
  }

  /**
   * Function to prepare the current selection size and max selection size.
   */
  private PrepareMealItem() {
    this.MaxSelections = 0;

    if (this.Item != null && this.Item !== undefined) {
      this.CurrentSelection =
        this.Item.selectedServingSizeIndex === undefined ||
        this.Item.selectedServingSizeIndex === null
          ? 0
          : this.Item.selectedServingSizeIndex;

      if (this.Item.isCustomFood) {
        this.MaxSelections = this.Item.photoCount - 1;
      } else {
        const observable = this.foodService.getFoodDefintion(
          this.Item.foodId.toString(),
          this.CurrentSelection + 1
        );

        observable.pipe(takeUntil(this.unsubscribe$)).subscribe((Item) => {
          if (Item.foodItem.photoCount > 0) {
            this.MaxSelections = Item.foodItem.photoCount - 1;
          }
        });
      }
    }
  }
}

/**
 * Class to perform the Plan day's selection size processing.
 */
export class PlanDayProcessing {
  Day: MealPlanDay;
  ProcessItems: Array<ItemProcessing> = null;
  IncrementStep = 0.5;
  protected readonly unsubscribe$ = new Subject<void>();

  constructor(Day: MealPlanDay, private foodService: FoodData) {
    this.Day = Day;
    this.ProcessItems = new Array<ItemProcessing>();
  }

  /**
   * Function to perform size selection processing of Plan day
   */
  UpdateProcessing() {
    this.ProcessItems = new Array<ItemProcessing>();

    if (this.Day != null && this.Day !== undefined) {
      // retreive meals
      for (const meal of this.Day.meals) {
        if (!meal.items) continue;
        // go through each meal and prepare
        for (const item of meal.items) {
          // const found = this.ProcessItems.find(currentitem => currentitem.Item.foodId == item.foodId);
          // if (found == null || found == undefined)
          {
            const IP = new ItemProcessing(item, this.foodService);
            this.ProcessItems.push(IP);
          }
        }
      }
    }
  }

  SubtractQuantity(Item) {
    let nextIncrement;
    const adjustementLeftOver = Item.quantity - Math.floor(Item.quantity);
    if (adjustementLeftOver > this.IncrementStep) {
      nextIncrement = adjustementLeftOver - this.IncrementStep;
    } else {
      nextIncrement =
        adjustementLeftOver > 0 ? adjustementLeftOver : this.IncrementStep;
    }

    if (Item.quantity - nextIncrement >= 0) {
      Item.quantity -= nextIncrement;
    }
  }

  /**
   * Method to decrease down the size of the meal items of the plan day
   */
  MoveSelectionDown() {
    if (this.ProcessItems != null && this.ProcessItems !== undefined) {
      let Change: Boolean = false;

      for (const item of this.ProcessItems) {
        if (item.Item.isCustomServingSize) continue;

        Change = false;

        if (item.Item.quantity > 1) {
          this.SubtractQuantity(item.Item);
          Change = true;
        } else {
          if (item.Item.selectedServingSizeIndex > 0) {
            item.Item.selectedServingSizeIndex -= 1;
            Change = true;
          } else {
            if (item.Item.quantity > this.IncrementStep) {
              this.SubtractQuantity(item.Item);
              Change = true;
            }
          }
        }

        if (Change) this.prepareSelection(item.Item);
      }
    }
  }

  AddQuantity(Item) {
    let nextIncrement;
    const adjustementLeftOver = Math.ceil(Item.quantity) - Item.quantity;
    if (adjustementLeftOver > this.IncrementStep) {
      nextIncrement = adjustementLeftOver - this.IncrementStep;
    } else {
      nextIncrement =
        adjustementLeftOver > 0 ? adjustementLeftOver : this.IncrementStep;
    }

    Item.quantity += nextIncrement;
  }

  /**
   * Method to increase up the size of the meal items of the Plan day
   */
  MoveSelectionUp() {
    if (this.ProcessItems != null && this.ProcessItems !== undefined) {
      for (const item of this.ProcessItems) {
        if (item.Item.isCustomServingSize) continue;

        if (item.Item.quantity < 1) {
          this.AddQuantity(item.Item);
        } else {
          if (item.Item.selectedServingSizeIndex < item.MaxSelections) {
            item.Item.selectedServingSizeIndex += 1;
          } else this.AddQuantity(item.Item);
        }

        this.prepareSelection(item.Item);
      }
    }
  }

  prepareSelection(Item: MealItem) {
    const CurrentSelection =
      Item.selectedServingSizeIndex === undefined ||
      Item.selectedServingSizeIndex === null
        ? 0
        : Item.selectedServingSizeIndex;

    const observable = this.foodService.getFoodDefintion(
      Item.foodId.toString(),
      CurrentSelection + 1,
      Item.isCustomFood
    );
    observable.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (data) => {
        Item.Current = data;
        Item.Update.next();
      },
      (err) => {}
    );
  }
}
