import { PatientSubscriptionsDataService } from './../../../@core/backend/common/services/CalorieFriend/patient-subscriptions-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { PatientSubscriptionsMetaService } from 'app/@core/backend/common/services/CalorieFriend/patient-subscriptions-meta.service';
import { InvoiceFilter, PatientWithInvoiceNumber } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { PatientSubscriptionsFormComponent } from './patient-subscriptions-form/patient-subscriptions-form.component';
import { PatientSubscriptionsMetaTableComponent } from './patient-subscriptions-meta-table/patient-subscriptions-meta-table.component';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import { filter, finalize, flatMap, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Patients4subscriptionComponent } from './patients4subscription/patients4subscription.component';
import { PatientData } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { PatientSubscriptionsTableComponent } from './patient-subscriptions-table/patient-subscriptions-table.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentSettingsService } from './../../../@core/backend/common/services/CalorieFriend/payment-settings.service';

@Component({
  selector: 'app-patient-subscriptions',
  templateUrl: './patient-subscriptions.component.html',
  styleUrls: ['./patient-subscriptions.component.scss'],
})
export class PatientSubscriptionsComponent implements OnInit {

  public connecting: boolean = false;
  constructor(
    private patientSubscriptionMetaService: PatientSubscriptionsMetaService,
    private patientSubscriptionDataService: PatientSubscriptionsDataService,
    private patientService: PatientData,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private paymentSettingsService: PaymentSettingsService
  ) {}

  @ViewChild('subscriptionsMetaTable')
  subscriptionsMetaTable: PatientSubscriptionsMetaTableComponent;

  @ViewChild('subscriptionsDataTable')
  subscriptionsDataTable: PatientSubscriptionsTableComponent;

  clients$: Observable<PatientWithInvoiceNumber[]> = of([]);

  ngOnInit() {
    this.clients$ = this.patientService.getAllClientsWithInvoiceNumber().pipe(shareReplay());
  }

  /**
   * !This function should be arrow functions
   * @param filter filter for querying invoices
   * @returns
   */
  loadSubscriptionsData = (filter: PatientSubscriptionsFilter) => {
    return this.patientSubscriptionDataService.list(filter);
  };

  /**
   * !This function should be arrow functions
   * @param filter filter for querying invoices
   * @returns
   */
  loadSubscriptionsMeta = (filter: PatientSubscriptionsFilter) => {
    return this.patientSubscriptionMetaService.list({ ...filter, isDeleted: false });
  };

  deleteSubscriptionMeta = (id: number) => {
    return this.dialogService
      .open(ConfirmMessageDialogComponent, {
        closeOnEsc: true,
        closeOnBackdropClick: true,
        context: { Message: 'Are you sure to delete this subscription meta data?' },
      })
      .onClose.pipe(
        flatMap((confirmed: boolean) => {
          if (confirmed) {
            return this.patientSubscriptionMetaService.delete(id).pipe(
              tap((res) => {
                if (res) {
                  this.toastrService.success('', 'Item deleted!');
                } else {
                  this.toastrService.danger('', 'Something wrong.');
                }
              })
            );
          }
          return of(false);
        })
      );
  };

  createSubscriptionMeta() {
    let ref: NbDialogRef<any>;

    ref = this.dialogService.open(PatientSubscriptionsFormComponent, {
      context: { editable: true },
      closeOnBackdropClick: false,
    });

    ref.onClose.subscribe({
      next: (saved: boolean) => {
        if (saved) {
          this.subscriptionsMetaTable?.refresh();
        }
      },
    });
  }

  onViewEditMeta({ id = 0, editable = false }) {
    let ref: NbDialogRef<any>;

    ref = this.dialogService.open(PatientSubscriptionsFormComponent, {
      context: { subscriptionMetaId: id, editable },
      closeOnBackdropClick: false,
    });
    ref.onClose.subscribe({
      next: (saved: boolean) => {
        if (saved) {
          this.subscriptionsMetaTable?.refresh();
        }
      },
    });
  }

  onSendSubscriptionMeta(id: number) {
    this.dialogService
      .open(Patients4subscriptionComponent, {
        context: {
          clients$: this.clients$,
        },
      })
      .onClose.subscribe({
        next: (clients: number[] = []) => {
          if (clients.length <= 0) return;
          this.toastrService.info(`Sending email to ${clients.length} clients...`, 'Sending');
          this.patientSubscriptionMetaService.send(id, clients).subscribe({
            next: (successCount) => {
              if (successCount > 0)
                this.toastrService.success(
                  `Successfully sent to ${successCount} clients`,
                  `${successCount} / ${clients.length}`
                );
              else this.toastrService.danger(`Sending email failed`, 'Failed');
            },
          });
        },
      });
  }

  cancelSubscriptionData(subId: number) {
    this.dialogService
      .open(ConfirmMessageDialogComponent, {
        closeOnEsc: true,
        closeOnBackdropClick: true,
        context: { Message: 'Are you sure to cancel this subscription?' },
      })
      .onClose.pipe(
        filter((ok) => !!ok),
        switchMap(() => this.patientSubscriptionDataService.cancel(subId))
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.toastrService.success(`Subscription #${subId} successfully canceled`, 'Success');
            this.subscriptionsDataTable.refresh();
          } else {
            this.toastrService.danger(`Operation Failed`, 'Failed');
          }
        },
        error: (e: HttpErrorResponse) => {
          this.toastrService.danger(e.error, 'Failed');
        }
      });
  }

  expressLogin() {
    this.connecting = true;
    this.paymentSettingsService.expressLogin().pipe(finalize(()=>this.connecting = false)).subscribe((res) => {
      window.open(res.url, "_blank");
    });
  }
}
