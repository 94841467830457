import { IComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import { HostListener, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map, skip } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';

import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';

@Injectable()
export class WorkoutGuard implements CanDeactivate<IComponentCanDeactivate> {
  constructor(private dialogService: NbDialogService) {}

  canDeactivate(
    component: IComponentCanDeactivate
  ): boolean | Observable<boolean> {
    console.log('============ on guard can deactivate ====================');
    return component.canDeactivate() ? true : this.openConfirmDialog();
  }

  openConfirmDialog(): any {
    const dlg = this.dialogService.open(ConfirmMessageDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        Title: $localize`:@@Warning:Warning`,
        Message:
          'Do you want to stop workout? You can resume workout anytime until end of day today.',
      },
    });
    return dlg.onClose.pipe(map((result) => result));
  }
}
