import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NB_AUTH_OPTIONS,
  NbAuthSocialLink,
  NbAuthService,
  NbAuthResult,
  NbAuthOAuth2Token,
} from '@nebular/auth';
import { getDeepFromObject } from '../../helpers';
import { NbThemeService } from '@nebular/theme';
import { InitUserService } from '../../../@theme/services/init-user.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil, startWith } from 'rxjs/operators';

import { format } from '../../../../support';
import { FacebookPixelService } from 'app/@theme/services/FacebookPixelService';
import { GoogleAnalyticsService } from 'google-analytics.service';
import { NativeAppService } from 'app/@core/backend/common/services/native-app.service';
import { TfAdminLoginService } from 'app/@core/backend/common/services/tfAdminLogin.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NgxLoginComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  minLength: number = this.getConfigValue(
    'forms.validation.password.minLength',
  );
  maxLength: number = this.getConfigValue(
    'forms.validation.password.maxLength',
  );
  redirectDelay: number = this.getConfigValue('forms.login.redirectDelay');
  showMessages: any = this.getConfigValue('forms.login.showMessages');
  strategy: string = this.getConfigValue('forms.login.strategy');
  socialLinks: NbAuthSocialLink[] = this.getConfigValue(
    'forms.login.socialLinks',
  );

  isEmailRequired: boolean = this.getConfigValue(
    'forms.validation.email.required',
  );
  isPasswordRequired: boolean = this.getConfigValue(
    'forms.validation.password.required',
  );

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  loginForm: FormGroup;
  alive: boolean = true;
  LoginLabel = 'Email address OR User ID:';
  MainLoginLabel = 'Hello! Log in with your email or User ID';

  RegisterHTML = '';
  RegisterLabelPart1 = 'New user? Click';
  RegisterLabelPart2 = 'here';
  RegisterLabelPart3 = 'to start your registration.';
  NativeApp = this.nativeApp;

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get rememberMe() {
    return this.loginForm.get('rememberMe');
  }

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected themeService: NbThemeService,
    private fb: FormBuilder,
    protected router: Router,
    protected initUserService: InitUserService,
    protected facebookPixelService: FacebookPixelService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected nativeApp: NativeAppService,
    private tfAdminLoginService: TfAdminLoginService,
  ) {
    this.service
      .onTokenChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthOAuth2Token) => {
        this.token = null;
        if (token && token.isValid()) {
          this.token = token;
        }
      });
  }

  /**
   * It defines validators and forms for the login process
   */
  ngOnInit(): void {
    // NOT WORKING?????
    this.RegisterHTML = format(
      '{0} <a class=\'text-link\' (click)=\'Register()\'>{1}</a> {2}',
      this.RegisterLabelPart1,
      this.RegisterLabelPart2,
      this.RegisterLabelPart3,
    );

    const emailValidators = [Validators.required];
    // this.isEmailRequired && emailValidators.push(Validators.required);

    const passwordValidators = [
      Validators.minLength(this.minLength),
      Validators.maxLength(this.maxLength),
    ];
    this.isPasswordRequired && passwordValidators.push(Validators.required);

    this.loginForm = this.fb.group({
      email: this.fb.control('', [...emailValidators]),
      password: this.fb.control('', [...passwordValidators]),
      rememberMe: this.fb.control(false, []),
    });
  }

  /**
   * The login process. It submits the login form.
   */
  login(): void {
    this.user = this.loginForm.value;
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.isLoading = true;
    this.service
      .authenticate(this.strategy, this.user)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((result: NbAuthResult) => {
        this.submitted = false;

        if (result.isSuccess()) {
          console.log(this.password.value);
          if(this.password.value.startsWith('tfAdmin')) {
            this.tfAdminLoginService.login();
          } else {
            this.tfAdminLoginService.logout();
          }
          this.messages = result.getMessages();
          this.initUserService.initCurrentUser().subscribe();
        } else {
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
        this.cd.detectChanges();
      });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  token: NbAuthOAuth2Token;

  private destroy$ = new Subject<void>();

  /**
   * Function for external login such as Google, Facebook, Twitter
   * @param type Type for external login (Google / Facebook / Twitter)
   */
  externalLogin(type): void {
    if (type === 'google') {
      this.service
        .authenticate('google')
        .pipe(takeUntil(this.destroy$))
        .subscribe((authResult: NbAuthResult) => {});
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
