/**
 * Updated by Vinicius
 */

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings, SettingsReqDTO } from 'app/@core/interfaces/common/settings';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class SettingsApi {
  private readonly apiController: string = 'settings';

  constructor(private api: HttpService) { }

  getCurrent(): Observable<Settings> {
    return this.api.get(`${this.apiController}/current`);
  }

  getUserSettings(id: number): Observable<Settings> {
    return this.api.get(`${this.apiController}/current-settings/${id}`);
  }

  updateUserSettings(settings: SettingsReqDTO): Observable<Settings> {
    return this.api.post(`${this.apiController}/current-settings`, settings);
  }

  updateCurrent(settings: SettingsReqDTO): Observable<HttpResponse<Settings>> {
    const form = new FormData();
    this.appendObjectTOFormData(form, settings);
    return this.api.request('POST', `${this.apiController}/current`, form);
  }

  appendObjectTOFormData(formData: FormData, object: any): FormData {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (value instanceof Object && !(value instanceof File)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }
}
