<br><br>

<nb-card [nbSpinner]="isWaiting" nbSpinnerSize="giant" nbSpinnerMessage="Loading..." nbSpinnerStatus="success">


    <nb-card-body>
        <app-meal-metrics-graph style="display:grid;" #MealGraph class="Metrics" [MealCals]="getSummary('cals')"
            [MealProtein]="getSummary('protein')" [MealCarbs]="getSummary('carbs')"
            [MealNetCarbs]="getSummary('netcarbs')" [MealFats]="getSummary('fats')" GraphInBack="true">
        </app-meal-metrics-graph>
        <div style="height: 10px;"></div>
        <ng-container *ngIf="patient | async as patient">
            <app-macro-goals-progress
                *ngIf="patient.enableMacroGoals"
                [Patient]="patient"
                [Cals]="getSummary('cals')"
                [Protein]="getSummary('protein')"
                [Carbs]="getSummary('carbs')"
                [Fats]="getSummary('fats')"
            ></app-macro-goals-progress>
        </ng-container>

        <ng-container *ngIf="patient | async as patient">
        
        </ng-container>
        <div style="height: 10px;"></div>
        <div *ngFor="let meal of currentDiary.content; let i = index">
            <div class="remove-meal-wrapper">
                <div class="remove-meal-action">
                    <button nbButton class="btn-remove-meal" alt="Copy" (click)="Copy(meal)"
                        style="padding: 0px !important">
                        <nb-icon icon="copy-outline" alt="Copy"></nb-icon>
                    </button>

                    <button nbButton alt="Paste" class="btn-remove-meal" (click)="Paste(meal, i)"
                        [hidden]="!HasClipboard()" style="padding: 0px !important">
                        <nb-icon icon="clipboard-outline" alt="Paste"></nb-icon>
                    </button>
                </div>
            </div>

            <app-meal-presentation #meals (onItemChange)="onItemChange()" #MealPresentation [DiaryPatientId]="patientId"
                [UserId]="userId" [PlanMeal]="meal" [Index]="i" [PlanView]="readOnly">
            </app-meal-presentation>
            <div style="height: 20px;"></div>

        </div>
    </nb-card-body>
</nb-card>

