import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';
import { GridData } from '../../../../interfaces/common/grid-data';
import { GoalView } from '../../../../interfaces/common/CalorieFriend/goals';

/**
 * Injectable class for Goals API
 */
@Injectable()
export class GoalsApi {
  /**
   * API Controller Name - 'Goals'
   */
  private readonly apiController: string = 'Goals';

  constructor(private api: HttpService) {}

  /**
   * API function to get the goals list in DataSource format.
   * Method: GET
   * URL: /
   */
  get goalsDataSource(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}`
    );
  }

  /**
   * API function to get all the goals list of the patient given by {patientId} param or {NotificationToken} param in GridData format for displaying graph.
   * @param patientId Identifier of the patient in number format
   * @param NotificationToken Identifier of the patient in number format
   * Method: GET
   * URL: /listForPatient/${patientId} or /listForPatient/${NotificationToken}
   */
  goalsList(
    patientId: number,
    NotificationToken: string
  ): Observable<GridData<GoalView>> {
    if (NotificationToken != null && NotificationToken != undefined)
      return this.api.get(
        `${this.apiController}/listForPatient/${NotificationToken}`
      ) as Observable<GridData<GoalView>>;
    else
      return this.api.get(
        `${this.apiController}/listForPatient/${patientId}`
      ) as Observable<GridData<GoalView>>;
  }

  /**
   * API function to get the Goal with the identifier @id
   * @param id Identifier of the goal
   * Method: GET
   * URL: /${id}
   */
  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to add a new goal.
   * @param Goal Goal Form data
   * Method: POST
   * URL: /
   */
  add(Goal: any): Observable<any> {
    return this.api.post(this.apiController, Goal);
  }

  /**
   * API function to delete an existing goal.
   * @param id Identifier of the goal in number format
   * Method: POST
   * URL: /delete/${id}
   */
  delete(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/delete/${id}`, null);
  }

  /**
   * API function to update an existing goal
   * @param Goal Goal Form data
   * Method: POST
   * URL: /edit
   */
  update(Goal: any): Observable<any> {
    return this.api.post(`${this.apiController}/edit`, Goal);
  }
}
