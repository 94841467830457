<nb-card [nbSpinner]="!loaded" class="nb_card_pop_up full_pop_up mobilePopup" 
  style="height: 90vh; position: relative; width: 90vw; max-width: 1000px;">
  <nb-card-header>
    <div class="title">
      <span>Quick Add</span>
    </div>
    <div class="actions">
      <button nbButton ghost shape="round"  (click)="onBack()" tabindex="-1">
        <nb-icon icon="close-outline" style="z-index: 1000;"></nb-icon>
      </button>
    </div>
  </nb-card-header>

  <nb-card-body [formGroup]="foodItemForm" style="overflow-x:hidden;">

    <input nbInput formControlName="description" type="text" hidden="true">
    <input nbInput formControlName="saturatedFat" type="text" hidden="true">
    <input nbInput formControlName="transFat" type="text" hidden="true">
    <input nbInput formControlName="cholesterol" type="text" hidden="true">
    <input nbInput formControlName="sodium" type="text" hidden="true">
    <input nbInput formControlName="sugar" type="text" hidden="true">
    <input nbInput formControlName="alcohol" type="text" hidden="true">
    <input nbInput formControlName="defaultServingSize" type="text" hidden="true">

    <div class="row">
      <div class="col-sm-10">
        <!-- Name -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@FoodName">Food Name</span>
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="name" [readonly]="!editable"
              [status]="(name?.errors?.required || name?.errors?.minlength) ? 'danger' : 'primary'" type="text"
              fullWidth shape="semi-round" i18n-placeholder="@@RequiredField"
              placeholder="{{foodNameLabel}} (required)" />
            <ngx-validation-message i18n-label="@@FoodName" label="Food Name" [showMinLength]="name?.errors?.minlength"
              [showRequired]="name?.errors?.required && name.touched" [minLength]="1">
            </ngx-validation-message>
          </div>
        </div>


      </div>

      <div class="col-sm-2"> 

        <div class="img-container" (mouseover)="imgHover=true" (mouseleave)="imgHover=false">

          <div class="food-img-actions" *ngIf="imgFoodItem != null">
            <a href="javascript:void(0)" (click)="onResetFoodImage()">
              <nb-icon class="control-item2" icon="trash-outline"></nb-icon>
            </a>
          </div>

          <div class="img-inside" (click)="onUploadFoodImage()">

            <img class="food-img" *ngIf="imgFoodItem" [src]="imgFoodItem"
              [ngStyle]="{opacity: imgHover==true  ? '0.25' : '1' }" width="100%" height="100%">
            <input type="hidden" formControlName="foodImageUrl" />
          </div>

        </div>



      </div>


    </div>


    <nb-card>
      <nb-card-header>
        <div i18n="@@NutritionFacts">Nutrition Facts</div>
        <div i18n="@@GeneralInfoSmall" class="small-header"></div>
      </nb-card-header>

      <nb-card-body>

        <div class="row">
          <div class="col-sm-12">

            <!-- Meal Metrics Pie Chart -->
            <div class="pie-chart-container">
              <app-meal-metrics-graph [MealCals]="calories.value" [MealProtein]="protein.value"
                [MealCarbs]="carbohydrate.value" [MealFats]="fats.value" [GraphInBack]="true">
              </app-meal-metrics-graph>
            </div>

          </div>


        </div>


        <!-- Calories -->
        <div class="form-group row">
          <div i18n="@@Calories" class="col-sm-4 label col-form-label required-label">Calories</div>
          <div class="col-sm-8">
            <input nbInput formControlName="calories" (input)="calorieChanged()" class="input-with-unit"
              [status]="(calories?.errors?.min) ? 'danger' : 'primary'" type="text" shape="semi-round"
              i18n-placeholder="@@Calories" placeholder="Calories">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Calories" label="Calories"
              [showMin]="calories?.errors?.min && calories.touched"
              [showRequired]="calories?.errors?.required && calories.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Fats -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Fats">Fats</span>
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="fats" (input)="onMacroChanged()" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="(fats?.errors?.required || fats?.errors?.min) ? 'danger' : 'primary'"
              type="text" shape="semi-round" i18n-placeholder="@@RequiredField" placeholder="{{fatsLabel}} (required)"
              value="0"/>
          </div>
        </div>

        <!-- Carbohydrate -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Carbohydrate">Carbohydrate</span>
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="carbohydrate" (input)="onMacroChanged()" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit"
              [status]="(carbohydrate?.errors?.required || carbohydrate?.errors?.min) ? 'danger' : 'primary'"
              type="text" shape="semi-round" i18n-placeholder="@@RequiredField"
              placeholder="{{carbohydrateLabel}} (required)" value="0">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Carbohydrate" label="Carbohydrate"
              [showMin]="carbohydrate?.errors?.min && carbohydrate.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

        <!-- Fiber -->
        <div class="form-group row">
          <div i18n="@@Fiber" class="col-sm-4 label col-form-label label-indent">Fiber</div>
          <div class="col-sm-8">
            <input nbInput formControlName="fiber" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit" [status]="fiber?.errors?.min ? 'danger' : 'default'" type="text"
              shape="semi-round" i18n-placeholder="@@Fiber" placeholder="Fiber" value="0">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Fiber" label="Fiber" [showMin]="fiber?.errors?.min && fiber.touched"
              [min]="0">
            </ngx-validation-message>
          </div>
        </div>


        <!-- Protein -->
        <div class="form-group row">
          <div class="col-sm-4 label col-form-label required-label">
            <span i18n="@@Protein">Protein</span>
          </div>
          <div class="col-sm-8">
            <input nbInput formControlName="protein" (input)="onMacroChanged()" [readonly]="!editable" (keypress)="validateNumber($event)"
              class="input-with-unit"
              [status]="(protein?.errors?.required || protein?.errors?.min) ? 'danger' : 'primary'" type="text"
              shape="semi-round" i18n-placeholder="@@RequiredField" placeholder="{{proteinLabel}} (required)">
            <div class="label col-form-label col-unit-label">g</div>
            <ngx-validation-message i18n-label="@@Protein" label="Protein"
              [showMin]="protein?.errors?.min && protein.touched" [min]="0">
            </ngx-validation-message>
          </div>
        </div>

    

      </nb-card-body>
    </nb-card>




  </nb-card-body>

  <nb-card-footer>
    <button i18n="@@Save" nbButton *ngIf="editable" size="small" (click)="onSave()" 
      [disabled]="!foodItemForm.valid">Save</button>
  </nb-card-footer>
</nb-card>


