import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

export interface ExerciceSearchTerm {
  id: number;
  searchTerm: string;
  revelencyScore: number;
}

export interface BodyPart {
  id: number;
  name: string;
}

export interface Muscle {
  id: number;
  name: string;
}

export interface Equipment {
  id: number;
  name: string;
}

export interface Exercise {
  id: number;
  exerciceId: string;
  searchWeight: number;
  name: string;
  videoName: string;
  imageName: string;
  imageNameFemale: string;

  equipments: Equipment[];
  bodyParts: BodyPart[];
  muscleTarget: Muscle[];
  muscleSynergist: Muscle[];
  searchTerms: ExerciceSearchTerm[];

  imageUrl: string;
  imageMediumUrl: string;
  femaleImageUrl: string;
  femaleImageMediumUrl: string;
  videoUrl: string;
  exerciseVideoThumbnailUrl?: string;
  femaleVideoUrl: string;
  youtubeUrl: string;

  selected: boolean;

  isCustom: boolean;
  isQuickAdd:boolean;
  customName:string;
}

export abstract class ExerciseData {
  abstract get gridDataSource(): DataSource;
  abstract GetExerciseDefintion(id: string): Observable<Exercise>;
  abstract ClearCache(): void;
  abstract GetFilterResult(query: string): Observable<string[]>;
  abstract GetFilterOptions(): Observable<any>;
}
