<div class="EditControlWrapper">
  <!--single line label element-->
  <label for="name" class="smart-edit-label" (click)="onClickEdit();" [hidden]="hideLabel ? true : DisplaySecond">
    <div [innerHTML]="formattedValue()"></div>

    <div *ngIf="(!this.value || this.value=='') && !readonly" style="color:lightgray; font-weight:300">(edit)</div>
  </label>

  <input nbInput [type]="InputType"
         #input [readonly]="readonly"
         min="0"
         (focusout)="onFocusout()"
         [hidden]="IsMultiLine == false ? hideEdit : true"
         [(ngModel)]="value"
         style="white-space: pre-wrap;"
         [minlength]="MinLength"
         [maxlength]="MaxLength"
         class="input-full-width size-medium status-basic shape-semi-round"
         (keyup)="onKeyup($event)"
         (input)="onInput()"
         (change)="onValuesChange()" />

  <div class="controls EditControlLayout" >
    <a class="edit" href="javascript:void(0);" (click)="onClickEdit();" [hidden]="hideLabel">
      <nb-icon icon="modify-pencil-outline" *ngIf="!readonly" pack="custom"></nb-icon>
      <nb-icon icon="eye-outline" *ngIf="readonly"></nb-icon>
    </a>
  </div>
</div>
