import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

import { HttpService } from '../http.service';
import {
  CustomFoodEdit,
  CustomFoodFilter,
  CustomFoodView,
} from 'app/@core/interfaces/common/CalorieFriend/custom-food';
import { GridData } from 'app/@core/interfaces/common/grid-data';

@Injectable()
export class CustomFoodApi {
  protected readonly apiController: string = 'custom-food';

  constructor(protected api: HttpService) {}

  // Created by Vinicius
  list(filter: CustomFoodFilter): Observable<GridData<CustomFoodEdit>> {
    return this.api.list(this.apiController, filter);
  }

  /**
   * API function to get the custom foods list of the current user in DataSource format.
   * Method: GET
   * URL: /
   */
  get customFoodDataSource(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/data`
    );
  }

  /**
   * API function to get the custom foods list with detail information for Meal Plans in DataSource format.
   * Method: GET
   * URL: /ListForMeal
   */
  customFoodDataSourceForMeal(userId: number, patientId: number): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/ListForMeal/${userId}/${patientId}`
    );
  }

  // Updated by Milos
  get(id: number): Observable<CustomFoodView> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to get portion information of the Custom food with the identifier @id
   * @param id Identifier of the custom food
   * @param portion Portion size index of the food
   * Method: GET
   * URL: /${id}/${portion}
   */
  GetFoodDefintion(
    id: number,
    portion: number,
    customServingSize: number
  ): Observable<any> {
    return this.api.get(
      `${this.apiController}/${id}/${portion}/${customServingSize}`
    );
  }

  // Updated by Milos
  add(
    customFood: CustomFoodEdit,
    userId: number,
    patientId: number
  ): Observable<boolean> {
    const form_data = new FormData();
    Object.keys(customFood).forEach((key) =>
      form_data.append(key, customFood[key])
    );

    return this.api.post(
      `${this.apiController}/create/${userId}/${patientId}`,
      form_data
    );
  }

  // Updated by Milos
  addQuickAdd(
    customFood: CustomFoodEdit,
    userId: number,
    patientId: number
  ): Observable<CustomFoodView> {
    const form_data = new FormData();
    Object.keys(customFood).forEach((key) =>
      form_data.append(key, customFood[key])
    );

    return this.api.post(
      `${this.apiController}/createQuickAdd/${userId}/${patientId}`,
      form_data
    );
  }

  // Updated by Vinicius
  delete(id: number, patientGuid?: string): Observable<any> {
    let params = {};
    if (patientGuid) {
      params = { ...params, patientGuid };
    }
    return this.api.delete(`${this.apiController}/${id}`, {
      params
    });
  }

  // Updated by Milos
  update(
    customFood: CustomFoodEdit,
    userId: number,
    patientId: number
  ): Observable<boolean> {
    const form_data = new FormData();
    Object.keys(customFood).forEach((key) =>
      form_data.append(key, customFood[key])
    );

    return this.api.put(
      `${this.apiController}/update/${userId}/${patientId}`,
      form_data
    );
  }

  /**
   * API function to get the custom foods list filtered by @query
   * @param query Query string
   * Method: GET
   * URL: /filter/${query}
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.get(`${this.apiController}/filter/${query}`);
  }
}

@Injectable()
export class CustomPublicFoodApi extends CustomFoodApi {
  /**
   * API function to get the public custom foods list with detail information for Meal Plans in DataSource format.
   * Method: GET
   * URL: /ListForMeal
   */
  get customPublicFoodDataSourceForMeal(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/ListForMealPublic`,
    );
  }
}
