

import { Component, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { DefaultFilter } from 'ng2-smart-table';
import { FormControl } from '@angular/forms';
import { Utility } from '../../../@core/utils/utility';

/**
 * Custom Filter component for 'Decimal Number' type fields of ng2 smart table
 * It validates the number with the ##.# pattern
 */
@Component({
  template: `
    <input
      #number
      [ngClass]="inputClass"
      [formControl]="inputControl"
      class="form-control"
      step="0.01" value="0.00"
      (change)="onChange($event)"
      (keypress)="validateNumber($event)"
      [placeholder]="column.title"
      type="number">
  `,
})
export class NgxNumberFieldComponent extends DefaultFilter implements OnInit, OnChanges {
  @Output() filter: EventEmitter<string> = new EventEmitter();

  inputControl = new FormControl();

  constructor() {
    super ();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onChange(event) {
    this.query = event.target.value || '';
    this.setFilter();
  }

  /**
   * Validator that validates the number with the ##.# pattern
   */
  validateNumber = Utility.validateNumber;

}
