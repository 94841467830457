import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CalendarOverrideItem,
  Patient,
  PatientData,
  PatientFilter,
} from '../../../../interfaces/common/CalorieFriend/patients';
import { PatientsApi } from '../../api/CalorieFriend/patients.api';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Settings } from 'app/@core/interfaces/common/settings';
import { PatientWithInvoiceNumber } from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { share } from 'rxjs/operators';

@Injectable()
export class PatientsService extends PatientData {
  private updated$ = new BehaviorSubject(0); // updated when created, updated or deleted

  onUpdated$ = this.updated$.asObservable();

  constructor(private api: PatientsApi) {
    super();
  }

  // Created by Milos
  list(filter: PatientFilter): Observable<GridData<Patient>> {
    return this.api.list(filter);
  }

  listWithCompliance(filter: PatientFilter): Observable<GridData<Patient>> {
    return this.api.listWithCompliacne(filter);
  }

  create(patient: Patient): Observable<Patient> {
    return this.api.add(patient);
  }

  get(id: number, guid: string): Observable<Patient> {
    return this.api.get(id, guid);
  }

  // Created by Vinicius
  getSettings(id: number, guid: string): Observable<Settings> {
    return this.api.getSettings(id, guid);
  }

  getCalendarOverrides(
    id: number,
    guid: string
  ): Observable<CalendarOverrideItem[]> {
    return this.api.getCalendarOverrides(id, guid);
  }

  setCalendarOverrides(
    id: number,
    overrides: CalendarOverrideItem,
    guid: string
  ): Observable<CalendarOverrideItem[]> {
    return this.api.setCalendarOverrides(id, overrides, guid);
  }

  // Reviewed by Milos
  getDemoPatient(): Observable<Patient> {
    return this.api.getDemoPatient();
  }

  update(patient: Patient, guid?: string): Observable<Patient> {
    return this.api.update(patient, guid);
  }

  // Reviewed by Milos
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  // Reviewed by Milos
  archive(id: number): Observable<boolean> {
    return this.api.archive(id);
  }

  // Reviewed by Milos
  unarchive(id: number): Observable<boolean> {
    return this.api.unarchive(id);
  }

  // Reviewed by Milos
  getActiveCount(): Observable<number> {
    return this.api.getActiveCount();
  }

  setPatientUpdated(id: number) {
    this.updated$.next(id);
  }

  getAllClientsWithInvoiceNumber(): Observable<PatientWithInvoiceNumber[]> {
    return this.api.getAllClientsWithInvoiceNumber().pipe(share());
  }

  getPatientSubscriptionItems(id): Observable<any> {
    return this.api.getPatientSubscriptionItems(id);
  }
}
