import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';

import { BaseComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import {
  CustomFoodEdit,
  CustomFoodData,
  CustomFoodView,
} from 'app/@core/interfaces/common/CalorieFriend/custom-food';
import { FoodData } from 'app/@core/interfaces/common/CalorieFriend/food';
import { Utility } from 'app/@core/utils/utility';
import { UserStore } from 'app/@core/stores/user.store';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ConversionFactorsData } from 'app/@core/utils/conversionFactors.data';
import { ConvertToGramPipe } from 'app/@theme/pipes';

@Component({
  selector: 'app-custom-food',
  templateUrl: './custom-food.component.html',
  styleUrls: ['./custom-food.component.scss'],
})
export class CustomFoodComponent
  extends BaseComponentCanDeactivate
  implements OnInit, OnDestroy {
  readonly foodNameLabel = $localize`:@@FoodName:Food Name`;
  readonly defaultServingSizeLabel = $localize`:@@DefaultServingSize:Default Serving Size`;
  readonly fatsLabel = $localize`:@@Fats:Fats`;
  readonly carbohydrateLabel = $localize`:@@Carbohydrate:Carbohydrate`;
  readonly proteinLabel = $localize`:@@Protein:Protein`;
  readonly validateNumber = Utility.validateNumber;

  id: number = 0; // from context
  isPublic: boolean = false; // from context
  currentUserId: number; // from store
  foodUserId: number;
  foodItemForm: FormGroup;
  imgHover: boolean = false; // template
  imgFoodItem: any = null;
  loaded: boolean = true;
  ConversionFactors: any = ConversionFactorsData;

  @Input('diaryPatientId') diaryPatientId: number;
  @Input('userId') userId: number;
  @Output() onItemQuickItemSaved = new EventEmitter();


  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dialogRef: NbDialogRef<CustomFoodComponent>,
    private location: Location,
    private customFoodService: CustomFoodData,
    private foodService: FoodData,
    private toasterService: NbToastrService,
    userStore: UserStore,
    dialogService: NbDialogService,
    private imageCompress: NgxImageCompressService,
  ) {
    super(dialogService);

    this.initForm();
    this.onChanges();

    if (userStore.getUser()) this.currentUserId = userStore.getUser().id;
    else
      userStore
        .onUserStateChange()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((user) => {
          if (!user) return;
          this.currentUserId = user.id;
        });
  }

  ngOnInit(): void {
    this.loadFoodItemData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadFoodItemData(): void {
    if (!this.id) return;

    this.customFoodService
      .get(this.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((item) => {
        this.foodItemForm.patchValue({
          id: this.id,
          isPublic: this.isPublic,
          name: item.name,
          description: item.description,
          calories: item.totalCalories,
          fats: item.fats,
          saturatedFat: item.saturatedFat,
          transFat: item.transFat,
          cholesterol: item.cholesterol,
          sodium: item.sodium,
          carbohydrate: item.carbohydrate,
          fiber: item.fiber,
          sugar: item.sugar,
          protein: item.protein,
          alcohol: item.alcohol,
          foodImageUrl: item.foodImageUrl,
          defaultServingSize: item.defaultServingSize,
          conversionFactorsValue: item.conversionFactorsValue? item.conversionFactorsValue: 'g'
        });

        this.foodUserId = item.createdByUserId;

        this.imgFoodItem = item.foodImageUrl;
        if (!this.imgFoodItem || this.imgFoodItem === '')
          this.imgFoodItem = null;

        super.resetDirty();
      });
  }

  initForm(): void {
    this.foodItemForm = this.fb.group({
      name: this.fb.control('', [Validators.required, Validators.minLength(1)]),
      description: this.fb.control('', []),
      calories: this.fb.control(0, [Validators.required, Validators.min(0)]),
      fats: this.fb.control('', [Validators.required, Validators.min(0)]),
      saturatedFat: this.fb.control('', [Validators.min(0)]),
      transFat: this.fb.control('', [Validators.min(0)]),
      cholesterol: this.fb.control('', [Validators.min(0)]),
      sodium: this.fb.control('', [Validators.min(0)]),
      carbohydrate: this.fb.control('', [
        Validators.required,
        Validators.min(0),
      ]),
      fiber: this.fb.control('', [Validators.min(0)]),
      sugar: this.fb.control('', [Validators.min(0)]),
      protein: this.fb.control('', [Validators.required, Validators.min(0)]),
      alcohol: this.fb.control('', [Validators.min(0)]),
      defaultServingSize: this.fb.control(100, [
        Validators.required,
        Validators.min(0),
      ]),
      fileFoodImage: this.fb.control('', []),
      foodImageUrl: this.fb.control('', []),
      conversionFactorsValue:  this.fb.control('g', [])
    });
    super.initForm(this.foodItemForm);
    //super.resetDirty();
  }


  /**
   * Set up the event handlers of the input controls of the form
   * so it should calculate the total Calorie automatically reflecting the changes
   */
  onChanges(): void {
    const func = (val) => {
      let value =
        +this.fats.value * 9 +
        +this.carbohydrate.value * 4 +
        +this.protein.value * 4 +
        +this.alcohol.value * 7;
      value = Math.round(value * 10) / 10;
      this.foodItemForm.patchValue({ calories: value });
    };

    this.foodItemForm.get('fats').valueChanges.subscribe(func);
    this.foodItemForm.get('carbohydrate').valueChanges.subscribe(func);
    this.foodItemForm.get('protein').valueChanges.subscribe(func);
    this.foodItemForm.get('alcohol').valueChanges.subscribe(func);
  }

  // close dialog or goes back
  onBack(): void {
    super.back(() => {
      if (this.dialogRef) {
        this.dialogRef.close();
        return;
      }
      this.location.back();
    });
  }

  onResetFoodImage(): void {
    this.imgFoodItem = null;
    this.foodItemForm.patchValue({
      foodImageUrl: null,
    });
  }



  onUploadFoodImage(): void {

 
    this.imageCompress.uploadFile().then(
      ({ image, orientation }) => {
        this.loaded = false;

        this.imageCompress
          .compressFile(image, -1, 25, 20) // 20% ratio, 40% quality
          .then(
            async (compressedImage) => {
              this.imgFoodItem = compressedImage;
              console.log("Size in bytes after compression is now:", this.imageCompress.byteCount(compressedImage));
              this.loaded = true;
           
            

         this.foodItemForm.patchValue({
                fileFoodImage: this.dataURLtoFile(compressedImage,"customfood"),
                foodImageUrl: null,
              });
          
                this.loaded = true;

            }
          ).catch(() => this.loaded = true).finally(() => this.loaded = true);
      }
    );




  }



  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}


  private convertToFoodItem(value: any): CustomFoodEdit {
    const convertToGram = new ConvertToGramPipe();
    if (!value.foodImageUrl || value.foodImageUrl === 'null')
      value.foodImageUrl = '';
    value.defaultServingSize = convertToGram.transform(value.defaultServingSize, value.conversionFactorsValue);
    
    const customFood: CustomFoodEdit = value;
    return customFood;
  }

  onSave(): void {

    this.loaded = false;
    const foodItem: CustomFoodEdit = this.convertToFoodItem(
      this.foodItemForm.value,
    );


    foodItem.id = this.id ?? 0;
    foodItem.isPublic = this.isPublic;

    const observable = !foodItem.id
      ? this.customFoodService.create(foodItem, this.userId ? this.userId : 0, this.diaryPatientId ? this.diaryPatientId : 0)
      : this.customFoodService.update(foodItem, this.userId ? this.userId : 0,  this.diaryPatientId ? this.diaryPatientId : 0);

    super.resetDirty();

    observable.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        super.resetDirty();

        if (!this.id) this.toasterService.success('', `Item Created!`);
        else this.toasterService.success('', `Item Updated!`);

        this.onBack();
        this.foodService.clearCache();
        this.loaded = true;
      },
      () => {
        this.toasterService.danger('', 'Problem occurred saving the Food Item');
        this.loaded = true;
      }

    );
  }

  onSaveQuickAdd(): void {
    this.loaded = false;
    const foodItem: CustomFoodEdit = this.convertToFoodItem(
      this.foodItemForm.value,
    );
    foodItem.id = 0;
    foodItem.isPublic = false;
    const observable = this.customFoodService.createQuickAdd(foodItem, this.userId ? this.userId : 0, this.diaryPatientId ? this.diaryPatientId : 0);

    observable.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.onItemQuickItemSaved.emit(res);
        this.dialogRef.close();
        this.loaded = true;
      },
      () => {
         this.loaded = true; this.toasterService.danger('', 'Problem occurred saving the Food Item');
      }
       
    );
  }


  get titleLabel(): string {
    let label = '';
    if (!this.id) label = $localize`:@@AddFoodItem:Add Food Item`;
    else if (!this.currentUserId || !this.foodUserId) label = 'Loading...';
    else if (this.currentUserId === this.foodUserId)
      label = $localize`:@@EditFoodItem:Edit Food Item`;
    else label = $localize`:@@ViewFoodItem:View Food Item`;

    return label;
  }

  get IsDirty(): boolean {
    return this.editable && this.isDirty;
  }

  get editable(): boolean {
    return !this.id || this.currentUserId === this.foodUserId;
  }

  // food form controls
  get name(): AbstractControl {
    return this.foodItemForm.get('name');
  }
  get description(): AbstractControl {
    return this.foodItemForm.get('description');
  }
  get calories(): AbstractControl {
    return this.foodItemForm.get('calories');
  }
  get fats(): AbstractControl {
    return this.foodItemForm.get('fats');
  }
  get saturatedFat(): AbstractControl {
    return this.foodItemForm.get('saturatedFat');
  }
  get transFat(): AbstractControl {
    return this.foodItemForm.get('transFat');
  }
  get cholesterol(): AbstractControl {
    return this.foodItemForm.get('cholesterol');
  }
  get sodium(): AbstractControl {
    return this.foodItemForm.get('sodium');
  }
  get carbohydrate(): AbstractControl {
    return this.foodItemForm.get('carbohydrate');
  }
  get fiber(): AbstractControl {
    return this.foodItemForm.get('fiber');
  }
  get sugar(): AbstractControl {
    return this.foodItemForm.get('sugar');
  }
  get protein(): AbstractControl {
    return this.foodItemForm.get('protein');
  }
  get alcohol(): AbstractControl {
    return this.foodItemForm.get('alcohol');
  }
  get defaultServingSize(): AbstractControl {
    return this.foodItemForm.get('defaultServingSize');
  }
  get foodImageUrl(): AbstractControl {
    return this.foodItemForm.get('foodImageUrl');
  }

}


@Component({
  selector: 'app-custom-food-quick-add',
  templateUrl: './custom-food-quick-add.component.html',
  styleUrls: ['./custom-food.component.scss'],
})
export class CustomFoodQuickAddComponent
  extends CustomFoodComponent
  implements OnInit, OnDestroy {





  initForm(): void {
    super.initForm();
    this.carbohydrate.setValue(0);
    this.fats.setValue(0);
    this.protein.setValue(0);
    super.resetDirty();


  }

  calorieChanged(): void {
    var fats = (this.calories.value / 3) / 9;
    var carbs = (this.calories.value / 3) / 4;
    var protein = (this.calories.value / 3) / 4;

    this.foodItemForm.patchValue({
      fats: Math.round(fats * 10) / 10,
      protein: Math.round(carbs * 10) / 10,
      carbohydrate: Math.round(protein * 10) / 10
    });
  }

  onMacroChanged(): void {
    this.calories.setValue(Math.round(this.fats.value * 9 + this.protein.value * 4 + this.carbohydrate.value * 4));
  }

  onChanges(): void {

    return;
  }

  onSave(): void {
    this.onSaveQuickAdd();
  }



}