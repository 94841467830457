<div class="gauge-container" [ngStyle]="{'width.px': size, 'height.px': size, 'font-size.px': size / 5}">
  <mwl-gauge
    [max]="100"
    [dialStartAngle]="-90"
    [dialEndAngle]="-90.001"
    [value]="value * 100"
    [animated]="true"
    [animationDuration]="1"
    [label]="gaugeLabel"
  ></mwl-gauge>
  <i [ngClass]="['fas', 'fa-'+icon]"
    [ngStyle]="{'transform': 'scale('+ size / 80 +')'}"
  ></i>
</div>
