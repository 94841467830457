import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

import {
  FoodData,
  FoodItemModel,
} from '../../../../interfaces/common/CalorieFriend/food';
import { FoodApi } from '../../api/CalorieFriend/food.api';
import { CustomFoodApi } from '../../api/CalorieFriend/custom-food.api';

@Injectable()
export class FoodService extends FoodData {
  constructor(private api: FoodApi, private customFoodApi: CustomFoodApi) {
    super();
  }

  // caching mechanism for the 'GetFoodDefinition' API endpoint
  foodPortionCaches: Map<string, Observable<FoodItemModel>> = new Map<
    string,
    Observable<FoodItemModel>
  >();

  /**
   * Service function to get the foods list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.foodDataSource;
  }

  /**
   * Service function to get portion information of the food with the identifier @id.
   * It uses caching mechanism to minimize the total calls to the back-end service.
   * So it checks if there is any result cached for the given parameters and returns it if exists.
   * If there is no cache, it calls the back-end API endpoint and add the result into the cache.
   * @param id Identifier of the food
   * @param portion Portion size index of the food
   * @param isCustomFood Custom food vs normal food
   */
  getFoodDefintion(
    id: string,
    Portion: number,
    isCustomFood?: boolean,
    isCustomServingSize?: boolean,
    customServingSize?: number,
  ): Observable<FoodItemModel> {
    const key =
      (isCustomFood ? 'custom' : 'normal') +
      '_' +
      id +
      '_' +
      (!isCustomServingSize
        ? Portion.toString()
        : 'customServing_' + customServingSize.toString());

    if (!this.foodPortionCaches.has(key)) {
      let api;
      if (isCustomFood) {
        api = this.customFoodApi.GetFoodDefintion(
          +id,
          Portion,
          isCustomServingSize ? customServingSize : 0,
        );
      } else {
        api = this.api.GetFoodDefintion(
          id,
          Portion,
          isCustomServingSize ? customServingSize : 0,
        );
      }

      const observable = api.pipe(
        map((data) => data),
        publishReplay(1),
        refCount(),
      );

      this.foodPortionCaches.set(key, observable);
      return this.foodPortionCaches.get(key);
    } else {
      return this.foodPortionCaches.get(key);
    }
  }

  // clear/refresh cache
  clearCache(): void {
    this.foodPortionCaches.clear();
  }

  /**
   * Service function to get the foods list filtered by @query
   * @param query Query string
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.GetFilterResult(query);
  }
}
