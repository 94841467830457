import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import {
  SubscriptionPlan,
  SubscriptionPlanData,
} from '../../../@core/interfaces/common/CalorieFriend/subscriptionPlan';

/**
 * The landing page component where users can register the accounts by choosing a user plan
 */
@Component({
  selector: 'user-plan',
  styleUrls: ['./user-plan.component.scss'],
  templateUrl: './user-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPlanComponent implements OnInit {
  public ITEM_ONE = 'Item One';
  public ITEM_TWO = 'Item Two';
  public ITEM_THREE = 'Item Three';
  public ITEM_FOUR = 'Item Four';
  public ITEM_FIVE = 'Item Five';
  public ITEM_SIX = 'Item Six';
  public CHOOSE = 'Choose This Plan';

  PlanSelectionlabel = 'Start Now and Choose Your Plan:';
  LoginLabel = 'Already registered? Click here to log in.';

  constructor(
    protected service: NbAuthService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected subscriptionService: SubscriptionPlanData,
  ) {}

  observablePlans: Observable<SubscriptionPlan[]>;

  /**
   * It initializes the list of User Plans
   */
  ngOnInit(): void {
    this.observablePlans = this.subscriptionService.list();
  }

  /**
   * It redirects the user to the 'Register' page with the chosen User Plan
   * @param PlanCount
   */
  CalorieFriendLogin(PlanCount: number = -1) {
    if (PlanCount >= 0 && PlanCount <= 3)
      this.router.navigate(['auth/cfregister', PlanCount]);
  }

  Login() {
    this.router.navigate([`/auth/login`]);
  }
}
