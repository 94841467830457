import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './../http.service';
import { share } from 'rxjs/operators';

@Injectable()
export class PaymentSettingsApi {
  private readonly apiController: string = 'payment-setting';
  constructor(private api: HttpService) { }

  connectToStripe(refreshUrl: string, returnUrl: string) {
    return this.api.get(`${this.apiController}/connect`, {
      params: {
        refreshUrl,
        returnUrl,
      },
    }).pipe(share());
  }

  expressLogin() {
    return this.api.get(`${this.apiController}/express-login`);
  }

  deleteSetting(): Observable<boolean> {
    return this.api.delete(`${this.apiController}`);
  }

  currentAccount() {
    return this.api.get(`${this.apiController}/current`);
  }
}
