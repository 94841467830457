import {
  Component,
  Input,
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterContentChecked,
  OnInit,
} from '@angular/core';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { Subject } from 'rxjs/Subject';

import {
  Meal,
  MealItem,
} from '../../../../../../../@core/interfaces/common/CalorieFriend/meal';
import { MealItemsComponent } from '../../../MealItems/meal-items.component';
import { CalorieFriendFood } from '../../../../../../../@core/interfaces/common/CalorieFriend/food';
import { ServingSizeSelectionComponent } from '../../../Meal/ServingSize/Selection/serving-size-selection.component';
import {
  MealItemPresentationComponent,
  MealItemPresentationComponentBase,
} from '../MealItemPresentation/meal-item-presentation.component';

import { BaseComponentCanDeactivate } from 'app/@auth/guards/changes.guard';
import { isObservable } from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import { OverlayMealItemPresentationComponent } from '../MealItemPresentation/overlay-meal-item-presentation/overlay-meal-item-presentation.component';
import { NativeAppService } from 'app/@core/backend/common/services/native-app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomFoodQuickAddComponent } from 'app/admin/public-foods/custom-food/custom-food.component';
import { EventEmitter } from 'stream';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';

/**
 * Base Component class for Food Items List presentation in one Meal Set.
 */
export class MealItemsPresentationComponentBase
  extends BaseComponentCanDeactivate
  implements OnInit, AfterContentChecked {
    @Input() Patient: Patient;
  get IsDirty() {
    let childrenDirty = false;
    this.MealItemsList.forEach(
      (c) => (childrenDirty = childrenDirty || c.IsDirty),
    );
    return this.isDirty || childrenDirty;
  }

  resetDirty() {
    super.resetDirty();
    this.MealItemsList.forEach((c) => c.resetDirty());
  }

  @Input('DiaryPatientId') DiaryPatientId: number;
  @Input('UserId') UserId: number;

  @Input('PlanMeal') PlanMeal: Meal;
  @Input('MealUpdate') MealUpdate = null;
  @Input('PlanView') PlanView: boolean = false;

  @ViewChild('scrollContainer') scrollContainer: any;
  @ViewChildren('MealItem')
  private MealItemsList: QueryList<MealItemPresentationComponentBase>;

  EmptyMealItem: MealItem = null;

  selectedItem: MealItem = null;
  DeleteIcon = 'trash';
  AddIcon = 'plus-circle-outline';

  PlanItems: Array<MealItem> = null;

  CurrentX = -1;
  CurrentY = -1;
  EditingItem = false;
  Editing = false;
  Deleting = false;
  AllowAddtingItems = false;

  DeleteMealItemConfirmationMessage = $localize`:@@DeleteMealItemConfirmationMessage:Are you sure you want to remove this item?`;

  AddItem = null;

  OverlayComponent = OverlayMealItemPresentationComponent;

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild('ItemsContainer') ItemsContainer: any = null;
  @ViewChild('rowLayout') rowLayout: ElementRef = null;

  @ViewChildren(MealItemPresentationComponent)
  private LoadedItems: QueryList<MealItemPresentationComponent>;

  ItemUpdateNotification: Subject<Object> = null;
  MaxRowItems = 0;

  itemsTable: Array<MealItem[]>;

  /** MealItemsPresentation ctor */
  constructor(
    private dialogService: NbDialogService,
    appRef: ApplicationRef,
    chdetRef: ChangeDetectorRef,
    AllowAddingItems = false,
    private deviceService: DeviceDetectorService,
  ) {
    super(dialogService);
    this.ItemUpdateNotification = new Subject<Object>();
    this.AllowAddtingItems = AllowAddingItems;
  }

  cdkDelay() {

    return 100;

    return this.deviceService.isDesktop() ? 0 : 100;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.PlanMeal !== null && this.PlanMeal !== undefined) {
        this.PlanItems = this.PlanMeal.items;
      }

      if (this.PlanItems == null || this.PlanItems === undefined)
        this.PlanItems = new Array<MealItem>();

      // sorting
      this.PlanItems = this.PlanItems.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      );

      if (this.PlanMeal.items == null || this.PlanMeal.items === undefined)
        this.PlanMeal.items = new Array<MealItem>();

      this.SetMaxItemsPerRow();
    });

    // this.ItemSubscribe();
  }

  ngAfterContentChecked() { }

  onResize($event) { }

  private SetMaxItemsPerRow() {
    this.initTable();
  }

  getItemsTable(): MealItem[][] {
    if (!this.rowLayout) return [];
    
    const oneMealWidth = 180;
    const width = this.rowLayout.nativeElement.clientWidth;
    const columnSize = Math.round(width / oneMealWidth);
    // view has been resized? => update table with new column size
    if (columnSize !== this.MaxRowItems) {
      this.MaxRowItems = columnSize;
      this.initTable();
    }
    return this.itemsTable;
  }

  initTable() {
    if (!this.PlanItems) return;

    let order = 0;
    for (const item of this.PlanItems) {
      item.order = order++;
    }

    // create table rows based on input list
    // example: [1,2,3,4,5,6] => [ [1,2,3], [4,5,6] ]
    const planItemWithEmpty = [...this.PlanItems, this.EmptyMealItem];
    this.itemsTable = planItemWithEmpty
      .filter((_, outerIndex) => outerIndex % this.MaxRowItems === 0) // create outter list of rows
      .map(
        (
          _,
          rowIndex, // fill each row from...
        ) =>
          planItemWithEmpty.slice(
            rowIndex * this.MaxRowItems, // ... row start and
            rowIndex * this.MaxRowItems + this.MaxRowItems, // ...row end
          ),
      );
  }

  reorderDroppedItem(event: CdkDragDrop<number[]>) {
    // same row/container? => move item in same row
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      // different rows? => transfer item from one to another list
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );


    }

    // update items after drop: flatten matrix into list
    // example: [ [1,2,3], [4,5,6] ] => [1,2,3,4,5,6]
    this.PlanItems = this.itemsTable.reduce(
      (previous, current) => previous.concat(current),
      [],
    );

    this.PlanItems = this.PlanItems.filter((item) => item != null);

    // re-initialize table - makes sure each row has same numbers of entries
    // example: [ [1,2], [3,4,5,6] ] => [ [1,2,3], [4,5,6] ]
    this.initTable();
    this.ItemUpdateNotification.next();
  }

  /**
   * Event handler of 'Select' a meal item action on the list.
   * If current action type is 'Edit', it shows the edit view of that meal item in a pop up window.
   * If current action type ie 'Delete', it removes the meal item from the list.
   * @param item MealItem object
   */
  onSelect(item) {
    this.selectedItem = item;

    if (this.Editing) {
      this.DisplayDialog();
    } else if (this.Deleting) {
      this.DeleteItem();
    }
  }


  onItemChange() {
    this.ItemUpdateNotification.next();
  }

  /**
   * Delete the selected meal item.
   */
  DeleteItem() {
    if (
      this.PlanMeal !== null &&
      this.PlanMeal !== undefined &&
      this.selectedItem !== null &&
      this.selectedItem !== undefined
    ) {
      const selectedItem = this.selectedItem;
      const dlg = this.dialogService
        .open(ConfirmMessageDialogComponent, {
          closeOnEsc: false,
          closeOnBackdropClick: false,
          context: {
            Message: $localize`:@@DeleteMealItemConfirmationMessage:Are you sure you want to remove this item?`,
          },
        })
        .onClose.subscribe((result) => {
          if (result) {
            this.PlanItems = this.PlanMeal.items = this.PlanMeal.items.filter(
              (item) => item !== selectedItem,
            );
            this.SetMaxItemsPerRow();
            this.ItemUpdateNotification.next();
          }
        });
    }

    this.selectedItem = null;
    this.isDirty = true;
  }


  onItemQuickItemSaved($event) {
    var food = $event.foodItem;
    food.id = null;
    food.mealID = this.PlanMeal.id;
    food.quantity = 1;
    food.order = this.PlanMeal.items.length + 1;

    this.PlanMeal.items.push(food);



    this.isDirty = true;
    this.PlanItems = this.PlanMeal.items;

    this.SetMaxItemsPerRow();

    this.SepcificItemSubscribe(food);
    this.ItemUpdateNotification.next();

  }


  onQuickAdd(): void {
    const ref: ComponentRef<CustomFoodQuickAddComponent> =
      this.dialogService.open(CustomFoodQuickAddComponent, {
        closeOnEsc: false,
        context: {
          userId: this.UserId,
          diaryPatientId: this.DiaryPatientId,

        },
      }).componentRef;

    ref.instance.onItemQuickItemSaved.subscribe(($event) => {
      this.onItemQuickItemSaved($event);
    });
  }
  /**
   * Method to open the MealItemsComponent component in a pop up window
   * to select meal items.
   */
  SelectItems() {
    const ActiveFood = new Array<CalorieFriendFood>();

    if (
      this.PlanMeal !== null &&
      this.PlanMeal !== undefined &&
      this.PlanMeal.items !== null &&
      this.PlanMeal.items !== undefined
    ) {
      for (const mealItem of this.PlanMeal.items) {
        ActiveFood.push(mealItem);
      }
    }
    this.dialogService.open(MealItemsComponent, {
      closeOnEsc: false,
      // context: { LoadedCurrentlyActiveFood: ActiveFood }
      context: {
        LoadedCurrentlyActiveFood: [],
        CallerParent: this,
        UserId: this.UserId,
        DiaryPatientId: this.DiaryPatientId,
        Patient: this.Patient,
      },
    });
  }

  /**
   * Method to update the meals list with the items added.
   * @param items Array of MealItem objects
   */
  public UpdateSelectedItem(items) {
    if (items !== null && items !== undefined) {
      const HoldItems = null;

      // if (this.PlanMeal !== null && this.PlanMeal !== null && this.PlanMeal.items !== undefined)
      //   HoldItems = this.PlanMeal.items;

      if (items !== null && items !== undefined) {
        if(items.length) {
        for (const food of items) {
          food.id = null;
          food.mealID = this.PlanMeal.id;
          this.PlanMeal.items.push(food);
          food.order = this.PlanMeal.items.length - 1;

          this.SepcificItemSubscribe(food);

        }
      } else {
        items.id = null;
        items.mealID = this.PlanMeal.id;
          this.PlanMeal.items.push(items);
          items.order = this.PlanMeal.items.length - 1;

          this.SepcificItemSubscribe(items); 
      }
        this.ItemUpdateNotification.next();
        this.isDirty = true;
      }

      this.PlanItems = this.PlanMeal.items;
      this.SetMaxItemsPerRow();
    }
  }

  /**
   * Mouse Move event handler
   * @param e Event object
   */
  onMouseMove(e) {
    this.CurrentX = e.clientX;
    this.CurrentY = e.clientY;
  }

  /**
   * Event handler of 'Edit' action.
   * @param event Event object
   */
  EditItem(item) {
    this.Editing = true;
    this.Deleting = true;
    this.onSelect(item);
  }

  /**
   * Event handler of 'Delete' action.
   * @param event Event object
   */
  Delete(item) {
    this.Editing = false;
    this.Deleting = true;
    this.onSelect(item);
  }

  /**
   * Method to display a pop up dialog to show the edit view of the selected meal.
   */
  DisplayDialog() {
    if (this.selectedItem !== null && this.selectedItem !== undefined) {
      this.isDirty = true;

      const FoundItem = this.PlanMeal.items.find(
        (item) => item.foodId === this.selectedItem.foodId,
      );

      const ref: ComponentRef<ServingSizeSelectionComponent> =
        this.dialogService.open(ServingSizeSelectionComponent, {
          closeOnEsc: false,
          context: {
            value: this.selectedItem, // FoundItem,
            ShowOK: true, // dialog contained wtihin another dialog that has dialog movement controls
            ShowDialog: false, // don't display until dynamic move to item location
          },
        }).componentRef;

      ref.onDestroy(() => {
        const Find = this.LoadedItems.find(
          (item) => item.Item === this.selectedItem,
        );
        if (Find != null && Find !== undefined) {
          if (
            Find.Item.Update != null &&
            Find.Item.Update !== undefined &&
            Find.Item.Update.next != null &&
            Find.Item.Update.next !== undefined
          )
            Find.Item.Update.next();
        }

        //this.ItemUpdateNotification.next();
      });
    }
  }

  private RefeshList() {
    const hold = this.PlanMeal.items;
    this.PlanMeal.items = null;
    this.PlanMeal.items = hold;
    return;
  }

  private ItemSubscribe() {
    if (this.PlanMeal != null && this.PlanMeal !== undefined) {
      for (const item of this.PlanMeal.items) {
        this.SepcificItemSubscribe(item);
      }
    }
  }

  /**
   * Method to subscribe the change of specific meal item.
   * @param item MealItem object
   */
  private SepcificItemSubscribe(item) {
    if (!isObservable(item.Update)) item.Update = new Subject();

    item.Update.subscribe((data) => {
      this.RefeshList();
      //this.ItemUpdateNotification.next();
    });
  }

  /**
   * Event handler of mouse wheel event to scroll to the left or the right to slide the images.
   * @param event Event object
   */
  public onWheel(event) {
    const toLeft =
      event.deltaY < 0 && this.scrollContainer.nativeElement.scrollLeft > 0;
    const toRight =
      event.deltaY > 0 &&
      this.scrollContainer.nativeElement.scrollLeft <
      this.scrollContainer.nativeElement.scrollWidth -
      this.scrollContainer.nativeElement.clientWidth;

    if (toLeft || toRight) {
      event.preventDefault();
      this.scrollContainer.nativeElement.scrollLeft += event.deltaY;
    }
  }
}

/**
 * Component for MealItems Presentation.
 * Inherits from the MealItemsPresentationComponentBase component.
 */
@Component({
  selector: 'app-meal-items-presentation',
  templateUrl: './meal-items-presentation.component.html',
  styleUrls: ['./meal-items-presentation.component.scss'],
})
export class MealItemsPresentationComponent extends MealItemsPresentationComponentBase {
  constructor(
    PCDialogService: NbDialogService,
    PCAppRef: ApplicationRef,
    PCChdetRef: ChangeDetectorRef,
    deviceService: DeviceDetectorService,
  ) {
    super(PCDialogService, PCAppRef, PCChdetRef, true, deviceService);
  }
}

@Component({
  selector: 'mobile-app-meal-items-presentation',
  templateUrl: './mobile-meal-items-presentation.component.html',
  styleUrls: ['./meal-items-presentation.component.scss'],
})
export class MobileMealItemsPresentationComponent extends MealItemsPresentationComponentBase {
  constructor(
    PCDialogService: NbDialogService,
    PCAppRef: ApplicationRef,
    PCChdetRef: ChangeDetectorRef,
    deviceService: DeviceDetectorService,
  ) {
    super(PCDialogService, PCAppRef, PCChdetRef, false, deviceService);
  }
}

/**
 * Component for MealItems Report presentation.
 * Inherits from the MealItemsPresentationComponentBase component.
 */
@Component({
  selector: 'app-meal-items-report-presentation',
  templateUrl: './meal-items-presentation-report.component.html',
  styleUrls: ['./meal-items-presentation.component.scss'],
})
export class MealItemsPresentationReportComponent extends MealItemsPresentationComponentBase {
  constructor(
    ReportDialogService: NbDialogService,
    ReportAppRef: ApplicationRef,
    ReportChdetRef: ChangeDetectorRef,
    deviceService: DeviceDetectorService,
  ) {
    super(ReportDialogService, ReportAppRef, ReportChdetRef, false, deviceService);
  }
}
