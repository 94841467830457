import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from 'app/@core/backend/common/services/users.service';

@Injectable()
export class InitialFormGuard implements CanActivate {
  constructor(private usersService: UsersService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.usersService.getCurrentUser().pipe(
      map((user) => {
        return user && user.isRegistrationCompleted
          ? true
          : this.redirectToPatients();
      }),
    );
  }

  redirectToPatients(): any {
    this.router.navigateByUrl('/Patients');
    return false;
  }
}
