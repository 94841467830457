import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

/**
 * Injectable class for Food API
 */
@Injectable()
export class FoodApi {
  /**
   * API Controller Name - 'food'
   */
  private readonly apiController: string = 'food';

  constructor(private api: HttpService) { }

  /**
   * API function to get the foods list in DataSource format.
   * Method: GET
   * URL: /
   */
  get foodDataSource(): DataSource {
    return this.api.getServerDataSource(`${this.api.apiUrl}/${this.apiController}`);
  }

  /**
   * API function to get portion information of the food with the identifier @id
   * @param id Identifier of the food
   * @param portion Portion size index of the food
   * Method: GET
   * URL: /${id}/${portion}
   */
  GetFoodDefintion(id: string, portion: number, customServingSize: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}/${portion}/${customServingSize}`);
  }

  /**
   * API function to get the foods list filtered by @query
   * @param query Query string
   * Method: GET
   * URL: /filter/${query}
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.get(`${this.apiController}/filter/${query}`);
  }

}
