import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import {
  MealPlan,
  TemplatePlan,
  BasePlanData,
  FitnessPlan,
  BasePlanFilter,
  BasePlan,
} from '../../../../interfaces/common/CalorieFriend/plan';
import {
  BasePlansApi,
  FitnessPlansApi,
  MealPlansApi,
  ProgramTemplateApi,
} from '../../api/CalorieFriend/plan.api';
import { GridData } from '../../../../interfaces/common/grid-data';
import { ProgramPlans, ProgramTemplate } from 'app/@core/interfaces/common/CalorieFriend/program-template';
import { SmartPlanTemplateGridFilter } from './../../../../interfaces/common/CalorieFriend/mealTemplates';

export class BasePlansService<T extends BasePlan> extends BasePlanData<T> {
  constructor(protected api: BasePlansApi<T>) {
    super();
  }

  // Created by Vinicius
  templateList(filter: BasePlanFilter): Observable<GridData<TemplatePlan<T>>> {
    return this.api.templateList(filter);
  }

  /**
   * Service function to get the plans list in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.plansDataSource;
  }

  list(filter: SmartPlanTemplateGridFilter): Observable<GridData<TemplatePlan<T>>> {
    return this.api.list(filter);
  }

  /**
   * Service function to get all the plans list of the patient given by
   * {patientId} param in GridData format for displaying graph.
   * @param patientId Identifier of the patient in number format
   * Reviewed by Vinicius
   */
  listById(patientId: number): Observable<GridData<FitnessPlan | MealPlan>> {
    return this.api.listById(patientId);
  }

  /**
   * Service function to get all the plans list of the patient given by
   * {TokenGuid} param in GridData format for displaying graph.
   * @param patientGuid Identifier of the patient in number format
   * Reviewed by Vinicius
   */
  listByGuid(patientGuid: string): Observable<GridData<T>> {
    return this.api.listByGuid(patientGuid);
  }

  /**
   * Service function to get templates list in DataSource format for ng2 smart table
   */
  get GridPlanTemplateDataSource(): DataSource {
    return this.api.PlanTemplatesDataSource;
  }

  /**
   * Service function to get the patient with the identifier @id
   * @param id Identifier in number format
   */
  get(id: number): Observable<T> {
    return this.api.get(id);
  }

  /**
   * Service function to add a new plan.
   * @param plan Plan Form data
   * Reviewed by Vinicius
   */
  create(plan: T): Observable<T> {
    return this.api.create(plan);
  }

  /**
   * Service function to update an existing plan
   * @param plan Plan Form data
   * Reviewed by Vinicius
   */
  update(plan: FitnessPlan | MealPlan): Observable<FitnessPlan | MealPlan> {
    return this.api.update(plan);
  }

  /**
   * Service function to duplicate an existing plan.
   * @param id Identifier of the plan in number format
   * Reviewed by Vinicius
   */
  duplicate(id: number): Observable<FitnessPlan | MealPlan> {
    return this.api.duplicate(id);
  }

  /**
   * Service function to delete an existing plan.
   * @param id Identifier of the plan in number format
   * Reviewed by Vinicius
   */
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to add a new template.
   * @param Template Template Form data
   */
  CreateTemplate(Template: TemplatePlan<T>): Observable<TemplatePlan<T>> {
    return this.api.CreateTemplate(Template);
  }

  /**
   * Service function to update an existing template
   * @param Template Template Form data
   */
  UpdateTemplate(Template: TemplatePlan<T>,  nameOnly = false): Observable<boolean> {
    return this.api.updateTemplate(Template, nameOnly);
  }

  // Updated by Vinicius
  duplicateTemplate(templateId: number): Observable<boolean> {
    return this.api.duplicateTemplate(templateId);
  }

  // Updated by Milos
  deleteTemplate(templateId: number): Observable<boolean> {
    return this.api.deleteTemplate(templateId);
  }

  /**
   * Service function to get an existing template by Id
   * @param id Template Id
   */
  GetTemplateById(id: number): Observable<TemplatePlan<T>> {
    return this.api.getTemplatById(id);
  }

  /**
   * Service function to get the meal plan as PDF format
   * @param Id Platient Identifier
   * @param PlanId Plan Identifier
   */
  SaveAsPDF(Id: number, PlanId: number): Observable<any> {
    return this.api.SaveAsPDF(Id, PlanId);
  }

  /**
   * Service function to get the meal plan as PDF format
   * @param TokenGuid Platient Identifier in GUID format
   * @param PlanId Plan Identifier
   */
  SaveAsPDFFromToken(TokenGuid: string, PlanId: number): Observable<any> {
    return this.api.SaveAsPDFFromToken(TokenGuid, PlanId);
  }

  /**
   * Service function to send a notfication e-mail to the patient.
   * @param id Patient Identifier
   * @param planId Plan Identifier
   * @param isProgram
   * Reviewed by Vinicius
   */
  submitPatientNotification(
    id: number,
    planId: number,
    isProgram: boolean = false
  ): Observable<boolean> {
    return this.api.submitPatientNotification(id, planId, isProgram);
  }

  /**
   * Service function to send a notfication e-mail to the patient.
   * @param token Patient Identifier in GUID format
   * @param planId Plan Identifier
   * @param isProgram
   * Reviewed by Vinicius
   */
  submitPatientNotificationByGuid(
    token: string,
    planId: number,
    isProgram: boolean = false
  ): Observable<any> {
    return this.api.submitPatientNotificationByGuid(token, planId, isProgram);
  }

  /**
   * Service function to activate an existing plan.
   * @param id Identifier of the plan in number format
   * @param active Active
   * Reviewed by Vinicius
   */
  activate(id: number, active: boolean): Observable<boolean> {
    return this.api.activate(id, active);
  }

  /**
   * Service function to move plan in Program Mode to change the zOrders.
   * @param id Identifier of the plan in number format
   * @param previous Previous Index of the plan
   * @param next Next Index of the plan
   * Reviewed by Vinicius
   */
  movePlan(
    patientId: number,
    planId: number,
    previous: number,
    next: number
  ): Observable<boolean> {
    return this.api.movePlan(patientId, planId, previous, next);
  }
}

@Injectable()
export class MealPlansService extends BasePlansService<MealPlan> {
  constructor(api: MealPlansApi) {
    super(api);
  }
}

@Injectable()
export class FitnessPlansService extends BasePlansService<FitnessPlan> {
  constructor(api: FitnessPlansApi) {
    super(api);
  }
}

@Injectable()
export class ProgramTemplateService extends BasePlansService<ProgramPlans> {
  constructor(api: ProgramTemplateApi) {
    super(api);
  }
}
