/**
 * Updated by Vinicius
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsApi } from '../api/settings.api';
import {
  SettingsData,
  Settings,
  SettingsReqDTO,
} from '../../../interfaces/common/settings';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class SettingsService extends SettingsData {
  constructor(private api: SettingsApi) {
    super();
  }

  getCurrent(): Observable<Settings> {
    return this.api.getCurrent();
  }

  getUserSettings(id: number): Observable<Settings> {
    return this.api.getUserSettings(id);
  }

  updateUserSettings(settings: SettingsReqDTO): Observable<Settings> {
    console.log('settings', settings);
    return this.api.updateUserSettings(settings);
  }

  updateCurrent(settings: SettingsReqDTO): Observable<HttpResponse<Settings>> {
    return this.api.updateCurrent(settings);
  }
}
