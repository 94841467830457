

import { Component, OnChanges, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DefaultFilter } from 'ng2-smart-table';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Custom Filter component for 'GoalType' dropdown list field of ng2 smart table
 */
@Component({
  template: `
    <nb-select
      [ngClass]="inputClass"
      class="full-width"
      (selectedChange)="onChange($event)">
      <nb-option value="">&nbsp;</nb-option>
      <nb-option i18n="@@BodyFatPercent" value="0">Body Fat %</nb-option>
      <nb-option i18n="@@BMI" value="1">BMI</nb-option>
      <nb-option i18n="@@Weight" value="2">Weight</nb-option>
    </nb-select>
  `,
})

export class GoalTypeFieldComponent extends DefaultFilter implements OnInit, OnChanges {

  @Output() filter: EventEmitter<string> = new EventEmitter();

  inputControl = new FormControl();

  constructor() {
    super ();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Event handler of 'Change' event of the input control
   * @param value Changed Value
  */
  onChange(value) {
    this.query = value || '';
    this.setFilter();
  }

}
