import { Injectable } from '@angular/core';
import 'external.js'

declare var externalJsHandler: any;

@Injectable({
  providedIn: 'root',
})
export class NativeAppService {


  constructor() { }

  isFromApp() {
    return window.navigator.userAgent.toLowerCase().startsWith('trainerfriendapp');
  }

  isFromAppFromAndroidApp() {
    return window.navigator.userAgent.toLowerCase().startsWith('trainerfriendapp;android'); 
  }


  isFromiOsApp() {
    return window.navigator.userAgent.toLowerCase().startsWith('trainerfriendapp;ios'); 
  }

  
  sendMessageToApp(key: string, value: string) {
    externalJsHandler.sendMessageToVuplex(key, value);
  }

  testExternal() {
    externalJsHandler.createAlert('test external good');
  }

  sendJavascriptMessageToApp(key, value) {
    // if (this.isFromApp()) {
      try {
        externalJsHandler.sendMessageToVuplex(key, value);
      }
      catch { }
    }
  // }

}
