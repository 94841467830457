import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NB_AUTH_OPTIONS, NbAuthService, NbAuthResult } from '@nebular/auth';
import { UserStore } from 'app/@core/stores/user.store';
import { getDeepFromObject } from '../../helpers';
import { UsersService } from './../../../@core/backend/common/services/users.service';

/**
 * Component for logging out process
 */
@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
})
export class NgxLogoutComponent implements OnInit {
  redirectDelay: number = this.getConfigValue('forms.logout.redirectDelay');
  strategy: string = this.getConfigValue('forms.logout.strategy');

  constructor(
    protected service: NbAuthService,
    private userStore: UserStore,
    private usersService: UsersService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected router: Router,
  ) {}

  /**
   * It calls the log out function immediately after the component loads
   */
  ngOnInit(): void {
    this.logout(this.strategy);
  }

  /**
   * The log out process the current user
   * @param strategy The current user login strategy like Email / External login
   */
  logout(strategy: string): void {
    this.service.logout(strategy).subscribe((result: NbAuthResult) => {
      this.userStore.setUser(null);
      this.usersService.clearUser();
      localStorage.clear();
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
