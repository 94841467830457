

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountriesApi } from '../api/countries.api';
import { CountryData, Country } from '../../../interfaces/common/countries';

/**
 * Injectable class for the Countries Service
 */
@Injectable()
export class CountriesService extends CountryData {

  /**
   * Injects a Countries API instance
   * @param api Countries API injectable
   */
  constructor(private api: CountriesApi) {
    super();
  }

  /**
   * Service function to get the countries list in array format.
   */
  list(): Observable<Country[]> {
    return this.api.list();
  }
}
