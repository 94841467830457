/**
 * Updated by Vinicius
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';

import { Patient } from '../interfaces/common/CalorieFriend/patients';
import { Settings } from '../interfaces/common/settings';

@Injectable({
  providedIn: 'root',
})
export class PatientStore {
  private _patient: Patient = null;
  private patientState$ = new BehaviorSubject(this._patient);
  private _settings: Settings = null;
  private settingsState$ = new BehaviorSubject(this._settings);
  private readonly SETTINGS_COOKIE_NAME = 'settings';

  constructor(private cookieService: CookieService) {}

  // patient
  patient(): Patient {
    return this._patient;
  }
  setPatient(patient: Patient): void {
    this._patient = _.clone(patient);
    this.patientState$.next(this._patient);
  }
  resetPatient(): void {
    this._patient = null;
    this.patientState$.next(this._patient);
  }
  onPatientChanged(): Observable<Patient> {
    return this.patientState$.pipe(share());
  }

  // settings
  settings(): Settings {
    const settings = this.cookieService.check(this.SETTINGS_COOKIE_NAME)
      ? JSON.parse(this.cookieService.get(this.SETTINGS_COOKIE_NAME))
      : null;
    return this._settings ?? settings;
  }
  setSettings(settings: Settings): void {
    this.cookieService.set(this.SETTINGS_COOKIE_NAME, JSON.stringify(settings));
    this._settings = settings;
    this.settingsState$.next(settings);
  }
}
