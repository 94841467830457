import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';

@Injectable()
export class PaymentApi {
  private readonly apiController: string = 'Payments';

  constructor(private api: HttpService) {}

  /**
   * API function to create a stripe check out session.
   * @param priceId Identifier of the price id in Stripe
   * Method: POST
   * URL: /create-checkout-session
   */
  createCheckoutSession(priceId: string, mode?: string): Observable<any> {
    if (!mode) mode = 'subscription';
    const data = { priceId: priceId, mode: mode };
    return this.api.post(`${this.apiController}/create-checkout-session`, data);
  }

  /**
   * API function to cancel the current subscription
   * Method: POST
   * URL: /cancel-subscription
   */
  cancelSubscription(): Observable<any> {
    return this.api.post(`${this.apiController}/cancel-subscription`, null);
  }

  /**
   * API function to update the current subscription to another price
   * @param newwPriceId Identifier of the price id in Stripe
   * Method: POST
   * URL: /update-subscription
   */
  updateSubscription(newPriceId: string): Observable<any> {
    const data = { newPriceId: newPriceId };
    return this.api.post(`${this.apiController}/update-subscription`, data);
  }

  /**
   * API function to get a stripe key.
   * Method: GET
   * URL: /create-checkout-session
   */
  getStripeKey(): Observable<any> {
    return this.api.get(`${this.apiController}/stripe-publishable-key`);
  }
}
