import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { NbCalendarRange } from '@nebular/theme';
import { PatientSubscriptionsMetaApi } from 'app/@core/backend/common/api/CalorieFriend/patient-subscriptions-meta.api';
import { PatientSubscriptionsFilter, PatientSubscriptionMeta, PatientSubscriptionData } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { PatientSubscriptionsTableComponentBase } from '../patient-subscriptions-table-base/patient-subscriptions-table.component.base';

@Component({
  selector: 'app-patient-subscriptions-data-table',
  templateUrl: './patient-subscriptions-table.component.html',
  styleUrls: ['./patient-subscriptions-table.component.scss'],
})
export class PatientSubscriptionsTableComponent extends PatientSubscriptionsTableComponentBase<PatientSubscriptionData> {

  @Output("onCancelSubscription")
  cancelEmitter = new EventEmitter<number>();
  @Output() openExpressLink = new EventEmitter<void>();

  get displayedColumns() {
    if (this.patientView) {
      return ['subscriptionId', 'startDate', 'status'];
    }
    return ['subscriptionId', 'patientId', 'startDate', 'status', 'actions'];
  }

  /**
   * TODO update function below for further update.
   * @param subscription PatientSubscription object to get actions
   * @returns boolean values showing actions are enabled or not (edit, send, delete etc.)
   */
  getSubscriptionActions(subscription: PatientSubscriptionData) {
    const cancelable = subscription.status != 'canceled';
    return {
      cancelable,
    };
  }

  onCancel(id: number) {
    this.cancelEmitter.emit(id);
  };
}
