import { AfterViewInit, Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

/**
 * Accept/reject Terms and Conditions component
 */
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements AfterViewInit {
  @ViewChild('content') content: ElementRef;

  public title = 'Terms & Conditions';
  public AGREE = 'Yes';
  public DISAGREE = 'No';

  constructor(protected ref: NbDialogRef<TermsConditionsComponent>) {}

  ngAfterViewInit() {
    this.content.nativeElement.scrollTop = 0;
  }
  public Agree() {
    this.ref.close(true);
  }

  Disagree() {
    this.ref.close(false);
  }
}
