import { Component, OnDestroy } from '@angular/core';
import { UserStore } from 'app/@core/stores/user.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['./auth.component.scss'],
  templateUrl: './auth.component.html',
})
export class NgxAuthComponent implements OnDestroy {
  logo: string = 'assets/images/logo.png';

  private unsubscribe$ = new Subject<void>();

  constructor(userStore: UserStore) {
    // logo from store or cookie
    userStore
      .onSettingsStateChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () =>
          (this.logo = userStore.settings()?.logo
            ? userStore.settings()?.logo
            : this.logo)
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
