import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { FoodDiary } from '../../../../interfaces/common/CalorieFriend/food-diary';

/**
 * Injectable class for Hidden Similar API
 */
@Injectable()
export class FoodDiaryApi {
  /**
   * API Controller Name - 'Tutorial'2
   */
  protected readonly apiController: string = 'fooddiary';

  constructor(protected api: HttpService) { }

  save(patientId:number, foodDiary:FoodDiary): Observable<boolean> {
    return this.api.post(`${this.apiController}/save/${patientId}`, foodDiary);
  }


  get(patientId:number, date:Date): Observable<FoodDiary> {
    return this.api.get(`${this.apiController}/get/${patientId}/${date.toJSON()}`);
  }

  getDiariesInRange(patientId: number, startDate: Date, endDate: Date) {
    return this.api
      .get(`${this.apiController}/diaries`, {
        params: {
          patientId,
          startDate: startDate.toISOString().substring(0, 19),
          endDate: endDate.toISOString().substring(0, 19),
        },
      })
      .toPromise<FoodDiary[]>();
  }

  getComplianceScore(patientId: number, startDate: Date, endDate?: Date) {
    return this.api.get(`${this.apiController}/compliance/${patientId}`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: (endDate || new Date()).toISOString()
      }
    });
  }

}


