import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '@nebular/auth';
import { UserData } from 'app/@core/interfaces/common/users';

/**
 * The component for changing the password of the user identified by token
 */
@Component({
  selector: 'verify-email',
  styleUrls: ['./verify-email.component.scss'],
  templateUrl: './verify-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailComponent implements OnInit {
  submitted: boolean = false;
  information: string = 'Please wait while verifying your email';
  message: string = '';
  verificationResult: boolean = false;

  constructor(
    protected userService: UserData,
    private route: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected router: Router,
  ) {}

  /**
   * It defines the form for changing the password and its input validators
   */
  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email');
    const validationCode = this.route.snapshot.paramMap.get('validationCode');

    this.userService.verifyEmail(email, validationCode).subscribe((result) => {
      this.submitted = true;
      this.verificationResult = result;
      if (result) {
        this.message = 'Thank you, your email has been validated.';
      } else {
        this.message = 'Sorry, your email is not verified.';
      }
      this.cd.detectChanges();
    });
  }
}
