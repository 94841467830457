import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { UserStore } from '../../../@core/stores/user.store';
import { User } from '../../../@core/interfaces/common/users';
import { NativeAppService } from 'app/@core/backend/common/services/native-app.service';
import { ChatService } from 'app/@chat/chat.service';
import { RoleProvider } from 'app/@auth/role.provider';
import { ROLES } from 'app/@auth/roles';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  userPictureOnly: boolean = true;
  user: User;
  logo: string = 'assets/images/logo.png';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'virgin',
      name: 'Virgin',
    },
  ];

  currentTheme = 'default';

  userMenu = this.getMenuItems();

  isMobile: boolean = false;
  isWarningClosed: boolean = false;
  readonly warningClosedCacheName = 'warning-closed';
  isAdmin: boolean = false;
  unSeenMessages: Observable<number>;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userStore: UserStore,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private nbMenuService: NbMenuService,
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService,
    private cdRef: ChangeDetectorRef,
    private nativeApp: NativeAppService,
    private chatService: ChatService,
    private roleProvider: RoleProvider,
  ) {
    if (!Boolean(this.cookieService.get(this.warningClosedCacheName)))
      this.isMobile = this.deviceService.isMobile();

    if (this.isFromApp()) {
      this.isMobile = false;
    }

    // logo from store or cookie
    userStore
      .onSettingsStateChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () =>
          (this.logo = this.userStore.settings()?.logo
            ? this.userStore.settings()?.logo
            : this.logo)
      );

    this.unSeenMessages = this.chatService.totalUnseenCount;
  }

  onClose() {
    this.isWarningClosed = true;
    this.cookieService.set(this.warningClosedCacheName, 'true', 365);
    this.cdRef.detectChanges();
  }

  getMenuItems() {
    const userLink = this.user ? '/pages/users/current/' : '';
    const subscriptionLink = this.user
      ? '/pages/CalorieFriend/manage-subscription/'
      : '';
    const items = [];
    items.push({
      title: $localize`:@@Profile:Profile`,
      link: userLink,
      queryParams: { profile: true },
    });
    if (!this.nativeApp.isFromApp())
      items.push({
        title: $localize`:@@ManageSubscription:Manage Subscription`,
        link: subscriptionLink,
      });

    items.push({
      title: $localize`:@@SupportRequest:Support Request`,
      link: 'mailto:helpdesk@trainerfriend.com?subject=%5BApp%5D%20Support%20Request',
      target: '_blank',
    });

    items.push({ title: $localize`:@@Logout:Log out`, link: '/auth/logout' });
    return items;
  }

  getAdminMenuItems() {
    const items = [];
    items.push({ title: $localize`:@@Logout:Log out`, link: '/auth/logout' });
    return items;
  }

  isFromApp() {
    return this.nativeApp.isFromApp();
  }

  onSupportLinkClicked() {
    this.nativeApp.sendMessageToApp(
      'openexternal',
      'mailto:helpdesk@trainerfriend.com?subject=%5BApp%5D%20Support%20Request'
    );
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userStore
      .onUserStateChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: User) => {
        this.user = user;
      });
      this.roleProvider.getRole().subscribe(role => {
        const roles = role instanceof Array ? role : [role];
        this.isAdmin = roles.some((x) => x && x.toLowerCase() === ROLES.ADMIN);
        this.userMenu = this.isAdmin ? this.getAdminMenuItems() : this.getMenuItems();
        console.log('isAdmin', this.isAdmin, roles);
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-context-menu'),
        map(({ item: { link } }) => link)
      )
      .subscribe((link) => {
        if (link.indexOf('mailto:') >= 0) {
          if (this.nativeApp.isFromApp()) {
            this.onSupportLinkClicked();
          } else {
            window.open(link, '_blank');
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeTheme(themeName: string) {
    // this.userStore.setSetting(themeName);
    // this.settingsService.updateCurrent(this.userStore.getUser().settings)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe();
    // this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleRightSidebar() {
    this.sidebarService.toggle(false, 'right');
  }
  navigateHome() {
    this.menuService.navigateHome();
    this.router.navigate(['/pages/CalorieFriend/Patients']);

    return false;
  }
}
