import { Component, Input, OnInit } from "@angular/core";
import { ExerciseLog } from "app/@core/interfaces/common/CalorieFriend/exerciseLog";
import * as moment from "moment";
@Component({
  templateUrl: './workout-logs-details.component.html',
  styleUrls: ['./workout-logs-details.component.scss'],
  selector: 'app-workout-logs-details'
})
export class WorkoutLogsDetailsComponent implements OnInit {
  @Input() public  logData: ExerciseLog[];
  public displayedColumns: string[] = ["CalculatedWeight", "displayWeight", "RepsOrTime", "Rest", "Set", "Tempo", "Weight", 'UsedWeight'];
  constructor() {}

  get sortedLogByDate(): ExerciseLog[] {
    return this.logData?.sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());
  }

  public ngOnInit(): void {

  }


  public formatDateAndTime(date: Date): string {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }


  public convertCamelCaseToTitleCase(str: string): string {
    return str
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (s) => s.toUpperCase());
  }

}
