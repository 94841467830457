import { Injectable } from '@angular/core';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { InvoicesApi } from '../../api/CalorieFriend/invoices.api';
import { PatientSubscriptionMeta, PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { PatientSubscriptionsMetaApi } from '../../api/CalorieFriend/patient-subscriptions-meta.api';

@Injectable()
export class PatientSubscriptionsMetaService {
  constructor(private api: PatientSubscriptionsMetaApi) {}

  list(filter: PatientSubscriptionsFilter): Observable<GridData<PatientSubscriptionMeta>> {
    return this.api.list(filter).pipe(share());
  }

  add(data: PatientSubscriptionMeta): Observable<PatientSubscriptionMeta> {
    return this.api.add(data);
  }

  edit(data: PatientSubscriptionMeta): Observable<PatientSubscriptionMeta> {
    return this.api.edit(data);
  }

  get(id: number, patientGuid?: string): Observable<PatientSubscriptionMeta> {
    return this.api.get(id, patientGuid).pipe(share());
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  send(id: number, patientIds: number[]): Observable<number> {
    return this.api.send(id, patientIds);
  }
}
