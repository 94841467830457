/**
 * Updated by Vinicius
 */

 import { Injectable } from '@angular/core';
 import { BehaviorSubject, Observable } from 'rxjs';
 import { share } from 'rxjs/operators';
 import { CookieService } from 'ngx-cookie-service';

 import { Settings } from '../interfaces/common/settings';
 import { User } from '../interfaces/common/users';
 import { NativeAppService } from '../backend/common/services/native-app.service';
import { TutorialService } from '../backend/common/services/CalorieFriend/tutorial-service';

 @Injectable({
   providedIn: 'root',
 })
 export class UserStore {
   private _user: User = null;
   private userState$ = new BehaviorSubject(this._user);
   private settingsState$ = new BehaviorSubject(null);
   private readonly SETTINGS_COOKIE_NAME = 'settings';

   constructor(
     private cookieService: CookieService,
     private nativeAppService: NativeAppService
   ) {}

   // user
   getUser(): User {
     return this._user;
   }
   setUser(user: User): void {
     this._user = user;
     if (this._user) this.setSettings(this._user.settings);
     this.userState$.next(user);

     if (user && user.uniqueGuid)
       this.nativeAppService.sendJavascriptMessageToApp('user', user.uniqueGuid);
   }
   onUserStateChange(): Observable<User> {
     return this.userState$.pipe(share());
   }

   isCustomAppInProduction(): boolean {
      return this._user?.isCustomAppInProduction;
    }

    customAppEnabled(): boolean {
      return this._user?.customAppEnabled;
    }

   // settings
   settings(isForManageBranding: boolean = false): Settings {
     if (!this._user && isForManageBranding) return null;
     if (!this._user)
       return this.cookieService.check(this.SETTINGS_COOKIE_NAME)
         ? JSON.parse(this.cookieService.get(this.SETTINGS_COOKIE_NAME))
         : null;

     if (!isForManageBranding && !this.isPayingUser()) return null;
     return this._user?.settings;
   }
   setSettings(settings: Settings): void {
    if (settings)
      this.nativeAppService.sendJavascriptMessageToApp('Settings', settings);
     if (!this._user) return;

     // tslint:disable-next-line: triple-equals
     if (settings?.themeName == 'undefined') settings.themeName = 'default';

     if (this.isPayingUser() && settings)
       this.cookieService.set(
         this.SETTINGS_COOKIE_NAME,
         JSON.stringify(settings)
       );
     else this.cookieService.delete(this.SETTINGS_COOKIE_NAME);
     this._user.settings = settings;
     this.settingsState$.next(settings);
   }
   onSettingsStateChange(): Observable<Settings> {
     return this.settingsState$.pipe(share());
   }

   daysLeftInProTrial(): number {
     if (!this._user) return 0;
     if (!this._user.createdDate) return 0;

     const diff: number = Math.abs(
       new Date(this._user.createdDate).getTime() - new Date().getTime()
     );
     const diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));
     if (diffDays > 30) return 0;
     return 30 - diffDays;
   }

   isUnlimitedTrialActive() {
     return (
       this._user.selectedPlan === 'propromo' &&
       this._user.planCode === 'free' &&
       this.daysLeftInProTrial() > 0
     );
   }

   isOnFreePlan() {
    return (
      this._user.selectedPlan === 'free' &&
      this._user.planCode === 'free'
    );
  }

  isOnDemoOfferPlan() {
    return (
      this._user.selectedPlan !== 'propromo' && this._user.selectedPlan !== 'free' &&
      this._user.planCode === 'free'
    );
  }



   isUnlimitedTrial() {
     return (
       this._user.selectedPlan === 'propromo' && this._user.planCode === 'free'
     );
   }

   // membership
   isPayingUser(): boolean {

    //return true;
     // account issue....
     if (!this._user || !this._user.planCode || !this._user.selectedPlan) {
       return false;
     }

     if (this.isUnlimitedTrialActive()) {
       return true;
     }

     // check if plans is set to free...
     if (this._user.planCode.toUpperCase() === 'FREE') {
       return false;
     }

     // any other plans should mean yes!
     return true;
   }
 }
