export const CUSTOM_ICON_PACK = {
  'food-outline': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="CurrentColor" x="0px" y="0px" \
        viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"> \
        <g> \
        <path d="M12.5,2c-0.3,0-0.5,0.1-0.7,0.3L2.3,12.5c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1 \
            c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1V21c0,0.3,0.1,0.6,0.4,0.8C2.6,21.9,2.8,22,3,22c0.1,0,0.1,0,0.2,0l17.9-2.8 \
            c0.5-0.1,0.8-0.5,0.8-1v-7.6c0-2.5-0.8-4.5-2.3-5.9C16.9,1.9,12.7,2,12.5,2z M12.9,4c1,0,3.6,0.3,5.4,2c0.9,0.9,1.5,2.1,1.6,3.6 \
            l-9.5,1.4l-4.7,0.7L12.9,4z M20,13.8c-1.9-0.6-4.1-0.4-5.5,0.9c-1.2,1-3.3,1.3-4.6,0.7C8.2,14.6,6,14.8,4,15.9V14L20,11.7V13.8z \
            M4,19.8v-1.5c1.5-1.2,3.6-1.8,4.9-1.2c2.1,1,5,0.6,6.8-0.9c1.1-0.9,2.9-1,4.2-0.2v1.3L4,19.8z"/> \
        <circle cx="14.4" cy="7.5" r="1.7"/> \
        </g> \
        </svg>',

  'archive-box-outline': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="CurrentColor" x="0px" y="0px" \
        viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"> \
        <g> \
        <path d="M21.7,7.5c-0.1-0.2-0.2-0.4-0.3-0.5l-4.7-4.7C16.5,2.1,16.2,2,16,2H7.7C7.5,2,7.2,2.1,7,2.3L2.2,7.1C2,7.3,1.9,7.6,2,7.9 \
            v5.8c0,0.6,0.4,1,1,1h0.2V21c0,0.6,0.4,1,1,1h15.5c0.6,0,1-0.4,1-1v-6.2h0.2c0.6,0,1-0.4,1-1V8C21.8,7.8,21.8,7.7,21.7,7.5z \
            M19.8,12.8h-0.2H4.1H4v-4l15.8,0V12.8z M8.1,4h7.4l2.8,2.8h-13L8.1,4z M18.6,20H5.1v-5.2h13.5V20z"/> \
        <path d="M9.6,11.8h4.5c0.6,0,1-0.4,1-1s-0.4-1-1-1H9.6c-0.6,0-1,0.4-1,1S9.1,11.8,9.6,11.8z"/> \
        <path d="M7.7,18.4H16c0.6,0,1-0.4,1-1s-0.4-1-1-1H7.7c-0.6,0-1,0.4-1,1S7.2,18.4,7.7,18.4z"/> \
        </g> \
        </svg>',

  'modify-pencil-outline': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="CurrentColor" x="0px" y="0px" \
        viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"> \
        <g> \
        <path d="M16.6,2.3c-0.4-0.4-1-0.4-1.4,0L3.1,14.4C3,14.6,2.9,14.8,2.8,15l-0.5,5.8c0,0.3,0.1,0.6,0.3,0.8C2.8,21.9,3.1,22,3.3,22 \
            c0,0,0,0,0.1,0l5.7-0.4c0.2,0,0.5-0.1,0.6-0.3L21.9,9.1c0.4-0.4,0.4-1,0-1.4L16.6,2.3z M9.7,17.9l7.5-7.5l0.3,0.3L10,18.2L9.7,17.9 \
            z M8.3,16.5l-0.4-0.4l7.5-7.5l0.4,0.4L8.3,16.5z M14,7.1l-7.5,7.5l-0.4-0.4l7.5-7.5L14,7.1z M6,19.8l-1.4-1.4l0.2-2.6l3.8,3.8l0,0 \
            L6,19.8z M18.9,9.2l-3.9-3.9l0.9-0.9l3.9,3.9L18.9,9.2z"/> \
        </g> \
        </svg>',

  'mealplans': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 792" enable-background="new 0 0 612 792" xml:space="preserve"> \
        <g id="_x34_8x48"> \
        </g> \
        <g id="icons"> \
          <g> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M318.8,689.3h-255c-14,0-25.5-11.5-25.5-25.5V192 \
              c0-14,11.5-25.5,25.5-25.5h51"/> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M420.8,166.5h51c14,0,25.5,11.5,25.5,25.5v51"/> \
            <polyline fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" points="280.5,638.3 89.3,638.3 89.3,217.5  \
              140.3,217.5 		"/> \
            <polyline fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" points="395.3,217.5 446.3,217.5 446.3,268.5 		 \
              "/> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M357,153.8h-38.3c0-28.1-22.9-51-51-51 \
              s-51,22.9-51,51h-38.3c-21.7,0-38.3,16.6-38.3,38.3v38.3h255V192C395.3,170.3,378.7,153.8,357,153.8z"/> \
            <line fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" x1="267.8" y1="141" x2="267.8" y2="166.5"/> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
              M439.9,675.2c-12.8,0-24.2,10.2-39.5,10.2c-35.7,0-81.6-37-99.5-100.7c-19.1-68.9,0-131.3,40.8-153c40.8-21.7,76.5,2.6,105.8,2.6 \
              c29.3,0,52.3-25.5,93.1-2.6c40.8,21.7,58.6,84.1,40.8,153c-17.8,63.8-63.8,100.7-99.4,100.7C464.1,685.4,452.6,675.2,439.9,675.2z \
              "/> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
              M516.4,365.4c-24.2,31.9-76.5,34.4-76.5,34.4s-10.2-49.7,14-81.6c24.2-31.9,70.1-37,70.1-37S540.6,333.5,516.4,365.4z"/> \
            <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
              M446.3,434.3c0-34.4-21.7-81.6-63.8-114.8"/> \
          </g> \
        </g> \
    </svg>',

  'myfood': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 792" enable-background="new 0 0 612 792" xml:space="preserve"> \
      <g> \
        <path fill="none" stroke="#515151" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M305.8,587.8c-12.8,0-24.2,10.2-39.5,10.2c-35.7,0-81.6-37-99.4-100.7c-19.1-68.9,0-131.3,40.8-153s76.5,2.5,105.8,2.5 \
          s52.3-25.5,93.1-2.5c40.8,21.7,58.6,84.1,40.8,153C429.5,561,383.6,598,347.9,598C330,598,318.6,587.8,305.8,587.8z"/> \
        <path fill="none" stroke="#515151" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M382.3,278c-24.2,31.9-76.5,34.4-76.5,34.4s-10.2-49.7,14-81.6s70.1-37,70.1-37S406.5,246.1,382.3,278z"/> \
        <path fill="none" stroke="#515151" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M312.2,346.8c0-34.4-21.7-81.6-63.8-114.8"/> \
      </g> \
    </svg>',

  'workoutplans': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 792" enable-background="new 0 0 612 792" xml:space="preserve"> \
      <g id="_x34_8x48"> \
      </g> \
      <g id="icons"> \
      <g> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M318.8,689.3h-255c-14,0-25.5-11.5-25.5-25.5V192 \
          c0-14,11.5-25.5,25.5-25.5h51"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M420.8,166.5h51c14,0,25.5,11.5,25.5,25.5v51"/> \
        <polyline fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" points="280.5,638.3 89.3,638.3 89.3,217.5  \
          140.3,217.5 		"/> \
        <polyline fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" points="395.3,217.5 446.3,217.5 446.3,268.5 		 \
          "/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M357,153.8h-38.3c0-28.1-22.9-51-51-51 \
          s-51,22.9-51,51h-38.3c-21.7,0-38.3,16.6-38.3,38.3v38.3h255V192C395.3,170.3,378.7,153.8,357,153.8z"/> \
        <line fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" x1="267.8" y1="141" x2="267.8" y2="166.5"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M447.5,434.3"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M540.6,431.7"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M439.9,399.8"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M382.5,319.5"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d=" \
          M446.3,434.3"/> \
      </g> \
      </g> \
      <g id="_x34_8x48_1_"> \
      </g> \
      <g id="icons_1_"> \
      <g> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M352.4,482.3h-46.9c-15.9,0-18.8-12.2-18.8-28.1 \
          s2.8-28.1,18.8-28.1h46.9"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M374,557.3c-16.9,29.1-41.3,46.9-68.4,46.9 \
          c-51.6,0-93.8-67.5-93.8-150s42.2-150,93.8-150c27.2,0,50.6,17.8,68.4,46.9"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M260.5,585.4c-47.8,0.9-86.3-58.1-86.3-131.3 \
          s38.4-132.2,86.3-131.3"/> \
        <ellipse fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" cx="511.8" cy="454.2" rx="84.4" ry="131.3"/> \
        <path fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" d="M517.5,585.4c-14.1,12.2-30,18.8-47.8,18.8 \
          c-54.4,0-98.5-67.5-98.5-150s44.1-150,98.5-150c16.9,0,32.8,6.6,46.9,17.8"/> \
        <ellipse fill="none" stroke="#505050" stroke-width="14" stroke-miterlimit="10" cx="516.5" cy="454.2" rx="23.4" ry="28.1"/> \
      </g> \
      </g> \
      </svg>',

  'myexercise': '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" fill="CurrentColor" \
	        viewBox="0 0 612 792" enable-background="new 0 0 612 792" xml:space="preserve">  \
<g id="_x34_8x48"> \
</g> \
<g id="icons"> \
	<g> \
		<path stroke-width="30" stroke-miterlimit="10" d="M261,447h-63.8c-21.7,0-25.5-16.6-25.5-38.3 \
			s3.8-38.3,25.5-38.3H261"/> \
		<path stroke-width="30" stroke-miterlimit="10" d="M290.3,549c-22.9,39.5-56.1,63.8-93.1,63.8 \
			c-70.1,0-127.5-91.8-127.5-204s57.4-204,127.5-204c37,0,68.9,24.2,93.1,63.8"/> \
		<path stroke-width="30" stroke-miterlimit="10" d="M136.1,587.3c-65,1.3-117.3-79.1-117.3-178.5 \
			S71,229,136.1,230.3"/> \
		<ellipse stroke-width="30" stroke-miterlimit="10" cx="477.8" cy="408.8" rx="114.8" ry="178.5"/> \
		<path stroke-width="30" stroke-miterlimit="10" d="M485.4,587.3c-19.1,16.6-40.8,25.5-65,25.5 \
			c-73.9,0-133.9-91.8-133.9-204s59.9-204,133.9-204c22.9,0,44.6,8.9,63.8,24.2"/> \
		<ellipse stroke-width="30" stroke-miterlimit="10" cx="484.1" cy="408.8" rx="31.9" ry="38.3"/> \
	</g> \
</g> \
</svg> \
',

};


