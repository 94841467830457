import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { AppSettings } from 'app/@core/interfaces/common/appSettings';

@Injectable()
export class AppSettingsApi {
  private readonly apiController: string = 'appSettings';

  constructor(private api: HttpService) {}

  get(id: number): Observable<AppSettings> {
    return this.api.get(`${this.apiController}/get/${id}`);
  }

  update(data: AppSettings): Observable<AppSettings> {
    const formData = this.toFormData(data);
    return this.api.post(this.apiController, formData);
  }

  sendNotifications(deviceToken: string): Observable<any> {
    return this.api.get(`${this.apiController}/notification/${deviceToken}`);
  }

  runBuild(userId?: number): Observable<any> {
    if(userId === undefined || userId === null) return this.api.get(`${this.apiController}/runBuild`);
    return this.api.get(`${this.apiController}/runBuild/${userId}`);
  }

  toFormData(data: any): FormData {
    const formData = new FormData();
  
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== 'null' && data[key] !== 'undefined') {
        formData.append(key, data[key]);
      }
    }
  
    return formData;
  }
}
