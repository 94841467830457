


import { Component, Input, forwardRef, HostBinding, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ViewCell } from 'ng2-smart-table';

/**
 * Custom 'ViewCell' component which displays the progress bar
 */
@Component({
  styleUrls: ['./progressbar.scss'],
  template: `
    <nb-progress-bar [value]="value" status="info" [displayValue]="true"></nb-progress-bar>
  `,
})

export class ProgressbarComponent implements ViewCell {
  @Input() value: string | number;
  @Input() rowData: any;
}
