<nb-layout windowMode>
  <nb-layout-column>
    <nb-card>
      <nb-card-header>
        <nav class="navigation">
          <a class="logo" href="#">
            <img src="assets/images/logo.png" class="image-logo">
          </a>
        </nav>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-lg-12">
            <div class="TextCenter">
              <div i18n="@@LandingTitle" class="onboarding-title">Welcome to Calorie Friend Pro</div>
              <div>
                <video width="541" controls>
                  <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                  Your browser does not support HTML video.
                </video>
              </div>
              <label i18n="PlanExplainLabel|Plan Explain@@PlanExplainID" class="video-text">
                bla bla bla ta ta bla bla bla bla ta ta bla bla bla bla ta ta bla bla ta ta bla bla bla ta ta bla bla ta
                ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla
                bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta
                ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla
                bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla bla ta ta bla
              </label>
            </div>
          </div>
        </div>

        <nb-card>
          <nb-card-header>
            <div class="plan-title">
              <ng-container i18n="PlanChoiceLabel|Select Initial Plan Label@@PlanLabelID">Start Now and Choose Your
                Plan:</ng-container>
            </div>
          </nb-card-header>

          <nb-card-body class="plans-body">
            <div class="row plans-container">
              <div class="col-sm-3 individual-plan" *ngFor="let plan of observablePlans | async ">
                <nb-card class="PlanBox">
                  <nb-card-header>
                    <div class="title" i18n="PlanFreeTitle|Free Plan@@FreePlanID">{{plan.planName}}</div>
                    <div class="plan-description">
                      <div i18n="@@PricePerMonth">0$/month</div>
                      <div i18n="@@SavePricePerYear">save 0$ with 0$/year</div>
                    </div>
                  </nb-card-header>

                  <nb-card-body>
                    <ul>
                      <li i18n="@@PlanPatients">Patients: {{plan.patientsCount}}</li>
                      <li i18n="@@PlanPrice">Price: {{plan.price}}</li>
                    </ul>
                  </nb-card-body>

                  <nb-card-footer>
                    <div class="actions-vertical">
                      <button i18n="@@ChooseThisPlan" nbButton type="submit" 
                        (click)="CalorieFriendLogin(plan.planType)">Choose This Plan</button>
                      <button i18n="@@MoreDetails" nbButton type="button" status="basic" class="back">More
                        Details</button>
                    </div>
                  </nb-card-footer>

                </nb-card>

              </div>
            </div>

            <div class="row LoginLayout" style="margin-top:20px">
              <div class="col-lg-12">
                <span i18n="@@AlreadyRegistered">Already registered? Click </span><a i18n="@@here"
                  href="javascript:void(0)" class="LoginLayout" (click)="Login()">here</a> <span i18n="@@ToLogin">to log
                  in.</span>
              </div>
            </div>

          </nb-card-body>
        </nb-card>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>
</nb-layout>
