import {
  NbAuthOAuth2JWTToken,
  NbPasswordAuthStrategy,
  NbOAuth2AuthStrategy,
  NbOAuth2ResponseType,
} from '@nebular/auth';
import { environment } from '../../environments/environment';

export const socialLinks = [
  {
    icon: 'google-outline',
    type: 'google',
  },
  {
    icon: 'facebook-outline',
    type: 'facebook',
  },
  {
    icon: 'twitter-outline',
    type: 'twitter',
  },
];

export const authOptions = {
  strategies: [
    NbPasswordAuthStrategy.setup({
      name: 'email',
      baseEndpoint: environment.apiUrl,
      token: {
        class: NbAuthOAuth2JWTToken,
        key: 'token',
      },
      login: {
        endpoint: '/auth/login',
        method: 'post',
      },
      register: {
        endpoint: '/auth/cf-sign-up',
        method: 'post',
      },
      logout: {
        endpoint: '/auth/sign-out',
        method: 'post',
      },
      requestPass: {
        endpoint: '/auth/request-pass',
        method: 'post',
      },
      resetPass: {
        endpoint: '/auth/change-pass',
        method: 'post',
      },
      refreshToken: {
        endpoint: '/auth/refresh-token',
        method: 'post',
      },
    }),
    NbOAuth2AuthStrategy.setup({
      name: 'google',
      clientId:
        '966928796797-4dke9pvcb1etb4pnl4h0dtc3s96onq0t.apps.googleusercontent.com',
      clientSecret: '',
      authorize: {
        endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
        responseType: NbOAuth2ResponseType.TOKEN,
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        redirectUri: 'https://localhost:44359/auth/callback',
      },
    }),
  ],
  forms: {
    login: {
      socialLinks: socialLinks,
    },
    register: {
      socialLinks: socialLinks,
    },
    requestPassword: {
      redirectDelay: 1500,
    },
    resetPassword: {
      redirectDelay: 1500,
    },
    restorePassword: {
      showMessages: {
        success: true,
        error: true,
      },
      redirectDelay: 2000,
    },
    validation: {
      fullName: {
        required: true,
        minLength: 6,
        maxLength: 20,
      },
    },
  },
};
