import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';

import { GridData } from '../../../../interfaces/common/grid-data';
import {
  BiometricHistoryView,
  BiometricHistoryFilter,
} from '../../../../interfaces/common/CalorieFriend/biometric-history';

@Injectable()
export class BiometricHistoryApi {
  private readonly apiController: string = 'biometric-history';

  constructor(private api: HttpService) {}

  /**
   * API function to get all the biometric histories list of the patient
   * given by {patientId} param or {NotificationToken} param in GridData format for displaying graph.
   * @param patientId Identifier of the Patient in number format
   * @param notificationToken Identifier of the Patient in GUID format for anonymous users
   * Method: GET
   * URL: /graphDataForPatient/${patientId} or /graphDataForPatient/${notificationToken}
   */
  allHistoryList(
    patientId: number,
    NotificationToken: string,
  ): Observable<GridData<BiometricHistoryView>> {
    if (NotificationToken)
      return this.api.get(
        `${this.apiController}/graphDataForPatient/${NotificationToken}`,
      ) as Observable<GridData<BiometricHistoryView>>;
    else
      return this.api.get(
        `${this.apiController}/graphDataForPatient/${patientId}`,
      ) as Observable<GridData<BiometricHistoryView>>;
  }

  // Updated by Vinicius
  list(
    filter: BiometricHistoryFilter,
  ): Observable<GridData<BiometricHistoryView>> {
    if (filter.patientGuid)
      return this.api.list(
        `${this.apiController}/list/${filter.patientGuid}`,
        filter,
      );
    return this.api.list(this.apiController, filter);
  }

  /**
   * API function to get the biometric history with the identifier @id
   * @param id Identifier of the biometric history
   * Method: GET
   * URL: /${id}
   */
  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to add a new biometric history.
   * @param BiometricHistory Biometric History Form data
   * Method: POST
   * URL: /
   */
  add(BiometricHistory: any): Observable<any> {
    return this.api.post(this.apiController, BiometricHistory);
  }

  /**
   * API function to delete an existing biometric history.
   * @param id Identifier of the biometric history in number format
   * Method: POST
   * URL: /delete/${id}
   */
  delete(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/delete/${id}`, null);
  }

  /**
   * API function to update an existing biometric history
   * @param BiometricHistory Biometric History Form data
   * Method: POST
   * URL: /edit
   */
  update(BiometricHistory: any): Observable<any> {
    return this.api.post(`${this.apiController}/edit`, BiometricHistory);
  }
}
