<nb-layout windowMode [withScroll]="false">
  <nb-layout-header fixed>
    <ngx-header></ngx-header>
  </nb-layout-header>

  <nb-sidebar
    class="menu-sidebar"
    tag="menu-sidebar"
    responsive
    start
    style="height: auto"
  >
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>
  <nb-sidebar
    right
    tag="right"
    class="right-sidebar"
    containerFixed
    fixed
    subheader
    state="collapsed"
    [style.height.px] = "windowInnerHeight - 76"
  >
    <ng-content select="ngx-chat-app"></ng-content>
  </nb-sidebar>

  <nb-layout-column class="scrollable-main">
    <ng-content select="router-outlet"> </ng-content>
  </nb-layout-column>

  <nb-layout-footer>
    <ngx-footer></ngx-footer>
  </nb-layout-footer>
</nb-layout>
