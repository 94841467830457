<div style="display: flex; flex-direction: column; width: 100%">
  <div style="display: flex; justify-content: space-between">
    <div class="header-container">
      <div style="margin-left: 5px">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
          <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">
          <img
            [src]="logo"
            class="image-logo"
            [class.p-1]="logo !== 'assets/images/logo.png'"
          />
        </a>
        <!-- <td>
            <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" >
              <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
            </nb-select>
          </td> -->
      </div>
    </div>

    <div class="header-container">
      <nb-actions size="medium">
        <!-- <nb-action class="control-item">
          <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <nb-action *ngIf="!isAdmin" class="control-item">
          <div
            class="chat-action"
            (click)="toggleRightSidebar()"
          >
            <i class="fa fa-comments"></i>
            <nb-badge *ngIf="unSeenMessages | async" [text]="unSeenMessages | async" status="danger" position="top right"></nb-badge>
          </div>
        </nb-action>

        <nb-action class="user-action" *nbIsGranted="['view', 'current-user']">
          <nb-user
            [nbContextMenu]="userMenu"
            onlyPicture
            nbContextMenuTag="user-context-menu"
            [name]="
              user?.firstName + ' ' + user?.lastName | ngxAuthToken | async
            "
          >
          </nb-user>
        </nb-action>
      </nb-actions>
    </div>
  </div>
</div>
