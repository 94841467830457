import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FromEnum, IChatMessage, IChatThread } from '../domain';
import { HttpService } from 'app/@core/backend/common/api/http.service';
import { IChatMessageDTO } from './../domain';

@Injectable({
  providedIn: 'root',
})
export class ChatApi {
  private apiController = 'messages';
  constructor(private httpService: HttpService) {}

  get(
    userId: number,
    patientId: number,
    beforeDate?: string
  ): Observable<IChatMessageDTO[]> {
    const params = {
      userId,
      patientId,
    };
    if (beforeDate) params['before'] = beforeDate;
    return this.httpService.get(`${this.apiController}`, { params });
  }
  post(message: IChatMessageDTO): Observable<IChatMessageDTO> {
    return this.httpService.post(`${this.apiController}`, message);
  }

  getUnSeenInfo(
    userId: number,
    patientId: number,
    from: FromEnum
  ): Observable<{count: number, last: IChatMessageDTO}> {
    return this.httpService.get(`${this.apiController}/unseen/info`, {
      params: { userId, patientId, from },
    });
  }
  getUnSeenMessages(
    userId: number,
    patientId: number,
    from: FromEnum
  ): Observable<IChatMessageDTO[]> {
    const params = { userId, patientId, from };
    if (!userId) delete params.userId;
    if (!patientId) delete params.patientId;
    return this.httpService.get(`${this.apiController}/unseen`, {
      params,
    });
  }

  markAsRead(userId: number, patientId: number, from: FromEnum) {
    return this.httpService.post(
      `${this.apiController}/seen`,
      {},
      { params: { userId, patientId, from } }
    );
  }
}
