import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import { BaseFilter, GridData } from '../grid-data';

export abstract class CustomFoodData {
  abstract list(filter: CustomFoodFilter): Observable<GridData<CustomFoodEdit>>; // Created by Vinicius
  abstract get gridDataSource(): DataSource;
  abstract gridDataSourceForMeal(userId: number, patientId: number): DataSource;
  abstract get(id: number): Observable<CustomFoodView>; // Updated by Milos
  abstract delete(id: number, patientGuid?: string): Observable<boolean>; // Updated by Vinicius
  abstract create(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<boolean>; // Updated by Milos
  abstract createQuickAdd(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<CustomFoodView>; // Updated by Milos
  abstract update(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<boolean>; // Updated by Milos
  abstract GetFilterResult(query: string): Observable<string[]>;
}

export abstract class CustomPublicFoodData {
  abstract get gridDataSourceForPublicMeal(): DataSource;
}

export interface CustomFoodBase {
  id: number;
  name: string;
  description: string;

  fats: number;
  saturatedFat: number;
  transFat: number;

  cholesterol: number;
  sodium: number;

  carbohydrate: number;
  fiber: number;
  sugar: number;

  protein: number;
  alcohol: number;

  createdByUserId: number;
  updatedByUserId: number;

  defaultServingSize: number;

  isPublic: boolean;
  patientId: number;

  conversionFactorsValue: string;
}

export interface CustomFoodEdit extends CustomFoodBase {
  fileFoodImage: File;
  selected: boolean;

  createdDate: Date;
  updatedDate: Date;
}

export interface CustomFoodView extends CustomFoodBase {
  totalCalories: number;
  foodImageUrl: string;

  publicSource: string;
  publicSourceLink: string;

  createdDate: String;
  updatedDate: String;
}

export interface CustomFoodFilter extends BaseFilter {
  filterByOnlyPublic?: boolean;
  filterByNameOrDescription?: string;
}
