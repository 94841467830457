import {
  Component,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { NbDialogRef } from '@nebular/theme';
import { format } from 'support';

import { MealItem } from '../../../../../../..//@core/interfaces/common/CalorieFriend/meal';
import {
  FoodData,
  FoodItemDataSource,
  FoodItemModel,
} from '../../../../../../../@core/interfaces/common/CalorieFriend/food';
import { OverlayMealItemPresentationComponent } from '../../MealLayout/MealItemPresentation/overlay-meal-item-presentation/overlay-meal-item-presentation.component';
import { runInThisContext } from 'vm';
import { CustomFoodData } from 'app/@core/interfaces/common/CalorieFriend/custom-food';
import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';
import { ConvertToGramPipe, GramConverterPipe } from 'app/@theme/pipes';
import { ConversionFactorsData } from 'app/@core/utils/conversionFactors.data';
import { UserStore } from 'app/@core/stores/user.store';
import { Patient } from 'app/@core/interfaces/common/CalorieFriend/patients';

export interface SelectionOption {
  selectName: string;
  selectIndex: number;
}

/**
 * Component for Serving Size Selection inside Meal Item Selection widget.
 */
@Component({
  selector: 'app-serving-size-selection',
  templateUrl: './serving-size-selection.component.html',
  styleUrls: [
    './serving-size-selection.component.scss',
    '../../../../../global.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ServingSizeSelectionComponent implements OnInit, AfterViewInit {
  @Input('value') value: any = null;
  @Input() Patient: Patient;

  private gramConverterPipe: GramConverterPipe = new GramConverterPipe();

  CaloriesLabel = 'Cals';
  ProteinLabel = 'Protein';
  CarbsLabel = 'Carbs';
  FatLabel = 'Fats';
  AboutLabel = $localize`:@@SmallAbout:about`;

  SelctedSize = '';
  ManualSelection = '';
  ButtonLabel = 'Add To Plan';
  ButtonAddedLabel = 'Added!';

  Selections = null;
  ItemImage = null;
  ItemLargeImage = null;

  isQuickAdd: boolean;
  CaloriesRendered = 0;
  ProteinRendered = 0;
  CarbsRendered = 0;
  NetCarbsRendered = 0;
  TotalFatRendered = 0;
  CurrentSelection = 0;
  ItemQuantity = 1;

  TotalGrams = 0;

  ShowOK: boolean = false;
  ViewOnly: boolean = false;
  ShowQuantitySelection: boolean = true;
  ShowFieldSet: boolean = false;
  ShowDialog: boolean = true;
  HasNoImage: boolean = false;

  Name = '';

  IncrementStep = 0.5;

  protected readonly unsubscribe$ = new Subject<void>();

  selectedValue = null;

  IsCustomServingSize: boolean = false;
  CustomServingSize: number = 1;

  InfoSource = '';
  convertToGram: ConvertToGramPipe = new ConvertToGramPipe();
  Update: (CallerThis) => void = null;

  AssociatedObject: any = null;

  Callback: (THIS, ThisObject) => void = null;
  DeleteCallback: (THIS, ThisObject) => void = null;

  CallerThis = null;
  PrepareItemselection = true;
  PrepareItemSelectionFromValue = false;
  @ViewChild('SelectionLayout1', { read: ElementRef })
  private SelectionLayout1: any = null;

  RemoveCallBack = null;
  SaveCallBack = null;

  ConversionFactors: any = ConversionFactorsData;

  public ConversionFactorsValue = 'g';

  /** ServiingSizeSelection ctor */
  constructor(
    private foodService: FoodData,
    private chdetRef: ChangeDetectorRef,
    private dialogService: NbDialogService,
    protected ref: NbDialogRef<ServingSizeSelectionComponent>,
    private userStore: UserStore
  ) {
    this.selectedValue = {
      selectName: '',
      selectIndex: -1,
    };
  }

  /**
   * Initializes the component.
   * Prepares the selection.
   */
  ngOnInit() {
    this.PrepareSelection();
    console.log(this.Patient);
    if (
      this.value !== null &&
      this.value !== undefined &&
      (this.value.quantity === null || this.value.quantity === undefined)
    ) {
      this.value.quantity = 1;
    }

    if (this.value.SelectedItem?.foodItem.conversionFactorsValue) {
      this.ConversionFactorsValue = this.value.SelectedItem.foodItem.conversionFactorsValue;
    }
    else if (this.value.conversionFactorsValue) {
      this.ConversionFactorsValue = this.value.conversionFactorsValue;
    }
    else {
      if(this.Patient?.conversionFactorPreference) this.ConversionFactorsValue = this.Patient?.conversionFactorPreference;
      else if(this.userStore.getUser()?.conversionFactorsPreference)this.ConversionFactorsValue = this.userStore.getUser().conversionFactorsPreference;
      else this.ConversionFactorsValue = 'g';
    }
    this.applyItem(this.value.Current?.foodItem);

    if (this.value.ShowDialog != null && this.value.ShowDialog !== undefined) {
      this.ShowDialog = this.value.ShowDialog;
    }

    if (
      this.value.ButtonCheckedUpdate != null &&
      this.value.ButtonCheckedUpdate !== undefined
    ) {
      this.Callback = this.value.ButtonCheckedUpdate;
    }

    if (
      this.value.DeleteCustomFood != null &&
      this.value.DeleteCustomFood !== undefined
    ) {
      this.DeleteCallback = this.value.DeleteCustomFood;
    }

    if (this.value.CallerThis != null && this.value.CallerThis !== undefined) {
      this.CallerThis = this.value.CallerThis;
    }
  }

  ngAfterViewInit() {
    // special condition where the food selection item
    // is placed within a bg tabvle - and a large amount of
    // space is applied between rows -- will review here
    // and correct (add hoc fix)

    if (
      this.SelectionLayout1 != null &&
      this.SelectionLayout1 !== undefined &&
      ((this.value.AdjustHeight != null &&
        this.value.AdjustHeight !== undefined &&
        this.value.AdjustHeight) ||
        this.value.AdjustHeight == null ||
        this.value.AdjustHeight === undefined)
    ) {
      const FirstParent = this.SelectionLayout1.nativeElement.parentElement;
      const SecondParent =
        FirstParent != null && FirstParent !== undefined
          ? FirstParent.parentElement
          : null;
      const ThirdParent =
        SecondParent != null && SecondParent !== undefined
          ? SecondParent.parentElement
          : null;

      if (ThirdParent != null && ThirdParent !== undefined) {
        // ThirdParent.style.height = "200px";
      }
    }
  }

  ConversionFactorsValueChange(event): void {
    this.value.conversionFactorsValue = event;
    if (this.value.isCustomServingSize) {
      this.CustomServingSize = this.gramConverterPipe.transform(this.value.customServingSize, this.value.conversionFactorsValue, true) as number;
    }
    this.applyItem(this.value.Current.foodItem);
  }

  GetFoodSource(item): string {
    let result: string = 'Unknown Source';
    try {
      if (item.foodItemDataSource === 'usda') {
        result = 'USDA';
      } else {
        result = item.source;
      }
      if (result.toLowerCase().indexOf('usda') >= 0) {
        result = "<a href='https://fdc.nal.usda.gov/' target='_blank'>USDA</a>";
      }
    } catch { }
    return result;
  }

  /**
   * Gathers the food information using API service and apply the current selection.
   */
  PrepareSelection(setCustomServing = true) {
    this.Selections = null;

    if (this.value != null && this.value !== undefined) {
      if (
        this.value.AssociatedObject !== null &&
        this.value.AssociatedObject !== undefined
      )
        this.AssociatedObject = this.value.AssociatedObject;

      if (
        this.value.displayQuantitySelection !== null &&
        this.value.displayQuantitySelection !== undefined
      )
        this.ShowQuantitySelection = this.value.displayQuantitySelection;

      if (
        this.value.displayWithinGroup !== null &&
        this.value.displayWithinGroup !== undefined
      )
        this.ShowFieldSet = this.value.displayWithinGroup;
    }

    const Item: MealItem = this.value;

    if (Item !== null && Item !== undefined) {
      this.ItemQuantity =
        Item.quantity != null && Item.quantity !== undefined
          ? Item.quantity
          : 1;
      this.value.quantity = this.ItemQuantity;
      this.selectedValue = Item.selectedServingSizeIndex;

      this.IsCustomServingSize = Item.isCustomServingSize ?? false;
      if (this.IsCustomServingSize) this.CustomServingSize = this.gramConverterPipe.transform(Item.customServingSize, Item.conversionFactorsValue, true) as number;

      if (!this.CustomServingSize) this.CustomServingSize = 1;

      if (Item.selectedServingSizeEntered > 0)
        this.ManualSelection = Item.selectedServingSizeEntered.toString();

      if (this.PrepareItemselection) {
        this.CurrentSelection =
          Item.selectedServingSizeIndex === undefined ||
            Item.selectedServingSizeIndex === null
            ? 0
            : Item.selectedServingSizeIndex;

        const observable = this.foodService.getFoodDefintion(
          Item.foodId.toString(),
          this.CurrentSelection + 1,
          Item.isCustomFood,
          Item.isCustomServingSize,
          Item.customServingSize
        );

        observable.pipe(takeUntil(this.unsubscribe$)).subscribe(
          (data) => {
            this.PrepareItemSelection(data);
          },
          (err) => { }
        );
      } else if (this.PrepareItemSelectionFromValue) {
        this.PrepareItemSelection(this.value.SelectedItem);
      }
    }
  }

  /**
   * Updates the current selection with {Selection} and prepare images for the current selection,
   * and apply it to update the presentation.
   * @param Selection Selection object
   */
  private PrepareItemSelection(Selection) {
    this.value.Current = Selection;
    if (
      this.value.Current.foodItem.foodItemDataSource.toString() === 'figwee' ||
      this.value.Current.foodItem.foodItemDataSource.toString() === 'custom'
    ) {
      this.ItemImage =
        this.value.Current.currentFoodImage.defaultImage.httpThumbPath;
      this.ItemLargeImage =
        this.value.Current.currentFoodImage.defaultImage.httpPath;
    }

    if (!this.ItemImage) {
      this.ItemImage = '/assets/images/NoImage.png';
      this.HasNoImage = true;
    }

    this.applyItem(this.value.Current.foodItem);
  }

  /**
   * Function to apply selection of the food item.
   * Calcuates the metrics and updates the food item information.
   * @param item Food item
   */
  private applyItem(item): void {
    if (!item) return;

    this.CalculateMetrics();

    this.isQuickAdd = item.isQuickAdd;
    this.Name =
      item.description == null || item.description === undefined
        ? item.friendlyName
        : item.description;

    this.InfoSource = this.GetFoodSource(item);

    this.Selections = new Array();

    if (item.photoCount > 0) {
      for (let index = 0; index < item.photoCount; index++) {
        // NutrientsDataItem.CurrentTotalUnit = (FoodItemUnit.DefaultUnit * totalGrams / FoodItemUnit.Weight);

        const TotalGram = (index + 1) * item.photoIncrementInGram; // current portion in grams -- CurrentPortionInGram

        let CurrentTotalUnit = 0;
        let CurrentTotalUnitLabel = '';

        if (
          !item.isCustomFood &&
          item.units != null &&
          item.units !== undefined &&
          item.units.length > 0
        ) {
          CurrentTotalUnit =
            (item.units[0].defaultUnit * TotalGram) / item.units[0].weight;
          CurrentTotalUnitLabel = format(
            item.units[0].unit,
            CurrentTotalUnit.toFixed(2)
          );
        }

        let PortionText = this.gramConverterPipe.transform(TotalGram, this.ConversionFactorsValue);

        if (CurrentTotalUnitLabel !== '') {
          PortionText = CurrentTotalUnitLabel + format(' ({0})', PortionText);
        }

        const Selection: SelectionOption = {
          selectName: PortionText as string,
          selectIndex: index,
        };

        this.Selections.push(Selection);

        if (this.CurrentSelection === index) {
          this.selectedValue = Selection;

          if (
            this.value !== null &&
            this.value !== undefined &&
            (this.value.selectedServingSizeEntered === null ||
              this.value.selectedServingSizeEntered === undefined ||
              this.value.selectedServingSizeEntered <= 0)
          )
            this.ManualSelection = Selection.selectName;
        }
      }

      if (this.Update !== null) this.Update(this.CallerThis);

      if (
        this.value !== null &&
        this.value !== undefined &&
        this.value.Update !== null &&
        this.value.Update !== undefined &&
        this.value.Update.next != null &&
        this.value.Update.next !== undefined
      )
        this.value.Update.next();

      this.chdetRef.detectChanges();
    }
  }

  /**
   * Calcualtes the metrics and update the labels and texts.
   */
  private CalculateMetrics() {
    if (
      this.value !== null &&
      this.value !== undefined &&
      this.value.Current !== null
    ) {
      this.CaloriesRendered = Math.round(
        this.value.Current.nutrientsDataItem.calorie * this.value.quantity
      );
      this.ProteinRendered = Math.round(
        this.value.Current.nutrientsDataItem.protein * this.value.quantity
      );
      this.CarbsRendered = Math.round(
        this.value.Current.nutrientsDataItem.carb * this.value.quantity
      );
      this.NetCarbsRendered = Math.round(
        this.value.Current.nutrientsDataItem.carb * this.value.quantity -
        this.value.Current.nutrientsDataItem.fiber * this.value.quantity
      );
      this.TotalFatRendered = Math.round(
        this.value.Current.nutrientsDataItem.fat * this.value.quantity
      );

      this.TotalGrams = Math.round(
        this.value.Current.nutrientsDataItem.currentPortionInGram *
        this.value.quantity
      );
      this.value.totalGrams = this.TotalGrams;
    }
  }

  ShowPhotoControls() {
    if (this.value && this.value.Current)
      return this.value.Current.foodItem.foodItemDataSource === 'figwee';
    return false;
  }

  /**
   * Event handler of 'change' event of Serving Size drop down list
   * @param Selection SelectionOption object
   */
  ServingSizeChanged(Selection: SelectionOption) {
    const HoldPrepareItemselection = this.PrepareItemselection;

    // need to retrieve new selection - activate via this property
    this.PrepareItemselection = true;

    if (this.value !== null && this.value !== undefined) {
      this.value.selectedServingSizeIndex = Selection.selectIndex;

      if (
        this.value.AssociatedObject !== null &&
        this.value.AssociatedObject !== undefined
      )
        this.AssociatedObject.selectedServingSizeIndex = Selection.selectIndex;

      this.PrepareSelection();
    }

    this.PrepareItemselection = HoldPrepareItemselection;
  }

  CloseWithNoSave() {
    this.ref.close();
    if (this.SaveCallBack) {
      this.SaveCallBack();
    }
  }

  Close() {
    this.PrepareSelection();

    if (this.SaveCallBack) {
      this.SaveCallBack();
    }
    this.ref.close();
  }

  Remove() {
    if (this.RemoveCallBack) {
      this.RemoveCallBack();
      this.Close();
    }
  }

  onClickNutritionalFacts() {
    this.dialogService.open(OverlayMealItemPresentationComponent, {
      hasScroll: false,
      closeOnBackdropClick: true,
      context: { OverlayItem: this.value },
      dialogClass: 'overlay-meal-item-dialog',
    });
  }

  /**
   * Event handler of the 'change' event of the Quantity input
   * @param quantity Meal Quantity
   */
  QuantityChanged(quantity) {
    this.value.quantity = +quantity;

    if (this.value.quantity - 0.5 < 0) {
      this.value.quantity = 0.5;
      quantity = this.ItemQuantity = this.value.quantity;
    }

    if (this.AssociatedObject != null && this.AssociatedObject !== undefined) {
      this.AssociatedObject.quantity = this.value.quantity;
    }

    this.CalculateMetrics();
  }

  ManualSizeChanged(serving) {
    this.ManualSelection = serving;
    this.value.selectedServingSizeEntered = +serving;

    if (this.AssociatedObject != null && this.AssociatedObject !== undefined) {
      this.AssociatedObject.selectedServingSizeIndex =
        this.value.selectedServingSizeIndex;
    }
  }

  onCustomServingSizeChanged($event) {
    if (this.value.isCustomServingSize) {
      this.value.quantity = 1;
      this.ItemQuantity = this.value.quantity;
      this.value.customServingSize = this.convertToGram.transform(this.CustomServingSize, this.ConversionFactorsValue);
      this.PrepareSelection(false);
    } else {
      this.value.customServingSize = null;
      this.ServingSizeChanged(this.selectedValue);
    }
  }

  onQuantityServingSizeChanged() {
    if (this.ItemQuantity > 0) {
      this.QuantityChanged(this.ItemQuantity);
    }
  }

  SubtractQuantity() {
    let nextIncrement;
    const adjustementLeftOver =
      this.value.quantity - Math.floor(this.value.quantity);
    if (adjustementLeftOver > this.IncrementStep) {
      nextIncrement = adjustementLeftOver - this.IncrementStep;
    } else {
      nextIncrement =
        adjustementLeftOver > 0 ? adjustementLeftOver : this.IncrementStep;
    }

    if (this.value.quantity - nextIncrement >= 0) {
      this.value.quantity -= nextIncrement;
      this.ItemQuantity = this.value.quantity;

      if (
        this.AssociatedObject != null &&
        this.AssociatedObject !== undefined
      ) {
        this.AssociatedObject.quantity = this.value.quantity;
      }

      this.CalculateMetrics();
    }
  }

  AddQuantity() {
    let nextIncrement;
    const adjustementLeftOver =
      Math.ceil(this.value.quantity) - this.value.quantity;
    if (adjustementLeftOver > this.IncrementStep) {
      nextIncrement = adjustementLeftOver - this.IncrementStep;
    } else {
      nextIncrement =
        adjustementLeftOver > 0 ? adjustementLeftOver : this.IncrementStep;
    }

    this.value.quantity += nextIncrement;
    this.ItemQuantity = this.value.quantity;

    if (this.AssociatedObject != null && this.AssociatedObject !== undefined) {
      this.AssociatedObject.quantity = this.value.quantity;
    }

    this.CalculateMetrics();
  }

  /**
   * Method to set the call back function
   * @param CallerThis Sender object
   * @param callback  Callback function
   */
  SetButtonCallBack(CallerThis, callback: (THIS, id: number) => void): void {
    this.Callback = callback;
    this.CallerThis = CallerThis;
  }

  /**
   * Method to set the delete call back function
   * @param CallerThis Sender object
   * @param callback  Callback function
   */
  SetDeleteButtonCallBack(
    CallerThis,
    callback: (THIS, id: number) => void
  ): void {
    this.DeleteCallback = callback;
    //this.CallerThis = CallerThis;
  }

  /**
   * Event handler of 'click' event of the button.
   */
  ClickButton() {
    if (this.Callback !== null) {
      this.Callback(this.CallerThis, this);
      // this.ButtonLabel = this.ButtonAddedLabel;
    }
  }

  ClickDeleteButton() {
    if (this.DeleteCallback !== null) {
      this.DeleteCallback(this.CallerThis, this);
    }
  }

  /**
   * Method to increase the serving size selection to next one
   */
  IncreaseServingSize() {
    if (this.IsCustomServingSize) return;

    if (this.ItemQuantity < 1) {
      this.AddQuantity();
    } else {
      if (this.Selections && this.selectedValue) {
        if (this.selectedValue.selectIndex < this.Selections.length - 1) {
          this.selectedValue =
            this.Selections[this.selectedValue.selectIndex + 1];
          this.ServingSizeChanged(this.selectedValue);
        } else {
          this.AddQuantity();
        }
      }
    }
  }

  /**
   * Method to decrease the serving size selection to previous one
   */
  DecreaseServingSize() {
    if (this.IsCustomServingSize) return;

    if (this.ItemQuantity > 1) {
      this.SubtractQuantity();
    } else {
      if (this.Selections && this.selectedValue) {
        if (this.selectedValue.selectIndex > 0) {
          this.selectedValue =
            this.Selections[this.selectedValue.selectIndex - 1];
          this.ServingSizeChanged(this.selectedValue);
        } else {
          if (this.ItemQuantity > this.IncrementStep) {
            this.SubtractQuantity();
          }
        }
      }
    }
  }
}

@Component({
  selector: 'app-serving-size-selection-diary',
  templateUrl: './serving-size-selection-diary.component.html',
  styleUrls: [
    './serving-size-selection.component.scss',
    '../../../../../global.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ServingSizeSelectionDiaryComponent extends ServingSizeSelectionComponent { }
