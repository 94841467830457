import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '../http.service';
import {
  ExerciseProgress,
  ExerciseProgressFilter,
  ExerciseProgressGraphData,
} from 'app/@core/interfaces/common/CalorieFriend/exercise-progress';
import { GridData } from 'app/@core/interfaces/common/grid-data';

@Injectable()
export class ExerciseProgressApi {
  private readonly apiController: string = 'exercise-progress';

  constructor(private api: HttpService) {}

  list(
    filter: ExerciseProgressFilter,
  ): Observable<GridData<ExerciseProgressGraphData>> {
    if (filter.patientGuid)
      return this.api.list(
        `${this.apiController}/list/${filter.patientGuid}`,
        filter,
      );
    return this.api.list(this.apiController, filter);
  }

  getOneMaxRep(
    patientId: number,
    exerciseId: string,
  ): Observable<ExerciseProgress> {
    return this.api.get(
      `${this.apiController}/max-rep/${patientId}/${exerciseId}`,
    );
  }

  changeOneMaxRep(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress> {
    return this.api.post(`${this.apiController}/max-rep`, exerciseProgress);
  }

  changeOneMaxRepByPatient(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress> {
    return this.api.post(
      `${this.apiController}/max-rep/patient`,
      exerciseProgress,
    );
  }
}
