import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GridData } from 'app/@core/interfaces/common/grid-data';
import {
  ExerciseProgress,
  ExerciseProgressData,
  ExerciseProgressFilter,
  ExerciseProgressGraphData,
} from '../../../../interfaces/common/CalorieFriend/exercise-progress';
import { ExerciseProgressApi } from '../../api/CalorieFriend/exercise-progress.api';

@Injectable()
export class ExerciseProgressService extends ExerciseProgressData {
  constructor(private api: ExerciseProgressApi) {
    super();
  }

  list(
    filter: ExerciseProgressFilter,
  ): Observable<GridData<ExerciseProgressGraphData>> {
    return this.api.list(filter);
  }

  getOneMaxRep(
    patientId: number,
    exerciseId: string,
  ): Observable<ExerciseProgress> {
    return this.api.getOneMaxRep(patientId, exerciseId);
  }

  changeOneMaxRep(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress> {
    return this.api.changeOneMaxRep(exerciseProgress);
  }

  changeOneMaxRepByPatient(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress> {
    return this.api.changeOneMaxRepByPatient(exerciseProgress);
  }
}
