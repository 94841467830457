import { HostListener, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map, skip } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';

import { ConfirmMessageDialogComponent } from 'app/@components/confirm-message-dialog/confirm-message-dialog.component';

export interface IComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export class BaseComponentCanDeactivate implements IComponentCanDeactivate {
  protected isDirty: boolean = false;

  constructor(private baseDialogService: NbDialogService) {}

  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm alert before navigating away
    return !this.IsDirty;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =
        'This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)';
    }
  }

  get IsDirty(): boolean {
    return this.isDirty;
  }

  initForm(form: FormGroup): void {
    form.valueChanges.pipe(skip(1)).subscribe(() => {
      this.isDirty = true;
    });
  }

  resetDirty() {
    this.isDirty = false;
  }

  back(callback: Function): void {
    if (!this.IsDirty) {
      callback();
      return;
    }

    this.baseDialogService
      .open(ConfirmMessageDialogComponent, {
        closeOnEsc: false,
        closeOnBackdropClick: false,
        context: {
          Title: $localize`:@@Unsaved changes:Unsaved changes`,
          Message:
            'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
        },
      })
      .onClose.subscribe((res) => {
        if (res) callback();
      });
  }
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<IComponentCanDeactivate>
{
  constructor(private dialogService: NbDialogService) {}

  canDeactivate(
    component: IComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return component.canDeactivate() ? true : this.openConfirmDialog();
  }

  openConfirmDialog(): any {
    const dlg = this.dialogService.open(ConfirmMessageDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        Title: $localize`:@@Unsaved changes:Unsaved changes`,
        Message:
          'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
      },
    });
    return dlg.onClose.pipe(map((result) => result));
  }
}
