

import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utility } from '../../@core/utils/utility';

/**
 * The component for inputting the weight in metric / imperial unit (Kg / Pounds)
 * @usageNotes
 * It is used for inputting the weight for patient profiles or assessment data
 */
@Component({
  selector: 'pounds-and-kg',
  templateUrl: './pounds-and-kg.html',
  styleUrls: ['./pounds-and-kg.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PoundsAndKgComponent),
      multi: true,
    },
  ],
})

export class PoundsAndKgComponent implements ControlValueAccessor {
  @Input()
  get value() {
    return this._value;
  }

  set value(val) {
    if (val) {
      this._value = val;
      this.kgs = Math.round(this._value * 10) / 10;
    }

    this.updateValues(false, false);

    this.onChange(val);
    this.onTouched();
  }

  public _value: number;

  /**
   * Input Property which identifies if it is for Metric or Imperial
  */
  @Input('isImperial') isImperial: boolean = false;

  /**
  * Input Property which identifies if it is for Edit or View
  */
  @Input('IsView') IsView: boolean = false;
  @Input('IgnoreUpdate') IgnoreUpdate: boolean = false;

  @Input('status') status;

  onChange: any = () => { };
  onTouched: any = () => { };

  pounds: any;
  kgs: any;

  constructor() {
  }

  /**
   * Implements ControlValueAccessor's registerOnChange
   * @param fn
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Implements ControlValueAccessor's writeValue
   * @param fn
   */
  writeValue(value) {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  /**
   * Implements ControlValueAccessor's registerOnTouched
   * @param fn
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * 'Change' event handler of input controls
  */
  onValuesChange() {

    this.updateValues(this.isImperial);

    this.onChange(!this.value || this.value == 0 ? '' : this.value);
  }

  /**
   * The function which calculates the Pounds from Kg or vice versa
  */
  updateValues(updateFromImperial: boolean, isFromUI: boolean = true) {
    if (!this.IgnoreUpdate) {
      if (!updateFromImperial) {
        if (!this._value && !isFromUI) {
          this.kgs = '';
          this.pounds = '';
        }
        if (isFromUI)
          this._value = this.kgs;

        const rawPounds = this._value / 0.453592;
        this.pounds = Number(rawPounds.toFixed(1));
        if (!this.pounds)
          this.pounds = null;

      } else {
        if (!this.pounds) {
          this.pounds = '';
          this.kgs = '';
        }

        this._value = this.pounds * 0.453592;
        this.kgs = Math.round(this._value * 10) / 10;
        if (this.kgs == 0)
          this.kgs = null;

      }
    } else {
      if (!this._value) { this._value = 0; this.kgs = 0; }
      if (!this.pounds) this.pounds = 0;
      this._value = this.pounds = this._value;
    }
  }

  /**
   * Validator that validates the number with the ##.# pattern
   */
  validateNumber = Utility.validateNumber;

}
