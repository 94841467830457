import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

export abstract class SubscriptionPlanData {
  abstract get gridDataSource(): DataSource;
  abstract list(): Observable<SubscriptionPlan[]>;
  abstract getAvailableList(): Observable<SubscriptionPlan[]>;
  abstract create(
    subscriptionPlan: SubscriptionPlan,
  ): Observable<SubscriptionPlan>;
  abstract get(id: number): Observable<SubscriptionPlan>;
  abstract getByCode(planCode: string): Observable<SubscriptionPlan>;
  abstract update(
    subscriptionPlan: SubscriptionPlan,
  ): Observable<SubscriptionPlan>;
  abstract delete(id: number): Observable<boolean>;
}

export interface SubscriptionPlan {
  id: number;
  planName: string;
  planCode: string;
  priceId: string;
  planType: number;
  patientsCount: number;
  price: number;
  isFreePlan: boolean;
  hasAssessments: boolean;
  hasBranding: boolean;
  hasPlanChoices: boolean;
}
