import { Injectable } from '@angular/core';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import {
  CustomFoodEdit,
  CustomFoodData,
  CustomFoodFilter,
  CustomFoodView,
  CustomPublicFoodData,
} from '../../../../interfaces/common/CalorieFriend/custom-food';
import {
  CustomFoodApi,
  CustomPublicFoodApi,
} from '../../api/CalorieFriend/custom-food.api';

@Injectable()
export class CustomFoodService extends CustomFoodData {
  constructor(private api: CustomFoodApi) {
    super();
  }

  // Created by Vinicius
  list(filter: CustomFoodFilter): Observable<GridData<CustomFoodEdit>> {
    return this.api.list(filter);
  }

  /**
   * Service function to get the custom foods list of the current user in DataSource format.
   */
  get gridDataSource(): DataSource {
    return this.api.customFoodDataSource;
  }

  /**
   * Service function to get the custom foods list with detail information for Meal Plans in DataSource format.
   */
  gridDataSourceForMeal(userId:number, patientId:number): DataSource {
    return this.api.customFoodDataSourceForMeal(userId, patientId);
  }

  // Updated by Milos
  get(id: number): Observable<CustomFoodView> {
    return this.api.get(id);
  }

  // Updated by Vinicius
  delete(id: number, patientGuid?: string): Observable<boolean> {
    return this.api.delete(id, patientGuid);
  }

  // Updated by Milos
  create(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<boolean> {
    return this.api.add(customFood, userId, patientId);
  }

  createQuickAdd(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<CustomFoodView> {
    return this.api.addQuickAdd(customFood, userId, patientId);
  }


  // Updatec by Milos
  update(customFood: CustomFoodEdit, userId: number, patientId: number): Observable<boolean> {
    return this.api.update(customFood, userId, patientId);
  }

  /**
   * Service function to get the custom foods list filtered by @query
   * @param query Query string
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.GetFilterResult(query);
  }
}

@Injectable()
export class CustomPublicFoodService extends CustomPublicFoodData {
  constructor(private api: CustomPublicFoodApi) {
    super();
  }

  /**
   * Service function to get the custom foods list with detail information for Meal Plans in DataSource format.
   */
  get gridDataSourceForPublicMeal(): DataSource {
    return this.api.customPublicFoodDataSourceForMeal;
  }
}
