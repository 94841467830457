import { Injectable } from '@angular/core';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { InvoicesApi } from '../../api/CalorieFriend/invoices.api';
import { PatientSubscriptionData, PatientSubscriptionMeta, PatientSubscriptionsFilter } from 'app/@core/interfaces/common/CalorieFriend/patient-subscription';
import { PatientSubscriptionsMetaApi } from '../../api/CalorieFriend/patient-subscriptions-meta.api';
import { PatientSubscriptionsDataApi } from '../../api/CalorieFriend/patient-subscriptions-data.api';

@Injectable()
export class PatientSubscriptionsDataService {
  constructor(private api: PatientSubscriptionsDataApi) {}

  list(filter: PatientSubscriptionsFilter): Observable<GridData<PatientSubscriptionData>> {
    return this.api.list(filter).pipe(share());
  }

  listForPatient(guid: string, filter: PatientSubscriptionsFilter): Observable<GridData<PatientSubscriptionData>> {
    return this.api.listForPatient(guid, filter).pipe(share());
  }

  get(id: number, patientGuid?: string): Observable<PatientSubscriptionData> {
    return this.api.get(id, patientGuid).pipe(share());
  }

  cancel(id: number): Observable<boolean>{
    return this.api.cancel(id);
  }
}
