import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() label: string;
  @Input() imageSrc: string;
  @Output() onResetImage = new EventEmitter<void>();
  @Output() onImageChange = new EventEmitter<File>();
  @ViewChild('fileInput') fileInput: ElementRef;
  imgHover = false;
  previewImage: string | ArrayBuffer;

  onFileInputClick() {
    this.fileInput.nativeElement.click();
  }

  onImageFileChanged(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.onImageChange.emit(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.previewImage = reader.result;
    };
  }

  resetImage() {
    this.previewImage = null;
    this.onResetImage.emit();
  }
}
