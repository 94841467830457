import { Observable } from 'rxjs';

import { BaseFilter, GridData } from '../grid-data';

export abstract class BiometricHistoryData {
  abstract list(
    filter: BiometricHistoryFilter,
  ): Observable<GridData<BiometricHistoryView>>; // Updated by Vinicius

  abstract AllHistoriesList(
    patientId: number,
    NotificationToken: string,
  ): Observable<GridData<BiometricHistoryView>>;
  abstract create(goal: any): Observable<BiometricHistory>;
  abstract get(id: number): Observable<BiometricHistory>;
  abstract delete(id: number): Observable<boolean>;
  abstract update(order: any): Observable<BiometricHistory>;
}

export interface BiometricHistory {
  id: number;
  PatientID: number;

  height: number;
  weight: number;
  bodyFat: number;
  source: string;

  measureDateTime: Date;
}

export interface BiometricHistoryView {
  id: number;

  height: string;
  weight: number;
  bodyFat: number;
  source: string;
  bmi: string;

  measureDateTime: string;
}

// Created by Vinicius
export interface BiometricHistoryFilter extends BaseFilter {
  patientId?: number;
  patientGuid?: string;
}
