import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './@auth/guards/auth.guard';
import { LandingComponent } from './landing.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () =>
      import('../app/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'PatientView',
    loadChildren: () =>
      import('./frontends/frontends.module').then((m) => m.FrontendsModule),
  },
  {
    path: 'Client',
    loadChildren: () =>
      import('./frontends/frontends.module').then((m) => m.FrontendsModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    component: LandingComponent,
  },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
