<div class="EditControlWrapper" (click)="onClickEdit();">
  <!--single label element-->
  <label for="name" style="float:left;white-space: pre-wrap;" [hidden]="hideLabel">
    <span>
      Time ({{ value }})
    </span>
  </label>

  <input #input [ngxTimepicker]="Time" (focusout)="onFocusout()" [hidden]="hideEdit" [(ngModel)]="_value"
    class="size-medium status-basic shape-semi-round" (change)="onValuesChange()" [placeholder]="placeholder" />
  <ngx-material-timepicker #Time (timeSet)="onTimeChange($event)"></ngx-material-timepicker>

  <div class="controls EditControlLayout" style="float:left;">
    <a class="edit" href="javascript:void(0);" [hidden]="hideLabel">
      <nb-icon icon="modify-pencil-outline" *ngIf="!readonly" pack="custom"></nb-icon>
      <nb-icon icon="eye-outline" *ngIf="readonly"></nb-icon>
    </a>
  </div>
</div>
