<nb-card>
  <nb-card-header><span *ngIf="!customName">{{ exerciseLabel }} - {{ this._exercise?.name }}</span><span
      *ngIf="customName">{{ exerciseLabel }} - {{ customName }}</span>
    <span *ngIf="isCompleted" class="time-details">Completed in {{ exerciseDuration }}</span>
    <span *ngIf="!isCompleted" class="time-details">Skipped</span>
  </nb-card-header>
  <nb-card-body *ngIf="isCompleted">
    <div class="row d-flex align-items-center">
      <div class="col-md-5 col-sm-12">
        <img *ngIf="!customName" [src]="this._exercise?.imageUrl" class="w-100" />

        <div *ngIf="customName" class="w-100"></div>
      </div>
      <div class="col-md-7 col-sm-12">
        <nb-tabset>
          <nb-tab tabTitle="Charts">
            <div class="chartWrapper">
              <div class="chartAreaWrapper">
                <!-- <app-workout-logs-details [logData]="_logs"></app-workout-logs-details> -->
                <chart *ngIf="graphData" type="line" [data]="graphData" [options]="graphOptions" class="d-block graph"
                  [ngStyle]="{
                'height.px': 200,
                'width.px': _logs.length * 100,
                minWidth: '100%'
              }">
                </chart>
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Details">
            <app-workout-logs-details [logData]="_logs"></app-workout-logs-details>
          </nb-tab>
        </nb-tabset>
      </div>
    </div>
  </nb-card-body>
</nb-card>
