import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {

  public static readonly CompleteRegistrationEvent: string = 'CompleteRegistration';
  public static readonly PageViewEvent: string = 'PageView';
  public static readonly PurchaseEvent: string = 'Purchase';

  constructor() { }

  load(eventName: string, eventParam: any = {}) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      }; if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq.disablePushState = true; // not recommended, but can be done
    (window as any).fbq('init', '813230999628886');
    (window as any).fbq('track', eventName, eventParam);
  }
}
