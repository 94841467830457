import { Injectable } from '@angular/core';
import { HiddenSimilarFoodData } from 'app/@core/interfaces/common/CalorieFriend/hidden-similar-food';
import { Observable } from 'rxjs';

import { HiddenSimilarFoodApi } from '../../api/CalorieFriend/hidden-similar-food.api';

/**
 * Injectable class for the HiddenSimilarFood Service
 */
@Injectable()
export class HiddenSimilarFoodService extends HiddenSimilarFoodData {
  /**
   * Injects a HiddenSimilarFood API instance
   * @param api HiddenSimilarFood API injectable
   */
  constructor(private api: HiddenSimilarFoodApi) {
    super ();
  }

  /**
   * Service function to add a new Similar Food.
   * @param HiddenSimilarFood Similar Food Form data
   */
  show(hiddenSimilarFoods: any): Observable<any> {
     return this.api.show(hiddenSimilarFoods);
  }

  /**
   * Service function to add a new Similar Food.
   * @param HiddenSimilarFood Similar Food Form data
   */
  hide(hiddenSimilarFoods: any): Observable<any> {
    return this.api.hide(hiddenSimilarFoods);
  }


}
