import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TfAdminLoginService {
  isTfAdmin: boolean = false;
  constructor() {}
  public login() {
    localStorage.setItem('isTfAdmin', 'true');
    this.isTfAdmin = true;
  }
  public logout() {
    localStorage.removeItem('isTfAdmin');
    this.isTfAdmin = false;
  }
  public get isLoggedIn(): boolean {
    console.log('isTfAdmin', localStorage.getItem('isTfAdmin'), localStorage.getItem('isTfAdmin').toString() === 'true');
    return localStorage.getItem('isTfAdmin').toString() === 'true';
  }
}
