import { Observable } from 'rxjs';

export abstract class TutorialData {
  abstract save(tutorial: string): Observable<any>;
}

export class TutorialIDs {
  static readonly DemoPatient = 'DemoPatient';
  static readonly AddNewPatient = 'AddNewPatient';
  static readonly NewPatient = 'NewPatient';
  static readonly NewPatientStep1 = 'NewPatientStep1';
  static readonly NewPatientStep2 = 'NewPatientStep2';
  static readonly AddNewPlan = 'AddNewPlan';
  static readonly LoadFromTemplate = 'LoadFromTemplate';
  static readonly SelectTemplate = 'SelectTemplate';
  static readonly FinalStep = 'FinalStep';
}
