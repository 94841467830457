import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-skeleton-user-list',
  templateUrl: './skeleton-user-list.component.html',
  styleUrls: ['./skeleton-user-list.component.scss']
})
export class SkeletonUserListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
