import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';
import { GridData } from '../grid-data';

export interface Goal {
  id: number;
  PatientID: number;

  goalType: number;
  goalValue: number;
  startValue: number;
  progress: number;
  goalName: string;

  targetDate: Date;
  createdDate: Date;
  startDate: Date;
  achievedDate: Date;
}

export interface GoalView {
  id: number;

  goalType: string;
  goalValue: number;
  startValue: number;
  goalName: string;

  startDate: string;
  targetDate: string;
  createdDate: string;
  updatedDate: string;
  achievedDate: string;

  status: number;
  daysLeft: number;

  progress: string;
  goalTypeDetail: string;

  biometricsData: Array<any>;
}

export abstract class GoalData {
  abstract get gridDataSource(): DataSource;
  abstract GoalsList(
    patientId: number,
    NotificationToken: string
  ): Observable<GridData<GoalView>>;
  abstract create(goal: any): Observable<Goal>;
  abstract get(id: number): Observable<Goal>;
  abstract delete(id: number): Observable<boolean>;
  abstract update(order: any): Observable<Goal>;
}
