import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'ngx-patient-calendar-header',
  templateUrl: './patient-calendar-header.component.html',
})
export class PatientCalendarHeaderComponent {
  @Input() view: CalendarView;
  @Input() viewDate: Date;
  @Input() locale: string = 'en';

  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
}
