import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';

import { GridData } from 'app/@core/interfaces/common/grid-data';
import {
  CustomExercise,
  CustomExerciseData,
  CustomExerciseFilter,
} from 'app/@core/interfaces/common/CalorieFriend/custom-exercise';
import { CustomExerciseApi } from '../../api/CalorieFriend/custom-exercise.api';

@Injectable()
export class CustomExerciseService extends CustomExerciseData {
  constructor(private api: CustomExerciseApi) {
    super();
  }

  // Created by Vinicius
  list(filter: CustomExerciseFilter): Observable<GridData<CustomExercise>> {
    return this.api.list(filter);
  }

  /**
   * Service function to get the custom foods list with detail information for Meal Plans in DataSource format.
   */
  get gridDataSourceForBlock(): DataSource {
    return this.api.customExerciseDataSourceForBlock;
  }

  /**
   * Service function to get the Custom Food with the identifier @id
   * @param id Identifier of the custom food
   */
  get(id: number): Observable<CustomExercise> {
    return this.api.get(id);
  }

  /**
   * Service function to add a new custom food.
   * @param CustomFood Custom Food Form data
   */
  create(CustomFood: any): Observable<CustomExercise> {
    return this.api.add(CustomFood);
  }

  // Updated by Vinicius
  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }

  /**
   * Service function to update an existing custom food
   * @param customFood Custom Food Form data
   */
  update(customFood: any): Observable<CustomExercise> {
    return this.api.update(customFood);
  }

  /**
   * Service function to get the custom foods list filtered by @query
   * @param query Query string
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.GetFilterResult(query);
  }
}
