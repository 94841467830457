import { map, switchMap } from 'rxjs/operators';
import { WorkoutApi } from './../../api/CalorieFriend/workout.api';
import {
  Workout,
  WorkoutDetail,
  WorkoutDetailStatus,
  WorkoutStatus,
} from './../../../../interfaces/common/CalorieFriend/workout';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { WorkoutTimerService } from './workout-timmer.service';
import { de } from 'date-fns/esm/locale';
export enum WorkoutMode {
  Start, // Not Started
  Workout, // Currently Workout
  Pause, // Paused
  Complete, //Completed
}

export interface IStarExcercise {
  blockId: number,
  excerciseId: number,
  isWorkout: boolean,
}

@Injectable()
export class WorkoutService {
  public workoutMode = new BehaviorSubject<WorkoutMode>(WorkoutMode.Start);
  /**
   * workout for current date
   */
  public workout = new BehaviorSubject<Workout | null>(null);
  public dayId = new BehaviorSubject<number>(0);
  public startExcercise =new BehaviorSubject<IStarExcercise>(null);
  public nextExcercise = new BehaviorSubject<void>(null);

  constructor(private api: WorkoutApi, private workoutTimer: WorkoutTimerService) {
    this.dayId
      .pipe(
        switchMap((dayId) => {
          if(dayId)
          return this.api.getByDate(dayId, new Date());
        else
        return of(null);
        })
      )
      .subscribe((workoutList: Workout[]) => {
        if(!workoutList) return;
        let workout = null;
        if (workoutList.length > 0) {
          workout = workoutList[workoutList.length - 1];
        }
        this.workout.next(workout);
        let mode = WorkoutMode.Start;
        if (workout) {
          if (
            workout.status == WorkoutStatus.Completed ||
            workout.status == 'completed'
          ) {
            mode = WorkoutMode.Complete;
          } else mode = WorkoutMode.Pause;
        }
        // this.workoutMode.next(mode);
        this.workoutTimer.updateStatus(mode);
      });
      this.workoutTimer.workoutMode.subscribe(res => {
        this.workoutMode.next(res);
      })
  }

  workoutModeToString(): string {
    switch(this.workoutMode.value) {
        case WorkoutMode.Start:
            return 'Not Started';
        case WorkoutMode.Workout:
            return 'Currently Workout';
        case WorkoutMode.Pause:
            return 'Paused';
        case WorkoutMode.Complete:
            return 'Completed';
        default:
            return 'Unknown';
    }
}
  public async getWorkoutsInMonth(
    patientId: number,
    year: number,
    month: number
  ) {
    const pastMonth = month - 1;
    let startDate = new Date(pastMonth == 0 ? year-1: year, pastMonth === 0? 12: pastMonth);
    const nextMonth = month + 1;
    let endDate = new Date(nextMonth === 13? year + 1: year, nextMonth === 13 ? 1 : nextMonth);
    const data = await this.api.getWorkoutsInRange(
      patientId,
      startDate,
      endDate
    );
    return data;
  }
  public async updateWorkout(id: number, updateData: Partial<Workout>) {
    const res = await this.api.update(id, updateData).toPromise();
    this.workout.next({ ...this.workout.value, ...updateData });
  }
  public async getWorkout(id: number) {
    return this.api.get(id).toPromise();
  }
  public async completeWorkout() {
    this.workoutTimer.updateStatus(WorkoutMode.Complete);
    let workout = this.workout.value;
    if (!workout?.id) return;
    await this.updateWorkout(workout.id, {
      endDateTime: new Date(),
      workoutTime: this.workoutTimer.workoutTime.value,
      status: WorkoutStatus.Completed,
    });
    // this.workoutMode.next(WorkoutMode.Complete);
  }
  public redoWorkout() {
    this.workout.next(null);
    // this.workoutMode.next(WorkoutMode.Start);
    this.workoutTimer.updateStatus(WorkoutMode.Start);
    this.workoutTimer.reset();
  }
  public async startWorkout(defaultWorkout?: Workout) {
    if (this.workout.value == null) {
      const res = await this.api.add(defaultWorkout).toPromise();
      this.workout.next(res);
    }
    this.workoutTimer.start();
  }
  /**
   * @description set workout mode into pause. called when the workout modal is closed.
   * @returns void
   */
  public async pauseWorkout(defaultWorkout?: Workout) {
    this.workoutTimer.pause();
    if (this.workout.value == null || defaultWorkout) {
      if(defaultWorkout.id) {
      const res = await this.api.update(defaultWorkout.id,defaultWorkout).toPromise();
      } else {
        const res = await this.api.add(defaultWorkout).toPromise();
      }
    }
  }
  /*
   * It's not necessary

  public stopWorkout() {
    this.workoutMode.next(WorkoutMode.Start);
  } */
  public getExistingWorkout(dayId: number) {
    return this.api.getByDate(dayId, new Date());
  }

  public async addDetail(detail: WorkoutDetail) {
    return this.api.addDetail(detail).toPromise();
  }

  public async getLastWorkoutItem(workoutId: number) {
    return this.api.getLastWorkoutItem(workoutId).toPromise();
  }

  public async getDetails(workoutId: number) {
    return this.api.getDetails(workoutId).toPromise<WorkoutDetail[]>();
  }

  public async getDetailsAll(workoutId: number) {
    return this.api.getDetailsAll(workoutId).toPromise<WorkoutDetail[]>();
  }

  public getComplianceScore(
    patientId: number,
    startDate: Date,
    endDate?: Date
  ): Observable<number> {
    return this.api.getComplianceScore(
      patientId,
      startDate,
      endDate || new Date()
    );
  }
}
