<nb-card class="serving_size_container" #SelectionLayout1
  [ngClass]="ShowOK == true ? 'nb_card_pop_up meal_item mobilePopup' : ''">

  <nb-card-header [hidden]="!ShowOK">
    <div class="title">
      <ng-container i18n="@@AddNewItemToBlock">Edit Item</ng-container>
    </div>
    <div class="actions">
      <!--<button i18n="@@AddNewItem" type="submit" style="width:120px;z-index: 1000;" class="btn-circle btn-xl btn-main StandardGreenButton" (click)="onNewCustomMealItem()">Add New Item</button>-->

      <button nbButton ghost  tabindex="-1">
        <nb-icon icon="close-outline" (click)="CloseWithNoSave()" style="z-index: 1000;"></nb-icon>
      </button>

    </div>
  </nb-card-header>






  <nb-card-body>
    <div style="position: absolute;right:0;top:0px" *ngIf="AssociatedObject && AssociatedObject.selected">
      <nb-checkbox readonly [checked]=true></nb-checkbox>
    </div>
    <div class="row serving_size_item" style="justify-content: center;">
      <div>
        <div class="serving_size_image">
          <img src="{{ItemImage}}">

          <div *ngIf="!value.isCustomServingSize">

            <div *ngIf="ShowPhotoControls()"
              style="float:right; background-color:rgba(255, 255, 255, 0.6); width:70px; border-bottom-left-radius:10px;">

              <a href="javascript:void(0);" class="action">
                <nb-icon icon="minus-circle-outline" style="width:35px; height:35px;" (click)="DecreaseServingSize()">
                </nb-icon>
              </a>

              <a href="javascript:void(0);" class="action">
                <nb-icon icon="plus-circle-outline" style="width: 35px; height: 35px;" (click)="IncreaseServingSize()">
                </nb-icon>
              </a>

            </div>

          </div>

          <div *ngIf="ItemQuantity != 1 && ShowPhotoControls()" class="QuantityCircle CircleLayout">
            <div>x<span class="quantity" [innerHTML]="ItemQuantity"></span></div>
          </div>

          <div class="custom-serving-size-overlay" *ngIf="value.isCustomServingSize">
            <span>{{CustomServingSize}} Grams</span>
          </div>

        </div>
        <div>
          <nb-checkbox *ngIf="!this.isQuickAdd" i18n="@@ManuallyEnterServingSize" [(ngModel)]="value.isCustomServingSize"
            (change)="onCustomServingSizeChanged()"><span style="font-size:small;">Manually enter portion weight</span>
          </nb-checkbox>
        </div>

      </div>
      <div class="item-description">
        <span style="font-size: 22px; font-weight:700">{{Name}}</span>
        <div style="font-size: 14px; font-weight:300; padding-bottom:10px;">
          <a href="javascript:void(0)" (click)="onClickNutritionalFacts()">Nutritional facts</a>
        </div>

        <div>
          <div *ngIf="!this.isQuickAdd">
            <div i18n="@@ServingSize">Serving Size</div>
            <div class="serving-size-selection" style="max-width:200px;">
              <select style="height:25px;" [(ngModel)]="selectedValue" (ngModelChange)='ServingSizeChanged($event)'
                *ngIf="!value.isCustomServingSize">
                <option *ngFor="let selection of Selections" [ngValue]="selection">{{selection.selectName}}</option>
              </select>
              <input style="max-width:50px;" type="number" [(ngModel)]="CustomServingSize"
                (change)="onCustomServingSizeChanged()" min="1" *ngIf="value.isCustomServingSize">
            </div>
          </div>

          <div *ngIf="!this.isQuickAdd">
            <div>Units</div>
            <div class="serving-size-selection" style="max-width:200px;">
              <select style="height:25px;" [(ngModel)]="selectedValue" (ngModelChange)='ServingSizeChanged($event)'
                *ngIf="!value.isCustomServingSize">
                <option *ngFor="let selection of ConversionFactors" [ngValue]="selection">{{selection.name}}</option>
              </select>
              <input style="max-width:50px;" type="number" [(ngModel)]="CustomServingSize"
                (change)="onCustomServingSizeChanged($event)" min="1" *ngIf="value.isCustomServingSize">
            </div>
          </div>

          <div i18n="@@Quantity">Quantity</div>
          <div style="margin:0px;">
            <div style="float:left;">
              <input type="number" step=".1" class="NoPadding" nbInput style="height: 25px;width: 50px"
                [disabled]="value.isCustomServingSize" [(ngModel)]="ItemQuantity" (ngModelChange)="QuantityChanged($event)"
                onkeydown="return false" min=".1" />
            </div>
            <div *ngIf="!this.isQuickAdd" style="float:left; font-size:small; margin-left:10px; color:gray">
              <span *ngIf="ItemQuantity != 1">
                (Total {{TotalGrams | gramConverter : ConversionFactorsValue}})

              </span>
            </div>
          </div>

        </div>
      </div>
      <div>
        <app-meal-metrics-graph [MealCals]="CaloriesRendered" [MealProtein]="ProteinRendered"
          [MealNetCarbs]="NetCarbsRendered" [MealCarbs]="CarbsRendered" [MealFats]="TotalFatRendered"
          [GraphInBack]="true"></app-meal-metrics-graph>
        <span style="font-size: 8pt; color: #424242;">Source: </span><span style="font-size: 8pt; color: #424242;"
          [innerHTML]="InfoSource"></span>
        <br /><br />

        <button *ngIf="!ShowOK" nbButton type="submit"  class="btn-circle btn-xl btn-main button_add_to_plan"
          (click)="ClickButton()" i18n="@@AddToPlan">Add To Plan</button>

      </div>
    </div>
  </nb-card-body>


  <nb-card-footer [hidden]="!ShowOK || ViewOnly">
    <table>
      <tr>
        <td>
          <button i18n="@@Save" nbButton class="btn-circle btn-xl btn-main btn-main"  (click)="Close()">Save</button>
        </td>
        <td>
          <button i18n="@@Remove" nbButton class="back btn-circle btn-xl" status="basic" (click)="Remove()">
            Remove <nb-icon icon="trash-2-outline"></nb-icon>
          </button>
        </td>
      </tr>
    </table>
  </nb-card-footer>


</nb-card>