import { Injectable } from '@angular/core';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import { HttpService } from '../http.service';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import {
  CustomExercise,
  CustomExerciseFilter,
} from 'app/@core/interfaces/common/CalorieFriend/custom-exercise';

@Injectable()
export class CustomExerciseApi {
  private readonly apiController: string = 'custom-exercise';

  constructor(private api: HttpService) {}

  // Created by Vinicius
  list(filter: CustomExerciseFilter): Observable<GridData<CustomExercise>> {
    return this.api.list(this.apiController, filter);
  }

  /**
   * API function to get the custom exercises list with detail information for Meal Plans in DataSource format.
   * Method: GET
   * URL: /ListForMeal
   */
  get customExerciseDataSourceForBlock(): DataSource {
    return this.api.getServerDataSource(
      `${this.api.apiUrl}/${this.apiController}/ListForBlock`,
    );
  }

  /**
   * API function to get the Custom Exercise with the identifier @id
   * @param id Identifier of the custom exercise
   * Method: GET
   * URL: /${id}
   */
  get(id: number): Observable<any> {
    return this.api.get(`${this.apiController}/${id}`);
  }

  /**
   * API function to add a new custom exercise.
   * @param customExercise Custom Exercise Form data
   * Method: POST
   * URL: /
   */
  add(customExercise: any): Observable<any> {
    const form_data = new FormData();
    Object.keys(customExercise).forEach((key) =>{
      if(customExercise[key] && customExercise[key] != 'null')
      form_data.append(key, customExercise[key]);
    },
    );

    return this.api.post(this.apiController, form_data);
  }

  // Updated by Vinicius
  delete(id: number): Observable<boolean> {
    return this.api.delete(`${this.apiController}/${id}`);
  }

  /**
   * API function to update an existing custom exercise
   * @param customExercise Custom Exercise Form data
   * Method: POST
   * URL: /edit
   */
  update(customExercise: any): Observable<any> {
    const form_data = new FormData();
    Object.keys(customExercise).forEach((key) => {
      if(customExercise[key] && customExercise[key] != 'null')
        form_data.append(key, customExercise[key])
      }
    );

    return this.api.post(`${this.apiController}/edit`, form_data);
  }

  /**
   * API function to get the custom exercises list filtered by @query
   * @param query Query string
   * Method: GET
   * URL: /filter/${query}
   */
  GetFilterResult(query: string): Observable<string[]> {
    return this.api.get(`${this.apiController}/filter/${query}`);
  }
}
