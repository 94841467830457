<div class="overlay-container modal-dialog modal-dialog-centered" role="document" style="">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLongTitle">
        <strong [innerHTML]="ItemSelection"></strong><br>
        <div [innerHTML]="PortionSelectionWithQuantity"></div>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">x</span>
      </button>
    </div>
    <div class="modal-body scrollbar-hidden" style="text-align:center; height:80vh; overflow: auto;">
      <img style="width:100%" src='{{ServingSize.ItemLargeImage}}' *ngIf="ServingSize.ItemLargeImage">

      <div class="custom-serving-size-overlay" *ngIf="ServingSize.value.isCustomServingSize">
        <span>{{Item.totalGrams}} Grams</span>
      </div>
      <span style="font-size: 8pt; color: #424242;">Source: </span><span style="font-size: 8pt; color: #424242;" [innerHTML]="InfoSource"></span>
      <br><br>

      <div id="grid">

        <section class="performance-facts" style="min-width:auto !important;">
          <div class="performance-facts__header" style="text-align:left;">
            <span class="performance-facts__title">Nutrition Facts</span>

            <p id="performance-facts-servingSize" style="text-align:left;">
              <span [innerHTML]="PortionSelectionWithQuantity"></span>
              <!-- Size 14 sprout(s) (about <span id="nutritionServingSize">294</span> g) -->
            </p>

          </div>
          <table class="performance-facts__table">
            <thead>
              <tr>
                <th colspan="3" class="small-info"><span i18n="@@AmountPerServing">Amount Per Serving</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="3"><b i18n="@@Calories">Calories</b>&nbsp;&nbsp;<span
                    id="nutritionFactsCalories">{{ServingSize.CaloriesRendered}}</span></th>
               
              </tr>
              <tr class="thick-row">
                <td colspan="3" class="small-info"><b i18n="@@DailyValue">% Daily Value*</b></td>
              </tr>
              <tr>
                <th colspan="2">
                  <div class="dot fat"></div><b>Total Fat</b> {{Math.round(Item.Current.nutrientsDataItem.fat)}}
                </th>
                <td><b>{{FatPercent}}%</b></td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  <span>Saturated Fat</span>
                  {{Math.round(Item.Current.nutrientsDataItem.saturatedFat)}}g
                </th>
                <td>
                  <b>{{SatFatPercent}}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Trans Fat
                  {{Math.round(Item.Current.nutrientsDataItem.transFat)}}g
                </th>
                <td>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Cholesterol</b>
                  {{Math.round(Item.Current.nutrientsDataItem.cholesterol)}}mg
                </th>
                <td>
                  <b>{{CholesterolPercent}}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <b>Sodium</b>
                  {{Math.round(Item.Current.nutrientsDataItem.sodium)}}mg
                </th>
                <td>
                  <b>{{SodiumPercent}}%</b>
                </td>
              </tr>
              <tr>
                <th colspan="2">
                  <div class="dot carb"></div><b>Total Carbohydrate</b>
                  {{Math.round(Item.Current.nutrientsDataItem.carb)}}g
                </th>
                <td>
                  <b>{{CarbPercent}}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Dietary Fiber
                  {{Math.round(Item.Current.nutrientsDataItem.fiber)}}g
                </th>
                <td>
                  <b>{{FiberPercent}}%</b>
                </td>
              </tr>
              <tr>
                <td class="blank-cell">
                </td>
                <th>
                  Sugars
                  {{Math.round(Item.Current.nutrientsDataItem.sugar)}}g
                </th>
                <td>
                </td>
              </tr>
              <tr class="thick-end">
                <th colspan="2">
                  <div class="dot protein"></div><b>Protein</b>
                  {{Math.round(Item.Current.nutrientsDataItem.protein)}}g
                </th>
                <td>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="small-info">* Percent Daily Values are based on a 2,000 calorie diet. Your daily values may be
            higher or lower depending on your calorie needs.</p>

        </section>
      </div>

      <br>

      <h4 class="similar-foods-title">Food with similar macronutrients</h4>
      <a href="javascript:void(0)" i18n="@@ShowAllSimilarFoods" class="float-right" (click)="onShowAll()"
        *ngIf="!isPatientView && currentUser && canShowAll">
        [Show All Similar Foods]
      </a>

          <!-- <a href="javascript:void(0)" i18n="@@HideAllSimilarFoods" class="float-right" (click)="onHideAll()"
        *ngIf="!isPatientView && currentUser && canHideAll">
        [Hide All Similar Foods]
      </a>-->

      <div class="mobile_row">

        <ng-container *ngFor="let similar of this.Item.Current.foodItem.similiarFoods">

          <div class="overlay_meal_item" *ngIf="!similar.isHidden || !isPatientView">

            <div style="position: relative;">

             <!-- <nb-icon class="show-hide-icon" icon="eye-off-outline"
                *ngIf="!isPatientView && currentUser && similar.isHidden" (click)="onShowSimilarFood(similar)">
              </nb-icon>

              <nb-icon class="show-hide-icon" icon="eye-outline"
                *ngIf="!isPatientView && currentUser && !similar.isHidden" (click)="onHideSimilarFood(similar)">
              </nb-icon> -->

              <a href="https://www.caloriefriend.com/{{similar.url}}" target="_blank">

                <img src="{{similar.foodItem.imageURL}}" alt="" class="rounded" />

                <p>
                  {{similar.foodItem.description}}
                </p>
              </a>

              <p>
                {{similar.similarity}}% similar
              </p>
            </div>
          </div>

        </ng-container>


      </div>

    </div>

  </div>
</div>