<div class="py-2 d-flex flex-column flex-md-row">
  <button
    nbButton
    status="primary"
    size="medium"
    outline
    class="mx-2 my-2 btn-circle btn-xl btn-main"
    shape="round"
    (click)="createInvoice(false)"
  >
    <nb-icon icon="plus"></nb-icon>
    Create Invoice
  </button>
  <button
    nbButton
    status="primary"
    size="medium"
    outline
      class="mx-2 my-2 btn-circle btn-xl btn-main"
    shape="round"
    (click)="createInvoice(true)"
  >
    <nb-icon icon="plus"></nb-icon>
    Create Recurring Invoice
  </button>
</div>
<app-invoices-table
  #invoiceTable
  [loadInvoices]="loadInvoices"
  [recurrent]="false"
  (onViewEdit)="onViewEdit($event, false)"
  [sendInvoice]="sendInvoice"
  [paymentStatus]="paymentStatus$"
  [deleteInvoice]="deleteInvoice"
></app-invoices-table>
<app-invoices-table
  #recurrentTable
  [loadInvoices]="loadInvoices"
  [recurrent]="true"
  (onViewEdit)="onViewEdit($event, true)"
  [deleteInvoice]="deleteInvoice"
></app-invoices-table>
