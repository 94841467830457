<ng-template #currentTimeMarkerTemplate>
    <div class="cal-current-time-marker"></div>
  </ng-template>
  <nb-card
    class="nb_card_pop_up full_pop_up meal_item mobilePopup"
    style="width: 1200px; max-width: 95vw !important"
  >
    <nb-card-header class="d-flex align-items-center title">
      <div class="title">
        Food Diary for {{day.toDateString()}}
      </div>
      <button
        nbButton
        type="button"
        static="basic"
        ghost
        shape="round"
        size="small"
        class="ml-auto"
        (click)="onClose()"
      >
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
  
    <nb-card-body>
    
        <ngx-food-diary-app [patientId]="patientId" [userId]="userId" [day]="day" [readOnly]="true"></ngx-food-diary-app>

    </nb-card-body>
    <nb-card-footer></nb-card-footer>
  </nb-card>
  