import { ChatThread } from 'app/@chat/domain';
import { Observable } from 'rxjs';

import { Goal } from './goals';
import { BaseFilter, GridData } from '../grid-data';
import { Settings } from '../settings';
import { PatientWithInvoiceNumber } from './invoices';

export abstract class PatientData {
  abstract list(filter: PatientFilter): Observable<GridData<Patient>>; // Created by Milos
  abstract listWithCompliance(filter: PatientFilter): Observable<GridData<Patient>>; // * Created by Intel
  abstract create(patient: Patient): Observable<Patient>;
  abstract get(id: number, guid: string): Observable<Patient>;
  abstract getSettings(id: number, guid: string): Observable<Settings>; // Created by Vinicius
  abstract getDemoPatient(): Observable<Patient>; // Reviewed by Milos
  abstract update(patient: Patient, guid?: string): Observable<Patient>; // Reviewed by Vinicius
  abstract delete(id: number): Observable<boolean>; // Reviewed by Milos
  abstract archive(id: number): Observable<boolean>; // Reviewed by Milos
  abstract unarchive(id: number): Observable<boolean>; // Reviewed by Milos
  abstract getActiveCount(): Observable<number>; // Reviewed by Milos
  abstract getCalendarOverrides(id: number, guid: string): Observable<CalendarOverrideItem[]>;
  abstract setCalendarOverrides(
    id: number,
    overrides: CalendarOverrideItem,
    guid: string
  ): Observable<CalendarOverrideItem[]>;
  abstract getPatientSubscriptionItems(patientId: number): Observable<any>;

  abstract getAllClientsWithInvoiceNumber(): Observable<PatientWithInvoiceNumber[]>

  abstract setPatientUpdated(id: number);
  abstract onUpdated$: Observable<number>;
}


export enum PatientProgramViewType {
  full = 0,
  currentPhase = 1,
  fourWeekFuture = 2,
  eightWeekFuture = 3,
  twelveWeekFuture = 4,
}

interface UserForPatient {
  firstName: string;
  lastName: string;
  email: string;
}
export interface Patient {
  createdByUser: UserForPatient;
  createdByUserId: number;
  id: number;
  firstName: string;
  lastName: string;
  eMail: string;
  dateOfBirth: Date;
  addressLine1: string;
  addressLine2: string;
  country: string;
  zipPostalCode: string;
  phoneNumber: string;
  height: string;
  weight: string;
  bodyFat: number;
  source: string;
  name: string;
  age: number;
  dateOfMeasure: Date;
  isPreferredUnitImperial: boolean;
  genderFemale: boolean;
  goalDefs: Array<Goal>;
  updateGeneralInfo: boolean;
  updateBiometrics: boolean;
  isArchived: boolean;
  plansCount: number;
  goalsCount: number;
  patientGuid: string;
  isDemoPatient: boolean;
  createdDate: Date;
  lastTimeSeen: string;
  fitnessProgramMode: number;
  mealProgramMode: number;
  fitnessProgramStartDate: Date;
  mealProgramStartDate: Date;
  calendarOverrides: any;
  programViewType: PatientProgramViewType;

  workoutCompliance7: number;
  diaryCompliance7: number;
  workoutCompliance30: number;
  diaryCompliance30: number;

  enableMacroGoals: boolean;
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
  autoFillFromPlan: boolean;

  conversionFactorPreference: string;
  checkInRequest: boolean;
}

export interface MacroGoals {
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
}
export interface PatientWithChatThread extends Patient{
  chatThread: ChatThread
}
export interface CalendarOverrideItem {
  SourceDay: Date;
  IsFitnessPlan: boolean;
  PlanDayId: number;
  DestDay: Date;
}

export interface PatientFilter extends BaseFilter {
  nameAndEmail?: string;
  isArchived?: boolean;
  isDemo?: boolean;
}
