<nb-card>
  
  <nb-card-header>
    <!-- Title -->
    <div class="title">Subscriptions</div>
    <div class="actions align-items-center">
      <!-- Search -->
      <nb-form-field class="search-field">
        <input
        nbInput
        (change)="onSearchChanged($event)"
        shape="semi-round"
        fieldSize="small"
        class="search"
        placeholder="Search"
        i18n-placeholder="@@SearchThreeDots"
      />
        <nb-icon nbSuffix icon="search-outline" pack="eva"></nb-icon>
      </nb-form-field>
      <!-- Add -->
 
    </div>
  </nb-card-header>




  <nb-card-body>
   

    <ng-container *ngIf="subscriptions | async as subscriptions">
      <ngx-skeleton-item-list *ngIf="!contentLoaded"></ngx-skeleton-item-list>
      <mat-table
        [dataSource]="subscriptions?.items || []"
        matSort
        [matSortDisableClear]="true"
        (matSortChange)="onSortChanged($event)"
        [class.hidden]="!contentLoaded"
      >
        <ng-container matColumnDef="subscriptionId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Subscription </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="subscriptionId">
            {{ row.subscription.invoiceItems[0].itemDescription}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="patientId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Client </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="PatientId">
            {{row.patient.firstName}}  {{row.patient.lastName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> StartDate </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="StartDate">
            {{row.startDate | date : 'MMM d, y'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Status">
            {{row.status}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="stripeSessionId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> StripeSessionId </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="StripeSessionId">
            {{row.stripeSessionId}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="d-none d-sm-flex"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item (click)="onCancel(row.id)" [disabled]="!row.cancelable">
                <mat-icon>unsubscribe</mat-icon>Cancel
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        matPaginator
        [length]="subscriptions?.totalCount"
        [pageSize]="5"
        [pageSizeOptions]="[2, 5, 10, 25, 50]"
        (page)="onPagingChanged($event)"
      ></mat-paginator>
    </ng-container>
  </nb-card-body>
  <nb-card-footer>
    <div class="float-right d-flex align-items-center footer-box">
      <nb-checkbox i18n="@@ShowArchived" status="info" class="archived mr-3"(checkedChange)="onShowCanceledChanged($event)" >
        Show Cancelled</nb-checkbox>

        <!--<button  nbButton type="submit"  (click)="openExpressLink.emit()"
          class="btn-circle btn-xl btn-main btn-circle btn-xl">View in stripe dashboard</button>-->
    </div>
  </nb-card-footer>
</nb-card>
