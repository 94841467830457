<div *ngIf="showHelpMessage && !isProgramTemplate" class="message-label" style="text-decoration: none;">No events planned for this month! Go to Workout/Meal Plans to plan events and make sure to toggle "Program Mode" to "On".</div>

<div [nbSpinner]="isLoading" style="position:relative;">
  <div style="min-height:60px;">
    <ngx-patient-calendar-header [(view)]="view" [(viewDate)]="viewDate">
    </ngx-patient-calendar-header>
  </div>
  <div class="d-flex justify-content-center p-2" style="gap: 1rem" *ngIf="!isProgramTemplate">
    <div>
      <div class="text-center">30 Days<br>Compliance</div>
      <div class="d-flex justify-content-center">
        <app-compliance-gauge [value]="monthCompliance[0] | async" icon="dumbbell" size="70"></app-compliance-gauge>
        <app-compliance-gauge [value]="monthCompliance[1] | async" icon="utensils" size="70"></app-compliance-gauge>
      </div>
    </div>
    <div>
      <div class="text-center">7 Days<br>Compliance</div>
      <div class="d-flex justify-content-center">
        <app-compliance-gauge [value]="rollingSevenDaysCompliance[0] | async" icon="dumbbell" size="70"></app-compliance-gauge>
        <app-compliance-gauge [value]="rollingSevenDaysCompliance[1] | async" icon="utensils" size="70"></app-compliance-gauge>
      </div>
    </div>
  </div>
  <ng-template #customOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">
    <!-- The [@collapse] is causing the error. -->
    <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
      <div *ngFor="let event of events" [ngClass]="event?.cssClass" class="menuItem">
        <div mwlDraggable [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active"
          [dropData]="{ event: event }" [dragAxis]="{ x: 0, y: 0 }" [validateDrag]="validateDrag"
          [touchStartLongPress]="{ delay: 300, delta: 30 }">
          <span class="fas fa-dumbbell" *ngIf="!event.meta.isMealPlan"></span>
          <span class="fas fa-utensils" *ngIf="event.meta.isMealPlan"></span>
          &nbsp;
          <span class="cal-event-title" [innerHTML]="event.title | calendarEventTitle: view:event"
            [attr.aria-hidden]="{} | calendarA11y: 'hideEventTitle'" (click)="onEventClicked(event)">
          </span>
          <ng-container *ngIf="
              event.meta.workoutStatus == 'completed' ||
              event.meta.workoutStatus == WorkoutStatus.Completed
            ">
            <span class="far fa-check-circle icon-color ml-1"></span>
            <a class="text-light mx-1" (click)="viewWorkoutLog(event.meta.workoutId)">Completed! - View Log</a>
          </ng-container>
          <ng-container *ngIf="
            event.meta.diaryCompleted
          ">
            <span class="far fa-check-circle icon-color ml-1"></span>
            <a class="text-light mx-1" (click)="viewFoodDiaryLog(event.start)">Completed! - View Log</a>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #customTemplate let-day="day" let-openDay="openDay" let-locale="locale"
    let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
    let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
    let-tooltipDelay="tooltipDelay" let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
    <div mwlDroppable (drop)="mouseDate(day)" class="cal-cell-top" [attr.aria-label]="
        { day: day, locale: locale } | calendarA11y: 'monthCell'
      ">
      <span aria-hidden="true">

        <span class="cal-day-number">{{
          day.date | calendarDate: 'monthViewDayNumber':locale
          }}</span>
      </span>
    </div>
    <div class="cal-events" *ngIf="day.events.length > 0">
      <div class="" *ngFor="let event of day.events; trackBy: trackByEventId" [ngStyle]="" [ngClass]=""
        (mouseenter)="highlightDay.emit({ event: event })" (mouseleave)="unhighlightDay.emit({ event: event })"
        [mwlCalendarTooltip]="
          event.title | calendarEventTitle: 'monthTooltip':event
        " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" mwlDraggable
        [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active"
        [dropData]="{ event: event, draggedFrom: day }"
        [dragAxis]="{ x: event.draggable && !event.meta.completed, y: event.draggable && !event.meta.completed }"
        [validateDrag]="validateDrag" [touchStartLongPress]="{ delay: 300, delta: 30 }"
        [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'">
        <div style="margin-left: 5px">

          <span class="fas fa-dumbbell icon-color-alternate  icon-size margin-right-check" [class.icon-stroke]="!isDateSmaller(today, event.start)"
            *ngIf="!event.meta.isMealPlan && !event.meta.unplanned && !event.meta.completed">
            <span *ngIf="isDateSmaller(today, event.start)"
              class="far fa-times-circle icon-color-alternate check-small undone"></span>
          </span>
          <span class="fas fa-utensils icon-color-alternate  icon-size margin-right-check" [class.icon-stroke]="!isDateSmaller(today, event.start)"
            *ngIf="event.meta.isMealPlan && !event.meta.unplanned && !event.meta.completed">
            <span *ngIf="isDateSmaller(today, event.start)"
              class="far fa-times-circle icon-color-alternate check-small undone"></span>
          </span>

          <span *ngIf="
            event.meta.workoutStatus == WorkoutStatus.Completed ||
            event.meta.workoutStatus == 'completed'
          " class="fas fa-dumbbell icon-size  icon-color ml-1 mt-3 align-middle margin-right-check">
            <span class="far fa-check-circle icon-color check-small"></span>
          </span>
          <span *ngIf="event.meta.diaryCompleted"
            class="fas fa-utensils icon-size icon-color ml-1 mt-3 align-middle margin-right-check">
            <span class="far fa-check-circle icon-color check-small"></span>
          </span>


        </div>
      </div>
    </div>
  </ng-template>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view id="calMonthView" *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events"
      [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="onDayClicked($event.day)"
      (eventClicked)="onEventClicked($event.event)" [cellTemplate]="customTemplate"
      [openDayEventsTemplate]="customOpenDayEventsTemplate" mwlDroppable dragOverClass="cal-drag-over" [day]="day"
      [refresh]="refreshCalendar" (drop)="eventDropped($event.dropData.event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [hourSegments]="0"
      [minimumEventHeight]="minimumEventHeight" [currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
      (eventClicked)="onEventClicked($event.event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [hourSegments]="0"
      [minimumEventHeight]="minimumEventHeight" [currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
      (eventClicked)="onEventClicked($event.event)">
    </mwl-calendar-day-view>
  </div>


<div>
  <div style="margin-top: 10px;"> </div>
  <div class="ledgend-item">
    <span class="fas fa-utensils icon-size icon-color align-middle"><span
        class="far fa-check-circle icon-color check-small"></span> </span>

    <span class="ledgend-text">&emsp; Food Diary Completed</span>
  </div>



  <div class="ledgend-item">
    <span class="fas fa-utensils icon-size icon-color align-middle"><span
        class="far fa-times-circle icon-color-alternate check-small undone"></span> </span>
    <span class="ledgend-text">&emsp; Meal Plan scheduled - Diary Uncompleted</span>
  </div>



  <div class="ledgend-item">
    <span class="fas fa-dumbbell icon-size icon-color align-middle"><span
        class="far fa-check-circle icon-color check-small"></span></span>
    <span class="ledgend-text">&emsp; Workout Completed</span>
  </div>


  <div class="ledgend-item">
    <span class="fas fa-dumbbell icon-size icon-color align-middle"><span
        class="far fa-times-circle icon-color-alternate check-small undone"></span></span>
    <span class="ledgend-text">&emsp; Workout Scheduled - Uncompleted</span>
  </div>



  <div class="ledgend-item">
    <span class="fas fa-dumbbell icon-color-alternate icon-size icon-stroke" style="margin-right: 5px !important;"></span>
    <span class="ledgend-text">&emsp; Workout Scheduled (future)</span>

  </div>

</div>
</div>

<ng-template #complianceGauge let-value>
  <mwl-gauge
    [max]="100"
    [dialStartAngle]="-90"
    [dialEndAngle]="-90.001"
    [value]="value * 100"
    [animated]="true"
    [animationDuration]="1"
    [label]="gaugeLabel"
    class="mx-2"
  ></mwl-gauge>
</ng-template>
