import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";

@Injectable()
export class MacroGoalsApi {
  protected readonly apiController: string = 'macro-goals';

  constructor(private api: HttpService) {}

  /**
   * Get Current Plan (Today or Active)
   * @param patientId Identifier of Patient (Number)
   * @returns Current meal plan of correspondinng patient.
   */
  getAutoMacroGoals(patientId: number) {
    return this.api.get(`${this.apiController}/auto/${patientId}`);
  }
}
