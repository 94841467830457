import { Observable } from 'rxjs';

import { BaseFilter, GridData } from '../grid-data';

export abstract class AssessmentData {
  abstract list(filter: AssessmentFilter): Observable<GridData<AssessmentView>>; // Updated by Vinicius
  abstract AllAssessmentsList(
    patientId: number,
    NotificationToken: string,
  ): Observable<GridData<AssessmentView>>;
  abstract create(goal: any): Observable<Assessment>;
  abstract getByNotification(
    id: number,
    notificationToken: any,
  ): Observable<Assessment>;
  abstract get(id: number): Observable<Assessment>;
  abstract delete(id: number): Observable<boolean>;
  abstract update(order: any): Observable<Assessment>;
  abstract submit(order: any): Observable<Assessment>;
}

export interface Assessment {
  patientName: string;
  id: number;
  guid: string;
  patientID: number;

  height: number;
  weight: number;
  bodyFat: number;

  mealPlanCompliance: number;
  energyLevel: number;
  stressLevel: number;
  sleepQuality: number;

  comment: string;

  submittedByPatient: boolean;

  startDate: Date;
  endDate: Date;

  startDateString: string;
  endDateString: string;

  fileProgressImage?: File;
  fileProgressImageLeft?: File;
  fileProgressImageRight?: File;
  progressImageUrl: string;
  progressImageLeftUrl: string;
  progressImageRightUrl: string;
  answersJson: string;
}

export interface AssessmentView {
  patientName: string;
  id: number;
  guid: string;

  height: string;
  weight: number;
  bodyFat: number;

  mealPlanCompliance: number;
  energyLevel: number;
  stressLevel: number;
  sleepQuality: number;

  comment: string;

  submittedByPatient: boolean;

  startDate: string;
  endDate: string;

  startDateString: string;
  endDateString: string;

  progressImageUrl: string;
  progressImageLeftUrl: string;
  progressImageRightUrl: string;
  answersJson: string;
}

// Created by Vinicius
export interface AssessmentFilter extends BaseFilter {
  patientId?: number;
  patientGuid?: string;
}

// Created by Vinicius
export const MEAL_PLAN_COMPILANCE = {
  '1': $localize`:@@MealPlanComplianceValue1:Did not comply with meal plan.`,
  '2': $localize`:@@MealPlanComplianceValue2:Partially complied with meal plan.`,
  '3': $localize`:@@MealPlanComplianceValue3:Complied with about half of meal plan.`,
  '4': $localize`:@@MealPlanComplianceValue4:Mostly complied with meal plan.`,
  '5': $localize`:@@MealPlanComplianceValue5:Fully complied with meal plan.`,
};

// Created by Vinicius
export const ENERGY_LEVEL = {
  '1': $localize`:@@EnergyLevelValue1:No energy.`,
  '2': $localize`:@@EnergyLevelValue2:Lower energy than usual.`,
  '3': $localize`:@@EnergyLevelValue3:Average energy level.`,
  '4': $localize`:@@EnergyLevelValue4:More energy than usual.`,
  '5': $localize`:@@EnergyLevelValue5:High-energy level.`,
};

// Created by Vinicius
export const STRESS_LEVEL = {
  '1': $localize`:@@StressLevelValue1:Very stressed.`,
  '2': $localize`:@@StressLevelValue2:More stress than usual.`,
  '3': $localize`:@@StressLevelValue3:About the same stress as usual.`,
  '4': $localize`:@@StressLevelValue4:Lower stress than usual.`,
  '5': $localize`:@@StressLevelValue5:No stress at all.`,
};

// Created by Vinicius
export const SLEEP_QUALITY = {
  '1': $localize`:@@SleepQualityValue1:Insomnia/No sleep at all.`,
  '2': $localize`:@@SleepQualityValue2:Lower quality of sleep than usual.`,
  '3': $localize`:@@SleepQualityValue3:About the same sleep quality as usual.`,
  '4': $localize`:@@SleepQualityValue4:Better quality of sleep than usual.`,
  '5': $localize`:@@SleepQualityValue5:Great quality/Restful sleep.`,
};
