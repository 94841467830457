import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbThemeModule,
  NbAlertModule,
  NbCardModule,
  NbBadgeModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import { AuthModule } from '../@auth/auth.module';

import {
  FooterComponent,
  HeaderComponent,
  FrontendFooterComponent,
  FrontendHeaderComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  MeasureConverterPipe,
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  GramConverterPipe,
  ConvertToGramPipe,
} from './pipes';
import {
  FrontendLayoutComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { InitUserService } from './services/init-user.service';

import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { VIRGIN_THEME } from './styles/theme.virgin';
import { FacebookPixelService } from './services/FacebookPixelService';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RoleProvider } from 'app/@auth/role.provider';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbEvaIconsModule,
  NbCardModule,
  NbAlertModule,
  NbBadgeModule,
  NbUserModule,
  NbActionsModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  FrontendHeaderComponent,
  FrontendFooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  FrontendLayoutComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];
const PIPES = [
  CapitalizePipe,
  MeasureConverterPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  GramConverterPipe,
  ConvertToGramPipe,
];

@NgModule({
  imports: [CommonModule, AuthModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [
            DEFAULT_THEME,
            COSMIC_THEME,
            CORPORATE_THEME,
            DARK_THEME,
            VIRGIN_THEME,
          ],
        ).providers,
        InitUserService,
        FacebookPixelService,
        CookieService,
        DeviceDetectorService,
        RoleProvider,
      ],
    };
  }
}
