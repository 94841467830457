import {
  ChangeDetectorRef,
  EventEmitter,
  Output,
  ViewChild,
  Component,
  Input,
  forwardRef,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * The component for text in a smart way.
 * @usageNotes
 * Instead of showing the large text boxes that uses a lot of screen space,
 * it shows abbreviation of text and edit icon.
 * And edit box is shown only when edit icon is clicked.
 */
@Component({
  selector: 'smart-edit',
  templateUrl: './smart-edit.component.html',
  styleUrls: ['./smart-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartEditComponent),
      multi: true,
    },
  ],
})
export class SmartEditComponent implements ControlValueAccessor, AfterViewInit {
  constructor(private cdRef: ChangeDetectorRef) { }

  formattedValue() {
    return this.value ? this.value.replace(/(?:\r\n|\r|\n)/g, '<br>') : this.value;
  }

  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  @Output() customfocusout: EventEmitter<void> = new EventEmitter();

  @Input()
  get value() {
    return this._value;
  }

  @Input()
  readonly;

  set value(val) {
    this._value = val;

    if (this.onChange) this.onChange(val);
    if (this.onTouched) this.onTouched();
  }

  @Input() formControlName: any;
  @Input() placeholder: any;
  @Input() status: any;

  @Input('DisplaySecond') DisplaySecond: boolean = false;
  @Input('SecondDisplayCallback') SecondDisplayCallback = null;
  @Input('CallbackThis') CallbackThis = null;
  @Input('IsMultiLine') IsMultiLine: boolean = false;
  @Input('MultiLineWidth') MultiLineWidth = null;
  @Input('MultiLineHeight') MultiLineHeight = null;
  @Input('MinLength') MinLength = null;
  @Input('MaxLength') MaxLength = null;
  @Input('OpenByDefault') OpenByDefault = false;

  public _value: string;
  public _SecondValue: string;

  /**
   * Input control to edit
   */
  @ViewChild('input') inputControl: ElementRef;
  @ViewChild('input2') inputControl2: ElementRef;

  textLength: number = 15;

  hideLabel: boolean = false;
  hideEdit: boolean = true;

  onChange?: any;
  onTouched?: any;

  /**
   * Implements ControlValueAccessor's registerOnChange
   * @param fn
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Implements ControlValueAccessor's writeValue
   * @param fn
   */
  writeValue(value) {
    if (value) {
      this.value = value;
      this.textLength = this.value?.length >= 10 ? this.value.length + 5 : 15;
    }
  }

  /**
   * Implements ControlValueAccessor's registerOnTouched
   * @param fn
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * 'Change' event handler of input controls
   */
  onValuesChange() {
    if (this.onChange) this.onChange(this.value);

    this.change.emit(this.value);
  }

  /**
   * Input event handler for <input> element
   */
  onInput() {
    this.textLength = this.value?.length >= 10 ? this.value?.length + 5 : 15;
  }

  onKeyup(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.inputControl.nativeElement.blur();
    }
  }

  /**
   * Input event handler for <textarea> element
   */
  onTextareaInput() {
    const element = this.inputControl2.nativeElement;
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 'px';
    element.style.width = '100%';
  }

  onFocusout() {
    this.hideLabel = false;
    this.hideEdit = true;
    this.UpdateSecondValue();

    // emit the focusout event
    this.customfocusout.emit();
  }

  onClickEdit() {
    if (this.readonly) return;

    this.hideLabel = true;
    this.hideEdit = false;

    // initialize the correct width
    this.onInput();
    this.onValuesChange();
    const tempValue = this.value;
    this.value += ' ';

    setTimeout(() => {
      // this will make the execution after the above boolean has changed

      // return back
      this.value = tempValue;

      if (this.IsMultiLine) {
        this.inputControl2.nativeElement.focus();
        this.onTextareaInput();
      } else {
        this.inputControl.nativeElement.focus();
      }
    }, 0);
  }

  ngAfterViewInit(): void {
    this.UpdateSecondValue();
    if (this.OpenByDefault) {
      //this.onClickEdit();
    }

  }

  UpdateSecondValue() {
    if (this.DisplaySecond) {
      if (this.SecondDisplayCallback != null && this.CallbackThis != null) {
        const value = this.SecondDisplayCallback(
          this.value,
          this.CallbackThis
        );
        if(value) this._SecondValue = value;
      }
    }
  }
}
