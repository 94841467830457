import { Observable } from "rxjs";

export interface AppSettings {
    id: number;
    googleAndroidServiceJson: string;
    googleIosServiceJson: string;
    apkUrl: string;
    ipaUrl: string;
    abbUrl: string;
    userId: number;
    configuration: string | null;
}

export abstract class AppSettingsData {
    abstract get(id: number): Observable<AppSettings>;
    abstract update(appSetting: AppSettings): Observable<AppSettings>;
    abstract sendNotifications(deviceToken): Observable<any>;
    abstract runBuild(userId?: number): Observable<any>;
}
