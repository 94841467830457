import { GaugeModule } from 'angular-gauge';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/@components/material.module';
import {
  NbInputModule,
  NbCardModule,
  NbSelectModule,
  NbProgressBarModule,
  NbIconModule,
  NbButtonModule,
  NbLayoutModule,
  NbSpinnerModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbDatepickerModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbBadgeModule,
  NbToastrModule,
  NbToggleModule
} from '@nebular/theme';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { NgxValidationMessageComponent } from './validation-message/validation-message.component';
import { NgxFilterByNumberComponent } from './custom-smart-table-components/filter-by-number/filter-by-number.component';
import { NgxNumberFieldComponent } from './custom-smart-table-components/number-field/number-field.component';
import { NgxDateFieldComponent } from './custom-smart-table-components/date-field/date-field.component';
import { FeetAndInchComponent } from './feet-and-inch/feet-and-inch.component';
import { PoundsAndKgComponent } from './pounds-and-kg/pounds-and-kg.component';
import { SmartEditComponent } from './smart-edit/smart-edit.component';
import { GoalTypeFieldComponent } from './custom-smart-table-components/goaltype-field/goaltype-field.component';

import { ProgressbarComponent } from './progressbar/progressbar.component';
import { ButtonComponent } from './button/button.component';
import { TranslationExtractionComponent } from './TranslationExtraction/translation-extraction.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { SmartTextAreaComponent } from './smart-textarea/smart-textarea.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SmartTimeEditComponent } from './smart-time-edit/smart-time-edit.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ConfirmMessageDialogComponent } from './confirm-message-dialog/confirm-message-dialog.component';
import { SmallSmartEditComponent } from './small-smart-edit/small-smart-edit.component';
import { NgxInlineColorPickerComponent } from './inline-color-picker/inline-color-picker.component';
import { DayOfWeekCheckerComponent } from './day-of-week-checker/day-of-week-checker.component';
import { PatientCalendarHeaderComponent } from './patient-calendar/patient-calendar-header/patient-calendar-header.component';
import { PatientCalendarComponent } from './patient-calendar/patient-calendar.component';
import { RouterModule } from '@angular/router';
import { SkeletonUserListComponent } from './skeleton/skeleton-user-list/skeleton-user-list.component';
import { SkeletonItemListImageComponent } from './skeleton/skeleton-item-list-image/skeleton-item-list-image.component';
import { SkeletonItemListComponent } from './skeleton/skeleton-item-list/skeleton-item-list.component';
import { ComplianceGaugeComponent } from './compliance-gauge/compliance-gauge.component';
import { MacroGoalsProgressComponent } from './macro-goals-progress/macro-goals-progress.component';
import { InvoicesComponent } from './billing/invoices/invoices.component';
import { InvoicesTableComponent } from './billing/invoices/invoices-table/invoices-table.component';
import { InvoiceFormComponent, RecurringInvoiceFormComponent } from './billing/invoices/invoice-form/invoice-form.component';
import { InvoiceItemDialogComponent } from './billing/invoices/invoice-item-dialog/invoice-item-dialog.component';
import { InvoicePaymentStatusComponent } from './billing/invoices/invoice-payment-status/invoice-payment-status.component';
import { PatientSubscriptionsComponent } from './billing/patient-subscriptions/patient-subscriptions.component';
import { PatientSubscriptionsMetaTableComponent } from './billing/patient-subscriptions/patient-subscriptions-meta-table/patient-subscriptions-meta-table.component';
import { PatientSubscriptionsFormComponent } from './billing/patient-subscriptions/patient-subscriptions-form/patient-subscriptions-form.component';
import { PatientSubscriptionsTableComponent } from './billing/patient-subscriptions/patient-subscriptions-table/patient-subscriptions-table.component';
import { Patients4subscriptionComponent } from './billing/patient-subscriptions/patients4subscription/patients4subscription.component';
import { NgxFilterByBooleanComponent } from './custom-smart-table-components/filter-by-boolean/filter-by-boolean.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';

const NB_MODULES = [
  NbLayoutModule,
  NbCardModule,
  NbButtonModule,
  NbSelectModule,
  NbProgressBarModule,
  NbEvaIconsModule,
  NbIconModule,
  NbToastrModule,
  NbSpinnerModule,
  NbInputModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbDatepickerModule,
  NbBadgeModule,
  NbToggleModule,
];

const COMPONENTS = [
  NgxValidationMessageComponent,
  NgxFilterByNumberComponent,
  NgxFilterByBooleanComponent,
  NgxNumberFieldComponent,
  NgxDateFieldComponent,
  FeetAndInchComponent,
  PoundsAndKgComponent,
  GoalTypeFieldComponent,
  ProgressbarComponent,
  ButtonComponent,
  TranslationExtractionComponent,
  SmartEditComponent,
  SmallSmartEditComponent,
  SmartTextAreaComponent,
  SmartTimeEditComponent,
  CheckboxComponent,
  ConfirmMessageDialogComponent,
  NgxInlineColorPickerComponent,
  DayOfWeekCheckerComponent,
  PatientCalendarHeaderComponent,
  PatientCalendarComponent,
  SkeletonUserListComponent,
  SkeletonItemListComponent,
  SkeletonItemListImageComponent,
  ComplianceGaugeComponent,
  MacroGoalsProgressComponent,
  InvoicesComponent,
  InvoicesTableComponent,
  InvoiceFormComponent,
  RecurringInvoiceFormComponent,
  InvoiceItemDialogComponent,
  InvoicePaymentStatusComponent,
  PatientSubscriptionsComponent,
  PatientSubscriptionsMetaTableComponent,
  PatientSubscriptionsTableComponent,
  PatientSubscriptionsFormComponent,
  Patients4subscriptionComponent,
  ImageUploadComponent,
];

@NgModule({
  imports: [
  ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    //BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    GaugeModule,
    ...NB_MODULES,
    MaterialModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  entryComponents: [],
})
export class ComponentsModule {}
