<div>
  <table>
    <tr>
      <td *ngFor="let item of PlanItems; let i = index">
        <div class="container">
          <app-meal-item-presentation #MealItem [MealItem]="item" [UpdateCallBack]="Update">
          </app-meal-item-presentation>
        </div>
      </td>
    </tr>
  </table>
</div>
