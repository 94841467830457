import { Injectable } from "@angular/core";
import { MacroGoalsData } from "app/@core/interfaces/common/CalorieFriend/macro-goals";
import { MacroGoalsApi } from "../../api/CalorieFriend/macro-goals.api";

@Injectable()
export class MacroGoalsService extends MacroGoalsData {
  constructor(private api: MacroGoalsApi) {
    super();
  }

  getAutoMacroGoals(patientId: number) {
    return this.api.getAutoMacroGoals(patientId);
  }
}