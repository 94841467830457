import { Injectable } from "@angular/core";
import { WorkoutMode } from "./workout.service";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { scan, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class WorkoutTimerService {
    public workoutMode = new BehaviorSubject<WorkoutMode>(WorkoutMode.Start);
    public workoutTime = new BehaviorSubject<number>(0);
    private timerSubscription: Subscription;

    public start() {
        if (this.workoutMode.value === WorkoutMode.Start || this.workoutMode.value === WorkoutMode.Pause) {
            this.workoutMode.next(WorkoutMode.Workout);
            this.timerSubscription = interval(1000).pipe(
                tap(() => this.workoutTime.next(this.workoutTime.value + 1))
            ).subscribe();
        }
    }

    public pause() {
        if (this.workoutMode.value === WorkoutMode.Workout) {
            this.workoutMode.next(WorkoutMode.Pause);
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
        }
    }

    public resume() {
        if (this.workoutMode.value === WorkoutMode.Pause) {
            this.workoutMode.next(WorkoutMode.Workout);
            this.timerSubscription = interval(1000).pipe(
                tap(() => this.workoutTime.next(this.workoutTime.value + 1))
            ).subscribe();
        }
    }

    public reset() {
        this.workoutTime.next(0);
    }

    public setTime(time: number) {
        this.workoutTime.next(time);
    }

    public updateStatus(status: WorkoutMode) {
        this.workoutMode.next(status);
    }

    public formatTime(time: number): string {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
}
