/**
 * Updated by Vinicius
 */

import { HttpResponse } from '@angular/common/http';
import { ColorSetting } from 'app/@theme/styles/colors';
import { Observable } from 'rxjs';

export abstract class SettingsData {
  abstract getCurrent(): Observable<Settings>;
  abstract getUserSettings(id: number): Observable<Settings>;
  abstract updateUserSettings(settings: SettingsReqDTO): Observable<Settings>;
  abstract updateCurrent(
    setting: SettingsReqDTO,
  ): Observable<HttpResponse<Settings>>;
}

export interface Settings {
  id: number;
  themeName: string;
  logo?: string;
  color?: ColorSetting;
  appLogo?: string;
  appColor?: ColorSetting;
  appName?: string;
  playStoreUrl?: string;
  appStoreUrl?: string;
}

export interface SettingsReqDTO {
  id?: number;
  themeName?: string;
  logo?: File;
  color?: ColorSetting | string;
  appLogo?: File;
  appColor?: ColorSetting | string;
  appName?: string;
  appStoreUrl?: string;
  playStoreUrl?: string;
  splashScreenLogo?: File;
}
