import { Observable } from 'rxjs';

import { BaseFilter, GridData } from '../grid-data';

export abstract class ExerciseProgressData {
  abstract list(
    filter: ExerciseProgressFilter,
  ): Observable<GridData<ExerciseProgressGraphData>>;
  abstract getOneMaxRep(
    patientId: number,
    exerciseId: string,
  ): Observable<ExerciseProgress>;
  abstract changeOneMaxRep(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress>;
  abstract changeOneMaxRepByPatient(
    exerciseProgress: ExerciseProgress,
  ): Observable<ExerciseProgress>;
}

export interface ExerciseProgress {
  id: number;
  patientID: number;
  exerciceId: string;
  dateTime: Date;
  oneMaxRep: number;
  firstSetWeight: number;
  source: string;
}

export interface ExerciseProgressGraphData {
  id: number;
  patientID: number;
  exerciceId: string;
  exerciseName: string;
  lastDateTime: Date;
  lastOneMaxRep: number;
  lastFirstSetWeight: number;
  lastSource: string;
  progresses: ExerciseProgressHistoryItem[];
}

export interface ExerciseProgressHistoryItem {
  oneMaxRep: number;
  firstSetWeight: number;
}

export interface ExerciseProgressFilter extends BaseFilter {
  patientId?: number;
  patientGuid?: string;
}
