import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

/**
 * Injectable class for Hidden Similar API
 */
@Injectable()
export class HiddenSimilarFoodApi {
  /**
   * API Controller Name - 'CustomFood'
   */
  protected readonly apiController: string = 'HiddenSimilarFood';

  constructor(protected api: HttpService) { }

  /**
   * API function to show a similar food item.
   * @param SimilarFoodItem Similar Food Form data
   * Method: POST
   * URL: /show
   */
  show(SimilarFoodItems: any): Observable<any> {
    const form_data = JSON.stringify(SimilarFoodItems);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.api.post(`${this.apiController}/show`, form_data, httpOptions);
  }

  /**
   * API function to show a similar food item.
   * @param SimilarFoodItem Similar Food Form data
   * Method: POST
   * URL: /show
   */
  hide(SimilarFoodItems: any): Observable<any> {
    const form_data = JSON.stringify(SimilarFoodItems);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.api.post(`${this.apiController}/hide`, form_data, httpOptions);
  }
}


