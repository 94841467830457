import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Observable } from 'rxjs';

import { FitnessPlanDay, MealPlanDay } from './plan-day';
import { BaseFilter, GridData } from '../grid-data';
import { MacroGoals } from './patients';
import { ProgramPlans, ProgramTemplate } from './program-template';
import { SmartPlanTemplateGridFilter } from './mealTemplates';

export abstract class BasePlanData<T extends BasePlan> {
  abstract templateList(
    filter: BasePlanFilter
  ): Observable<GridData<TemplatePlan<T>>>; // Created by Vinicius
  abstract list(filter: SmartPlanTemplateGridFilter): Observable<GridData<TemplatePlan<T>>>;
  abstract get gridDataSource(): DataSource;
  abstract listById(
    patientId: number
  ): Observable<GridData<FitnessPlan | MealPlan>>; // Reviewed by Vinicius
  abstract listByGuid(patientGuid: string): Observable<GridData<T>>; // Reviewed by Vinicius
  abstract get GridPlanTemplateDataSource(): DataSource;
  abstract get(id: number): Observable<T>;
  abstract create(plan: T): Observable<T>; // Reviewed by Vinicius
  abstract update(
    plan: FitnessPlan | MealPlan
  ): Observable<FitnessPlan | MealPlan>; // Reviewed by Vinicius
  abstract duplicate(id: number): Observable<FitnessPlan | MealPlan>; // Reviewed by Vinicius
  abstract delete(id: number): Observable<boolean>; // Reviewed by Vinicius
  abstract CreateTemplate(Template: TemplatePlan<T>): Observable<TemplatePlan<T>>;
  abstract UpdateTemplate(Template: TemplatePlan<T>, nameOnly: boolean): Observable<boolean>;
  abstract GetTemplateById(id: number): Observable<TemplatePlan<T>>;
  abstract duplicateTemplate(templateId: number): Observable<boolean>; // Updated by Vinicius
  abstract deleteTemplate(templateId: number): Observable<boolean>; // Updated by Milos
  abstract SaveAsPDF(Id: number, PlanId: number): Observable<any>;
  abstract SaveAsPDFFromToken(
    TokenGuid: string,
    PlanId: number
  ): Observable<any>;
  abstract submitPatientNotification(
    id: number,
    planId: number,
    isProgram?: boolean
  ): Observable<boolean>;
  abstract submitPatientNotificationByGuid(
    token: string,
    planId: number,
    isProgram?: boolean
  ): Observable<any>;
  abstract activate(id: number, active: boolean): Observable<boolean>; // Reviewed by Vinicius
  abstract movePlan(
    patientId: number,
    planId: number,
    previous: number,
    next: number
  ): Observable<boolean>; // Reviewed by Vinicius
}

export abstract class MealPlanData extends BasePlanData<MealPlan> {
}

export abstract class ProgramTemplateData extends BasePlanData<ProgramPlans> {
  abstract AssignTemplate(Template: TemplatePlan<ProgramPlans>): Observable<boolean>;
}

export abstract class FitnessPlanData extends BasePlanData<FitnessPlan> {}

export const DEFAULT_DURATION = 2;

export interface BasePlan {
  id?: number;
  name?: string;
  description?: string;
  notes?: string;
  patientID?: number;
  createdDate?: string;

  zOrder?: number;
  active?: boolean;
  duration?: number;
}

export interface MealPlan extends BasePlan {
  days: Array<MealPlanDay>;
}

export interface FitnessPlan extends BasePlan {
  days: Array<FitnessPlanDay>;
}

export interface TemplatePlan<T> {
  id?: number; // Inserted by Vinicius
  name: string;
  description: string;
  thePlan?: T;
  programPlans?: T;
  isPublic: boolean;
}

export interface BasePlanFilter extends BaseFilter {
  publicOnly?: boolean;
  name?: string;
}