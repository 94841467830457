
<div class="container">

  <div class="row">


    <div style="margin-left:auto; margin-right:auto">
      <h2 class="title">Forgot Password?</h2>
      <p i18n="Reset Password Title@@ResetPasswordFullText" class="sub-title">Enter your email address and we’ll send
        a link to reset your password</p>
  
    </div>


  </div>
</div>


<nb-card class="nb-card-mobile nb-card-auth">
  <nb-card-body class="nb-card-body-mobile">
    <div class="row">

      <div style="width:100%; margin:auto">

 
        <div *ngIf="showMessages?.error && errors?.length && !submitted" class="message-label"
        style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
          <p class="alert-title"><b>Oh snap!</b></p>
            <div *ngFor="let error of errors" class="alert-message">{{ error }}</div>
        </div>

        <div *ngIf="showMessages?.success && messages?.length && !submitted" class="message-label"
        style="margin:15px; text-decoration: none !important; padding: 5pt !important;">
        <p class="alert-title"><b>Success!</b></p>
            <div *ngFor="let message of messages" class="alert-message">{{ message }}</div>
        </div>

        <div [formGroup]="requestPasswordForm" aria-labelledby="title" class="loginForm">

          <div class="form-control-group">
            <label i18n="@@EmailAddress" for="input-email">Email address:</label>
            <input nbInput autofocus fullWidth fieldSize="large" id="input-email" formControlName="email"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null" i18n-placeholder="@@EmailAddress"
              placeholder="Email address">
            <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
              [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
          </div>

          <button nbButton fullWidth  size="large" i18n="@@RequestPassword" class="btn-circle btn-xl btn-main"
            [disabled]="submitted || !requestPasswordForm.valid" [class.btn-pulse]="submitted" (click)="requestPass()">
            Request password
          </button>
          <section class="sign-in-or-up" aria-label="Sign in or sign up">
            <p><a i18n="@@BackToLogin" class="text-link" routerLink="../login">Back to Log In</a></p>
          </section>

        </div>

      </div>

    </div>

  </nb-card-body>
</nb-card>
