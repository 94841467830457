import { Component, OnInit } from '@angular/core';
import { User } from 'app/@core/interfaces/common/users';
import { UserStore } from 'app/@core/stores/user.store';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html'
})

export class OneColumnLayoutComponent implements OnInit {
  isMobile: boolean = false;
  readonly warningClosedCacheName = 'warning-closed';
  windowInnerHeight: number;



  public constructor(
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService,
    private userStore: UserStore,
    private router: Router,
  ) {
    this.isMobile = this.deviceService.isMobile();

  
  }
  ngOnInit(): void {
    this.windowInnerHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowInnerHeight = window.innerHeight;
    });
  }

  warningClosed() {
    return this.cookieService.get(this.warningClosedCacheName) === 'true';
  }

}
