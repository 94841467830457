import { Component, ViewChild } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-email-accessible-validation',
  templateUrl: './email-accessible-validation.component.html',
  styleUrls: ['./email-accessible-validation.component.scss'],
})
export class EmailAccessibleValidationComponent {
  @ViewChild('ConfirmNumber', { static: false }) ConfirmNumber?: any;

  eMail: string = null;
  SubmitEmailLabel = $localize`:@@NoSubmitEmailValidationFail:A problem occurred sending email with confirmation number. Please try again.`;
  ConfirmartionSuccess = $localize`:@@ConfirmartionSuccess:eMail confirmation has succeeded.`;
  ConfirmationNumber = null;

  constructor(
    protected ref: NbDialogRef<EmailAccessibleValidationComponent>,
    private toasterService: NbToastrService,
  ) {}

  SendConfirm() {}

  Validate() {
    if (this.ConfirmNumber.nativeElement.value === this.ConfirmationNumber) {
      this.toasterService.success('', this.ConfirmartionSuccess);
      this.ref.close(true);
    } else {
      this.toasterService.warning('', this.SubmitEmailLabel);
    }
  }
}
