import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { NbCalendarRange } from '@nebular/theme';
import {
  Invoice,
  InvoiceFilter,
  InvoicePayment,
  InvoicePaymentStatus,
  RecurringInvoice,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.scss'],
})
export class InvoicesTableComponent implements OnInit, AfterViewInit {
  get displayedColumns() {
    if (this.recurrent) {
      return ['customer', 'price', 'dayOfMonth', 'occurrence', 'actions'];
    }
    if (this.patientView) {
      return ['invoiceNumber', 'issueDate', 'price', 'paid'];
    }
    return ['invoiceNumber', 'customer', 'issueDate', 'price', 'status', 'paid', 'actions'];
  }

  @Input()
  loadInvoices: (filter: InvoiceFilter, recurrent?: boolean) => Observable<GridData<Invoice | RecurringInvoice>>;

  @Input()
  recurrent?: boolean = false;
  
  @Input()
  patientView?: boolean = false;

  @Input()
  deleteInvoice: (id: number, recurrent?: boolean) => Observable<boolean>;

  @Output('onViewEdit')
  viewEditEmitter: EventEmitter<{ id: number; editable?: boolean }> = new EventEmitter();

  @Input()
  sendInvoice: (id: number) => Observable<boolean>;

  @Input('paymentStatus')
  paymentStatus$: Observable<InvoicePaymentStatus>;

  invoices: Observable<GridData<Invoice | RecurringInvoice>> = of({
    totalCount: 0,
    items: [],
  });
  contentLoaded = false;
  paging: BehaviorSubject<PageEvent> = new BehaviorSubject({
    pageIndex: 0,
    pageSize: 5,
    length: 0,
  });
  sort: BehaviorSubject<Sort> = new BehaviorSubject({} as Sort);
  search: BehaviorSubject<string> = new BehaviorSubject('');
  range = new BehaviorSubject<NbCalendarRange<Date>>(null);

  invoicesSending = {};

  constructor() {}

  ngOnInit() {
    this.invoices = combineLatest([this.paging, this.sort, this.search, this.range]).pipe(
      flatMap(([paging, sort, search, range]) => {
        const filter: InvoiceFilter = {
          pageSize: paging.pageSize,
          pageNumber: paging.pageIndex + 1,
          sortBy: sort.active || '',
          orderBy: sort.direction || '',
          search,
        };
        if (range?.start) filter.startDate = range.start.toISOString();
        if (range?.end) filter.endDate = range.end.toISOString();
        this.contentLoaded = false;
        return this.loadInvoices(filter, this.recurrent).pipe(
          map((gridData) => {
            gridData.items = gridData.items.map((item) => ({
              ...item,
              ...this.getInvoiceActions(item),
            }));
            return gridData;
          })
        );
      })
    );
    this.invoices.subscribe({
      next: () => {
        this.contentLoaded = true;
      },
      error: () => {
        this.contentLoaded = true;
      },
    });
  }
  ngAfterViewInit(): void {}

  onFilterChanged() {}
  onPagingChanged(event: PageEvent) {
    this.paging.next(event);
  }
  onSortChanged(event: Sort) {
    this.sort.next(event);
  }
  onSearchChanged(event: InputEvent) {
    this.search.next((event.target as HTMLInputElement).value);
  }
  onRangeChanged(event: NbCalendarRange<Date>) {
    this.range.next(event);
  }
  onRangeInputChanged(event: InputEvent) {
    if ((event.target as HTMLInputElement).value == '') {
      this.onRangeChanged(null);
    }
  }

  onClickInvoice(id: number) {
    // TODO uncomment below if needed to view invoices when clicking entire row
    // this.viewEditEmitter.emit({ id });
  }

  onView(id: number) {
    this.viewEditEmitter.emit({ id });
  }
  onEdit(id: number) {
    this.viewEditEmitter.emit({ id, editable: true });
  }

  onSend(id: number) {
    if (this.sendInvoice) {
      this.invoicesSending = {
        ...this.invoicesSending,
        [id]: true,
      };
      this.sendInvoice(id).subscribe({
        next: (result) => {
          if (result) this.refresh();
          delete this.invoicesSending[id];
        },
        error: (err) => {
          delete this.invoicesSending[id];
        },
      });
    }
  }

  onDelete(id: number, event: MouseEvent) {
    event?.stopPropagation();
    this.deleteInvoice &&
      this.deleteInvoice(id, this.recurrent).subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.paging.next(this.paging.value);
  }

  /**
   * TODO update function below for further update.
   * @param invoice Invoice to get actions
   * @returns boolean values showing actions are enabled or not (edit, send, delete etc.)
   */
  private getInvoiceActions(invoice: Invoice | RecurringInvoice) {
    // recurrent invoice and un-paid invoices are deletable.
    const deletable = this.recurrent || !((invoice as Invoice).isPaid);
    const sendable = !this.recurrent;
    const editable = this.recurrent || !(invoice as Invoice).sent;
    return {
      deletable,
      sendable,
      editable,
    };
  }
  getInvoiceStatus(invoice: Invoice) {
    if (this.invoicesSending[invoice.id]) return 'sending';
    return invoice.isPaid ? 'completed' : invoice.sent ? 'sent' : 'draft';
  }
}
