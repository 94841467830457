<nb-card>
  <nb-card-header>
    <!-- Title -->
    <div class="title">Current Packages</div>
    <div class="actions">
      <!-- Search -->
      <nb-form-field class="search-field">
        <input
          nbInput
          (change)="onSearchChanged($event)"
          shape="semi-round"
          fieldSize="small"
          class="search"
          placeholder="Search"
          i18n-placeholder="@@SearchThreeDots"
        />
        <nb-icon nbSuffix icon="search-outline" pack="eva"></nb-icon>
      </nb-form-field>
      <!-- Add -->
    </div>
  </nb-card-header>

  <nb-card-body>
    <ng-container *ngIf="subscriptions | async as subscriptions">
      <ngx-skeleton-item-list *ngIf="!contentLoaded"></ngx-skeleton-item-list>
      <mat-table
        [dataSource]="subscriptions?.items || []"
        matSort
        [matSortDisableClear]="true"
        (matSortChange)="onSortChanged($event)"
        [class.hidden]="!contentLoaded"
      >
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="onEdit(row.id)">{{ row.invoiceItems[0].itemDescription }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="recurrence">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Recurrence </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Recurrence">
            <ng-container *ngIf="row.frequencyIntervalCount > 1"
              >Every {{ row.frequencyIntervalCount }} {{ row.frequencyInterval }}s</ng-container
            >
            <ng-container *ngIf="row.frequencyIntervalCount <= 1">Every {{ row.frequencyInterval }}</ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Amount">{{
            getTotalPriceOfInvoices(row?.invoiceItems) | currency : row.currency || 'CAD' : 'symbol' : '1.2-2'
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tax">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Tax </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Tax">
            <ng-container *ngIf="row.tax > 0">{{ row.tax }}%</ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="discount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Discount </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Discount">
            <ng-container *ngIf="row.discount > 0">{{ row.discount }}%</ng-container></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="d-none d-sm-flex"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Actions">
            <!-- <button nbButton size="tiny" status="primary" *ngIf="row.sendable" (click)="onSend(row.id)">Send</button>
            <button nbButton size="tiny" status="info" (click)="onView(row.id)">View</button> -->
            <!-- <button nbButton size="tiny"(click)="onEdit(row.id)" [disabled]="!row.editable">
              Edit
            </button> -->
            <div class="d-flex justify-content-end">
              <div class="action-btn">
                <button title="invite" class="btn-circle btn-xl btn-alternate" (click)="onSend(row.id)">
                  <span>Invite</span>
                </button>
              </div>
              <div class="action-btn">
                <button
                  i18n-title="@@Delete"
                  title="Delete"
                  class="btn-circle btn-xl btn-alternate animation-fill"
                  (click)="onCopy(row.subscriptionLink, copyText)"
                >
                  <span #copyText>Copy link</span>
                </button>
              </div>
              <div class="action-btn">
                <button title="copy" class="btn-circle btn-xl btn-alternate" (click)="onDelete(row.id)">
                  <nb-icon icon="trash-2-outline" i18n-title="@@Delete" title="Delete"></nb-icon>
                </button>
              </div>
            </div>
            <!-- <button nbButton size="tiny" status="warning" (click)="onDelete(row.id)" [disabled]="!row.deletable">
              Delete
            </button> -->
            <!-- <button mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item *ngIf="row.sendable" (click)="onSend(row.id)"><mat-icon>send</mat-icon>Send</button>
              <button mat-menu-item (click)="onView(row.id)"><mat-icon>visibility</mat-icon>View</button>
              <button mat-menu-item (click)="onEdit(row.id)" [disabled]="!row.editable">
                <mat-icon>edit</mat-icon>Edit
              </button>
              <button mat-menu-item (click)="onDelete(row.id)" [disabled]="!row.deletable">
                <mat-icon>delete</mat-icon>Delete
              </button>
            </mat-menu> -->
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        matPaginator
        [length]="subscriptions?.totalCount"
        [pageSize]="5"
        [pageSizeOptions]="[2, 5, 10, 25, 50]"
        (page)="onPagingChanged($event)"
      ></mat-paginator>
    </ng-container>
  </nb-card-body>
</nb-card>
