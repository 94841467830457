import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * The component for displaying validation messages for input controls
 */
@Component({
  selector: 'ngx-validation-message',
  styleUrls: ['./validation-message.component.scss'],
  template: `
    <div class="warning">
      <span
        class="caption status-danger"
        i18n="@@MinLengthWarning"
        *ngIf="showMinLength"
      >
        Min {{ label }} length is {{ minLength }} symbols
      </span>
      <span
        class="caption status-danger"
        i18n="@@MaxLengthWarning"
        *ngIf="showMaxLength"
      >
        Max {{ label }} length is {{ maxLength }} symbols
      </span>
      <span
        class="caption status-danger"
        i18n="@@IncorrectPatternWarning"
        *ngIf="showPattern"
      >
        Incorrect {{ label }}
      </span>
      <span
        class="caption status-danger"
        i18n="@@RequiredWarning"
        *ngIf="showRequired"
      >
        {{ label }} is required</span
      >
      <span
        class="caption status-danger"
        i18n="@@MinValueWarning"
        *ngIf="showMin"
        >Min value of {{ label }} is {{ min }}</span
      >
      <span
        class="caption status-danger"
        i18n="@@MaxValueWarning"
        *ngIf="showMax"
        >Max value of {{ label }} is {{ max }}</span
      >
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxValidationMessageComponent),
      multi: true,
    },
  ],
})
export class NgxValidationMessageComponent {
  @Input()
  label: string = '';

  @Input()
  showRequired?: boolean;

  @Input()
  min?: number;

  @Input()
  showMin?: boolean;

  @Input()
  max?: number;

  @Input()
  showMax: boolean;

  @Input()
  minLength?: number;

  @Input()
  showMinLength?: boolean;

  @Input()
  maxLength?: number;

  @Input()
  showMaxLength?: boolean;

  @Input()
  showPattern?: boolean;
}
