import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { ExerciseLog } from '../../../../interfaces/common/CalorieFriend/exerciseLog';

/**
 * Injectable class for ExerciseLog API
 */
@Injectable()
export class ExerciseLogApi {
  /**
   * API Controller Name - 'ExerciseLog'
   */
  private readonly apiController: string = 'ExerciseLog';

  constructor(private api: HttpService) {}

  /**
   * API function to get the biometric histories list of the patient given by {patientId} param or {NotificationToken} param in DataSource format which can be used for ng2 smart table.
   * @param patientId Identifier of the Patient in number format
   * @param notificationToken Identifier of the Patient in GUID format for anonymous users
   * Method: GET
   * URL: /listForPatient/${patientId} or /listForPatient/${notificationToken}
   */
  exerciseLogsList(
    patientId: number,
    fitnessItemId: number
  ): Observable<Array<ExerciseLog>> {
    return this.api.get(
      `${this.apiController}/graphData/${patientId}/${fitnessItemId}`
    ) as Observable<Array<ExerciseLog>>;
  }
  exerciseLogsListByExerciseId(patientId: number, exerciseId: string) {
    return this.api
      .get(`${this.apiController}/graphDataExercise/${patientId}/${exerciseId}`)
      .toPromise<ExerciseLog[]>();
  }
  getLastExerciseLog(fitnessItemId: number): Observable<any> {
    return this.api.get(
      `${this.apiController}/GetLastExerciseLog/${fitnessItemId}`
    );
  }

  /**
   * API function to add a new biometric history.
   * @param ExerciseLog Biometric History Form data
   * Method: POST
   * URL: /
   */
  add(ExerciseLog: any): Observable<any> {
    return this.api.post(this.apiController, ExerciseLog);
  }

  /**
   * API function to delete an existing biometric history.
   * @param id Identifier of the biometric history in number format
   * Method: POST
   * URL: /delete/${id}
   */
  delete(id: number): Observable<any> {
    return this.api.post(`${this.apiController}/delete/${id}`, null);
  }

  /**
   * API function to update an existing biometric history
   * @param ExerciseLog Biometric History Form data
   * Method: POST
   * URL: /edit
   */
  update(ExerciseLog: any): Observable<any> {
    return this.api.post(`${this.apiController}/edit`, ExerciseLog);
  }
}
