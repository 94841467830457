import { Pipe, PipeTransform } from '@angular/core';
import { Conversions } from 'app/@core/utils/conversionFactors.data';

@Pipe({name: 'gramConverter'})
export class GramConverterPipe implements PipeTransform {
  transform(value: number, unit: string, numberOnly = false): string| number {
    const conversionFactor = Conversions[unit];
    if (!conversionFactor) {
      if (!value) value = 0;
     return numberOnly? value.toFixed(2): `${value.toFixed(2)} g`;
    }
    return numberOnly? (value / conversionFactor).toFixed(2): `${(value / conversionFactor).toFixed(2)} ${unit}`;
  }
}