import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * The component for text in a smart way.
 * @usageNotes
 * Instead of showing the large text boxes that uses a lot of screen space,
 * it shows abbreviation of text and edit icon.
 * And edit box is shown only when edit icon is clicked.
 */
@Component({
  selector: 'smart-time-edit',
  templateUrl: './smart-time-edit.html',
  styleUrls: ['./smart-time-edit.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartTimeEditComponent),
      multi: true,
    },
  ],
})
export class SmartTimeEditComponent implements ControlValueAccessor {
  @Input()
  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;

    this.onChange(val);
    this.onTouched();
  }

  @Input() readonly: any;
  @Input() formControlName: any;
  @Input() placeholder: any;
  @Input() status: any;

  @ViewChild('Time') Time: any;

  public _value: string = '';
  public _SecondValue: string = '';

  /**
   * Input control to edit
   */
  @ViewChild('input') inputControl: ElementRef;

  hideLabel: boolean = false;
  hideEdit: boolean = true;

  onChange: any = () => {};
  onTouched: any = () => {};

  /**
   * Implements ControlValueAccessor's registerOnChange
   * @param fn
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Implements ControlValueAccessor's writeValue
   * @param fn
   */
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  /**
   * Implements ControlValueAccessor's registerOnTouched
   * @param fn
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * 'Change' event handler of input controls
   */
  onValuesChange() {
    this.onChange(this.value);
  }

  /**
   * 'onTimeSet' event handler of ngxTimePicker
   */
  onTimeChange(value) {
    this.value = value;
  }

  onFocusout() {
    this.hideLabel = false;
    this.hideEdit = true;
  }

  onClickEdit() {
    this.hideLabel = true;
    this.hideEdit = false;

    setTimeout(() => {
      // this will make the execution after the above boolean has changed
      this.inputControl.nativeElement.focus();
      this.inputControl.nativeElement.click();
    }, 0);
  }
}
