import { BehaviorSubject } from 'rxjs';
export interface IChatUser {
  id: number;
  uid: string;
  name: string;
  avatar?: string;
  active?: boolean;
  lastSeen?: string;
}
export interface IChatThreadUser extends IChatUser {}
export interface IChatThread {
  id: number;
  name: string;
  draftMessage?: string;
  //unSeenCount?: BehaviorSubject<number>;
  users: IChatThreadUser[];
}
export interface ChatThread extends IChatThread {
  unSeenCount: number;
  lastMessage: IChatMessage;
}


export interface IChatMessage {
  id?: number;
  threadId: number;
  dateTime: string;
  seen?: boolean;
  senderId: number;
  //  sender?: IChatUser;
  reply?: boolean;
  payloads: IChatPayload[];
}

type IChatPayloadType = 'Mannual' | 'File';
export interface IChatPayload {
  messageType: MessageTypeEnum;
  data: string;
}
export enum FromEnum {
  User = 0,
  Patient = 1,
}
export enum MessageTypeEnum {
  Mannual = 0,
  File = 1,
  Automated = 2,
  Workout = 3,
  MealPlan = 4,
  Goal = 5,
  Reminder = 6,
  Assessment = 7,
  Audio = 8
}
export interface IChatMessageDTO {
  id?: number;
  userId: number;
  patientId: number;
  messagePayloads: IChatMessagePayloadDTO[];
  dateTime: string;
  from: FromEnum; // 0 for user, 1 for patient
  seen: boolean;
}
export type IChatMessagePayloadDTO = IChatPayload;

export interface IUserConnectionInfo {
  userId: string;
  connectionIds: string[];
}
export interface IActiveUserConnections {
  [userId: string]: string[];
}
