<nb-card class="nb-card-mobile">
  <nb-card-body class="nb-card-body-mobile">
    <h1>Step 1: Create your account</h1>
    <div>

      <div class="loginForm">

        <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
          <p class="alert-title"><b>Oh snap!</b></p>
          <ul class="alert-message-list">
            <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
          </ul>
        </nb-alert>

        <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
          <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
          </ul>
        </nb-alert>

        <div [formGroup]="registerForm" aria-labelledby="title">
          <div class="form-control-group">
            <label i18n="@@EmailAddress" for="input-email">Email address:</label>
            <input nbInput fullWidth fieldSize="large" id="input-email" formControlName="email"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null" i18n-placeholder="@@EmailAddress"
              placeholder="Email address" (keyup)="EMAilChange($event)">
            <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
              [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
            <label i18n-placeholder="@@EmaiDuplicateWarning" style="color:red" [hidden]="HideDuplicateEmail">Email
              already exsits</label>
          </div>

          <div class="form-control-group">
            <label i18n="@@Password" for="input-password">Password</label>
            <input nbInput fullWidth fieldSize="large" id="input-password" formControlName="password" type="password"
              [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" i18n-placeholder="@@Password"
              placeholder="Password">
            <ngx-validation-message label="Password"
              [showMinLength]="password?.hasError('minlength') && password.touched"
              [showMaxLength]="password?.hasError('maxlength') && password.touched"
              [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
          </div>

          <div class="form-control-group">
            <label i18n="@@ConfirmPassword" for="input-re-password">Confirm Password</label>
            <input nbInput fullWidth fieldSize="large" id="input-re-password" formControlName="confirmPassword"
              type="password"
              [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
              i18n-placeholder="@@ConfirmPassword" placeholder="Confirm Password">
            <ngx-validation-message i18n-label="@@ConfirmPassword" label="Confirm Password"
              [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
              [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
              [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched" [minLength]="minLength"
              [maxLength]="maxLength"></ngx-validation-message>
            <p i18n="@@InvalidConfirmPassword" class="caption status-danger"
              *ngIf="password.value != confirmPassword.value">Password and confirm password does not match!</p>
          </div>

          <div class="form-control-group">
            <nb-checkbox #Aggreement name="termsandconditions" formControlName="terms" class="float-left"
              (click)="AcceptAgreement()"></nb-checkbox>
            <a i18n="@@AgreeTermsAndConditions" href="javascript:void(0)" class="condition-link"
              (click)='DisplayTermsAgreement()'> I agree to the Terms & Conditions</a>
          </div>

          <div class="form-control-group accept-group">
            <button i18n="@@Register" nbButton type="submit"  size="medium" class="btn-regsiter"
              [disabled]="EnableRegistration()" (click)="onRegister()">
              Next
            </button>
          </div>

        </div>

      </div>
    </div>
  </nb-card-body>
</nb-card>

<br />
<br />
<br />
<br />
