import { Component, Input, OnInit, OnDestroy, Optional } from '@angular/core';
import { NbDialogService, NbDialogRef, NbToastrService } from '@nebular/theme';
import {
  Invoice,
  InvoiceBase,
  InvoiceItem,
  PatientWithInvoiceNumber,
  RecurringInvoice,
} from 'app/@core/interfaces/common/CalorieFriend/invoices';
import { User } from 'app/@core/interfaces/common/users';
import { UserStore } from 'app/@core/stores/user.store';
import { InvoiceItemDialogComponent } from '../invoice-item-dialog/invoice-item-dialog.component';
import { PatientsService } from 'app/@core/backend/common/services/CalorieFriend/patients.service';
import { GridData } from 'app/@core/interfaces/common/grid-data';
import { Patient, PatientData } from 'app/@core/interfaces/common/CalorieFriend/patients';
import { Observable, Subscription, of } from 'rxjs';
import { InvoicesService } from 'app/@core/backend/common/services/CalorieFriend/invoices.service';
import { RecurringInvoicesService } from 'app/@core/backend/common/services/CalorieFriend/recurring-invoices.service';
import { switchMap, tap } from 'rxjs/operators';


abstract class InvoiceFormComponentBase implements OnInit, OnDestroy {
  @Input()
  invoiceId?: number;

  abstract get isRecurrent(): boolean;

  abstract isAutoSend: boolean;

  @Input('editable')
  editable: boolean = false;

  @Input()
  patientGuid?: string;
  get isPatientView() {
    return Boolean(this.patientGuid);
  }

  abstract getInvoice$(invoiceId: number): Observable<InvoiceBase>;

  abstract onInvoiceChange(invoice: InvoiceBase);
  abstract onAddClient(client: PatientWithInvoiceNumber);
  abstract getDialogRef(): NbDialogRef<any>;

  logo = 'assets/images/logo.png';
  user: User;

  get displayedColumns() {
    const columns = ['itemName', 'itemPrice', 'itemQTY', 'itemSubtotal'];
    if (this.editable) {
      columns.push('actions');
    }
    return columns;
  }

  invoice: Partial<InvoiceBase> = {};

  get invoiceAsAny() {
    return this.invoice as any;
  }

  get title() {
    if (!this.invoiceId) {
      return `Create new ${this.isRecurrent ? 'recurring' : ''} invoice`;
    }
    const action = this.editable ? 'Edit' : 'View';
    if (!this.isRecurrent) return `${action} Invoice - inv_${this.invoiceAsAny.invoiceNumber || ''}`;
    else return `${action} Recurring Invoice`;
  }

  allClients: Observable<PatientWithInvoiceNumber[]>;

  $subscription: Subscription;

  issueDate: Date = new Date();
  dueDate: Date = new Date();

  loading: boolean = false;
  saveSubscription$: Subscription;

  constructor(
    private userStore: UserStore,
    private patientsService: PatientData,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  ngOnDestroy(): void {
    this.$subscription?.unsubscribe();
  }

  ngOnInit() {
    this.logo = this.userStore.settings()?.logo;
    this.user = this.userStore.getUser();

    if(this.editable)
      this.allClients = this.patientsService.getAllClientsWithInvoiceNumber();
    else this.allClients = of([]);

    this.invoice = {
      invoiceItems: [],
    };
    if (this.invoiceId) {
      let invoice$: Observable<InvoiceBase> = this.getInvoice$(this.invoiceId);

      this.$subscription = invoice$.subscribe({
        next: (invoice) => {
          if (!invoice) {
            this.toastrService.danger(
              "An error happened while getting the invoice or maybe it doesn't exist",
              'Error occured'
            );
            this.getDialogRef()?.close();
          }
          this.invoice = invoice;
          this.user = this.invoice.createdByUser;
          this.onInvoiceChange(invoice);
        },
        error: (e) => {
          this.toastrService.danger(e.message);
          this.getDialogRef()?.close();
        },
      });
    }
  }

  addInvoiceItem() {
    this.dialogService
      .open(InvoiceItemDialogComponent, {
        context: {
          itemCurrency: this.invoice.currency || 'USD',
        },
      })
      .onClose.subscribe({
        next: (item) => {
          if (!item) return;
          const { itemName, itemPrice, itemCurrency } = item;
          this.invoice.currency = itemCurrency;
          this.invoice.invoiceItems = [
            ...this.invoice.invoiceItems,
            {
              itemDescription: itemName,
              itemPrice,
              itemCode: '',
            },
          ];
        },
      });
  }

  deleteInvoiceItem(item: InvoiceItem) {
    this.invoice.invoiceItems = this.invoice.invoiceItems.filter(
      (x) => x.itemDescription != item.itemDescription || x.itemPrice != item.itemPrice
    );
  }

  addClient(client: PatientWithInvoiceNumber) {
    this.invoice.patient = client;
    this.invoice.patientId = client.id;
    /**
     * set invoice number automatically only if not recurrent mode.
     */
    this.onAddClient(client);
  }

  get subTotal() {
    return this.invoice.invoiceItems.reduce((sum, cur) => sum + cur.itemPrice, 0);
  }
  get amountDue() {
    return (this.subTotal * (100 - (this.invoice.discount || 0)) / 100) * (100 + (this.invoice.tax || 0)) / 100;
  }

  onSaveInvoice() {
    let res: Observable<any>;
    if (!this.invoice.patientId) {
      this.toastrService.warning('', 'Please add a client');
      //alert("select patient Id")
      return;
    }

    this.loading = true;

    res = this.saveInvoice().pipe(
      tap((invoice) => {
        this.invoiceId = invoice.id;
      }),
      switchMap((invoice) => {
        if (this.isAutoSend) return this.sendInvoice();
        return of(invoice);
      })
    );

    this.saveSubscription$ = res.subscribe({
      next: (result) => {
        if (this.isAutoSend) {
          if (result) this.toastrService.success('', 'Successfully saved and sent!');
          else {
            this.toastrService.danger('', 'Sending failed!');
          }
        } else {
          this.toastrService.success('', 'Successfully saved!');
        }
        this.loading = false;
        this.getDialogRef()?.close(true);
        //alert("succeed");
      },
      error: (e) => {
        this.toastrService.danger('', 'Error occured while saving the invoice');
        this.loading = false;
      },
    });
  }
  abstract saveInvoice(): Observable<InvoiceBase>;
  abstract sendInvoice(): Observable<boolean>;

  onClose() {
    this.getDialogRef()?.close();
  }

  onCancel() {
    this.loading = false;
    if (this.saveSubscription$) this.saveSubscription$.unsubscribe();
  }
}

@Component({
  selector: 'app-recurring-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class RecurringInvoiceFormComponent extends InvoiceFormComponentBase implements OnInit, OnDestroy {
  constructor(
    userStore: UserStore,
    patientsService: PatientData,
    dialogService: NbDialogService,
    toastrService: NbToastrService,
    private invoiceService: RecurringInvoicesService,
    @Optional() private dialogRef: NbDialogRef<RecurringInvoiceFormComponent>
  ) {
    super(userStore, patientsService, dialogService, toastrService);
  }

  isAutoSend: boolean = false;
  invoice: Partial<RecurringInvoice>;

  get isRecurrent(): boolean {
    return true;
  }
  get canEdit(): boolean {
    return true;
  }
  getInvoice$(invoiceId: number): Observable<RecurringInvoice> {
    return this.invoiceService.get(invoiceId);
  }

  getDialogRef(): NbDialogRef<any> {
    return this.dialogRef;
  }

  onInvoiceChange(invoice: Invoice) {}
  onAddClient(client: PatientWithInvoiceNumber) {}
  saveInvoice(): Observable<InvoiceBase> {
    if (this.invoiceId) {
      return this.invoiceService.edit(this.invoice as RecurringInvoice);
    } else {
      return this.invoiceService.add(this.invoice as RecurringInvoice);
    }
  }
  sendInvoice(): Observable<boolean> {
    return of(true);
  }
}

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class InvoiceFormComponent extends InvoiceFormComponentBase implements OnInit, OnDestroy {
  constructor(
    userStore: UserStore,
    patientsService: PatientData,
    dialogService: NbDialogService,
    toastrService: NbToastrService,
    private invoiceService: InvoicesService,
    @Optional() private dialogRef: NbDialogRef<InvoiceFormComponent>
  ) {
    super(userStore, patientsService, dialogService, toastrService);
  }


  invoice: Partial<Invoice>;
  isAutoSend: boolean = true;


  get isRecurrent(): boolean {
    return false;
  }

  getInvoice$(invoiceId: number): Observable<Invoice> {
    return this.invoiceService.get(invoiceId, this.patientGuid);
  }
  getDialogRef(): NbDialogRef<any> {
    return this.dialogRef;
  }

  onInvoiceChange(invoice: Invoice) {
    this.issueDate = new Date(invoice.issueDate);
    this.dueDate = new Date(invoice.dueDate);
  }
  onAddClient(client: PatientWithInvoiceNumber) {
    this.invoice.invoiceNumber = client.invoiceNumber;
  }
  saveInvoice(): Observable<InvoiceBase> {
    this.invoice.issueDate = this.issueDate.toLocaleDateString();
    this.invoice.dueDate = this.dueDate.toLocaleDateString();

    if (this.invoiceId) {
      return this.invoiceService.edit(this.invoice as Invoice);
    } else {
      return this.invoiceService.add(this.invoice as Invoice);
    }
  }
  sendInvoice(): Observable<boolean> {
    return this.invoiceService.send(this.invoiceId);
  }
}