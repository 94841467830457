import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthService, NbAuthResult } from '@nebular/auth';
import { NbDialogService } from '@nebular/theme';

import { UserData } from 'app/@core/interfaces/common/users';
import { PendingUserService } from 'app/@core/backend/common/services/CalorieFriend/pendingUsers-service';
import { FacebookPixelService } from 'app/@theme/services/FacebookPixelService';
import { GoogleAnalyticsService } from 'google-analytics.service';
import { NgxRegisterComponent } from '../register.component';
import { PaymentApi } from 'app/@core/backend/common/api/CalorieFriend/payment.api';
import { InitUserService } from 'app/@theme/services/init-user.service';

declare var Stripe: any;

/**
 * Component for the register form
 */
@Component({
  selector: 'ngx-register-stripe',
  styleUrls: ['../register.component.scss'],
  templateUrl: './register-stripe.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterStripeComponent
  extends NgxRegisterComponent
  implements OnInit {
  stripeKey: string;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected facebookPixelService: FacebookPixelService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    private paymentApi: PaymentApi,
    _fb: FormBuilder,
    _route: ActivatedRoute,
    _dialogService: NbDialogService,
    _pendingUserService: PendingUserService,
    _usersService: UserData,
    initUserService: InitUserService,
  ) {
    super(
      service,
      options,
      cd,
      _fb,
      router,
      _route,
      _dialogService,
      _pendingUserService,
      _usersService,
      facebookPixelService,
      googleAnalyticsService,
      initUserService,
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.paymentApi.getStripeKey().subscribe((data) => {
      this.stripeKey = data.key;
    });
  }

  onRegister(): void {
    this.user = this.registerForm.value;

    // calorie friend has a plan type for user registration
    this.user.PlanCode = this.PlanCode;

    this.errors = this.messages = [];
    this.submitted = true;

    this.service
      .register(this.strategy, this.user)
      .subscribe((result: NbAuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.messages = result.getMessages();

          // fb pixel
          this.facebookPixelService.load(
            FacebookPixelService.CompleteRegistrationEvent,
          );

          // GA
          this.googleAnalyticsService.eventEmitter(
            FacebookPixelService.CompleteRegistrationEvent,
            'Users',
            'Register',
          );
        } else {
          this.errors = result.getErrors();
        }

        const stripe = Stripe(this.stripeKey);

        this.createCheckoutSession('price_1JwsO5E1rIOc7okVaeldgsGS').then(
          function (data) {
            // Call Stripe.js method to redirect to the new Checkout page
            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then((result) => {
                console.log(result);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.waiting = false;
              });
          },
        );

        this.cd.detectChanges();
      });
  }

  createCheckoutSession(priceId): Promise<any> {
    return this.paymentApi
      .createCheckoutSession(priceId, 'payment')
      .toPromise();
  }
}
