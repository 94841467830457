import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { StripeAccount } from 'app/@core/interfaces/common/stripe';
import { BehaviorSubject, Observable } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { PaymentSettingsApi } from '../../api/CalorieFriend/payment-settings.api';

@Injectable()
export class PaymentSettingsService {

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentAccount: BehaviorSubject<StripeAccount> = new BehaviorSubject(null);

  constructor(private api: PaymentSettingsApi) {
    this.getCurrentAccount();
  }
  public connectToStripe() {
    const refreshUrl = location.href;
    const returnUrl = `${location.origin}/pages/CalorieFriend/Billing`;
    const res = this.api.connectToStripe(refreshUrl, returnUrl);
    res.subscribe({
      next: ({ url }) => {
        location.href = url;
      },
    });
    return res;
  }

  public getCurrentAccount() {
    this.isLoading.next(true);
    this.api.currentAccount().subscribe({
      next: (account) => {
        this.isLoading.next(false);
        this.currentAccount.next(account);
      }
    });
  }

  public reset(): Observable<boolean> {
    return this.api.deleteSetting().pipe(
      tap(() => this.currentAccount.next(null)),
    );
  }

  public expressLogin(): Observable<any>{
    return this.api.expressLogin();
  }
}
