

import { EventEmitter, Output, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * The component for text in a smart way.
 * @usageNotes
 * Instead of showing the large text boxes that uses a lot of screen space,
 * it shows abbreviation of text and edit icon.
 * And edit box is shown only when edit icon is clicked.
 */
@Component({
  selector: 'small-smart-edit',
  templateUrl: './small-smart-edit.html',
  styleUrls: ['./small-smart-edit.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmallSmartEditComponent),
      multi: true,
    },
  ],
})

export class SmallSmartEditComponent implements ControlValueAccessor {
  formattedValue() {
    return this.value;
  }

  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  @Output() customfocusout: EventEmitter<void> = new EventEmitter();

  value

  @Input()
  readonly;


  @Input() formControlName: any;
  @Input() placeholder: any;
  @Input() status: any;

  @Input('DisplaySecond') DisplaySecond: boolean = false;
  @Input('SecondDisplayCallback') SecondDisplayCallback = null;
  @Input('CallbackThis') CallbackThis = null;
  @Input('IsMultiLine') IsMultiLine: boolean = false;
  @Input('MultiLineWidth') MultiLineWidth = null;
  @Input('MultiLineHeight') MultiLineHeight = null;
  @Input('MinLength') MinLength = null;
  @Input('MaxLength') MaxLength = null;
  @Input('InputType') InputType: string = 'text';


  /**
   * Input control to edit
   */
  @ViewChild('input') inputControl: ElementRef;

  hideLabel: boolean = false;
  hideEdit: boolean = true;

  onChange: any = () => { };
  onTouched: any = () => { };

  /**
   * Implements ControlValueAccessor's registerOnChange
   * @param fn
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Implements ControlValueAccessor's writeValue
   * @param fn
   */
  writeValue(value) {
    if (value) {
      this.value = value;

    }
  }

  /**
   * Implements ControlValueAccessor's registerOnTouched
   * @param fn
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
  * 'Change' event handler of input controls
  */
  onValuesChange() {
    this.onChange(this.value);

    this.change.emit(this.value);

    // setTimeout(() => { // this will make the execution after the above boolean has changed
    //   var textLength = this.value.length > 10 ? this.value.length : 10;
    //   // this.inputControl.nativeElement.style.width.ch = textLength;
    // }, 0);
  }

  /**
   * Input event handler for <input> element
   */
  onInput() {

  }

  onKeyup(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.inputControl.nativeElement.blur();
    }
  }

  onFocusout() {
    this.hideLabel = false;
    this.hideEdit = true;
    // emit the focusout event
    this.customfocusout.emit();
  }

  onClickEdit() {

    if (this.readonly)
      return;

    this.hideLabel = true;
    this.hideEdit = false;

    // initialize the correct width
    this.onInput();
    this.onValuesChange();
    const tempValue = this.value;
    this.value += ' ';

    setTimeout(() => { // this will make the execution after the above boolean has changed

      // return back
      this.value = tempValue;

      if (this.IsMultiLine) {
      } else {
        this.inputControl.nativeElement.focus();
      }
    }, 0);
  }

  ngAfterViewInit(): void {
   
  }
}
