<div [hidden]="!GraphInBack" class="metric_whole_graph">
  <div class="Metrics metrics-inner-wrapper" style="border:none">
    <div style="border:none">
      <div style="border:none">
        <ul class="Metrics" style="border:none">
          <li style="border:none" class="primary-color">
            <div style="border:none;" class="Metrics meal-and-percent">{{MealCals}} {{CalsLabel}}</div>
          </li>
          <li style="border:none" class="protein-color">
            <div style="border:none;" class="Metrics meal-and-percent">{{MealProtein}} g. ({{MealProteinPercent}} %)
              {{MealProteinLabel}}</div>
          </li>
          <li style="border:none" class="carbs-color">
            <div style="border:none;" class="Metrics meal-and-percent">{{MealCarbs}} g. ({{MealCarbsPercent}} %)
              {{MealCarbsLabel}}</div>
          </li>
          <li style="border:none" class="carbs-color">
            <div style="border:none;" class="Metrics meal-and-percent">{{MealNetCarbs}} g. {{MealNetCarbsLabel}}</div>
          </li>
          <li style="border:none" class="fats-color">
            <div style="border:none;" class="Metrics meal-and-percent">{{MealFats}} g. ({{MealFatsPercent}} %)
              {{MealFatsLabel}}</div>
          </li>
        </ul>
      </div>
      <div colspan="2" style="border:none;">
        <div class="emptyChart">

        <chart class="ChartLayout" type="pie" [data]="data" [options]="options" [style]="chartStyle"></chart>
     </div> </div>
    </div>
  </div>
</div>
