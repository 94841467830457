import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';

import { HttpService } from './api/http.service';
import { UserData } from '../../interfaces/common/users';
import { UsersService } from './services/users.service';
import { UsersApi } from './api/users.api';
import { CountryData } from '../../interfaces/common/countries';
import { CountriesService } from './services/countries.service';
import { CountriesApi } from './api/countries.api';
import { SettingsApi } from './api/settings.api';
import { SettingsData } from '../../interfaces/common/settings';
import { SettingsService } from './services/settings.service';

import { PatientData } from '../../interfaces/common/CalorieFriend/patients';
import { PatientsService } from './services/CalorieFriend/patients.service';
import { PatientsApi } from '../../backend/common/api/CalorieFriend/patients.api';

import { GoalData } from '../../interfaces/common/CalorieFriend/goals';
import { GoalsService } from './services/CalorieFriend/goal-service';
import { GoalsApi } from '../../backend/common/api/CalorieFriend/goals.api';

import {
  CustomFoodData,
  CustomPublicFoodData,
} from '../../interfaces/common/CalorieFriend/custom-food';
import {
  CustomFoodService,
  CustomPublicFoodService,
} from './services/CalorieFriend/custom-food.service';
import {
  CustomFoodApi,
  CustomPublicFoodApi,
} from './api/CalorieFriend/custom-food.api';

import { FoodData } from '../../interfaces/common/CalorieFriend/food';
import { FoodService } from './services/CalorieFriend/food.service';
import { FoodApi } from '../../backend/common/api/CalorieFriend/food.api';
import { SubscriptionPlanData } from '../../interfaces/common/CalorieFriend/subscriptionPlan';
import { SubscriptionPlansService } from './services/CalorieFriend/subscriptionPlans.service';
import { SubscriptionPlansApi } from './api/CalorieFriend/subscriptionPlans.api';

import {
  FitnessPlanData,
  MealPlanData,
  ProgramTemplateData,
} from '../../interfaces/common/CalorieFriend/plan';
import {
  FitnessPlansService,
  MealPlansService,
  ProgramTemplateService,
} from './services/CalorieFriend/plan.service';
import {
  FitnessPlansApi,
  MealPlansApi,
  ProgramTemplateApi,
} from '../../backend/common/api/CalorieFriend/plan.api';
import { BiometricHistoryData } from '../../interfaces/common/CalorieFriend/biometric-history';
import { BiometricHistoryService } from './services/CalorieFriend/biometric-history.service';
import { BiometricHistoryApi } from './api/CalorieFriend/biometric-history.api';
import { AssessmentData } from '../../interfaces/common/CalorieFriend/assessment';
import { AssessmentsService } from './services/CalorieFriend/assessments.service';
import { AssessmentApi } from './api/CalorieFriend/assessment.api';
import { PendingUserService } from './services/CalorieFriend/pendingUsers-service';
import { PendingUserApi } from './api/CalorieFriend/pendingUser.api';
import { PaymentApi } from './api/CalorieFriend/payment.api';
import { HiddenSimilarFoodService } from './services/CalorieFriend/hidden-similar-food-service';
import { HiddenSimilarFoodData } from 'app/@core/interfaces/common/CalorieFriend/hidden-similar-food';
import { HiddenSimilarFoodApi } from './api/CalorieFriend/hidden-similar-food.api';
import { TutorialData } from 'app/@core/interfaces/common/CalorieFriend/tutorial';
import { TutorialService } from './services/CalorieFriend/tutorial-service';
import { TutorialApi } from './api/CalorieFriend/tutorial.api';
import { ExerciseData } from 'app/@core/interfaces/common/CalorieFriend/exercise';
import { ExerciseService } from './services/CalorieFriend/exercise-service';
import { CustomExerciseData } from 'app/@core/interfaces/common/CalorieFriend/custom-exercise';
import { ExerciseApi } from './api/CalorieFriend/exercise.api';
import { CustomExerciseApi } from './api/CalorieFriend/custom-exercise.api';
import { CustomExerciseService } from './services/CalorieFriend/custom-exercise.service';
import { ExerciseLogData } from 'app/@core/interfaces/common/CalorieFriend/exerciseLog';
import { ExerciseLogService } from './services/CalorieFriend/exerciseLog-service';
import { ExerciseProgressData } from 'app/@core/interfaces/common/CalorieFriend/exercise-progress';
import { ExerciseProgressService } from './services/CalorieFriend/exercise-progress.service';
import { ExerciseLogApi } from './api/CalorieFriend/exerciseLog.api.';
import { ExerciseProgressApi } from './api/CalorieFriend/exercise-progress.api';
import { WorkoutApi } from './api/CalorieFriend/workout.api';
import { FoodDiaryService } from './services/CalorieFriend/food-diary.service.';
import { FoodDiaryData } from 'app/@core/interfaces/common/CalorieFriend/food-diary';
import { FoodDiaryApi } from './api/CalorieFriend/food-diary.api';
import { PaymentSettingsApi } from './api/CalorieFriend/payment-settings.api';
import { InvoicesApi } from './api/CalorieFriend/invoices.api';
import { RecurringInvoicesApi } from './api/CalorieFriend/recurring-invoices.api';
import { ChatService } from '../../../@chat/chat.service';
import { MacroGoalsData } from 'app/@core/interfaces/common/CalorieFriend/macro-goals';
import { MacroGoalsService } from './services/CalorieFriend/macro-goals.service';
import { MacroGoalsApi } from './api/CalorieFriend/macro-goals.api';
import { PatientSubscriptionsMetaApi } from './api/CalorieFriend/patient-subscriptions-meta.api';
import { PatientSubscriptionsDataApi } from './api/CalorieFriend/patient-subscriptions-data.api';
import { WorkoutTimerService } from './services/CalorieFriend/workout-timmer.service';
import { AppSettingsApi } from './api/appSettings.api';
import { AppSettingsData } from 'app/@core/interfaces/common/appSettings';
import { AppSettingsService } from './services/appSettings.service';
import { AssessmentSettingsApi } from './api/CalorieFriend/assessment-settings.api';
import { AssessmentSettingService } from './services/CalorieFriend/assessment-settings.service';

const API = [
  UsersApi,
  CountriesApi,
  SettingsApi,
  HttpService,
  PatientsApi,
  GoalsApi,
  FoodApi,
  MealPlansApi,
  FitnessPlansApi,
  ProgramTemplateApi,
  SubscriptionPlansApi,
  BiometricHistoryApi,
  AssessmentApi,
  CustomFoodApi,
  CustomPublicFoodApi,
  PendingUserApi,
  PaymentApi,
  HiddenSimilarFoodApi,
  TutorialApi,
  ExerciseApi,
  CustomExerciseApi,
  ExerciseLogApi,
  ExerciseProgressApi,
  FoodDiaryApi,
  WorkoutApi,
  MacroGoalsApi,
  PaymentSettingsApi,
  InvoicesApi,
  RecurringInvoicesApi,
  PatientSubscriptionsMetaApi,
  PatientSubscriptionsDataApi,
  AppSettingsApi,
  AssessmentSettingsApi,
];

const SERVICES = [
  { provide: UserData, useClass: UsersService },
  { provide: AppSettingsData, useClass: AppSettingsService},
  { provide: CountryData, useClass: CountriesService },
  { provide: SettingsData, useClass: SettingsService },
  { provide: PatientData, useClass: PatientsService },
  { provide: GoalData, useClass: GoalsService },
  { provide: FoodData, useClass: FoodService },
  { provide: MealPlanData, useClass: MealPlansService },
  { provide: FitnessPlanData, useClass: FitnessPlansService },
  { provide: ProgramTemplateData, useClass: ProgramTemplateService },

  { provide: CustomFoodData, useClass: CustomFoodService },
  { provide: SubscriptionPlanData, useClass: SubscriptionPlansService },
  { provide: BiometricHistoryData, useClass: BiometricHistoryService },
  { provide: AssessmentData, useClass: AssessmentsService },
  { provide: CustomPublicFoodData, useClass: CustomPublicFoodService },
  { provide: PendingUserService, useClass: PendingUserService },
  { provide: HiddenSimilarFoodData, useClass: HiddenSimilarFoodService },
  { provide: TutorialData, useClass: TutorialService },
  { provide: ExerciseData, useClass: ExerciseService },
  { provide: CustomExerciseData, useClass: CustomExerciseService },
  { provide: ExerciseLogData, useClass: ExerciseLogService },
  { provide: ExerciseProgressData, useClass: ExerciseProgressService },
  { provide: FoodDiaryData, useClass: FoodDiaryService },
  { provide: MacroGoalsData, useClass: MacroGoalsService},
  ChatService,
  WorkoutTimerService,
  AssessmentSettingService,
];

@NgModule({
  imports: [CommonModule, NbAuthModule],
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders<CommonBackendModule> {
    return {
      ngModule: CommonBackendModule,
      providers: [...API, ...SERVICES],
    };
  }
}
